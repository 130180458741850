import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  box: {
    width: '544px',
    minHeight: '640px',
    padding: '24px 40px',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    marginBottom: '24px',
    textAlign: 'left',
  },
  row: {
    marginBottom: '16px',
  },
  rowGroup: {
    marginBottom: '24px',
  },
  footer: {
    marginTop: '27px',
  },
  button: {
    padding: '7px 47px',
    marginRight: '10px',
  },
  description: {
    '& textarea': {
      minHeight: '74px',
    },
  },
  commentCaption: {
    color: COLORS.black60,
  },
  groupLabel: {
    marginBottom: '14px',
  },
  checkboxLabel: {
    marginLeft: 0,
    '&:not(:last-child)': {
      marginRight: '57px',
    },
  },
  radioLabel: {
    marginLeft: 0,
    '&:not(:last-child)': {
      marginRight: '15px',
    },
  },
  documentAttachedLink: {
    justifyContent: 'space-between',
    marginTop: '7px',
  },
  documentHelperErrorText: {
    marginTop: '7px',
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fileFieldWrapper: {
    flexShrink: 0,
    marginRight: '30px',
  },
  uploadFileCaption: {
    maxWidth: '300px',
    color: COLORS.black40,
  },
};

export default styles;
