import { useState } from 'react';
import { Alert, Button, Typography } from '@mui/material';
import { isNil, isEmpty, head } from 'ramda';

import { isAxiosError, isUnprocessedEntityError } from 'src/utils/responseErrors';
import { isString } from 'src/utils/string';
import clsx from 'src/utils/clsx';
import useModals from 'src/hooks/useModals';
import useLoading from 'src/hooks/useLoading';
import Box from 'src/components/Box';

import styles from './styles';

type ConfirmationDialogProps = {
  title?: string;
  description?: string | React.ReactNode | string[];
  confirmButtonText: string;
  dismissButtonText?: string;
  noConfirm?: boolean;
  onConfirm?: () => Promise<void>;
  onDismiss?: () => Promise<void>;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = props => {
  const {
    title = 'Are you sure?',
    description = '',
    confirmButtonText,
    dismissButtonText = 'Cancel',
    noConfirm = false,
    onConfirm = null,
    onDismiss = null,
  } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { hideAllModals } = useModals();
  const { funcWithLoading: onConfirmWithLoading, isPending: isOnConfirmPending } = useLoading(onConfirm);
  const { funcWithLoading: onDismissWithLoading, isPending: isOnDismissPending } = useLoading(onDismiss);

  const handleConfirm = async () => {
    try {
      if (onConfirm) {
        await onConfirmWithLoading();
      }
      hideAllModals();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        setFormError(head(error.response.data.errors.nonFieldErrors));
      }
    }
  };

  const handleDismiss = async () => {
    try {
      if (!isNil(onDismiss)) {
        await onDismissWithLoading();
        return;
      }
      hideAllModals();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        setFormError(head(error.response.data.errors.nonFieldErrors));
      }
    }
  };

  const renderDescriptionItem = (value: string | React.ReactNode) => {
    if (isString(value)) {
      return (
        <Typography variant="body1" component="p">
          {value}
        </Typography>
      );
    }

    return value;
  };

  const descriptionContent = Array.isArray(description)
    ? description.map(value => renderDescriptionItem(value))
    : renderDescriptionItem(description);

  const wrapperStyles = clsx(styles.wrapper, [[{ minHeight: 'initial' }, noConfirm]]);
  const buttonsContainerStyles = clsx(styles.buttons, [[{ justifyContent: 'end' }, noConfirm]]);
  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <Box sx={wrapperStyles}>
        <Typography variant="h3" sx={styles.title}>
          {title}
        </Typography>
        {!isEmpty(description) && <Box sx={styles.description}>{descriptionContent}</Box>}
        <Box sx={buttonsContainerStyles}>
          {!noConfirm && (
            <Button
              variant="contained"
              sx={styles.button}
              onClick={handleConfirm}
              disabled={isOnConfirmPending || isOnDismissPending}
            >
              {confirmButtonText}
            </Button>
          )}

          <Button
            variant="outlined"
            sx={styles.button}
            onClick={handleDismiss}
            disabled={isOnConfirmPending || isOnDismissPending}
          >
            {dismissButtonText}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ConfirmationDialog;
