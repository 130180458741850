import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import EngagementTimeSheetsRepository, {
  IndexParams,
  PartialUpdateParams,
  MultipleUpdateParams,
} from 'src/repositories/manager/TimeSheetsRepository';
import { Meta } from 'src/types/meta';
import { TotalEngagementTimeSheet } from 'src/types/resources/EngagementTimeSheet';
import { ManagerEngagementTimeSheetDates } from 'src/types/resources/ManagerEngagementTimeSheetDates';
import { ListResponse, SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { addIdToEngagementTimeSheetDates } from 'src/utils/engagementTimeSheets';

export type EngagementTimeSheetsSliceStateType = {
  meta: Meta;
  totalEngagementTimeSheet: TotalEngagementTimeSheet;
  adjustmentsTotal:
    | {
        fetchStatus: Exclude<FetchStatus, FetchStatus.fulfilled>;
      }
    | {
        fetchStatus: FetchStatus.fulfilled;
        data: TotalEngagementTimeSheet;
      };
  engagementTimeSheetDates: ManagerEngagementTimeSheetDates[];
  isAdjustableTimesheets: boolean;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  partialUpdate: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  multipleUpdate: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  total: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  checkPendingTimeSheets: {
    fetchStatus: FetchStatus;
    periodContainsPendingTimeSheets: null | boolean;
    error: unknown;
  };
};

export type EngagementTimeSheetsSliceActionsType = {
  loadEngagementTimeSheetDates: (params?: IndexParams) => {
    unwrap: () => Promise<ListResponse<ManagerEngagementTimeSheetDates>>;
  };
  loadTotalEngagementTimeSheet: (params?: IndexParams) => {
    unwrap: () => Promise<SingleResponse<TotalEngagementTimeSheet>>;
  };
  loadAdjustmentsTotal: (params?: Partial<IndexParams>) => {
    unwrap: () => Promise<SingleResponse<TotalEngagementTimeSheet>>;
  };
  partialUpdateEngagementTimeSheetDate: (params: PartialUpdateParams) => { unwrap: () => void };
  updateEngagementTimeSheetDates: (params: MultipleUpdateParams) => { unwrap: () => Promise<unknown> };
  resetEngagementTimeSheetDates: () => void;
  checkPeriodForPendingTimeSheets: (params?: IndexParams) => {
    unwrap: () => Promise<SingleResponse<{ exists: boolean }>>;
  };
};

const initialState: EngagementTimeSheetsSliceStateType = {
  meta: {} as Meta,
  totalEngagementTimeSheet: {} as TotalEngagementTimeSheet,
  adjustmentsTotal: {
    fetchStatus: FetchStatus.idle,
  },
  engagementTimeSheetDates: [],
  isAdjustableTimesheets: false,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  partialUpdate: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  multipleUpdate: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  total: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  checkPendingTimeSheets: {
    fetchStatus: FetchStatus.idle,
    periodContainsPendingTimeSheets: null,
    error: null,
  },
};

export const loadEngagementTimeSheetDates = createAsyncAction(
  'manager/engagementTimeSheetDates/index',
  EngagementTimeSheetsRepository.index,
);
export const loadTotalEngagementTimeSheet = createAsyncAction(
  'manager/engagementTimeSheetDates/total',
  EngagementTimeSheetsRepository.total,
);
export const loadAdjustmentsTotal = createAsyncAction(
  'manager/adjustments/total',
  EngagementTimeSheetsRepository.adjustmentsTotal,
);
export const partialUpdateEngagementTimeSheetDate = createAsyncAction(
  'manager/engagementTimeSheetDates/partialUpdate',
  EngagementTimeSheetsRepository.partialUpdate,
);
export const updateEngagementTimeSheetDates = createAsyncAction(
  'manager/engagementTimeSheetDates/multipleUpdate',
  EngagementTimeSheetsRepository.multipleUpdate,
);
export const checkPeriodForPendingTimeSheets = createAsyncAction(
  'manager/engagementTimeSheetDates/checkForPendingTimeSheets',
  EngagementTimeSheetsRepository.checkPendingTimeSheets,
);

const EngagementTimeSheetsSlice = createSlice({
  name: 'manager/engagementTimeSheets',
  initialState,
  reducers: {
    resetEngagementTimeSheetDates(state) {
      state.engagementTimeSheetDates = [];
      state.isAdjustableTimesheets = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadEngagementTimeSheetDates.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadEngagementTimeSheetDates.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.engagementTimeSheetDates = payload.results.map(engagementTimeSheetDate => ({
        ...engagementTimeSheetDate,
        timesheetDates: addIdToEngagementTimeSheetDates(engagementTimeSheetDate.timesheetDates),
      }));
      state.isAdjustableTimesheets = payload.results.some(
        engagementTimeSheetDate => engagementTimeSheetDate.isAdjustableTimesheets === true,
      );
    });
    builder.addCase(loadEngagementTimeSheetDates.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(partialUpdateEngagementTimeSheetDate.pending, state => {
      state.partialUpdate.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(partialUpdateEngagementTimeSheetDate.fulfilled, state => {
      state.partialUpdate.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(partialUpdateEngagementTimeSheetDate.rejected, state => {
      state.partialUpdate.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(updateEngagementTimeSheetDates.pending, state => {
      state.multipleUpdate.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateEngagementTimeSheetDates.fulfilled, state => {
      state.multipleUpdate.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateEngagementTimeSheetDates.rejected, state => {
      state.multipleUpdate.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadTotalEngagementTimeSheet.pending, state => {
      state.total.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTotalEngagementTimeSheet.fulfilled, (state, { payload }) => {
      state.total.fetchStatus = FetchStatus.fulfilled;
      state.totalEngagementTimeSheet = payload.data;
    });
    builder.addCase(loadTotalEngagementTimeSheet.rejected, state => {
      state.total.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadAdjustmentsTotal.pending, state => {
      state.adjustmentsTotal.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadAdjustmentsTotal.rejected, state => {
      state.adjustmentsTotal.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(loadAdjustmentsTotal.fulfilled, (state, { payload }) => {
      state.adjustmentsTotal = {
        fetchStatus: FetchStatus.fulfilled,
        data: payload.data,
      };
    });

    builder.addCase(checkPeriodForPendingTimeSheets.pending, state => {
      state.checkPendingTimeSheets.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(checkPeriodForPendingTimeSheets.rejected, state => {
      state.checkPendingTimeSheets.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(checkPeriodForPendingTimeSheets.fulfilled, (state, { payload }) => {
      state.checkPendingTimeSheets.fetchStatus = FetchStatus.fulfilled;
      state.checkPendingTimeSheets.periodContainsPendingTimeSheets = payload.data.exists;
    });
  },
});

const {
  actions: { resetEngagementTimeSheetDates },
} = EngagementTimeSheetsSlice;

export { resetEngagementTimeSheetDates };

export default EngagementTimeSheetsSlice.reducer;
