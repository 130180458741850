import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  box: {
    width: '544px',
    minHeight: '308px',
    padding: '24px 40px',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    marginBottom: '24px',
  },
  row: {
    marginBottom: '16px',
  },
  footer: {
    marginTop: '27px',
  },
  button: {
    padding: '7px 47px',
    marginRight: '10px',
  },
  documentDescription: {
    marginTop: '4px',
    color: COLORS.black60,
  },
  divider: {
    margin: '24px 0',
  },
  fileButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
  },
  fileButton: {
    flexShrink: 0,
    minWidth: '170px',
  },
  fileButtonDescription: {
    color: COLORS.black40,
  },
  documentAttachedLink: {
    justifyContent: 'space-between',
    marginTop: '7px',
  },
  documentHelperErrorText: {
    marginTop: '7px',
  },
  checkboxControl: {
    marginLeft: 0,
    alignItems: 'flex-start',
  },
};

export default styles;
