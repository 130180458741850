import { Button, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useState } from 'react';

import {
  attributesToSubmit,
  ConfirmPasswordFormData,
  initialValues,
  validationSchema,
} from 'src/forms/confirmPassword';
import useSnackbar from 'src/hooks/useSnackbar';
import useUsers from 'src/hooks/useUsers';
import useModals from 'src/hooks/useModals';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import PasswordField from 'src/components/PasswordField';
import Box from 'src/components/Box';

import styles from './styles';

type ConfirmPasswordProps = Record<string, unknown> & {
  uuid: string;
  token: string;
};

const ConfirmPassword: React.FC<ConfirmPasswordProps> = props => {
  const { uuid, token } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { hideModal } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();
  const { confirmPassword } = useUsers();

  const handleSubmit = async (
    formData: ConfirmPasswordFormData,
    { setErrors }: FormikHelpers<ConfirmPasswordFormData>,
  ) => {
    const params = attributesToSubmit(formData, uuid, token);
    try {
      await confirmPassword(params).unwrap();
      hideModal();
      enqueueSuccessSnackbar('The password was confirmed');
    } catch (error) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(error.response.data.errors.nonFieldErrors);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    handleSubmit: handleFormikSubmit,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
  } = useFormik<ConfirmPasswordFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form noValidate onSubmit={handleFormikSubmit}>
      <Box sx={styles.box}>
        <Typography variant="h3">Create your password</Typography>
        <Box sx={styles.row}>
          <PasswordField
            id="password"
            label="Password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            isTouched={touched.password}
            isRequirementsVisible
          />
        </Box>
        <Box sx={styles.row}>
          <PasswordField
            id="confirmPassword"
            label="Confirm password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmPassword}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />
        </Box>
        <Typography variant="body1" color="error">
          {formError}
        </Typography>
        <Box sx={styles.buttons}>
          <Button variant="contained" type="submit" sx={styles.button} disabled={isSubmitting}>
            Confirm
          </Button>
          <Button variant="outlined" onClick={hideModal} sx={styles.button}>
            Cancel
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ConfirmPassword;
