import { isNil } from 'ramda';
import * as yup from 'yup';

import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';
import { CreateDepartmentVariant } from 'src/enums/Department';
import { ValidationError } from 'src/enums/Validation';
import { Department } from 'src/types/resources/Department';

export type CreateDepartmentFormData = {
  name: string;
  depId: string;
  description: string;
  submitVariant?: CreateDepartmentVariant;
};

export type CreateDepartmentFormDataToSubmit = Omit<CreateDepartmentFormData, 'submitVariant' | 'departmentId'> & {
  organizationId: number;
  depId: string;
};

export const validationFields = {
  name: yup.string().max(20, getMaxFieldLengthErrorMessage(20)).required(ValidationError.default).default(''),
  depId: yup.string().max(10, getMaxFieldLengthErrorMessage(10)).default(''),
  description: yup.string().nullable().max(100, getMaxFieldLengthErrorMessage(100)).default(null),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (department?: Department): CreateDepartmentFormData => {
  if (isNil(department)) {
    return validationSchema.getDefault();
  }
  const { name, depId, description } = department;

  return {
    name,
    depId,
    description,
  };
};

export const attributesToSubmit = (
  values: CreateDepartmentFormData,
  organizationId: number,
): CreateDepartmentFormDataToSubmit => {
  return {
    ...values,
    organizationId,
  };
};
