import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    padding: { tablet: '33px 40px 40px' },
    minHeight: '300px',
  },
  title: {
    boxShadow: 'inset 0px -1px 0px rgba(97, 148, 185, 0.25)',
    paddingBottom: '12px',
  },
  addButton: {
    '& svg': {
      fill: 'currentColor',
    },
  },
  buttons: {
    marginTop: '40px',
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  list: {
    margin: '24px 0 31px',
    padding: '0 0 20px',
    listStyleType: 'disc',
    overflowX: 'scroll',
  },
  listItem: {
    display: 'list-item',
    padding: '14px 0 18px',
    borderBottom: `1px solid ${COLORS.black10}`,
    '&:last-child': {
      borderBottom: 0,
    },
    width: '760px',
  },
  listItemContent: {
    display: 'flex',
  },
  documentInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '225px',
  },
  documentInfoEmpty: {
    justifyContent: 'center',
  },
  documentPreviewLinkWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  documentPreviewLink: {
    marginTop: '7px',
  },
  documentFields: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  documentLabel: {
    display: 'inline-block',
  },
  documentFormats: {
    marginBottom: '40px',
  },
  documentDate: {
    width: '150px',
    '& .MuiInput-root': {
      height: '38px',
    },
  },
  documentHelperErrorText: {
    marginTop: '7px',
  },
  deleteButton: {
    marginLeft: '10px',
    position: 'relative',
    top: '3px',
    '& svg': {
      fill: COLORS.red100,
    },
  },
};

export default styles;
