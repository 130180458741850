import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Typography, TextField, Button, Alert } from '@mui/material';
import { isNil, head } from 'ramda';

import { ReactComponent as DeleteIcon } from 'src/assets/images/deleteIcon.svg';
import { ModalLayout, ModalType } from 'src/enums/ModalType';
import useModals from 'src/hooks/useModals';
import useSnackbar from 'src/hooks/useSnackbar';
import useGroups from 'src/hooks/useGroups';
import { Group } from 'src/types/resources/Group';
import { attributesToSubmit, initialValues, GroupFormData, validationSchema } from 'src/forms/organizationGroup';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import Box from 'src/components/Box';

import styles from './styles';

type GroupFormProps = {
  group?: Group;
};

const GroupForm: React.FC<GroupFormProps> = props => {
  const { group } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { createGroup, updateGroup, deleteGroup, isGroupsDeletePending, loadGroups } = useGroups();
  const { showModal, hideModal } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();

  const isAddForm = isNil(group);
  const formTitle = isAddForm ? 'Create Group' : 'Edit Group';
  const submitButtonText = isAddForm ? 'Create' : 'Save';
  const snackbarText = isAddForm ? 'Group was added' : 'Group was edited';

  const handleSubmit = async (formData: GroupFormData, { setErrors }: FormikHelpers<GroupFormData>) => {
    const params = attributesToSubmit(formData);
    setFormError(null);
    try {
      if (isAddForm) {
        await createGroup(params).unwrap();
      } else {
        await updateGroup({ id: group.id, params }).unwrap();
      }
      enqueueSuccessSnackbar(snackbarText);
      loadGroups();
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(error.response.data.errors.nonFieldErrors);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const handleGroupDelete = (groupId: number) => async () => {
    try {
      await deleteGroup({ id: groupId });
      loadGroups();
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        setFormError(head(error.response.data.errors.nonFieldErrors));
      }
    }
  };

  const handleDeleteModalShow = () => {
    showModal({
      type: ModalType.ConfirmationDialog,
      modalLayout: ModalLayout.talentModals,
      props: {
        description: 'You are about to delete a group.',
        confirmButtonText: 'Delete',
        onConfirm: handleGroupDelete(group.id),
      },
    });
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit: handleFormikSubmit,
  } = useFormik<GroupFormData>({
    initialValues: initialValues(group),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.wrapper}>
          <Typography variant="h3" sx={styles.title}>
            {formTitle}
          </Typography>

          <Box sx={styles.row}>
            <TextField
              id="name"
              label="Group Name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </Box>

          <Box sx={styles.footer}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
              {submitButtonText}
            </Button>
            <Button variant="outlined" onClick={hideModal} sx={styles.button}>
              Cancel
            </Button>
            {!isAddForm && (
              <Button
                variant="text"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteModalShow}
                sx={styles.buttonDelete}
                disabled={isGroupsDeletePending}
              >
                Delete Group
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </>
  );
};

export default GroupForm;
