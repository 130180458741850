import { EngagementPO, NewEngagementPOSchema, EngagementPOSchema } from 'src/types/resources/EngagementPO';
import { SingleResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  page?: number;
  parentLookupEngagementId: number;
};

export type CreateParams = {
  parentLookupEngagementId: number;
  params: NewEngagementPOSchema;
};

export type PartialUpdateParams = {
  id: ID;
  parentLookupEngagementId: number;
  params: Partial<EngagementPOSchema>;
};

export default {
  create({ params }: CreateParams): Promise<SingleResponse<EngagementPO>> {
    const url = apiRoutes.organizationAdmin.engagementPOsPath();
    return Fetcher.post(url, params);
  },
  partialUpdate({ id, parentLookupEngagementId, params }: PartialUpdateParams): Promise<SingleResponse<EngagementPO>> {
    const url = apiRoutes.manager.engagementPOPath(parentLookupEngagementId, id);
    return Fetcher.patch(url, params);
  },
};
