import { List, ListItem, Typography } from '@mui/material';

import { FilterLabelsType } from '../../types';

import { getFilterListMapForTimeTrackingModal } from './utils/getFilterListMapForTimeTrackingModal';
import { TimeTrackingApproveModalType } from './constants/TimeTrackingModalConstatants';

interface TimeTrackingModalFilterListProps {
  filterLabels: FilterLabelsType;
  modalType: TimeTrackingApproveModalType;
}

const TimeTrackingModalFilterList = (props: TimeTrackingModalFilterListProps) => {
  const { filterLabels, modalType } = props;

  const filterListMap = getFilterListMapForTimeTrackingModal(filterLabels, modalType);
  const filtersArray = Object.entries(filterListMap).filter(([, values]) => values.length);

  if (filtersArray.length === 0) {
    return null;
  }

  return (
    <Typography>
      <Typography variant="body1" display="inline-block" fontWeight="bold" mr="4px">
        Filters Applied:
      </Typography>
      {filtersArray.length === 1 ? (
        filtersArray.map(([title, values]) => (
          <Typography key={title} display="inline-block">
            {title}: {values.join(', ')}
          </Typography>
        ))
      ) : (
        <List sx={{ listStyleType: 'disc' }}>
          {filtersArray.map(([title, values]) => (
            <ListItem key={title} sx={{ p: 0, display: 'list-item', ml: '20px' }}>
              {title}: {values.join(', ')}
            </ListItem>
          ))}
        </List>
      )}
    </Typography>
  );
};

export default TimeTrackingModalFilterList;
