import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from '../palette';

type MuiAutocompleteType = {
  defaultProps: ComponentsProps['MuiAutocomplete'];
  styleOverrides: ComponentsOverrides['MuiAutocomplete'];
};

const MuiAutocomplete: MuiAutocompleteType = {
  styleOverrides: {
    root: {
      '& .MuiInput-root': {
        flexWrap: 'nowrap',
        padding: '10px 12px',
      },
    },
    hasPopupIcon: {
      '& svg': {
        fill: COLORS.black60,
      },
    },
    endAdornment: {
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
    },
  },
  defaultProps: {},
};

export default MuiAutocomplete;
