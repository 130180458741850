import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  title: {
    padding: '10px 12px',
  },
  multilineTextCrop: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
  lineClamp1: {
    WebkitLineClamp: '1',
  },
  lineClamp2: {
    WebkitLineClamp: '2',
  },
};

export default styles;
