import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';

import { UserRole } from 'src/enums/UserRole';
import { CreateOrganizationUserFormData } from 'src/forms/createOrganizationUser';
import UserRolePresenter from 'src/presenters/UserRolePresenter';

type RoleRadioControlProps = RadioGroupProps & {
  userRole: UserRole;
  isAddForm: boolean;
  error: string;
  formValues: CreateOrganizationUserFormData;
  initialFormValues: CreateOrganizationUserFormData;
  setFormValues: (values: React.SetStateAction<CreateOrganizationUserFormData>, shouldValidate?: boolean) => void;
};

const roleOptions = [
  {
    value: UserRole.OrganizationAdmin,
    label: UserRolePresenter.description(UserRole.OrganizationAdmin),
    availableRoles: [UserRole.OrganizationAdmin, UserRole.Manager],
  },
  {
    value: UserRole.Manager,
    label: UserRolePresenter.description(UserRole.Manager),
    availableRoles: [UserRole.OrganizationAdmin, UserRole.Manager],
  },
  {
    value: UserRole.Contact,
    label: UserRolePresenter.description(UserRole.Contact),
    availableRoles: [UserRole.Contact],
  },
];

const RoleRadioControl: React.FC<RoleRadioControlProps> = props => {
  const { userRole, isAddForm, error, formValues, initialFormValues, setFormValues, ...restProps } = props;

  const handleUserRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedRole = event.target.value as UserRole;
    const newValues = {
      ...formValues,
      role: selectedRole,
      contactType: initialFormValues.contactType,
      customContactType: initialFormValues.contactType,
    } as CreateOrganizationUserFormData;

    if (UserRolePresenter.isAdminRole(selectedRole)) {
      setFormValues({
        ...newValues,
        departmentId: null,
        hasPermissionViewRate: true,
        hasPermissionSendEngagement: true,
      });
    }

    if (UserRolePresenter.isManagerRole(selectedRole)) {
      setFormValues({
        ...newValues,
        departmentId: formValues.departmentId || initialFormValues.departmentId,
        hasPermissionViewRate: isAddForm ? false : initialFormValues.hasPermissionViewRate,
        hasPermissionSendEngagement: isAddForm ? false : initialFormValues.hasPermissionSendEngagement,
      });
    }

    if (UserRolePresenter.isContactRole(selectedRole)) {
      setFormValues({
        ...newValues,
        departmentId: formValues.departmentId || initialFormValues.departmentId,
        hasPermissionViewRate: false,
        hasPermissionSendEngagement: false,
      });
    }
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Role</FormLabel>
      <RadioGroup onChange={handleUserRoleChange} {...restProps}>
        {roleOptions.map(roleOption => {
          const isAvailableRole = roleOption.availableRoles.includes(userRole);
          return (
            <FormControlLabel
              key={roleOption.value}
              value={roleOption.value}
              control={<Radio />}
              label={roleOption.label}
              disabled={!isAddForm && !isAvailableRole}
            />
          );
        })}
      </RadioGroup>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default RoleRadioControl;
