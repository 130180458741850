import * as yup from 'yup';

import { ValidationError } from 'src/enums/Validation';
import { SavedSearchParam } from 'src/types/resources/SavedSearch';
import { SearchResourcesFormData } from 'src/forms/SearchResources';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';

export type SaveResourcesSearchFormData = {
  name: string;
};
export type SaveResourcesSearchFormDataToSubmit = {
  name: string;
  params: SavedSearchParam[];
};

export const validationFields = {
  name: yup
    .string()
    .max(50, getMaxFieldLengthErrorMessage(50))
    .required(ValidationError.default)
    .default('')
    .matches(/^\S+(\s+\S*)*$/g, '* This field cannot start from blankspaces'),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (): SaveResourcesSearchFormData => {
  return validationSchema.getDefault();
};

export const attributesToSubmit = (
  values: SaveResourcesSearchFormData,
  params: SearchResourcesFormData,
): SaveResourcesSearchFormDataToSubmit => {
  return {
    name: values.name,
    params: [
      {
        name: 'specializations',
        value: JSON.stringify(params.specializations),
      },
      {
        name: 'availability',
        value: JSON.stringify(params.availability),
      },
      {
        name: 'city',
        value: params.city,
      },
      {
        name: 'firstName',
        value: params.firstName,
      },
      {
        name: 'lastName',
        value: params.lastName,
      },
    ],
  };
};
