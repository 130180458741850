import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    width: {
      mobile: '100%',
      tablet: '800px',
    },
    padding: '33px 40px 40px',
  },
  title: {
    boxShadow: 'inset 0px -1px 0px rgba(97, 148, 185, 0.25)',
    paddingBottom: '12px',
  },
  row: {
    margin: '16px 0',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '40px',
    flexWrap: 'wrap',
  },
  button: {
    width: {
      mobile: '100%',
      tablet: 'auto',
    },
    padding: '8px 48px',
  },
};

export default styles;
