import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiTableContainerType = {
  defaultProps: ComponentsProps['MuiTableContainer'];
  styleOverrides: ComponentsOverrides['MuiTableContainer'];
};

const MuiTableContainer: MuiTableContainerType = {
  styleOverrides: {
    root: {
      '& table': {
        tableLayout: 'fixed',

        '& table': {
          '& thead': {
            zIndex: 999,
          },
        },

        '& thead': {
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          backgroundColor: COLORS.white,
        },
      },

      '& .renderCell': {
        padding: 0,
        paddingLeft: '28px',
        border: 0,
      },

      '& .MuiTableContainer-root': {
        padding: 0,
        border: 0,
        borderRadius: 0,

        '& tr': {
          '&:last-of-type > td': {
            borderBottom: `1px solid ${COLORS.black10}`,
          },
        },
      },
    },
  },
  defaultProps: {},
};

export default MuiTableContainer;
