import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { ManagerRequiredDocument } from 'src/types/resources/RequiredDocument';
import { ListResponse, SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import RequiredDocumentsRepository, {
  IndexParams,
  CreateParams,
  UpdateParams,
  DeleteParams,
} from 'src/repositories/manager/RequiredDocumentsRepository';

export type RequiredDocumentsSliceStateType = {
  meta: Meta;
  requiredDocuments: ManagerRequiredDocument[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  partialUpdate: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  deactivate: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type RequiredDocumentsSliceActionsType = {
  loadRequiredDocuments: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<ManagerRequiredDocument>> };
  createRequiredDocument: (params: CreateParams) => { unwrap: () => Promise<SingleResponse<ManagerRequiredDocument>> };
  updateRequiredDocument: (params: UpdateParams) => { unwrap: () => Promise<SingleResponse<ManagerRequiredDocument>> };
  partialUpdateRequiredDocument: (params: UpdateParams) => {
    unwrap: () => Promise<SingleResponse<ManagerRequiredDocument>>;
  };
  deactivateRequiredDocument: (params: UpdateParams) => {
    unwrap: () => Promise<SingleResponse<ManagerRequiredDocument>>;
  };
  deleteRequiredDocument: (params: DeleteParams) => { unwrap: () => Promise<void> };
};

const initialState: RequiredDocumentsSliceStateType = {
  meta: {} as Meta,
  requiredDocuments: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  partialUpdate: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  deactivate: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadRequiredDocuments = createAsyncAction(
  'managerRequiredDocuments/index',
  RequiredDocumentsRepository.index,
);
export const createRequiredDocument = createAsyncAction(
  'managerRequiredDocuments/create',
  RequiredDocumentsRepository.create,
);
export const updateRequiredDocument = createAsyncAction(
  'managerRequiredDocuments/update',
  RequiredDocumentsRepository.update,
);
export const partialUpdateRequiredDocument = createAsyncAction(
  'managerRequiredDocuments/partialUpdate',
  RequiredDocumentsRepository.partialUpdate,
);
export const deactivateRequiredDocument = createAsyncAction(
  'managerRequiredDocuments/deactivate',
  RequiredDocumentsRepository.deactivate,
);
export const deleteRequiredDocument = createAsyncAction('managerRequiredDocuments/delete', (arg: DeleteParams) =>
  RequiredDocumentsRepository.delete(arg),
);

const RequiredDocumentsSlice = createSlice({
  name: 'organizationRequiredDocuments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadRequiredDocuments.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadRequiredDocuments.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.requiredDocuments = payload.results;
    });
    builder.addCase(loadRequiredDocuments.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(createRequiredDocument.fulfilled, (state, { payload }) => {
      state.requiredDocuments = state.requiredDocuments.concat([payload.data]);
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteRequiredDocument.fulfilled, (state, { meta }) => {
      state.requiredDocuments = state.requiredDocuments.filter(doc => doc.id !== meta.arg.id);
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addMatcher(
      isAnyOf(
        updateRequiredDocument.fulfilled,
        partialUpdateRequiredDocument.fulfilled,
        deactivateRequiredDocument.fulfilled,
      ),
      (state, { payload }) => {
        state.requiredDocuments = state.requiredDocuments.map(doc => (doc.id === payload.data.id ? payload.data : doc));
        state.delete.fetchStatus = FetchStatus.fulfilled;
      },
    );
  },
});

export default RequiredDocumentsSlice.reducer;
