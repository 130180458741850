import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiRadioType = {
  defaultProps: ComponentsProps['MuiRadio'];
  styleOverrides: ComponentsOverrides['MuiRadio'];
};

const MuiRadio: MuiRadioType = {
  styleOverrides: {
    root: {
      color: COLORS.black40,
      padding: '0',
      marginRight: '9px',
      '&.Mui-checked': {
        color: COLORS.orange5,
      },
      '& svg': {
        borderWidth: '2px',
      },
    },
  },
  defaultProps: {},
};

export default MuiRadio;
