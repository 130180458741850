import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiPaperType = {
  defaultProps: ComponentsProps['MuiPaper'];
  styleOverrides: ComponentsOverrides['MuiPaper'];
};

const MuiPaper: MuiPaperType = {
  styleOverrides: {
    root: {
      '&.datepickerWithHiddenHeader': {
        '& .MuiCalendarPicker-root > div:first-of-type': {
          display: 'none',
        },
      },
    },
  },
  defaultProps: {},
};

export default MuiPaper;
