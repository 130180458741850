import { TextFieldProps } from '@mui/material';

import { SelectItem } from 'src/utils/select';
import clsx from 'src/utils/clsx';
import Select from 'src/components/Select';

import styles from './styles';

type PurchaseOrderSelectProps = TextFieldProps & {
  values?: SelectItem[];
  isReadOnly?: boolean;
  isDisabled?: boolean;
};

const PurchaseOrderSelect: React.FC<PurchaseOrderSelectProps> = props => {
  const { isReadOnly = false, isDisabled = false, ...restProps } = props;

  return (
    <Select
      id="purchaseOrderId"
      type="text"
      sx={styles.timeTextField}
      SelectProps={{
        sx: clsx(styles.select, [
          [styles.poSelect, true],
          [styles.poSelectReadOnly, isReadOnly],
          [styles.poSelectDisabledValue, isDisabled],
        ]),
        inputProps: { sx: styles.selectInput },
        MenuProps: { sx: clsx(styles.selectMenu, [[styles.poSelectMenu, true]]) },
        readOnly: isReadOnly,
      }}
      {...restProps}
    />
  );
};

export default PurchaseOrderSelect;
