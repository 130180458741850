export enum ModalType {
  SentEmailConfirmation = 'SentEmailConfirmation',
  ConfirmPassword = 'ConfirmPassword',
  NewPassword = 'NewPassword',
  ResetPassword = 'ResetPassword',
  ConfirmNewUser = 'ConfirmNewUser',
  TalentOverview = 'TalentOverview',
  TalentDocuments = 'TalentDocuments',
  TalentProfileCommentsForm = 'TalentProfileCommentsForm',
  TalentWorkExperience = 'TalentWorkExperience',
  TalentWorkExperienceForm = 'TalentWorkExperienceForm',
  TalentExternalLinks = 'TalentExternalLinks',
  TalentExternalLinksForm = 'TalentExternalLinksForm',
  TalentSpecialization = 'TalentSpecialization',
  TalentSpecializationForm = 'TalentSpecializationForm',
  TalentAvailability = 'TalentAvailability',
  TalentAvailabilityForm = 'TalentAvailabilityForm',
  TalentCertificate = 'TalentCertificate',
  TalentCertificatePreview = 'TalentCertificatePreview',
  TalentCertificateForm = 'TalentCertificateForm',
  TalentAppliedMessage = 'TalentAppliedMessage',
  InviteUser = 'InviteUser',
  CreateOrganizationForm = 'CreateOrganizationForm',
  CreateOrganizationUserForm = 'CreateOrganizationUserForm',
  ToggleUserActivationForm = 'ToggleUserActivationForm',
  ToggleTalentActivationForm = 'ToggleTalentActivationForm',
  ToggleUserTSApprovalForm = 'ToggleUserTSApprovalForm',
  GroupForm = 'GroupForm',
  CreateSubgroupForm = 'CreateSubgroupForm',
  ContactInfoForm = 'ContactInfoForm',
  InviteToGroupForm = 'InviteToGroupForm',
  SpecializationSelectForm = 'SpecializationSelectForm',
  SelectTalent = 'SelectTalent',
  ChangeRateForm = 'ChangeRateForm',
  CreateLibraryDocumentForm = 'CreateLibraryDocumentForm',
  UploadEngagementRequiredDocumentForm = 'UploadEngagementRequiredDocumentForm',
  ShareGroupForm = 'ShareGroupForm',
  CreateDepartmentForm = 'CreateDepartmentForm',
  ManageSpecializationsForm = 'ManageSpecializationsForm',
  ConfirmationDialog = 'ConfirmationDialog',
  SaveResourcesSearchForm = 'SaveResourcesSearchForm',
  TalentNotFoundFeedbackForm = 'TalentNotFoundFeedbackForm',
  DeclineTimeSheetForm = 'DeclineTimeSheetForm',
  PhoneNumberForm = 'PhoneNumberForm',
  CreatePurchaseOrderForm = 'CreatePurchaseOrderForm',
  CreateTimeSheetEntryForm = 'CreateTimeSheetEntryForm',
  CreateTimeSheetAdjustmentForm = 'CreateTimeSheetAdjustmentForm',
  KnowledgeCenterFAQFeedbackForm = 'KnowledgeCenterFAQFeedbackForm',
  EngagementTermsForm = 'EngagementTermsForm',
  AppliedMessage = 'AppliedMessage',
  TimeTrackingBulkApprove = 'TimeTrackingBulkApprove',
}

export enum ModalLayout {
  default = 'default',
  talentModals = 'talentModals',
}
