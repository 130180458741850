import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { alpha } from '@mui/system';

import { COLORS } from 'src/theme/palette';

type MuiButtonType = {
  defaultProps: ComponentsProps['MuiButton'];
  styleOverrides: ComponentsOverrides['MuiButton'];
};

const MuiButton: MuiButtonType = {
  styleOverrides: {
    root: {
      borderRadius: '6px',
      textTransform: 'none',
      '& svg': {
        fill: 'currentColor',
      },
    },
    sizeSmall: {
      fontSize: 14,
      padding: '3px 11px',
    },
    sizeMedium: {
      fontSize: 14,
      padding: '7px 15px',
    },
    sizeLarge: {
      fontSize: 16,
      padding: '11px 24px',
    },
    text: {
      color: COLORS.orange5,
      border: '1px solid transparent',
      '&:hover, &:focus-visible': {
        backgroundColor: COLORS.orange10,
        borderColor: COLORS.orange8,
      },
      '&:active': {
        backgroundColor: COLORS.orange9,
      },
      '&.MuiButton-textError': {
        color: COLORS.red100,
        '&:hover, &:focus': {
          backgroundColor: alpha(COLORS.red100, 0.2),
          borderColor: alpha(COLORS.red100, 0.6),
        },
        '&:active': {
          backgroundColor: alpha(COLORS.red100, 0.1),
        },
      },
      '&.Mui-disabled': {
        color: COLORS.black40,
      },
    },
    outlined: {
      color: COLORS.black80,
      borderColor: COLORS.black20,
      backgroundColor: COLORS.white,
      '&:hover, &:focus': {
        backgroundColor: COLORS.black02,
        borderColor: COLORS.black20,
      },
      '&:active': {
        backgroundColor: COLORS.black10,
      },
      '&.Mui-disabled': {
        color: COLORS.black40,
        backgroundColor: COLORS.black02,
      },
    },
    contained: {
      backgroundColor: COLORS.orange5,
      '&:hover, &:focus': {
        backgroundColor: COLORS.orange4,
      },
      '&:active': {
        backgroundColor: COLORS.orange3,
      },
      '&.Mui-disabled': {
        color: COLORS.white,
        backgroundColor: COLORS.orange9,
      },
    },
  },
  defaultProps: {
    disableElevation: true,
    disableRipple: true,
  },
};

export default MuiButton;
