import { AxiosResponse } from 'axios';

import apiRoutes from 'src/routes/apiRoutes';
import { Comment, ProfileComment } from 'src/types/resources/Comment';
import TalentProfile from 'src/types/resources/TalentProfile';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';
import { CommentsFormDataToSubmit } from 'src/forms/talents/comments';

export type CreateParams = CommentsFormDataToSubmit;
export type UpdateParams = {
  id: Comment['id'];
  params: Partial<CreateParams>;
};

export type LinkToProfileParams = {
  profileId: TalentProfile['id'];
  commentId: Comment['id'];
};

export default {
  index(): Promise<ListResponse<Comment>> {
    const url = apiRoutes.organization.orgComment();
    return Fetcher.get(url);
  },
  create(params: CreateParams): Promise<SingleResponse<Comment>> {
    const url = apiRoutes.organization.orgComment();
    return Fetcher.post(url, params);
  },
  delete(id: ID): Promise<AxiosResponse> {
    const url = apiRoutes.organization.orgComment(id);
    return Fetcher.delete(url);
  },
  partialUpdate({ id, params }: UpdateParams): Promise<SingleResponse<Comment>> {
    const url = apiRoutes.organization.orgComment(id);
    return Fetcher.patch(url, params);
  },
  linkToProfile(params: LinkToProfileParams): Promise<SingleResponse<ProfileComment>> {
    const url = apiRoutes.organization.linkedToProfileComment();
    return Fetcher.post(url, params);
  },
};
