import UsersPresenter from 'src/presenters/UsersPresenter';

import useManagerTimeSheets from './manager/useTimeSheets';
import useOrgAdminTimeSheets from './organizationAdmin/useTimeSheets';
import useUsers from './useUsers';

const useOrgTimeSheets = () => {
  const { currentUser } = useUsers();

  const isOrgAdmin = UsersPresenter.hasOrganizationAdminAccess(currentUser);
  const isManager = UsersPresenter.hasManagerAccess(currentUser);
  if (!(isOrgAdmin || isManager)) {
    throw new Error('useOrgTimeSheets hook should be used only for Org Admins or Managers');
  }
  const currentUserHook = isOrgAdmin ? useOrgAdminTimeSheets : useManagerTimeSheets;

  return currentUserHook();
};

export default useOrgTimeSheets;
