import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';
import typography from 'src/theme/typography';

type MuiInputLabelType = {
  defaultProps: ComponentsProps['MuiInputLabel'];
  styleOverrides: ComponentsOverrides['MuiInputLabel'];
};

const MuiInputLabel: MuiInputLabelType = {
  styleOverrides: {
    standard: {
      position: 'relative',
      color: COLORS.black100,
      fontWeight: typography.button.fontWeight,
      fontSize: typography.button.fontSize,
      lineHeight: typography.button.lineHeight,
      textTransform: typography.button.textTransform,
      display: 'block',
      padding: '0',
      marginBottom: '4px',
      transform: 'none',

      '&&[data-shrink=false] + .MuiInputBase-formControl input::placeholder': {
        opacity: '0.6 !important',
      },
    },
  },
  defaultProps: {
    disableAnimation: true,
    shrink: false,
    variant: 'standard',
  },
};

export default MuiInputLabel;
