import { Link } from 'react-router-dom';
import { Typography, Breadcrumbs as MuiBreadcrumbs, Link as MuiLink, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import { ReactComponent as ArrowRightIcon } from 'src/assets/images/arrowRightIcon.svg';
import { isLastArrayIndex } from 'src/utils/keysConverter';
import Box from 'src/components/Box';

export type Crumb = {
  id?: number;
  label: string;
  path: string;
};

export type BreadcrumbsProps = {
  crumbs: Crumb[];
  renderItem?: (crumb: Crumb) => JSX.Element;
  sx?: SxProps<Theme>;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = props => {
  const { crumbs = [], renderItem, sx = {} } = props;

  const normalizedCrumbs = crumbs.filter(crumb => Boolean(crumb.label));

  return (
    <MuiBreadcrumbs sx={sx} separator={<ArrowRightIcon />} aria-label="breadcrumb">
      {normalizedCrumbs.map((crumb, index) => {
        const { label, path } = crumb;
        if (isLastArrayIndex(index, normalizedCrumbs)) {
          return (
            <Typography key={label} variant="button">
              {label}
            </Typography>
          );
        }

        if (renderItem) {
          return <Box key={label}>{renderItem(crumb)}</Box>;
        }

        return (
          <MuiLink component={Link} underline="hover" key={label} to={path}>
            {label}
          </MuiLink>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
