import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiTypographyType = {
  defaultProps: ComponentsProps['MuiTypography'];
  styleOverrides: ComponentsOverrides['MuiTypography'];
};

const MuiTypography: MuiTypographyType = {
  styleOverrides: {
    root: {},
  },
  defaultProps: {
    variant: 'body1',
  },
};

export default MuiTypography;
