import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { KnowledgeCenterFAQ } from 'src/types/resources/KnowledgeCenter';
import { ListResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import TalentsKnowledgeCenterFAQRepository, {
  IndexParams as TalentIndexParams,
} from 'src/repositories/talents/KnowledgeCenterFAQRepository';
import ManagerKnowledgeCenterFAQRepository, {
  IndexParams as ManagerIndexParams,
} from 'src/repositories/manager/KnowledgeCenterFAQRepository';

export type KnowledgeCenterFAQSliceStateType = {
  meta: Meta;
  faqItems: KnowledgeCenterFAQ[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type KnowledgeCenterFAQSliceActionsType = {
  loadTalentsKnowledgeCenterFAQs: (params?: TalentIndexParams) => {
    unwrap: () => Promise<ListResponse<KnowledgeCenterFAQ>>;
  };
  loadManagerKnowledgeCenterFAQs: (params?: ManagerIndexParams) => {
    unwrap: () => Promise<ListResponse<KnowledgeCenterFAQ>>;
  };
  resetKnowledgeCenterFAQs: () => void;
};

const initialState: KnowledgeCenterFAQSliceStateType = {
  meta: {} as Meta,
  faqItems: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadTalentsKnowledgeCenterFAQs = createAsyncAction(
  'knowledgeCenterFAQ/talents/index',
  TalentsKnowledgeCenterFAQRepository.index,
);

export const loadManagerKnowledgeCenterFAQs = createAsyncAction(
  'knowledgeCenterFAQ/manager/index',
  ManagerKnowledgeCenterFAQRepository.index,
);

const KnowledgeCenterFAQSlice = createSlice({
  name: 'knowledgeCenterFAQ',
  initialState,
  reducers: {
    resetKnowledgeCenterFAQs(state) {
      state.index.fetchStatus = FetchStatus.idle;
      state.faqItems = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTalentsKnowledgeCenterFAQs.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTalentsKnowledgeCenterFAQs.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.faqItems = payload.results;
    });
    builder.addCase(loadTalentsKnowledgeCenterFAQs.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadManagerKnowledgeCenterFAQs.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadManagerKnowledgeCenterFAQs.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.faqItems = payload.results;
    });
    builder.addCase(loadManagerKnowledgeCenterFAQs.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
  },
});

const {
  actions: { resetKnowledgeCenterFAQs },
} = KnowledgeCenterFAQSlice;

export { resetKnowledgeCenterFAQs };

export default KnowledgeCenterFAQSlice.reducer;
