import { Environment } from 'src/enums/Environment';
import { Environment as EnvironmentType } from 'src/types/utils';

export const getEnvironment = (environment: Environment): EnvironmentType => {
  const isDev = environment === Environment.dev;
  const isQa = environment === Environment.qa;
  const isUat = environment === Environment.uat;
  const isProd = environment === Environment.prod;

  return {
    isDev,
    isQa,
    isUat,
    isProd,
  };
};
