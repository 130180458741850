import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import TalentOrganization from 'src/types/resources/TalentOrganization';
import { ListResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import OrganizationsRepository, { IndexParams } from 'src/repositories/talents/OrganizationsRepository';

export type OrganizationsStateType = {
  meta: Meta;
  organizations: TalentOrganization[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type OrganizationsActionsType = {
  loadOrganizations: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<TalentOrganization>> };
};

const initialState: OrganizationsStateType = {
  meta: {} as Meta,
  organizations: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadOrganizations = createAsyncAction('talents/organizations/index', OrganizationsRepository.index);

const Organizations = createSlice({
  name: 'talents/organizations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadOrganizations.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadOrganizations.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.organizations = payload.results;
    });
    builder.addCase(loadOrganizations.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
  },
});

export default Organizations.reducer;
