export enum AccountType {
  client = 'client',
  prospect = 'prospect',
  test = 'test',
}

export enum AccountTypeLabel {
  client = 'Client',
  prospect = 'Prospect',
  test = 'Test',
}
