import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    width: '512px',
    padding: '24px',
  },
  row: {
    margin: '24px 0',
  },
  additionalRow: {
    margin: '24px 0',
    '& > :not(:last-child)': {
      marginBottom: '7px',
    },
  },
  footer: {
    marginTop: '40px',
  },
  addButton: {
    margin: '7px 0',
    display: 'flex',
  },
  button: {
    padding: '7px 47px',
    marginRight: '10px',
  },
  radioGroup: {
    marginTop: '16px',
    gap: '16px',
  },
  helperText: {
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.black40,
  },
  assignedDepartment: {
    marginBottom: '7px',
  },
  switch: {
    gap: '12px',
  },
  checkboxGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '40px',
    marginTop: '8px',
  },
};

export default styles;
