import { createSlice } from '@reduxjs/toolkit';

import { createAsyncAction } from 'src/utils/createAsyncAction';
import SettingsRepository from 'src/repositories/settings/SettingsRepository';

import type { RootState } from '.';

export type SettingsStateType = {
  data: {
    usageFee: number;
  };
  loading: boolean;
};

export type TalentsSliceActionsType = {
  loadSettings: () => void;
};

const initialState: SettingsStateType = {
  data: {
    usageFee: null,
  },
  loading: false,
};

export const loadSettings = createAsyncAction('settings', SettingsRepository.index);

const talentsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSettings.pending, state => {
      state.loading = true;
    });
    builder.addCase(loadSettings.fulfilled, (state, { payload }) => {
      state.data.usageFee = Number(payload.find(item => item.name === 'talent_usage_fee').value) || null;
      state.loading = false;
    });
  },
});

export const selectSettingsTalentUsageFee = (state: RootState): number => state.settings.data.usageFee;
export const selectSettingsLoading = (state: RootState): SettingsStateType['loading'] => state.settings.loading;

export default talentsSlice.reducer;
