import { IndexParams, CreateParams, UpdateParams } from 'src/repositories/superAdmin/DepartmentsRepository';
import {
  DepartmentsSliceStateType,
  DepartmentsSliceActionsType,
  loadDepartments,
  createDepartment,
  updateDepartment,
} from 'src/store/superAdmin/departmentsSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';

type UseDepartmentsType = Pick<DepartmentsSliceStateType, 'departments' | 'meta'> &
  DepartmentsSliceActionsType & {
    isDepartmentsLoadFinished: boolean;
  };

const useDepartments = (): UseDepartmentsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    departments,
    index: { fetchStatus: departmentsIndexFetchStatus },
  } = useAppSelector(state => state.superAdminDepartments);

  const isDepartmentsLoadFinished = getFetchStatus(departmentsIndexFetchStatus).isFinished;

  return {
    meta,
    departments,
    isDepartmentsLoadFinished,
    loadDepartments: (params?: IndexParams) => dispatch(loadDepartments(params)),
    createDepartment: (params: CreateParams) => dispatch(createDepartment(params)),
    updateDepartment: (params: UpdateParams) => dispatch(updateDepartment(params)),
  };
};

export default useDepartments;
