import {
  EngagementTimeSheetsSliceStateType,
  EngagementTimeSheetsSliceActionsType,
  loadTotalEngagementTimeSheet,
  loadEngagementTimeSheetDates,
  partialUpdateEngagementTimeSheetDate,
  updateEngagementTimeSheetDates,
  resetEngagementTimeSheetDates,
  loadAdjustmentsTotal,
  checkPeriodForPendingTimeSheets,
} from 'src/store/organizationAdmin/timeSheetsSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import {
  IndexParams,
  PartialUpdateParams,
  MultipleUpdateParams,
} from 'src/repositories/organizationAdmin/TimeSheetsRepository';

type UseTimeSheetsType = Pick<
  EngagementTimeSheetsSliceStateType,
  'engagementTimeSheetDates' | 'totalEngagementTimeSheet' | 'isAdjustableTimesheets' | 'meta' | 'adjustmentsTotal'
> &
  EngagementTimeSheetsSliceActionsType & {
    isEngagementTimeSheetDatesFinished: boolean;
    isEngagementTimeSheetDatePartialUpdatePending: boolean;
    isEngagementTimeSheetDatesUpdatePending: boolean;
    isTotalEngagementTimeSheetLoadFinished: boolean;
  };

const useTimeSheets = (): UseTimeSheetsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    engagementTimeSheetDates,
    totalEngagementTimeSheet,
    isAdjustableTimesheets,
    index: { fetchStatus: timeSheetsIndexStatus },
    total: { fetchStatus: timeSheetsTotalStatus },
    partialUpdate: { fetchStatus: timeSheetPartialUpdateStatus },
    multipleUpdate: { fetchStatus: timeSheetsUpdateStatus },
    adjustmentsTotal,
  } = useAppSelector(state => state.organizationAdminTimeSheets);

  const isEngagementTimeSheetDatesFinished = getFetchStatus(timeSheetsIndexStatus).isFinished;
  const isEngagementTimeSheetDatePartialUpdatePending = getFetchStatus(timeSheetPartialUpdateStatus).isPending;
  const isEngagementTimeSheetDatesUpdatePending = getFetchStatus(timeSheetsUpdateStatus).isPending;
  const isTotalEngagementTimeSheetLoadFinished = getFetchStatus(timeSheetsTotalStatus).isFinished;

  return {
    meta,
    engagementTimeSheetDates,
    adjustmentsTotal,
    totalEngagementTimeSheet,
    isAdjustableTimesheets,
    isEngagementTimeSheetDatesFinished,
    isEngagementTimeSheetDatePartialUpdatePending,
    isEngagementTimeSheetDatesUpdatePending,
    isTotalEngagementTimeSheetLoadFinished,
    loadAdjustmentsTotal: (params?: Partial<IndexParams>) => dispatch(loadAdjustmentsTotal(params)),
    loadEngagementTimeSheetDates: (params?: IndexParams) => dispatch(loadEngagementTimeSheetDates(params)),
    loadTotalEngagementTimeSheet: (params?: IndexParams) => dispatch(loadTotalEngagementTimeSheet(params)),
    partialUpdateEngagementTimeSheetDate: (params: PartialUpdateParams) =>
      dispatch(partialUpdateEngagementTimeSheetDate(params)),
    updateEngagementTimeSheetDates: (params: MultipleUpdateParams) => dispatch(updateEngagementTimeSheetDates(params)),
    resetEngagementTimeSheetDates: () => dispatch(resetEngagementTimeSheetDates()),
    checkPeriodForPendingTimeSheets: (params: IndexParams) => dispatch(checkPeriodForPendingTimeSheets(params)),
  };
};

export default useTimeSheets;
