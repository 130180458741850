import {
  DocumentsSliceStateType,
  DocumentsSliceActionsType,
  loadDocuments,
  updateDocuments,
} from 'src/store/talents/documentsSlice';
import { UpdateParams } from 'src/repositories/talents/DocumentsRepository';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';

type UseDocumentsType = Pick<DocumentsSliceStateType, 'documents'> &
  DocumentsSliceActionsType & {
    isLoadDocumentsFinished: boolean;
  };

const useDocuments = (): UseDocumentsType => {
  const dispatch = useAppDispatch();
  const {
    documents,
    index: { fetchStatus: loadFetchStatus },
  } = useAppSelector(state => state.talentsDocuments);

  const isLoadDocumentsFinished = getFetchStatus(loadFetchStatus).isFinished;

  return {
    documents,
    isLoadDocumentsFinished,
    loadDocuments: () => dispatch(loadDocuments()),
    updateDocuments: (args: UpdateParams) => dispatch(updateDocuments(args)),
  };
};

export default useDocuments;
