import { pathOr } from 'ramda';

import { UserState } from 'src/enums/UserState';
import { OrganizationTalent } from 'src/types/resources/Organization';
import { capitalize } from 'src/utils/string';

const OrganizationTalentPresenter = {
  billableHours: (talent: OrganizationTalent): string => {
    const billableHours = parseInt(talent.billableHours, 10);
    return billableHours ? `${billableHours} H` : '';
  },
  status: (talent: OrganizationTalent): string => {
    return capitalize(talent.status);
  },
  valueByField: (talent: OrganizationTalent, field: keyof OrganizationTalent): string => {
    return pathOr('', [field], talent);
  },
  isDeactivated: (talent: OrganizationTalent): boolean => {
    const { status } = talent;

    return status === UserState.deactivated;
  },
};

export default OrganizationTalentPresenter;
