import UsersPresenter from 'src/presenters/UsersPresenter';

import useManagerTimeSheetsAdjustments from './manager/useTimeSheetsAdjustments';
import useOrgAdminTimeSheetsAdjustments from './organizationAdmin/useTimeSheetsAdjustments';
import useUsers from './useUsers';

const useOrgTimeSheetAdjustments = () => {
  const { currentUser } = useUsers();

  const isOrgAdmin = UsersPresenter.hasOrganizationAdminAccess(currentUser);
  const isManager = UsersPresenter.hasManagerAccess(currentUser);
  if (!(isOrgAdmin || isManager)) {
    throw new Error('useOrgTimeSheetAdjustments hook should be used only for Org Admins or Managers');
  }
  const currentUserHook = isOrgAdmin ? useOrgAdminTimeSheetsAdjustments : useManagerTimeSheetsAdjustments;

  return currentUserHook();
};

export default useOrgTimeSheetAdjustments;
