import { useEffect, useState } from 'react';
import { Button, InputAdornment, TextField, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { isEmpty, isNil } from 'ramda';

import { ReactComponent as SearchIcon } from 'src/assets/images/searchIcon.svg';
import { ExpertiseType } from 'src/enums/Specialization';
import SpecializationsRepository from 'src/repositories/SpecializationsRepository';
import Specialization from 'src/types/resources/Specialization';
import useLoading from 'src/hooks/useLoading';
import useModals from 'src/hooks/useModals';
import ToggleTagGroup from 'src/components/Talents/Specialization/components/ToggleTagGroup';
import Box from 'src/components/Box';
import TalentSpecialization from 'src/types/resources/TalentSpecialization';

import styles from './styles';

const MIN_TAGS_FOR_SEARCH_INPUT = 15;

type StepFormProps = {
  commonSpecialization: Specialization;
  talentSpecialization?: TalentSpecialization;
  expertiseLevel: ExpertiseType;
  selectedTagId: number | null;
  onChangeTagId: React.Dispatch<React.SetStateAction<number | null>>;
  onChangeFinalStep: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeSpecialization: React.Dispatch<React.SetStateAction<Specialization>>;
  onChangeExpertiseLevel: React.Dispatch<React.SetStateAction<ExpertiseType>>;
};

const StepForm: React.FC<StepFormProps> = props => {
  const {
    commonSpecialization,
    talentSpecialization,
    selectedTagId,
    expertiseLevel,
    onChangeTagId,
    onChangeFinalStep,
    onChangeSpecialization,
    onChangeExpertiseLevel,
  } = props;
  const [filter, setFilter] = useState<string>('');
  const [selectedSpecialization, setSelectedSpecialization] = useState<Specialization | null>(null);
  const { hideModal } = useModals();
  const { funcWithLoading: fetchSpecializationWithLoading, isFinished: isFetchSpecializationFinished } = useLoading(
    SpecializationsRepository.show,
  );

  const tags = commonSpecialization?.children || [];
  const filteredTags = tags.filter(tag => tag.name.toLowerCase().includes(filter.toLowerCase()) || filter === '');
  const isShowSearchInput = tags.length > MIN_TAGS_FOR_SEARCH_INPUT;

  const handleChangeTag = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: number | null) => {
    if (isNil(value)) {
      setSelectedSpecialization(null);
    }
    onChangeExpertiseLevel(value === talentSpecialization?.specialization?.id ? talentSpecialization?.expertise : null);
    onChangeTagId(value);
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleExpertiseLevelChange = (event: React.MouseEvent<HTMLElement>, value: ExpertiseType) => {
    onChangeExpertiseLevel(value);
  };

  const handleSubmit = () => {
    if (!isFetchSpecializationFinished) return;
    if (isEmpty(selectedSpecialization?.children)) {
      onChangeFinalStep(true);
    }
    onChangeSpecialization(selectedSpecialization);
    onChangeTagId(null);
  };

  const fetchSpecialization = async (id: number) => {
    const response = await fetchSpecializationWithLoading(id);
    setSelectedSpecialization(response.data);
  };

  useEffect(() => {
    if (selectedTagId) {
      fetchSpecialization(selectedTagId);
    }
  }, [selectedTagId]);

  return (
    <Box sx={styles.form}>
      <Typography sx={styles.title} variant="h3">
        Choose {commonSpecialization?.name} Platforms
      </Typography>

      {isShowSearchInput && (
        <Box sx={styles.row}>
          <TextField
            id="search"
            sx={styles.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              placeholder: 'Search',
            }}
            value={filter}
            onChange={handleChangeFilter}
          />
        </Box>
      )}

      <Box sx={styles.row}>
        <ToggleTagGroup exclusive tags={filteredTags} value={selectedTagId} onChange={handleChangeTag} />
      </Box>

      {!isNil(selectedSpecialization) && isEmpty(selectedSpecialization?.children) && !isNil(selectedTagId) && (
        <Box sx={styles.row}>
          <Typography sx={styles.label} variant="caption" component="p">
            Level of expertise
          </Typography>
          <ToggleButtonGroup value={expertiseLevel} exclusive onChange={handleExpertiseLevelChange}>
            <ToggleButton value={ExpertiseType.novice} sx={styles.expertiseButton}>
              {ExpertiseType.novice}
            </ToggleButton>
            <ToggleButton value={ExpertiseType.intermediate} sx={styles.expertiseButton}>
              {ExpertiseType.intermediate}
            </ToggleButton>
            <ToggleButton value={ExpertiseType.expert} sx={styles.expertiseButton}>
              {ExpertiseType.expert}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      <Box sx={styles.row}>
        <Box sx={styles.buttons}>
          <Button variant="contained" sx={styles.button} onClick={handleSubmit} disabled={isNil(selectedTagId)}>
            Add
          </Button>
          <Button variant="outlined" onClick={hideModal} sx={styles.button}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default StepForm;
