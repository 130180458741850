import { EditorState } from 'draft-js';
import { isNil } from 'ramda';
import * as yup from 'yup';

import { GetMaxLengthTest, WYSIWYG_NOT_EMPTY } from 'src/forms/customRules';
import { ValidationError } from 'src/enums/Validation';
import { convertFromHtmlFormat, convertToHtmlFormat } from 'src/utils/textEditor';
import { Comment, CommentPermissionId } from 'src/types/resources/Comment';
import CommentPresenter from 'src/presenters/CommentPresenter';

export type CommentsFormData = {
  commentText: EditorState;
  isShared: boolean;
};

export type CommentsFormDataToSubmit = {
  commentPermissionId: Comment['commentPermission']['id'];
  commentText: Comment['commentText'];
  isActive: Comment['isActive'];
};

const emptyEditorState = EditorState.createEmpty();

export const validationFields = {
  commentText: yup
    .mixed()
    .test(WYSIWYG_NOT_EMPTY)
    .test(GetMaxLengthTest(1000))
    .default(emptyEditorState)
    .required(ValidationError.default),
  isShared: yup.boolean().default(false),
};

export const validationSchema = yup.object(validationFields);

export const createInitialValues = (comment?: Comment): CommentsFormData => {
  if (isNil(comment)) {
    return validationSchema.getDefault();
  }

  const convertedComment = convertFromHtmlFormat(comment.commentText);

  return {
    commentText: convertedComment,
    isShared: comment.commentPermission.id === CommentPermissionId.superAdminPublic,
  };
};

export const attributesToSubmit = (
  values: CommentsFormData,
  isRevuudCommentForm: boolean,
): CommentsFormDataToSubmit => {
  const { commentText, isShared } = values;

  const convertedComment = convertToHtmlFormat(commentText);

  return {
    commentText: convertedComment,
    commentPermissionId: CommentPresenter.calculatePermissionId(isShared, isRevuudCommentForm),
    isActive: true,
  };
};
