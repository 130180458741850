import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { isEmpty, isNil } from 'ramda';

import { ExpertiseType } from 'src/enums/Specialization';
import Specialization, { ShortSpecialization } from 'src/types/resources/Specialization';
import TalentSpecialization from 'src/types/resources/TalentSpecialization';
import SpecializationsRepository from 'src/repositories/SpecializationsRepository';
import SpecializationPresenter from 'src/presenters/SpecializationPresenter';
import { createRootSpecialization } from 'src/forms/talents/specialization';
import useLoading from 'src/hooks/useLoading';
import Loader from 'src/components/Loader';
import StepForm from 'src/components/Talents/Specialization/components/StepForm';
import FinalForm from 'src/components/Talents/Specialization/components/FinalForm';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Box from 'src/components/Box';

import styles from './styles';

type FormWrapperProps = {
  parentTalentSpecialization?: Specialization | null;
  talentSpecialization?: TalentSpecialization | null;
};

const FormWrapper: React.FC<FormWrapperProps> = props => {
  const { parentTalentSpecialization, talentSpecialization } = props;
  const [isFinalStep, setIsFinalStep] = useState<boolean>(false);
  const [selectedTagId, setSelectedTagId] = useState<number | null>(talentSpecialization?.specialization?.id);
  const [selectedExpertiseLevel, setSelectedExpertiseLevel] = useState<ExpertiseType | null>(
    talentSpecialization?.expertise,
  );
  const [specialization, setSpecialization] = useState<Specialization | null>(
    isNil(talentSpecialization) ? null : parentTalentSpecialization,
  );
  const { funcWithLoading: fetchRootSpecializationsWithLoading } = useLoading(SpecializationsRepository.index);
  const { funcWithLoading: fetchChildSpecializationWithLoading } = useLoading(SpecializationsRepository.show);

  const isAddForm = isNil(parentTalentSpecialization) && isNil(talentSpecialization);

  const fetchRootSpecializations = async () => {
    const response = await fetchRootSpecializationsWithLoading();
    setSpecialization(createRootSpecialization(response.results));
  };

  const fetchChildSpecialization = async (id: number) => {
    const response = await fetchChildSpecializationWithLoading(id);
    setSpecialization(response.data);
    if (isEmpty(response.data.children)) {
      setIsFinalStep(true);
    }
  };

  const handleClickBreadcrumb = (id: number | null) => async () => {
    if (!isAddForm && parentTalentSpecialization?.id === id) {
      setSpecialization(parentTalentSpecialization);
      setSelectedTagId(talentSpecialization?.specialization?.id);
      setSelectedExpertiseLevel(talentSpecialization?.expertise);
      setIsFinalStep(false);
      return;
    }
    setSpecialization(null);
    setSelectedExpertiseLevel(null);
    setSelectedTagId(null);
    if (isNil(id)) {
      await fetchRootSpecializations();
    } else {
      await fetchChildSpecialization(id);
    }
    setIsFinalStep(false);
  };

  useEffect(() => {
    if (selectedTagId) {
      if (isAddForm) {
        fetchChildSpecialization(selectedTagId);
      }
    } else {
      fetchRootSpecializations();
    }
  }, []);

  if (isNil(specialization)) {
    return (
      <Box sx={styles.wrapper}>
        <Loader />
      </Box>
    );
  }

  const ancestorElements = SpecializationPresenter.fullAncestorElements(specialization);

  const renderBreadcrumbs = () => {
    const crumbs = ancestorElements.map((ancestor: ShortSpecialization) => ({
      id: ancestor.id,
      label: ancestor.name,
      path: '#',
    }));

    return (
      <Breadcrumbs
        crumbs={crumbs}
        renderItem={crumb => (
          <Button sx={styles.breadcrumbsButton} onClick={handleClickBreadcrumb(crumb.id)}>
            {crumb.label}
          </Button>
        )}
      />
    );
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.breadcrumbs}>{specialization && renderBreadcrumbs()}</Box>
      {isFinalStep ? (
        <FinalForm
          commonSpecialization={specialization}
          talentSpecialization={talentSpecialization}
          expertiseLevel={selectedExpertiseLevel}
        />
      ) : (
        <StepForm
          commonSpecialization={specialization}
          talentSpecialization={talentSpecialization}
          expertiseLevel={selectedExpertiseLevel}
          selectedTagId={selectedTagId}
          onChangeTagId={setSelectedTagId}
          onChangeFinalStep={setIsFinalStep}
          onChangeSpecialization={setSpecialization}
          onChangeExpertiseLevel={setSelectedExpertiseLevel}
        />
      )}
    </Box>
  );
};

export default FormWrapper;
