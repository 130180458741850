import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  button: {
    position: 'relative',
    borderRadius: '4px',
    minWidth: '0',
    minHeight: '0',
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    fontSize: '17px',
    fontWeight: 'bold',
    width: '25px',
    height: '25px',
    color: COLORS.black80,
    backgroundColor: COLORS.white,
    border: 'none',
    padding: '5px',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: COLORS.black02,
      borderColor: COLORS.black20,
    },
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  withCounter: {
    '&:after': {
      position: 'absolute',
      top: '0',
      left: '0',
      display: 'block',
    },
  },
  activeButton: {
    backgroundColor: COLORS.black10,
  },
};

export default styles;
