import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import Engagement, { ShortEngagement, TalentEngagement } from 'src/types/resources/Engagement';
import { SingleResponse, ListResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import EngagementsRepository, {
  ShowParams,
  AcceptParams,
  DeclineParams,
  IndexParams,
} from 'src/repositories/talents/EngagementsRepository';

export type EngagementsStateType = {
  meta: Meta;
  engagements: Engagement[] | ShortEngagement[];
  engagement: TalentEngagement;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  short: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  accept: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  decline: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type EngagementsActionsType = {
  loadEngagements: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<Engagement>> };
  loadShortEngagements: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<ShortEngagement>> };
  showEngagement: (params: ShowParams) => { unwrap: () => Promise<SingleResponse<TalentEngagement>> };
  acceptEngagement: (params: AcceptParams) => { unwrap: () => Promise<SingleResponse<TalentEngagement>> };
  declineEngagement: (params: DeclineParams) => { unwrap: () => Promise<SingleResponse<TalentEngagement>> };
};

const initialState: EngagementsStateType = {
  meta: {} as Meta,
  engagements: [],
  engagement: {} as TalentEngagement,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  short: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  accept: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  decline: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadEngagements = createAsyncAction('talents/engagements/index', EngagementsRepository.index);
export const loadShortEngagements = createAsyncAction('talents/engagements/short', EngagementsRepository.short);
export const showEngagement = createAsyncAction('talents/engagements/show', EngagementsRepository.show);
export const acceptEngagement = createAsyncAction('talents/engagements/accept', EngagementsRepository.accept);
export const declineEngagement = createAsyncAction('talents/engagements/decline', EngagementsRepository.decline);

const Engagements = createSlice({
  name: 'talents/engagements',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadEngagements.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadEngagements.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.engagements = payload.results;
    });
    builder.addCase(loadShortEngagements.pending, state => {
      state.short.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadShortEngagements.fulfilled, (state, { payload }) => {
      state.short.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.engagements = payload.results;
    });
    builder.addCase(loadEngagements.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(showEngagement.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showEngagement.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.engagement = payload.data;
    });
    builder.addCase(showEngagement.rejected, state => {
      state.show.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(acceptEngagement.pending, state => {
      state.accept.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(acceptEngagement.fulfilled, state => {
      state.accept.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(acceptEngagement.rejected, state => {
      state.accept.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(declineEngagement.pending, state => {
      state.decline.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(declineEngagement.fulfilled, state => {
      state.decline.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(declineEngagement.rejected, state => {
      state.decline.fetchStatus = FetchStatus.failed;
    });
  },
});

export default Engagements.reducer;
