import * as yup from 'yup';

import { VALID_PHONE_NUMBER } from 'src/forms/customRules';
import { formatPhoneNumberForSubmit } from 'src/utils/phoneNumber';

export type PhoneNumberFormData = {
  phone: string;
};

export const validationFields = {
  phone: yup.string().test(VALID_PHONE_NUMBER).default('').label('Phone'),
};

export const validationSchema = yup.object(validationFields);

export const initialValues: PhoneNumberFormData = validationSchema.getDefault();

export const attributesToSubmit = (values: PhoneNumberFormData): PhoneNumberFormData => {
  const { phone } = values;

  return {
    phone: formatPhoneNumberForSubmit(phone),
  };
};
