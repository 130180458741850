import { isNil, omit } from 'ramda';
import * as yup from 'yup';

import { User } from 'src/types/resources/User';
import { ValidationError } from 'src/enums/Validation';
import { VALID_PHONE_NUMBER } from 'src/forms/customRules';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';
import { formatPhoneNumberForForm, formatPhoneNumberForSubmit } from 'src/utils/phoneNumber';

export type ContactInfoFormData = {
  firstName: string;
  lastName: string;
  phone: string;
  city: string;
  address1: string;
  address2: string;
  zipCode: string;
  state: string;
  isTalentEngagementsEmpty?: boolean;
};

export const validationFields = {
  firstName: yup.string().required(ValidationError.default).default('').label('First name'),
  lastName: yup.string().required(ValidationError.default).default('').label('Last name'),
  phone: yup
    .string()
    .test(VALID_PHONE_NUMBER)
    .when('isTalentEngagementsEmpty', {
      is: (value: boolean) => value,
      otherwise: yup.string().required(ValidationError.default),
    })
    .default('')
    .label('Phone')
    .required(ValidationError.default),
  address1: yup.string().nullable().max(300, getMaxFieldLengthErrorMessage(300)).default(null).label('Address Line 1'),
  address2: yup.string().max(300, getMaxFieldLengthErrorMessage(300)).default('').label('Address Line 2'),
  city: yup.string().max(150, getMaxFieldLengthErrorMessage(150)).default('').label('City'),
  state: yup.string().max(150, getMaxFieldLengthErrorMessage(150)).default('').label('State'),
  zipCode: yup.string().max(10, getMaxFieldLengthErrorMessage(10)).default('').label('ZIP'),
  isTalentEngagementsEmpty: yup.boolean().default(true),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (user?: User): ContactInfoFormData => {
  if (isNil(user)) {
    return validationSchema.getDefault();
  }

  return {
    firstName: user.firstName,
    lastName: user.lastName,
    address1: user.address1 || '',
    address2: user.address2 || '',
    phone: user.phone ? formatPhoneNumberForForm(user.phone) : '',
    state: user.state || '',
    city: user.city || '',
    zipCode: user.zipCode || '',
  };
};

export const attributesToSubmit = (values: ContactInfoFormData): ContactInfoFormData => {
  const { phone, ...restValues } = values;

  return {
    ...omit(['isTalentEngagementsEmpty'], restValues),
    phone: formatPhoneNumberForSubmit(phone),
  };
};
