import { ListResponse, SingleResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import { DataTableRequestParams } from 'src/types/dataTable';
import { Opportunity } from 'src/types/resources/Opportunity';

import {
  OpportunityCreateRequestBody,
  OpportunityTableItem,
  AddSpecializationToOpportunityRequestBody,
  OpportunityUpdateRequestBody,
  OpportunitySpecializationRequestBody,
} from '../types/Opportunity';
import { OpportunityOrgSideDetails } from '../types/OpportunityOrgSideDetails';

// TODO fix when will be ready api. To prevent multiply work
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OpportunityAnyResponse = any;

type OpportunityResponse = Opportunity;

export const opportunityApi = {
  create(opportunityData: OpportunityCreateRequestBody): Promise<SingleResponse<OpportunityAnyResponse>> {
    const url = apiRoutes.organization.opportunity();
    return Fetcher.post<OpportunityCreateRequestBody, SingleResponse<OpportunityAnyResponse>>(url, opportunityData);
  },
  put(
    opportunityId: ID,
    opportunityData: OpportunityCreateRequestBody,
  ): Promise<SingleResponse<OpportunityAnyResponse>> {
    const url = apiRoutes.organization.opportunity(opportunityId);
    return Fetcher.put<OpportunityCreateRequestBody, SingleResponse<OpportunityAnyResponse>>(url, opportunityData);
  },
  delete(id: ID): Promise<void> {
    const url = apiRoutes.organization.opportunity(id);
    return Fetcher.delete(url);
  },
  patch(
    opportunityId: ID,
    opportunityData: OpportunityUpdateRequestBody,
  ): Promise<SingleResponse<OpportunityResponse>> {
    const url = apiRoutes.organization.opportunity(opportunityId);
    return Fetcher.patch<OpportunityUpdateRequestBody, SingleResponse<OpportunityResponse>>(url, opportunityData);
  },
  addSpecializationToOpportunity(requestData: AddSpecializationToOpportunityRequestBody): Promise<unknown> {
    const url = apiRoutes.organization.opportunitySpecialization();
    return Fetcher.post(url, requestData);
  },
  deleteOpportunitySpecialization(opportunitySpecializationId: ID) {
    return Fetcher.delete(apiRoutes.organization.opportunitySpecialization(opportunitySpecializationId));
  },
  updateOpportunitySpecialization(opportunitySpecializationId: ID, requestData: OpportunitySpecializationRequestBody) {
    return Fetcher.put(apiRoutes.organization.opportunitySpecialization(opportunitySpecializationId), requestData);
  },
  fetchDataTable(params: DataTableRequestParams): Promise<ListResponse<OpportunityTableItem>> {
    const url = apiRoutes.organization.opportunities();
    return Fetcher.get(url, params);
  },
  fetchOpportunityDetailsWithApplicants(
    id: number,
    params?: DataTableRequestParams,
  ): Promise<SingleResponse<OpportunityOrgSideDetails>> {
    const url = apiRoutes.organization.opportunity(id);
    return Fetcher.get(url, params);
  },
};
