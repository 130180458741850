import React, { useEffect, useState } from 'react';
import { Alert, Button, Typography } from '@mui/material';
import { isNil } from 'ramda';

import { sortByIds } from 'src/utils/keysConverter';
import CommonSpecialization from 'src/types/resources/Specialization';
import { SearchSpecializationFormData } from 'src/forms/searchSpecialization';
import SpecializationPresenter from 'src/presenters/SpecializationPresenter';
import useModals from 'src/hooks/useModals';
import useOrganizationAdminTalents from 'src/hooks/organizationAdmin/useOrganizationAdminTalents';
import ToggleTagGroup from 'src/components/Talents/Specialization/components/ToggleTagGroup';
import Loader from 'src/components/Loader';
import MatchedResourcesCount from 'src/components/MatchedResourcesCount';
import Box from 'src/components/Box';

import styles from './styles';

type FormProps = {
  commonSpecialization?: CommonSpecialization;
  formError?: string | null;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => Promise<unknown>;
  isSubmitting: boolean;
  values: SearchSpecializationFormData;
};

const FinalForm: React.FC<FormProps> = props => {
  const { commonSpecialization, formError, setFieldValue, isSubmitting, values } = props;
  const [currentSpecialization, setCurrentSpecialization] = useState<CommonSpecialization | null>(null);
  const { hideModal } = useModals();
  const { matchedTalentsCount, isLoadMatchedTalentsCountPending } = useOrganizationAdminTalents();

  const currentSpecializationName = commonSpecialization?.name || '';

  const handleChangeToggle = (fieldName: string) => {
    return async <T,>(event: React.MouseEvent<HTMLElement>, newValue: T) => {
      await setFieldValue(fieldName, newValue);
    };
  };

  useEffect(() => {
    setCurrentSpecialization(() => {
      return {
        ...commonSpecialization,
        roles: sortByIds(commonSpecialization.roles, values.roles),
        skills: sortByIds(commonSpecialization.skills, values.skills),
      };
    });
  }, []);

  if (isNil(currentSpecialization)) {
    return <Loader />;
  }

  const isRolesPresent = SpecializationPresenter.isRolesPresent(currentSpecialization);
  const isSkillsPresent = SpecializationPresenter.isSkillsPresent(currentSpecialization);

  return (
    <>
      {formError && (
        <Alert sx={styles.error} severity="error">
          {formError}
        </Alert>
      )}

      <Box sx={styles.form}>
        <Typography sx={styles.title} variant="h3">
          {`Choose ${currentSpecializationName} Skills`}
        </Typography>

        {isRolesPresent && (
          <Box sx={styles.row}>
            <Typography sx={styles.label} variant="caption" component="p">
              {`Roles and Credentials within ${currentSpecializationName}`}
            </Typography>
            <ToggleTagGroup
              tags={currentSpecialization?.roles}
              value={values.roles}
              onChange={handleChangeToggle('roles')}
            />
          </Box>
        )}

        {isSkillsPresent && (
          <Box sx={styles.row}>
            <Typography sx={styles.label} variant="caption" component="p">
              Additional experiences
            </Typography>
            <ToggleTagGroup
              tags={currentSpecialization?.skills}
              value={values.skills}
              onChange={handleChangeToggle('skills')}
            />
          </Box>
        )}

        <Box sx={styles.row}>
          <Box sx={styles.buttons}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
              Add
            </Button>
            <Button variant="outlined" onClick={hideModal} sx={styles.button}>
              Cancel
            </Button>
            <Box sx={styles.matchedResourcesCountContainer}>
              <MatchedResourcesCount count={matchedTalentsCount} isLoading={isLoadMatchedTalentsCountPending} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FinalForm;
