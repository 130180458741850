import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  root: {
    color: COLORS.black60,
    fontWeight: 400,
    lineHeight: 1.25,
  },
  link: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
};

export default styles;
