export default {
  leavePagePopup: 'You are about to leave the page. If you leave it, you will lose all unsaved data.',
  confirmLeaveButton: 'Yes, leave the page',
  removeApplicantConfirmationPopup:
    'When you remove a Talent they will be notified that they are not a fit for this position and will no longer be accessible from this list of Applicants',
  removeUnappliedApplicantConfirmationPopup:
    'When you remove a Talent they will no longer be accessible from this list of Applicants.',
  profileEmptyCommentsOrgView:
    'Click the + sign to add a comment that will be shared with your colleagues. The Talent will not see these comments. They are for internal use only.',
  profileEmptyCommentsSuperAdminView: 'If an organization adds a comment it will be seen here.',
  profileCommentFormPlaceholder: 'This comment is for internal use only and cannot be seen by Talent.',
  profileCommentFormSubtitle: 'This comment will be shared with all Administrators and Managers in your company.',
  profileCommentDeleteForm: 'If you delete this comment, it will no longer be seen by any users',

  profileEmptyRevuudCommentsOrgView: 'If a Revuud employee has a comment about this Talent, you will see it here.',
  profileEmptyRevuudCommentsSuperAdminView:
    'Add a comment here that can be viewed by Revuud Super Admin only or made public to be shared with all organization Administrators and Managers.',
  profileRevuudCommentFormSubtitle:
    'By making your comment public, all Org Users will be able to see the comment. If it is not public, only Super Admin can view the comment. Talent can never see comments.',
  profileRevuudCommentFormCheckbox: 'Make this comment public to share with Org Users.',
  profileRevuudCommentPublicLabelTooltip: 'All Org Admins and Managers can see this comment',
  wrongUserIdMessengerError: 'There is no chat with this user yet.',
};
