import {
  SubgroupSlice,
  SubgroupsSliceStateType,
  SubgroupsSliceActionsType,
  loadSubgroups,
  showSubgroup,
  createSubgroup,
  updateSubgroup,
  deleteSubgroup,
  resetSubgroups,
} from 'src/store/subgroupsSlice';
import { ShowParams, UpdateParams, DeleteParams } from 'src/repositories/organizationAdmin/SubgroupsRepository';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { CreateSubgroupFormData } from 'src/forms/createSubgroup';

type UseSubgroupsType = Pick<SubgroupsSliceStateType, 'subgroups' | 'subgroup'> &
  SubgroupsSliceActionsType & {
    getSubgroupsByGroupId: (id: number) => SubgroupSlice;
    isSubgroupsLoadFinished: boolean;
    isSubgroupLoadFinished: boolean;
    isSubgroupDeletePending: boolean;
  };

const useSubgroups = (): UseSubgroupsType => {
  const dispatch = useAppDispatch();
  const {
    subgroups,
    subgroup,
    index: { fetchStatus: subgroupsIndexFetchStatus },
    show: { fetchStatus: subgroupShowFetchStatus },
    delete: { fetchStatus: subgroupDeleteFetchStatus },
  } = useAppSelector(state => state.subgroups);

  const getSubgroupsByGroupId = (id: number) => subgroups[id] || ({} as SubgroupSlice);

  const isSubgroupsLoadFinished = getFetchStatus(subgroupsIndexFetchStatus).isFinished;
  const isSubgroupLoadFinished = getFetchStatus(subgroupShowFetchStatus).isFinished;
  const isSubgroupDeletePending = getFetchStatus(subgroupDeleteFetchStatus).isPending;

  return {
    subgroups,
    subgroup,
    isSubgroupsLoadFinished,
    isSubgroupLoadFinished,
    isSubgroupDeletePending,
    getSubgroupsByGroupId,
    loadSubgroups: id => dispatch(loadSubgroups(id)),
    showSubgroup: (params: ShowParams) => dispatch(showSubgroup(params)),
    createSubgroup: (params: CreateSubgroupFormData) => dispatch(createSubgroup(params)),
    updateSubgroup: (args: UpdateParams) => dispatch(updateSubgroup(args)),
    deleteSubgroup: (args: DeleteParams) => dispatch(deleteSubgroup(args)),
    resetSubgroups: () => dispatch(resetSubgroups()),
  };
};

export default useSubgroups;
