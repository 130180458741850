import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    width: '560px',
    minHeight: '250px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: '12px',
  },
  row: {
    marginTop: '8px',
  },
  rowWithColumn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '4px',
  },
  fieldsColumn: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexGrow: 1,
    paddingTop: '4px',
    overflow: 'hidden',
  },
  originalFieldColumn: {
    width: 'calc(50% - 8px)',
  },
  newFieldColumn: {
    width: 'calc(50% - 25px)',
  },
  formFieldContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  label: {
    flexShrink: 0,
    margin: 0,
  },
  originalValue: {
    color: COLORS.black60,
    marginLeft: 0,
  },
  poContainer: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  descriptionHeader: {
    marginBottom: '4px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  talentCommentViewState: {
    padding: 0,
    backgroundColor: 'red',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '48px',
  },
  warning: {
    gap: '4px',
  },
  warningText: {
    color: COLORS.red100,
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
};

export default styles;
