import { ChangeEvent, useState } from 'react';
import { head, isEmpty, isNil } from 'ramda';
import { FormikHelpers, getIn, useFormik } from 'formik';
import { Alert, Button, Stack, TextField, Typography } from '@mui/material';

import { ReactComponent as CheckCircleIcon } from 'src/assets/images/checkCircleNoneFillIcon.svg';
import { ReactComponent as PlusIcon } from 'src/assets/images/plusIcon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/deleteIcon.svg';
import { ReactComponent as ClipIcon } from 'src/assets/images/clipIcon.svg';
import { useModals, useUsers } from 'src/hooks';
import usePurchaseOrders from 'src/hooks/manager/usePurchaseOrders';
import {
  CreatePurchaseOrderFormData,
  createPurchaseOrderForSubmit,
  createInitialValues,
  validationSchema,
  validationFields,
} from 'src/forms/createPurchaseOrder';
import { PurchaseOrder } from 'src/types/resources/PurchaseOrders';
import Box from 'src/components/Box';
import TextFieldWithCounter from 'src/components/TextFieldWithCounter';
import FileField from 'src/components/FileField';
import AttachedFileLink from 'src/components/AttachedFileLink';
import { extractResponseErrors, isAxiosError, isUnprocessedEntityError } from 'src/utils/responseErrors';
import { REQUIRED_DOCUMENT_VALID_FILE_TYPES } from 'src/utils/constants';

import styles from './styles';

type CreatePurchaseOrderProps = {
  onSubmit?: () => void;
  purchaseOrder?: PurchaseOrder;
};

const CreatePurchaseOrderForm: React.FC<CreatePurchaseOrderProps> = props => {
  const { purchaseOrder } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const [successCreatedPOIndexes, setSuccessCreatedPOIndexes] = useState<number[]>([]);

  const { hideModal } = useModals();
  const { createPurchaseOrders, updatePurchaseOrders } = usePurchaseOrders();
  const { currentUser } = useUsers();

  const isAddForm = isNil(purchaseOrder);
  const formTitle = isAddForm ? 'Add New Purchase Order' : 'Edit Purchase Order';

  const handleSubmitError = (error: unknown) => {
    if (isAxiosError(error) && isUnprocessedEntityError(error)) {
      setFormError(head(error.response.data.errors.nonFieldErrors));
    }
  };

  const handleSubmit = async (
    formData: CreatePurchaseOrderFormData,
    { setFieldError }: FormikHelpers<CreatePurchaseOrderFormData>,
  ) => {
    setFormError(null);
    try {
      const { onSubmit } = props;
      if (isAddForm) {
        const submitData = formData.map(data => createPurchaseOrderForSubmit(data, currentUser.organization.id));
        const requests = submitData.map((PO, index) =>
          successCreatedPOIndexes.includes(index) ? Promise.resolve(PO) : createPurchaseOrders(PO).unwrap(),
        );
        const response = await Promise.allSettled(requests);

        const [fulfilledPOIndexes, rejectedPOIndexes]: Array<number[]> = response.reduce(
          (acc, item, index) => {
            acc[item.status === 'fulfilled' ? 0 : 1].push(index);
            return acc;
          },
          [[], []],
        );

        setSuccessCreatedPOIndexes(fulfilledPOIndexes);

        rejectedPOIndexes.forEach(rejectedPOIndex => {
          const rejectedPONumber = response[rejectedPOIndex] as PromiseRejectedResult;
          const error = rejectedPONumber.reason;
          const errors = extractResponseErrors(error) as { name: string };
          setFieldError(`[${rejectedPOIndex}.poNumber]`, errors?.name);
        });
        if (isEmpty(rejectedPOIndexes)) {
          onSubmit?.();
          hideModal();
        }
      } else {
        updatePurchaseOrders({
          id: purchaseOrder.id,
          params: createPurchaseOrderForSubmit(formData.at(0), currentUser.organization.id),
        })
          .unwrap()
          .then(hideModal)
          .catch(e => {
            const errors = extractResponseErrors(e) as { name: string };
            setFieldError(`[${0}.poNumber]`, errors?.name);
          });
      }
    } catch (error) {
      handleSubmitError(error);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setValues,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit: handleFormikSubmit,
  } = useFormik<CreatePurchaseOrderFormData>({
    initialValues: createInitialValues(purchaseOrder),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const handlePurchaseOrdersAdd = () => {
    setValues([...values, validationFields.getDefault()]);
  };

  const handlePOFieldRemove = (index: number) => () => {
    setValues(values.filter((value, currentIndex) => currentIndex !== index));

    setSuccessCreatedPOIndexes(prevSuccessIndexes =>
      prevSuccessIndexes.map(prevSuccessIndex => (prevSuccessIndex > index ? prevSuccessIndex - 1 : prevSuccessIndex)),
    );
  };

  const handleFileChange = (fieldName: string) => async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files[0] || null;
    if (selectedFile) {
      await setFieldValue(fieldName, {
        source: selectedFile,
        url: null,
      });
    }
  };

  const handleDeleteFile = (fieldName: string) => async () => {
    await setFieldValue(fieldName, {
      source: null,
      url: null,
    });
  };

  const renderFileField = (index: number, isSuccessCreatedPO: boolean) => {
    const isFileFieldError = getIn(touched, `[${index}].file.source`) && getIn(errors, `[${index}].file.source`);
    const isFileExist = getIn(values, `[${index}].file.source`) || getIn(values, `[${index}].file.url`);
    const isVisibleUploadButton = isFileFieldError || !isFileExist;

    return (
      <Stack gap={1}>
        {isFileFieldError && (
          <Typography component="p" color="error">
            {errors[index].file.source}
          </Typography>
        )}
        {isFileExist && (
          <AttachedFileLink
            deleteIcon={<DeleteIcon />}
            file={values[index].file}
            onDelete={handleDeleteFile(`[${index}].file`)}
          />
        )}
        {isVisibleUploadButton && (
          <FileField
            name={`[${index}].file`}
            disabled={isSuccessCreatedPO}
            accept={REQUIRED_DOCUMENT_VALID_FILE_TYPES.join(',')}
            id={`[${index}].file`}
            buttonText="Upload"
            onChange={handleFileChange(`[${index}].file`)}
            startIcon={<ClipIcon />}
          />
        )}
      </Stack>
    );
  };

  const renderPurchaseOrderFields = (POs: CreatePurchaseOrderFormData) => {
    return POs.map((PO, index) => {
      const key = `purchaseOrder[${index}]`;
      const isSuccessCreatedPO = successCreatedPOIndexes.includes(index);
      const isPOused = purchaseOrder?.isUsed;
      const isPORemoveButtonVisible = POs.length > 1 && !isSuccessCreatedPO;

      return (
        <Stack key={key} sx={styles.row} component="section">
          <Stack direction="row">
            <TextField
              name={`[${index}].poNumber`}
              label="PO Number"
              placeholder="Add PO Number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[index].poNumber}
              error={touched[index]?.poNumber && Boolean(errors[index]?.poNumber)}
              disabled={isPOused || isSuccessCreatedPO}
              helperText={touched[index]?.poNumber && errors[index]?.poNumber}
              InputProps={{
                endAdornment: (
                  <Stack direction="row" alignItems="center" gap="8px">
                    {isSuccessCreatedPO && (
                      <Box sx={styles.inputCheckIcon}>
                        <CheckCircleIcon />
                      </Box>
                    )}
                  </Stack>
                ),
              }}
            />
            <TextField
              name={`[${index}].poDescription`}
              label="PO Description"
              placeholder="Add PO Description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[index].poDescription}
              error={touched[index]?.poDescription && Boolean(errors[index]?.poDescription)}
              helperText={touched[index]?.poDescription && errors[index]?.poDescription}
              disabled={isSuccessCreatedPO}
              multiline
              maxRows={2}
              InputProps={{
                endAdornment: (
                  <Stack direction="row" alignItems="center" gap="8px">
                    {isSuccessCreatedPO && (
                      <Box sx={styles.inputCheckIcon}>
                        <CheckCircleIcon />
                      </Box>
                    )}
                  </Stack>
                ),
              }}
            />
          </Stack>
          <TextFieldWithCounter
            name={`[${index}].poNote`}
            label="PO Note"
            placeholder="Add PO Note"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[index].poNote}
            multiline
            rows={7}
            disabled={isSuccessCreatedPO}
            error={Boolean(errors[index]?.poNote)}
            helperText={errors[index]?.poNote}
          />
          {renderFileField(index, isSuccessCreatedPO)}
          {isPORemoveButtonVisible && (
            <Button
              type="button"
              variant="text"
              endIcon={<DeleteIcon />}
              sx={styles.removeButton}
              size="small"
              onClick={handlePOFieldRemove(index)}
            >
              Delete
            </Button>
          )}
        </Stack>
      );
    });
  };

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.wrapper}>
          <Typography variant="h3" sx={styles.title}>
            {formTitle}
          </Typography>
          {renderPurchaseOrderFields(values)}
          {isAddForm && (
            <Button
              type="button"
              variant="text"
              startIcon={<PlusIcon />}
              sx={styles.additionalButton}
              size="small"
              onClick={handlePurchaseOrdersAdd}
            >
              Add One More
            </Button>
          )}
          <Box sx={styles.footer}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
              {isAddForm ? 'Add' : 'Save'}
            </Button>
            <Button variant="outlined" sx={styles.button} onClick={hideModal}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default CreatePurchaseOrderForm;
