import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  circularProgress: {
    marginLeft: '10px',
  },
};

export default styles;
