import { FormikHelpers, useFormik } from 'formik';
import { Typography, Button, Alert, FormControlLabel, Checkbox } from '@mui/material';
import { useState } from 'react';
import { EditorState } from 'draft-js';
import { head, isNil } from 'ramda';

import { useModals, useSnackbar } from 'src/hooks';
import { TalentsSliceActionsType } from 'src/store/talentsSlice';
import {
  attributesToSubmit,
  createInitialValues,
  CommentsFormData,
  validationSchema,
} from 'src/forms/talents/comments';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import { areContentStatesEqual } from 'src/utils/areTextEditorStatesEqual';
import { Comment } from 'src/types/resources/Comment';
import TextEditor from 'src/components/TextEditor';
import Box from 'src/components/Box';
import dictionary from 'src/dictionary';

import styles from './styles';

type CommentsProps = {
  comment?: Comment;
  isRevuudComments?: boolean;
  create: TalentsSliceActionsType['createComment'];
  partialUpdate: TalentsSliceActionsType['partialUpdateComment'];
};

const CreateCommentForm: React.FC<CommentsProps> = ({ comment, isRevuudComments, create, partialUpdate }) => {
  const {
    profileRevuudCommentFormCheckbox,
    profileCommentFormPlaceholder,
    profileCommentFormSubtitle,
    profileRevuudCommentFormSubtitle,
  } = dictionary;
  const [formError, setFormError] = useState<string | null>(null);
  const { hideModal } = useModals();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useSnackbar();

  const isAddForm = isNil(comment);
  const formTitle = isAddForm ? 'Add Comment' : 'Edit Comment';

  const handleSubmit = async (formData: CommentsFormData, { setErrors }: FormikHelpers<CommentsFormData>) => {
    const params = attributesToSubmit(formData, isRevuudComments);
    try {
      if (isAddForm) {
        await create(params).unwrap();
      } else {
        await partialUpdate({ id: comment.id, params }).unwrap();
      }
      hideModal();
      enqueueSuccessSnackbar(isAddForm ? 'Comment Saved' : 'Changes Saved');
    } catch (error) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        if (error.response.data.errors.nonFieldErrors) {
          setFormError(head(error.response.data.errors.nonFieldErrors));
        } else {
          hideModal();
          enqueueErrorSnackbar();
        }
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const initialValues = createInitialValues(comment);
  const { values, errors, submitForm, touched, setFieldValue, isSubmitting } = useFormik<CommentsFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const isEditorValueInitial = areContentStatesEqual(
    values.commentText.getCurrentContent(),
    initialValues.commentText.getCurrentContent(),
  );
  const isCheckboxValueInitial = initialValues.isShared === values.isShared;
  const isValueInitial = isEditorValueInitial && isCheckboxValueInitial;

  const handleWisywigChange = (editorState: EditorState) => {
    setFieldValue('commentText', editorState);
  };

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <Box sx={styles.wrapper}>
        <Typography variant="h2" sx={styles.title}>
          {formTitle}
        </Typography>
        <Typography variant="subtitle2" sx={styles.subTitle}>
          {isRevuudComments ? profileRevuudCommentFormSubtitle : profileCommentFormSubtitle}
        </Typography>
        <Box>
          <TextEditor
            placeholder={isRevuudComments ? null : profileCommentFormPlaceholder}
            value={values.commentText}
            onChange={handleWisywigChange}
            error={touched.commentText && errors.commentText}
            usePredefinedMinHeight
            maxValue={1000}
            isCounterVisible
            autoFocus
          />
          {isRevuudComments && (
            <FormControlLabel
              sx={{ mb: 2 }}
              control={
                <Checkbox
                  checked={values.isShared}
                  onChange={event => setFieldValue('isShared', event.target.checked)}
                  name="isShared"
                />
              }
              label={profileRevuudCommentFormCheckbox}
            />
          )}
        </Box>

        <Box>
          <Button variant="contained" disabled={isSubmitting || isValueInitial} onClick={submitForm} sx={styles.button}>
            Save
          </Button>
          <Button variant="outlined" disabled={isSubmitting} onClick={hideModal} sx={styles.button}>
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CreateCommentForm;
