import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import { UserRole } from 'src/enums/UserRole';
import { Chat } from 'src/types/resources/Chat';
import { SingleResponse } from 'src/types/utils';
import TalentsChatsRepository, { CreateParams as TalentCreateParams } from 'src/repositories/talents/ChatsRepository';
import ManagerChatsRepository, { CreateParams as ManagerCreateParams } from 'src/repositories/manager/ChatsRepository';
import SuperAdminChatsRepository, {
  CreateParams as SuperAdminCreateParams,
} from 'src/repositories/superAdmin/ChatsRepository';
import { createAsyncAction } from 'src/utils/createAsyncAction';

export type MessengerSliceStateType = {
  channelId: string;
  unreadMessagesCount: number;
  isMessengerInstanceReady: boolean;
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

type CreateChatType<CreateParams> = (params: CreateParams) => {
  unwrap: () => Promise<SingleResponse<Chat>>;
};

export type MessengerSliceActionsType = {
  setUnreadMessagesCount: (count: number) => void;
  setIsMessengerInstanceReady: (status: boolean) => void;
  resetChannelId: () => void;
  setChannelId: (id: string) => void;
  createTalentChat: CreateChatType<TalentCreateParams>;
  createManagerChat: CreateChatType<ManagerCreateParams>;
  createSuperAdminChat: CreateChatType<SuperAdminCreateParams>;
  createChat: CreateChatType<{ id: ID; role: UserRole }>;
};

const initialState: MessengerSliceStateType = {
  channelId: '',
  unreadMessagesCount: 0,
  isMessengerInstanceReady: false,
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const createTalentChat = createAsyncAction('messenger/talent/create', TalentsChatsRepository.create);
export const createManagerChat = createAsyncAction('messenger/manager/create', ManagerChatsRepository.create);
export const createSuperAdminChat = createAsyncAction('messenger/superAdmin/create', SuperAdminChatsRepository.create);

const messengerSlice = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    setUnreadMessagesCount: (state, { payload }: PayloadAction<number>) => {
      state.unreadMessagesCount = payload;
    },
    setIsMessengerInstanceReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isMessengerInstanceReady = payload;
    },
    resetChannelId: state => {
      state.channelId = '';
    },
    setChannelId: (state, { payload }) => {
      state.channelId = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(createTalentChat.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createTalentChat.fulfilled, (state, { payload }) => {
      state.create.fetchStatus = FetchStatus.fulfilled;
      state.channelId = payload.data?.channelId;
    });
    builder.addCase(createTalentChat.rejected, state => {
      state.create.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(createManagerChat.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createManagerChat.fulfilled, (state, { payload }) => {
      state.create.fetchStatus = FetchStatus.fulfilled;
      state.channelId = payload.data?.channelId;
    });
    builder.addCase(createManagerChat.rejected, state => {
      state.create.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(createSuperAdminChat.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createSuperAdminChat.fulfilled, (state, { payload }) => {
      state.create.fetchStatus = FetchStatus.fulfilled;
      state.channelId = payload.data?.channelId;
    });
    builder.addCase(createSuperAdminChat.rejected, state => {
      state.create.fetchStatus = FetchStatus.failed;
    });
  },
});

export const {
  actions: { setUnreadMessagesCount, setIsMessengerInstanceReady, resetChannelId, setChannelId },
} = messengerSlice;

export default messengerSlice.reducer;
