import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const styles: SxStyles = {
  editableBox: {
    position: 'relative',
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover .editableBoxMain': {
      borderColor: COLORS.orange9,
    },
    '&:hover .editableBoxAside': {
      opacity: 1,
    },
  },
  editableBoxMain: {
    flexGrow: 1,
    paddingX: 3,
    paddingY: 2,
    marginRight: '48px',
    background: COLORS.orange11,
    borderRadius: '8px',
    border: '1px solid transparent',
  },
  editableBoxAside: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    transition: 'opacity 0.3s ease',
    opacity: 0,
    '& .actionButton': {
      width: '40px',
      minWidth: 'auto',
      height: '40px',
      padding: '8px',
    },
    '& .actionButton:not(:last-child)': {
      marginBottom: '6px',
    },
  },
};

export default styles;
