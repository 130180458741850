import camelcase from 'camelcase';
import { head, isNil } from 'ramda';
import * as yup from 'yup';

import { ValidationError } from 'src/enums/Validation';
import { LibraryDocumentType } from 'src/enums/DocumentLibrary';
import { EngagementPlace } from 'src/enums/Engagement';
import { ManagerRequiredDocument } from 'src/types/resources/RequiredDocument';
import { DocumentFile } from 'src/forms/talents/documents';
import { ONE_OF_CHECKBOXES_TRUE, VALID_FILE_SIZE, getValidFileTypeTest } from 'src/forms/customRules';
import { DOCUMENT_LIBRARY_VALID_FILE_TYPES } from 'src/utils/constants';
import { CreateParams } from 'src/repositories/manager/RequiredDocumentsRepository';

export const answerTypeValues = Object.values(LibraryDocumentType);
const defaultAnswerType = head(answerTypeValues);

export type DocumentLibraryFormData = {
  name: string;
  description?: string;
  internalComment?: string;
  places: {
    remote: boolean;
    onSite: boolean;
  };
  answerType?: LibraryDocumentType;
  isActive?: boolean;
  file?: DocumentFile | null;
};

export const answerTypeSchema = yup.mixed().oneOf(answerTypeValues).default(defaultAnswerType);

export const libraryFileSchema = yup.object().when('answerType', {
  is: (answerType: LibraryDocumentType) => answerType === LibraryDocumentType.upload,
  then: yup
    .object()
    .nullable()
    .shape({
      url: yup.string().nullable().default(null),
      source: yup
        .mixed()
        .nullable()
        .test(getValidFileTypeTest(DOCUMENT_LIBRARY_VALID_FILE_TYPES))
        .test(VALID_FILE_SIZE)
        .default(null),
    }),
  otherwise: yup
    .object()
    .nullable()
    .shape({
      url: yup.string().nullable().default(null),
      source: yup
        .mixed()
        .nullable()
        .test(getValidFileTypeTest(DOCUMENT_LIBRARY_VALID_FILE_TYPES))
        .test(VALID_FILE_SIZE)
        .when('url', {
          is: (value: string) => isNil(value),
          then: schema => schema.required(ValidationError.requiredFile),
        })
        .default(null),
    }),
});

export const validationFields = {
  name: yup.string().required(ValidationError.default).default(''),
  description: yup.string().default(''),
  internalComment: yup.string().default(''),
  places: yup
    .object()
    .shape({
      remote: yup.boolean().default(false),
      onSite: yup.boolean().default(false),
    })
    .test(ONE_OF_CHECKBOXES_TRUE),
  answerType: answerTypeSchema,
  file: libraryFileSchema,
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (document?: ManagerRequiredDocument): DocumentLibraryFormData => {
  const defaultFormData = validationSchema.getDefault();

  if (isNil(document)) {
    return {
      ...defaultFormData,
      file: {
        source: null,
        url: null,
      },
    };
  }

  const { places: allPlaces } = defaultFormData;

  const checkedPlaces = document.places.reduce((acc, place) => ({ ...acc, [camelcase(place)]: true }), {});

  return {
    ...document,
    places: { ...allPlaces, ...checkedPlaces },
    file: {
      source: null,
      url: document.file,
      name: document.originalFileName,
    },
  };
};

export const attributesToSubmit = (values: DocumentLibraryFormData): CreateParams => {
  const { places, file, answerType, ...restValues } = values;

  const placesForSubmit = Object.entries(places)
    .filter(([, value]) => Boolean(value))
    .map(([key]) => EngagementPlace[key]);

  const isFileChanged = !isNil(file?.source) && isNil(file?.url);

  const dataToSubmit = {
    ...restValues,
    answerType,
    places: placesForSubmit,
  };

  if (isFileChanged) {
    return {
      ...dataToSubmit,
      file: file?.source,
      originalFileName: file?.source.name,
    };
  }

  return dataToSubmit;
};
