import { AvailabilityFormDataToSubmit } from 'src/forms/talents/availability';
import apiRoutes from 'src/routes/apiRoutes';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';
import Availability from 'src/types/resources/Availability';

export type ShowParams = {
  id: number;
};
export type CreateParams = AvailabilityFormDataToSubmit;
export type UpdateParams = {
  id: number;
  params: AvailabilityFormDataToSubmit;
};
export type DeleteParams = {
  id: number;
};

export default {
  index(): Promise<ListResponse<Availability>> {
    const url = apiRoutes.talent.availabilitiesPath();
    return Fetcher.get(url);
  },
  show({ id }: ShowParams): Promise<SingleResponse<Availability>> {
    const url = apiRoutes.talent.availabilityPath(id);
    return Fetcher.get(url);
  },
  create(params: CreateParams): Promise<SingleResponse<Availability>> {
    const url = apiRoutes.talent.availabilitiesPath();
    return Fetcher.post(url, params);
  },
  update({ id, params }: UpdateParams): Promise<SingleResponse<Availability>> {
    const url = apiRoutes.talent.availabilityPath(id);
    return Fetcher.put(url, params);
  },
  delete({ id }: DeleteParams): Promise<void> {
    const url = apiRoutes.talent.availabilityPath(id);
    return Fetcher.delete(url);
  },
};
