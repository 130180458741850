import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const styles: SxStyles = {
  wrapper: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    marginBottom: '10px',
    '&:hover button': {
      visibility: 'visible',
      opacity: '1',
    },
  },
  description: {
    padding: '14px 0px 2px 23px',
    border: `1px solid ${COLORS.orange9}`,
    backgroundColor: COLORS.orange11,
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    color: COLORS.black80,
  },
  list: {
    margin: '12px 0',
    padding: '0 0 0 20px',
    listStyle: 'unset',
    listStyleType: 'disc',
  },
  listItem: {
    display: 'list-item',
    padding: '0',
  },
  listTitle: {
    marginBottom: '7px',
    color: 'inherit',
  },
  listTitleTerm: {
    marginRight: '5px',
    fontWeight: 700,
    color: 'inherit',
  },
  buttons: {
    width: '42px',
    marginLeft: '8px',
  },
  button: {
    marginBottom: '6px',
    visibility: 'hidden',
    opacity: '0',
    transition: 'all .3s ease',
    borderRadius: '6px',
    padding: '7px',
    border: `1px solid ${COLORS.black20}`,
    '& > svg': {
      fill: COLORS.black80,
    },
  },
};

export default styles;
