import { ManagerRequiredDocument } from 'src/types/resources/RequiredDocument';
import { SingleResponse, ListResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  page?: number;
  ordering?: string;
};

export type CreateParams = Omit<ManagerRequiredDocument, 'id' | 'file'> & {
  file?: File | string;
};

export type UpdateParams = {
  id: ID;
  params: Partial<CreateParams>;
};

export type DeleteParams = {
  id: ID;
};

export default {
  index(params: IndexParams = { page: 1, ordering: '' }): Promise<ListResponse<ManagerRequiredDocument>> {
    const url = apiRoutes.manager.requiredDocumentsPath();
    return Fetcher.get(url, params);
  },
  create(params: CreateParams): Promise<SingleResponse<ManagerRequiredDocument>> {
    const url = apiRoutes.manager.requiredDocumentsPath();
    return Fetcher.postFormData(url, params);
  },
  update({ id, params }: UpdateParams): Promise<SingleResponse<ManagerRequiredDocument>> {
    const url = apiRoutes.manager.requiredDocumentPath(id);
    return Fetcher.updateFormData(url, params);
  },
  partialUpdate({ id, params }: UpdateParams): Promise<SingleResponse<ManagerRequiredDocument>> {
    const url = apiRoutes.manager.requiredDocumentPath(id);
    return Fetcher.partialUpdateFormData(url, params);
  },
  deactivate({ id, params }: UpdateParams): Promise<SingleResponse<ManagerRequiredDocument>> {
    const url = apiRoutes.manager.requiredDocumentDeactivatePath(id);
    return Fetcher.postFormData(url, params);
  },
  delete({ id }: DeleteParams): Promise<void> {
    const url = apiRoutes.manager.requiredDocumentPath(id);
    return Fetcher.delete(url);
  },
};
