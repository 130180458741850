import { DocumentsFormDataToSubmit } from 'src/forms/talents/documents';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import DocumentsResources from 'src/types/resources/Documents';

export type UpdateParams = {
  params: DocumentsFormDataToSubmit;
};

export default {
  index(): Promise<{ data: DocumentsResources }> {
    const url = apiRoutes.talent.documentsPath();
    return Fetcher.get(url);
  },
  partialUpdate({ params }: UpdateParams): Promise<{ data: DocumentsResources }> {
    const url = apiRoutes.talent.documentsPath();
    return Fetcher.partialUpdateFormData(url, params);
  },
};
