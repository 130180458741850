import { isNil } from 'ramda';
import { useEffect, useState } from 'react';
import { Autocomplete, TextField, Popper, CircularProgress, PopperProps } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { Address } from 'src/types/address';
import { DEBOUNCE_WAIT } from 'src/utils/fetcher';
import { formatAddress as formatOption } from 'src/utils/addressAutocomplete';
import useSnackbar from 'src/hooks/useSnackbar';
import useLoading from 'src/hooks/useLoading';
import useAddressAutocomplete from 'src/hooks/useAddressAutocomplete';

type AddressAutocompleteProps = {
  onChangeAddress: (address: Address) => void;
  setFieldValue?: (field: string, value: unknown, shouldValidate?: boolean | undefined) => Promise<unknown>;
  setFieldError?: (field: string, value: string | undefined) => void;
  setFieldTouched?: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => Promise<unknown>;
  initialValue?: string;
  error?: string;
};

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = props => {
  const {
    initialValue = '',
    error = '',
    onChangeAddress,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    ...restProps
  } = props;
  const [isAutocompleteOpened, setIsAutocompleteOpened] = useState<boolean>(false);
  const [autocompleteOption, setAutocompleteOption] = useState<Address | string | null>({
    streetLine: initialValue || '',
  } as Address);
  const [inputValue, setInputValue] = useState<string>(initialValue);
  const [options, setOptions] = useState<Address[]>([]);
  const { queryAutocompleteForAddresses } = useAddressAutocomplete();
  const { enqueueErrorSnackbar } = useSnackbar();

  const fetchAddresses = async (query: string, hasSecondaries = false) => {
    try {
      const lookup = await queryAutocompleteForAddresses(query, hasSecondaries);
      setOptions(lookup.result);
    } catch (fetchError) {
      enqueueErrorSnackbar('Failed to search the address');
    }
  };

  const { funcWithLoading: fetchAddressesWithLoading, isPending: isPendingFetchAddresses } = useLoading(fetchAddresses);

  const debouncedFetchAddresses = useDebouncedCallback(fetchAddressesWithLoading, DEBOUNCE_WAIT);

  const handleAutocompleteValueChange = async (event: React.SyntheticEvent, newOption: Address | string) => {
    await setFieldError('address1', undefined);
    if (typeof newOption === 'string') return;

    if (newOption?.entries > 1) {
      await debouncedFetchAddresses(formatOption(newOption), true);
      setIsAutocompleteOpened(true);
    } else {
      setAutocompleteOption(newOption);

      if (isNil(newOption)) {
        setFieldValue('address1', null);
        return;
      }

      onChangeAddress(newOption);
    }
  };

  const handleAutocompleteInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
    if (!newInputValue) {
      setOptions([]);
      return;
    }
    debouncedFetchAddresses(newInputValue);
  };

  const handleAutocompleteOpen = () => {
    setIsAutocompleteOpened(true);
  };

  const handleAutocompleteClose = () => {
    setIsAutocompleteOpened(false);
    setFieldTouched('address1');
  };

  const renderAddressOption = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Address) => {
    const { id: optionId } = optionProps;

    const addressContent = formatOption(option, 'more entries');

    return (
      <li {...optionProps} key={optionId}>
        {addressContent}
      </li>
    );
  };

  useEffect(() => {
    if (initialValue) {
      setAutocompleteOption({ streetLine: initialValue } as Address);
    }
  }, [initialValue]);

  const popper = (popperProps: PopperProps) => (inputValue ? <Popper {...popperProps} /> : null);

  return (
    <Autocomplete
      {...restProps}
      open={isAutocompleteOpened}
      fullWidth
      autoHighlight
      value={autocompleteOption}
      inputValue={inputValue}
      filterOptions={x => x}
      options={options}
      getOptionLabel={option => option.streetLine}
      loading={isPendingFetchAddresses}
      onChange={handleAutocompleteValueChange}
      onInputChange={handleAutocompleteInputChange}
      renderOption={renderAddressOption}
      onOpen={handleAutocompleteOpen}
      onClose={handleAutocompleteClose}
      PopperComponent={popper}
      renderInput={params => (
        <TextField
          {...params}
          label="Address Line 1"
          error={Boolean(error)}
          helperText={Boolean(error) && error}
          placeholder="Begin typing an address..."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isPendingFetchAddresses ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            inputProps: {
              ...params.inputProps,
              id: 'address1',
              autoComplete: 'new-password', // disable autocomplete and autofill
            },
          }}
        />
      )}
    />
  );
};

export default AddressAutocomplete;
