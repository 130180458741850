import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  modalWrapper: {
    padding: '25px',
    width: '512px',
    boxSizing: 'border-box',
  },
  title: {
    marginBottom: '24px',
  },
  description: {
    marginBottom: '24px',
  },
  checkboxList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  controlLabel: {
    marginLeft: 0,
  },
  buttons: {
    marginTop: '40px',
  },
  button: {
    padding: '8px 48px',
    marginRight: '10px',
  },
};

export default styles;
