import { ComponentsOverrides, ComponentsProps, tableCellClasses } from '@mui/material';

type MuiTableRowType = {
  defaultProps: ComponentsProps['MuiTableRow'];
  styleOverrides: ComponentsOverrides['MuiTableRow'];
};

const MuiTableRow: MuiTableRowType = {
  styleOverrides: {
    root: {
      position: 'relative',
      [`&:last-of-type > .${tableCellClasses.body}`]: {
        border: 'none',
        [`& tr:last-of-type > td`]: {
          border: 0,
        },
      },
    },
  },
  defaultProps: {},
};

export default MuiTableRow;
