import { head, isNil, pick } from 'ramda';
import { useMemo, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Alert as MuiAlert, Button, FormHelperText, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { TimeSheetState as TimeSheetStateEnum } from 'src/enums/TimeSheet';
import { attributesToSubmit, initialValues, TimeSheetsFormData, validationSchema } from 'src/forms/talents/timeSheet';
import EngagementTimeSheetPresenter from 'src/presenters/EngagementTimeSheetPresenter';
import NestedTimeSheetPresenter from 'src/presenters/NestedTimeSheetPresenter';
import { IndexParams } from 'src/repositories/talents/TimeSheetsRepository';
import EngagementTimeSheet from 'src/types/resources/EngagementTimeSheet';
import { NestedTimeSheet } from 'src/types/resources/TimeSheet';
import clsx from 'src/utils/clsx';
import { getTimeSheetLongDate, MAX_HOURS_PER_DAY } from 'src/utils/date';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useModals from 'src/hooks/useModals';
import useTimeSheets from 'src/hooks/talents/useTimeSheets';
import Box from 'src/components/Box';
import TypographyNoWrap from 'src/components/TypographyNoWrap';
import TimeTrackingTotalTime from 'src/components/TimeTrackingTotalTime';
import TimeFields from 'src/components/TimeTracking/components/TimeFields';
import PurchaseOrderSelect from 'src/components/TimeTracking/components/PurchaseOrderSelect';

import AlertPayPeriodAmountChanged from './components/AlertPayPeriodAmountChanged';
import EditButton from './components/EditButton';
import styles from './styles';

type CreateTimeSheetEntryFormProps = {
  engagementTimeSheet?: EngagementTimeSheet;
  parentTimeSheet?: NestedTimeSheet;
  adjustmentTimeSheet?: NestedTimeSheet;
  timekeepingDate: string;
  searchParams: IndexParams;
};

type FieldsInEditState = Partial<Record<keyof TimeSheetsFormData, boolean>>;

const getInitialFieldsInEditState = (isEditFormState: boolean): FieldsInEditState => ({
  purchaseOrderId: isEditFormState,
  hours: isEditFormState,
  talentComment: isEditFormState,
});

const CreateTimeSheetAdjustmentForm: React.FC<CreateTimeSheetEntryFormProps> = props => {
  const { engagementTimeSheet, parentTimeSheet, adjustmentTimeSheet, timekeepingDate, searchParams } = props;

  const isAddFormState = isNil(adjustmentTimeSheet);
  const originalTimeSheet = isNil(parentTimeSheet) ? null : parentTimeSheet;

  const [formError, setFormError] = useState<string | null>(null);
  const [focusedTimeField, setFocusedTimeField] = useState<'hours' | 'minutes' | null>(null);
  const [fieldsInEditState, setFieldsInEditState] = useState<FieldsInEditState>(
    getInitialFieldsInEditState(!isAddFormState),
  );

  const { hideModal } = useModals();
  const {
    loadEngagementTimeSheets,
    loadTotalEngagementTimeSheet,
    createEngagementTimeSheet,
    updateEngagementTimeSheet,
  } = useTimeSheets();

  const isTimeSheetPOExist = !isNil(adjustmentTimeSheet?.purchaseOrder);

  const isPONumberEditState = fieldsInEditState.purchaseOrderId;
  const isHoursEditState = fieldsInEditState.hours;
  const isTalentCommentEditState = fieldsInEditState.talentComment;

  const isTimeSheetOpen = NestedTimeSheetPresenter.isOpen(adjustmentTimeSheet);
  const isTimeSheetDeclined = NestedTimeSheetPresenter.isDeclined(adjustmentTimeSheet);

  const isEmptyPO = EngagementTimeSheetPresenter.isEmptyPO(engagementTimeSheet);
  const isSinglePO = EngagementTimeSheetPresenter.isSinglePO(engagementTimeSheet);
  const isMultiplePO = EngagementTimeSheetPresenter.isMultiplePO(engagementTimeSheet);

  const isSelectPOReadOnly = isEmptyPO || (isSinglePO && (isTimeSheetOpen || isTimeSheetPOExist));

  const handleSubmit = async (formData: TimeSheetsFormData, { setErrors }: FormikHelpers<TimeSheetsFormData>) => {
    const params = attributesToSubmit(engagementTimeSheet.id, timekeepingDate, formData, parentTimeSheet?.id);
    setFormError(null);
    try {
      if (isAddFormState) {
        await createEngagementTimeSheet(params).unwrap();
      } else {
        await updateEngagementTimeSheet({
          id: adjustmentTimeSheet.id,
          params: { ...params, state: isTimeSheetDeclined ? TimeSheetStateEnum.pending : undefined },
        }).unwrap();
      }
      loadTotalEngagementTimeSheet(pick(['payPeriodAfter', 'payPeriodBefore'], searchParams)).unwrap();
      await loadEngagementTimeSheets(searchParams).unwrap();
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setErrors(parseToFormikErrors(errors));
        setFormError(head(error.response.data.errors?.nonFieldErrors || []));
      }
    }
  };

  const {
    initialValues: initialFormikValues,
    values,
    errors,
    touched,
    dirty,
    isSubmitting,
    handleChange,
    handleSubmit: handleFormikSubmit,
    setFieldValue,
  } = useFormik<TimeSheetsFormData>({
    initialValues: initialValues(engagementTimeSheet, adjustmentTimeSheet || parentTimeSheet),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const purchaseOrderSelectValues = useMemo(
    () =>
      engagementTimeSheet.po?.map(engagementPO => ({
        value: engagementPO?.id,
        label: NestedTimeSheetPresenter.poLabel(engagementPO),
      })),
    [],
  );

  const handleHoursFieldChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    const parsedValue = parseInt(value, 10);
    if (parsedValue > MAX_HOURS_PER_DAY) return;
    await setFieldValue('hours', Number.isNaN(parsedValue) ? 0 : parsedValue);
    if (!values.minutes) {
      await setFieldValue('minutes', 0);
    }
  };

  const handleMinutesChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    await setFieldValue('minutes', value);
    if (!values.hours) {
      await setFieldValue('hours', 0);
    }
  };

  const handleTimeFieldFocus = (fieldName: 'hours' | 'minutes') => () => {
    setFocusedTimeField(fieldName);
  };

  const handleTimeFieldBlur = async () => {
    setFocusedTimeField(null);
  };

  const handlePOChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newPurchaseOrderId = event.target.value;
    await setFieldValue('purchaseOrderId', newPurchaseOrderId);
  };

  const handleFieldInEditStateChange = (fieldName: keyof TimeSheetsFormData) => () => {
    setFieldsInEditState(prevState => ({ ...prevState, [fieldName]: !prevState[fieldName] }));
    if (fieldName === 'hours') {
      setFieldValue('hours', initialFormikValues.hours);
      setFieldValue('minutes', initialFormikValues.minutes);
    } else {
      setFieldValue(fieldName, initialFormikValues[fieldName]);
    }
  };

  return (
    <>
      {formError && <MuiAlert severity="error">{formError}</MuiAlert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.wrapper}>
          <Typography sx={styles.title} variant="h3">
            Adjustment: {getTimeSheetLongDate(timekeepingDate)}
          </Typography>
          <Stack sx={clsx(styles.row, [[styles.rowWithColumn, true]])}>
            <Stack sx={styles.fieldsColumn}>
              <Box sx={styles.originalFieldColumn}>
                <Stack sx={styles.formFieldContainer}>
                  <Typography sx={styles.label} variant="button" component="span">
                    {`${isPONumberEditState ? 'Original PO' : 'PO'}:`}
                  </Typography>
                  <Box sx={styles.poContainer}>
                    <TypographyNoWrap sx={styles.originalValue} variant="button" tooltipVariant="light">
                      {NestedTimeSheetPresenter.poLabel(originalTimeSheet?.purchaseOrder)}
                    </TypographyNoWrap>
                  </Box>
                </Stack>
                {touched.purchaseOrderId && Boolean(errors.purchaseOrderId) && (
                  <FormHelperText error>{errors.purchaseOrderId}</FormHelperText>
                )}
              </Box>
              <Box sx={styles.newFieldColumn}>
                {isPONumberEditState && (
                  <Stack sx={styles.formFieldContainer} direction="row" alignItems="center">
                    <Typography sx={styles.label} variant="button" component="span">
                      New PO:
                    </Typography>
                    <Box sx={styles.poContainer}>
                      <PurchaseOrderSelect
                        onChange={handlePOChange}
                        value={values.purchaseOrderId || ''}
                        values={purchaseOrderSelectValues}
                        error={touched.purchaseOrderId && Boolean(errors.purchaseOrderId)}
                        placeholder={isEmptyPO ? 'PO not assigned' : 'Choose PO'}
                        isReadOnly={isSelectPOReadOnly}
                        isDisabled={!isMultiplePO && isTimeSheetOpen}
                      />
                    </Box>
                  </Stack>
                )}
              </Box>
            </Stack>
            <EditButton isEditState={isPONumberEditState} onClick={handleFieldInEditStateChange('purchaseOrderId')} />
          </Stack>
          <Stack sx={clsx(styles.row, [[styles.rowWithColumn, true]])}>
            <Stack sx={styles.fieldsColumn}>
              <Box sx={styles.originalFieldColumn}>
                <Stack sx={styles.formFieldContainer} direction="row" alignItems="center">
                  <Typography sx={styles.label} variant="button" component="span">
                    {`${isHoursEditState ? 'Original Worked Hours' : 'Worked Hours'}:`}
                  </Typography>
                  <TimeTrackingTotalTime
                    sx={styles.originalValue}
                    time={originalTimeSheet?.hours || '0.00'}
                    isTotalVisible={false}
                    isSmall
                  />
                </Stack>
                {touched.hours && Boolean(errors.hours) && <FormHelperText error>{errors.hours}</FormHelperText>}
              </Box>
              <Box sx={styles.newFieldColumn}>
                {isHoursEditState && (
                  <Stack sx={styles.formFieldContainer}>
                    <Typography sx={styles.label} variant="button" component="span">
                      New Worked Hours:
                    </Typography>
                    <TimeFields
                      HoursFieldProps={{
                        value: values.hours ?? '',
                        error: touched.hours && Boolean(errors.hours),
                        onChange: handleHoursFieldChange,
                        onBlur: handleTimeFieldBlur,
                        onFocus: handleTimeFieldFocus('hours'),
                      }}
                      MinutesFieldProps={{
                        value: values.minutes ?? '',
                        error: touched.hours && Boolean(errors.hours),
                        onChange: handleMinutesChange,
                        onFocus: handleTimeFieldFocus('minutes'),
                      }}
                      focusedTimeField={focusedTimeField}
                    />
                  </Stack>
                )}
              </Box>
            </Stack>
            <EditButton isEditState={isHoursEditState} onClick={handleFieldInEditStateChange('hours')} />
          </Stack>
          {!isNil(values.hours) && !isNil(values.minutes) && (
            <Box sx={styles.row}>
              <AlertPayPeriodAmountChanged
                originalHours={Number(originalTimeSheet?.hours) || 0}
                newHours={values.hours + values.minutes}
                hourlyFee={engagementTimeSheet.hourlyFee}
              />
            </Box>
          )}
          <Box sx={styles.row}>
            <Stack sx={styles.descriptionHeader}>
              <FormLabel sx={styles.label}>Description:</FormLabel>
              <EditButton
                isEditState={isTalentCommentEditState}
                onClick={handleFieldInEditStateChange('talentComment')}
              />
            </Stack>
            {isTalentCommentEditState ? (
              <TextField
                id="talentComment"
                name="talentComment"
                type="textarea"
                multiline
                rows={2}
                sx={styles.textarea}
                value={values.talentComment}
                onChange={handleChange}
                placeholder={isAddFormState ? originalTimeSheet?.talentComment : ''}
                error={touched.talentComment && Boolean(errors.talentComment)}
                helperText={touched.talentComment && errors.talentComment}
              />
            ) : (
              <Typography>{originalTimeSheet?.talentComment}</Typography>
            )}
          </Box>
          <Box sx={styles.footer}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={styles.button}
              loading={isSubmitting}
              disabled={!dirty}
            >
              Save
            </LoadingButton>
            <Button variant="outlined" onClick={hideModal} sx={styles.button}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default CreateTimeSheetAdjustmentForm;
