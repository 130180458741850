import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  box: {
    width: { tablet: '520px' },
    minHeight: { tablet: '610px' },
    padding: { tablet: '24px 40px 40px' },
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    marginBottom: 4,
    textAlign: 'left',
  },
  row: {
    marginBottom: 3,
  },
  dateRow: {
    position: 'relative',
    justifyContent: 'flex-end',
    '&:before': {
      content: '"—"',
      position: 'absolute',
      top: '48px',
      left: '50%',
      lineHeight: 0,
      transform: 'translateX(-50%)',
    },
    '.availableToDateField': {
      marginTop: '28px',
    },
  },
  paper: {
    overflow: 'visible',
    margin: 0,
    transform: 'translateY(-8px)',
    borderRadius: '8px',
  },
  footer: {
    marginTop: 'auto',
  },
  button: {
    padding: '7px 47px',
    marginRight: '10px',
  },
};

export default styles;
