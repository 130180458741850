import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  selectedMenuItem: {
    color: COLORS.black100,
  },
};

export default styles;
