import { useState } from 'react';
import { Alert, Button, FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { head, isEmpty, isNil, omit } from 'ramda';

import { CreateDepartmentVariant } from 'src/enums/Department';
import {
  attributesToSubmit,
  CreateDepartmentFormData,
  initialValues,
  validationSchema,
} from 'src/forms/superAdmin/createDepartment';
import { Department } from 'src/types/resources/Department';
import { Organization } from 'src/types/resources/Organization';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useOrganizations from 'src/hooks/superAdmin/useOrganizations';
import useDepartments from 'src/hooks/superAdmin/useDepartments';
import useModals from 'src/hooks/useModals';
import useSnackbar from 'src/hooks/useSnackbar';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Box from 'src/components/Box';

import styles from './styles';

type CreateDepartmentFormProps = {
  organization?: Organization;
  department?: Department;
};

const CreateDepartmentForm: React.FC<CreateDepartmentFormProps> = props => {
  const { organization, department } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const [isCancelWarningVisible, setIsCancelWarningVisible] = useState<boolean>(false);
  const { showOrganization } = useOrganizations();
  const { loadDepartments, createDepartment, updateDepartment } = useDepartments();
  const { hideModal, hideAllModals } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();

  const isAddForm = isNil(department);
  const isDepartmentsExist = organization?.departmentsNum > 0;
  const formTitle = isAddForm ? 'Add Departments' : 'Edit Departments';
  const snackbarText = isAddForm ? 'Departments was created' : 'Departments was updated';
  const subtitleText = isAddForm
    ? `You are adding a Department to ${organization?.name}.`
    : `You are editing a Department in ${organization?.name}.`;

  const handleSubmit = async (
    formData: CreateDepartmentFormData,
    { setErrors, resetForm }: FormikHelpers<CreateDepartmentFormData>,
  ) => {
    const params = attributesToSubmit(formData, organization.id);
    const isSaveSubmit = formData.submitVariant === CreateDepartmentVariant.save;
    const isSaveAndAddSubmit = formData.submitVariant === CreateDepartmentVariant.saveAndAdd;

    setFormError(null);

    try {
      if (isAddForm) {
        await createDepartment(params).unwrap();
      } else {
        await updateDepartment({ id: department.id, params }).unwrap();
      }

      if (isSaveSubmit) {
        hideModal();
      }

      if (isSaveAndAddSubmit) {
        resetForm();
      }

      if (!isDepartmentsExist) {
        await showOrganization({ id: organization.id }).unwrap();
      }

      loadDepartments({ organizationId: organization.id }).unwrap();
      enqueueSuccessSnackbar(snackbarText);
    } catch (error) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(head(error.response.data.errors?.nonFieldErrors || []));
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit: handleFormikSubmit,
    setFieldValue,
    submitForm,
  } = useFormik<CreateDepartmentFormData>({
    initialValues: initialValues(department),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const isFormValuesEmpty = Object.values(omit(['submitVariant'], values)).every(isEmpty);

  const handleFormSave = async () => {
    await setFieldValue('submitVariant', CreateDepartmentVariant.save);
    submitForm();
  };

  const handleFormSaveAndAdd = async () => {
    await setFieldValue('submitVariant', CreateDepartmentVariant.saveAndAdd);
    submitForm();
  };

  const handleFormCancel = () => {
    setIsCancelWarningVisible(true);
  };

  const handleConfirmFormCancel = async () => {
    hideAllModals();
  };

  const handleUnсonfirmFormCancel = async () => {
    setIsCancelWarningVisible(false);
  };

  const renderCancelWarning = () => {
    return (
      <ConfirmationDialog
        title="Warning!"
        description={
          'You have populated fields. All this information will be discarded.\nAre you sure you want to cancel Department adding?'
        }
        confirmButtonText="Yes, Discard"
        dismissButtonText="No, Continue Adding"
        onConfirm={handleConfirmFormCancel}
        onDismiss={handleUnсonfirmFormCancel}
      />
    );
  };

  const renderForm = () => {
    return (
      <>
        <Typography variant="h3" sx={styles.title}>
          {formTitle}
        </Typography>
        <Typography variant="caption" sx={styles.subtitle} component="p">
          {subtitleText}
        </Typography>
        {!isDepartmentsExist && (
          <>
            <Typography variant="caption" sx={styles.subtitle} component="p">
              You need to create at least one Department.
            </Typography>
            <Typography variant="caption" sx={styles.subtitle} component="p">
              You can add more later in the Organization Departments view.
            </Typography>
          </>
        )}
        <form noValidate onSubmit={handleFormikSubmit}>
          <Box sx={styles.row}>
            <TextField
              id="name"
              label="Department Name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </Box>
          <Box sx={styles.row}>
            <TextField
              id="depId"
              label="Department ID"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.depId}
              error={touched.depId && Boolean(errors.depId)}
              helperText={touched.depId && errors.depId}
              sx={styles.departmentId}
            />
          </Box>
          <Box sx={styles.row}>
            <FormControl component="div" variant="standard">
              <FormLabel
                htmlFor="description"
                component="label"
                error={touched.description && Boolean(errors.description)}
              >
                Description
              </FormLabel>
              <TextField
                id="description"
                type="textarea"
                multiline
                rows={2}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
                placeholder="Optional, max limit is 100 symbols."
              />
            </FormControl>
          </Box>
          <Box sx={styles.buttons}>
            <Button variant="contained" sx={styles.button} disabled={isSubmitting} onClick={handleFormSave}>
              Save
            </Button>
            {isAddForm && (
              <Button variant="outlined" sx={styles.button} disabled={isSubmitting} onClick={handleFormSaveAndAdd}>
                Save &amp; Add
              </Button>
            )}
            {isDepartmentsExist && (
              <Button
                variant="outlined"
                sx={styles.button}
                disabled={isSubmitting}
                onClick={!isAddForm || isFormValuesEmpty ? hideModal : handleFormCancel}
              >
                Cancel
              </Button>
            )}
          </Box>
        </form>
      </>
    );
  };

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      {isCancelWarningVisible ? renderCancelWarning() : <Box sx={styles.modalWrapper}>{renderForm()}</Box>}
    </>
  );
};

export default CreateDepartmentForm;
