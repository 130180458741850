import { FileType } from 'src/enums/Validation';
import { EngagementStatus } from 'src/enums/Engagement';

export const PAGINATION_ROWS_PER_PAGE = 50;
export const ORGANIZATION_TIME_SHEETS_PAGINATION_ROWS_PER_PAGE = 100;

export const MIN_PASSWORD_LENGTH = 9;

export const IMAGE_VALID_FILE_TYPES = [FileType.jpg, FileType.jpeg, FileType.png, FileType.gif];

export const CERTIFICATE_VALID_FILE_TYPES = [
  FileType.jpg,
  FileType.jpeg,
  FileType.png,
  FileType.gif,
  FileType.tiff,
  FileType.doc,
  FileType.docx,
  FileType.xls,
  FileType.xlsx,
  FileType.pdf,
  FileType.psd,
  FileType.eps,
  FileType.ai,
  FileType.indd,
  FileType.raw,
];

export const DOCUMENT_LIBRARY_VALID_FILE_TYPES = [
  FileType.jpg,
  FileType.jpeg,
  FileType.png,
  FileType.doc,
  FileType.docx,
  FileType.xls,
  FileType.xlsx,
  FileType.pdf,
  FileType.csv,
];

export const REQUIRED_DOCUMENT_VALID_FILE_TYPES = [
  FileType.jpg,
  FileType.jpeg,
  FileType.png,
  FileType.doc,
  FileType.docx,
  FileType.pdf,
];

export const TALENT_VIDEO_VALID_FILE_TYPES = [FileType.mp4, FileType.webm, FileType.mov];

export const KEY_CODES = {
  SHIFT: 16,
  ENTER: 13,
};

export const ENGAGEMENT_STATUSES = [
  EngagementStatus.active,
  EngagementStatus.pending,
  EngagementStatus.accepted,
  EngagementStatus.terminated,
  EngagementStatus.expired,
  EngagementStatus.declined,
  EngagementStatus.withdrawn,
  EngagementStatus.draft,
];
