import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import TalentOrganization from 'src/types/resources/TalentOrganization';
import { ListResponse } from 'src/types/utils';

export type IndexParams = {
  page?: number;
};

export type TalentOrganizationsListParams = {
  page?: number;
  search?: string;
  ordering?: string;
  showOrgsWithArchivedOpportunities?: boolean;
};

export default {
  index(params: IndexParams = { page: 1 }): Promise<ListResponse<TalentOrganization>> {
    const url = apiRoutes.talent.organizationsPath();
    return Fetcher.get(url, params);
  },
  fetchOrganizationsList(params: TalentOrganizationsListParams): Promise<ListResponse<TalentOrganization>> {
    return Fetcher.get(apiRoutes.talent.organizationNames(), params);
  },
};
