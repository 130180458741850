import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';
import typography from 'src/theme/typography';

type MuiToggleButtonType = {
  defaultProps: ComponentsProps['MuiToggleButton'];
  styleOverrides: ComponentsOverrides['MuiToggleButton'];
};

const MuiToggleButton: MuiToggleButtonType = {
  styleOverrides: {
    root: {
      ...typography.button,
      padding: '5px 15px',
      backgroundColor: COLORS.orange11,
      color: COLORS.orange5,
      borderRadius: '5px',
      border: '1px solid transparent',

      '&.Mui-selected': {
        backgroundColor: COLORS.orange5,
        color: COLORS.white,
        '&:hover': {
          backgroundColor: COLORS.orange6,
        },
      },
      '&.Mui-disabled': {
        borderColor: COLORS.black02,
      },
    },
  },
  defaultProps: {},
};

export default MuiToggleButton;
