import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const styles: SxStyles = {
  searchField: {
    backgroundColor: COLORS.white,
  },
};

export default styles;
