import { Button, Typography } from '@mui/material';
import { useFormik } from 'formik';

import { useModals } from 'src/hooks';
import Box from 'src/components/Box';
import TextEditor from 'src/components/TextEditor';
import {
  attributesToSubmit,
  initialValues,
  OpportunityApplyFormData,
  validationSchema,
} from 'src/forms/talents/opportunityApply';
import { MAX_APPLY_MESSAGE_TEXTAREA_LENGTH } from 'src/enums/Validation';
import { TalentOpportunityApplyInfo } from 'src/features/opportunities/types/talentOpportunityApply';

import styles from './styles';

type Props = {
  handleApply: (data: TalentOpportunityApplyInfo) => void;
};
const TalentOpportunityApplyForm: React.FC<Props> = ({ handleApply }) => {
  const { hideModal } = useModals();

  const handleSubmit = (formData: OpportunityApplyFormData) => {
    const paramsToSubmit = attributesToSubmit(formData);
    handleApply(paramsToSubmit);
  };
  const { values, errors, submitForm, touched, setFieldValue } = useFormik<OpportunityApplyFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h3">Confirm That You Are the Ideal Talent</Typography>
      <Typography>
        Note to applicants: The information you enter here will serve as a crucial prescreen for the position for which
        you are applying. Ensure your entries below highlight your qualifications and directly align with the
        Opportunity. Emphasize relevant skills and experience to make a strong impression and increase your chances of
        success in the application process.
      </Typography>
      <TextEditor
        label="Skills & Qualifications"
        isCounterVisible
        maxValue={MAX_APPLY_MESSAGE_TEXTAREA_LENGTH}
        placeholder="Highlight relevant qualifications and skills that directly align with this Opportunity."
        value={values.skillsAndQualifications}
        onChange={newState => setFieldValue('skillsAndQualifications', newState)}
        error={touched.skillsAndQualifications && errors.skillsAndQualifications}
        usePredefinedMinHeight
      />
      <TextEditor
        label="Experience"
        isCounterVisible
        maxValue={MAX_APPLY_MESSAGE_TEXTAREA_LENGTH}
        placeholder="Highlight previous job experience that is relevant to this Opportunity."
        value={values.experience}
        onChange={newState => setFieldValue('experience', newState)}
        error={touched.experience && errors.experience}
        usePredefinedMinHeight
      />
      <TextEditor
        label="Tell Us More"
        isCounterVisible
        maxValue={MAX_APPLY_MESSAGE_TEXTAREA_LENGTH}
        placeholder="Share with us the reasons that make you a strong candidate for this Opportunity."
        value={values.applyMessage}
        onChange={newState => setFieldValue('applyMessage', newState)}
        error={touched.applyMessage && errors.applyMessage}
        usePredefinedMinHeight
      />
      <Box sx={styles.buttons}>
        <Button variant="contained" sx={styles.button} onClick={submitForm}>
          Apply
        </Button>
        <Button variant="outlined" sx={styles.button} onClick={hideModal}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default TalentOpportunityApplyForm;
