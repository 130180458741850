import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const styles: SxStyles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  row: {
    marginTop: '23px',
    '&:last-of-type': {
      marginTop: 'auto',
    },
  },
  buttons: {
    marginTop: '40px',
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  searchInput: {
    width: { tablet: '400px' },
    '& svg': {
      fill: COLORS.black40,
    },
  },
  label: {
    color: COLORS.black60,
    marginBottom: '8px',
  },
  caption: {
    marginLeft: '20px',
    color: COLORS.black60,
  },
  expertiseButton: {
    textTransform: 'capitalize',
  },
};

export default styles;
