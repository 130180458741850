// DialogActions
import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiDialogType = {
  defaultProps: ComponentsProps['MuiDialogActions'];
  styleOverrides: ComponentsOverrides['MuiDialogActions'];
};

const MuiDialog: MuiDialogType = {
  styleOverrides: {
    root: {
      justifyContent: 'flex-start',
      padding: '12px 24px 24px 24px',
    },
  },
  defaultProps: {},
};

export default MuiDialog;
