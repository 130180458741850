import * as yup from 'yup';

import { DocumentFile } from 'src/forms/talents/documents';
import { getValidFileTypeTest, VALID_FILE_SIZE } from 'src/forms/customRules';
import { CERTIFICATE_VALID_FILE_TYPES } from 'src/utils/constants';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';

export type KnowledgeCenterFAQFeedbackFormData = {
  message: string;
  file: DocumentFile | null;
};

export type KnowledgeCenterFAQFeedbackFormDataToSubmit = {
  message: string;
  file: File;
};

export const validationFields = {
  message: yup.string().max(1000, getMaxFieldLengthErrorMessage(1000)).default(''),
  file: yup
    .object()
    .nullable()
    .shape({
      url: yup.string().nullable().default(null),
      source: yup
        .mixed()
        .nullable()
        .test(getValidFileTypeTest(CERTIFICATE_VALID_FILE_TYPES))
        .test(VALID_FILE_SIZE)
        .default(null),
    }),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = validationSchema.getDefault();

export const attributesToSubmit = (
  values: KnowledgeCenterFAQFeedbackFormData,
): KnowledgeCenterFAQFeedbackFormDataToSubmit => {
  return {
    ...values,
    file: values.file?.source,
  };
};
