import { emptyApi } from 'src/services/emptyApi';
import { EngagementTerm } from 'src/types/resources/EngagementTerms';
import { SingleResponse, ListResponse, ListRequestParams } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';

const engagementTermsApi = emptyApi.injectEndpoints({
  endpoints: builder => ({
    getOrgEngagementTerms: builder.query<
      ListResponse<EngagementTerm>,
      { listParams: ListRequestParams; organizationId: ID }
    >({
      query: params => ({
        url: apiRoutes.organization.engagementTerms(),
        params: params.listParams,
      }),

      providesTags: ['EngagementTerms'],
    }),
    createEngagementTerm: builder.mutation<
      SingleResponse<EngagementTerm>,
      Pick<EngagementTerm, 'text' | 'isAcknowledgementStatementAccepted'> & { organizationId: ID }
    >({
      query: params => {
        return {
          url: apiRoutes.organization.engagementTerms(),
          method: 'POST',
          body: params,
        };
      },
      invalidatesTags: ['EngagementTerms'],
    }),
    editEngagementTerm: builder.mutation<
      SingleResponse<EngagementTerm>,
      { termId: ID; data: Pick<EngagementTerm, 'text' | 'isAcknowledgementStatementAccepted'> & { organizationId: ID } }
    >({
      query: ({ termId, data }) => {
        return {
          url: apiRoutes.organization.engagementTerms(termId),
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['EngagementTerms'],
    }),
  }),
});

export const { useCreateEngagementTermMutation, useGetOrgEngagementTermsQuery, useEditEngagementTermMutation } =
  engagementTermsApi;
