import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import MuseoSansRounded300 from 'src/assets/fonts/MuseoSansRounded-300.woff2';
import MuseoSansRounded500 from 'src/assets/fonts/MuseoSansRounded-500.woff2';
import MuseoSansRounded700 from 'src/assets/fonts/MuseoSansRounded-700.woff2';

type MuiCssBaselineType = {
  defaultProps: ComponentsProps['MuiCssBaseline'];
  styleOverrides: ComponentsOverrides['MuiCssBaseline'];
};

const MuiCssBaseline: MuiCssBaselineType = {
  styleOverrides: {
    html: {
      scrollBehavior: 'auto',
    },
    '@font-face': {
      fontFamily: 'Museo',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 300,
      src: `local('Museo'), local('Museo'), url(${MuseoSansRounded300}) format('woff2')`,
      unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
    },
    fallbacks: [
      {
        '@font-face': {
          fontFamily: 'Museo',
          fontStyle: 'normal',
          fontDisplay: 'swap',
          fontWeight: 500,
          src: `local('Museo'), local('Museo'), url(${MuseoSansRounded500}) format('woff2')`,
          unicodeRange:
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
        },
      },
      {
        '@font-face': {
          fontFamily: 'Museo',
          fontStyle: 'normal',
          fontDisplay: 'swap',
          fontWeight: 700,
          src: `local('Museo'), local('Museo'), url(${MuseoSansRounded700}) format('woff2')`,
          unicodeRange:
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
        },
      },
    ],
  },
  defaultProps: {},
};

export default MuiCssBaseline;
