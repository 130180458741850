export default {
  title: 'Engagement Terms',
  noTermsAdded: 'No terms have been added yet',
  addTerms: 'Add Terms',
  editTerms: 'Edit Terms',
  termsFormModalDescription:
    "Organization administrators may incorporate additional terms on Engagements that are created in Revuud by inserting the text in the Engagement Terms field below. The field has a 1,500-character limit. The text will be displayed below the Revuud terms on the Engagement. The submission of additional terms will not amend or substitute any portion of the existing Revuud terms. Any addition or modification to your Organization's terms will be included on any new Engagement created after the submission of the terms.",
  formAddTerms: 'Add Engagement Terms',
  formEditTerms: 'Edit Engagement Terms',
  termsTextFieldPlaceholder: 'Add additional terms to be displayed on Engagements',
  formAcknowledgement:
    'I understand and agree that, by incorporating additional terms on any Engagement created in Revuud that these terms will not amend or substitute any portion of the existing Revuud terms.',
};
