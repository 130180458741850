import { OrganizationUser } from 'src/types/resources/Organization';
import { ReassignAction } from 'src/enums/OrganizationUser';
import { UserRole } from 'src/enums/UserRole';
import apiRoutes from 'src/routes/apiRoutes';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';
import { CreateOrganizationUserFormData } from 'src/forms/createOrganizationUser';

export type IndexParams = {
  organizationId?: number;
  departmentId?: number;
  role?: UserRole | string;
  forAction?: ReassignAction;
  managerId?: number;
  ordering?: string;
  page?: number;
};

export type IndexReturnedType = {
  results: OrganizationUser[];
};

export type UpdateParams = {
  id: number;
  params: CreateOrganizationUserFormData;
};

export type ReassignParams = {
  id: number;
  params: {
    managerId: number;
    isEngagementsOnly: boolean;
  };
};

export type PatchParams = {
  id: number;
  params: Partial<CreateOrganizationUserFormData>;
};

export default {
  index(params: IndexParams): Promise<ListResponse<OrganizationUser>> {
    const url = apiRoutes.superAdmin.organizationUsersPath();
    return Fetcher.get(url, params);
  },
  create(params: CreateOrganizationUserFormData): Promise<SingleResponse<OrganizationUser>> {
    const url = apiRoutes.superAdmin.organizationUsersPath();
    return Fetcher.post(url, params);
  },
  reassign({ id, params }: ReassignParams): Promise<SingleResponse<OrganizationUser>> {
    const url = apiRoutes.superAdmin.organizationUserReassignPath(id);
    return Fetcher.post(url, params);
  },
  update({ id, params }: UpdateParams): Promise<SingleResponse<OrganizationUser>> {
    const url = apiRoutes.superAdmin.organizationUserPath(id);
    return Fetcher.put(url, params);
  },
  partialUpdate({ id, params }: PatchParams): Promise<SingleResponse<OrganizationUser>> {
    const url = apiRoutes.superAdmin.organizationUserPath(id);
    return Fetcher.patch(url, params);
  },
};
