import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { GroupFormData } from 'src/forms/organizationGroup';
import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { ListResponse, SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { Group } from 'src/types/resources/Group';
import GroupsRepository, {
  IndexParams,
  DeleteParams,
  UpdateParams,
} from 'src/repositories/organizationAdmin/GroupsRepository';

export type GroupsSliceStateType = {
  meta: Meta;
  groups: Group[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type GroupsSliceActionsType = {
  loadGroups: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<Group>> };
  createGroup: (params: GroupFormData) => { unwrap: () => Promise<SingleResponse<Group>> };
  updateGroup: (params: UpdateParams) => { unwrap: () => Promise<SingleResponse<Group>> };
  deleteGroup: (params: DeleteParams) => { unwrap: () => void };
};

const initialState: GroupsSliceStateType = {
  meta: {} as Meta,
  groups: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadGroups = createAsyncAction('groups/index', GroupsRepository.index);
export const createGroup = createAsyncAction('groups/create', GroupsRepository.create);
export const updateGroup = createAsyncAction('groups/update', GroupsRepository.update);
export const deleteGroup = createAsyncAction('groups/delete', GroupsRepository.delete);

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadGroups.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadGroups.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.groups = payload.results;
    });
    builder.addCase(loadGroups.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(deleteGroup.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteGroup.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteGroup.rejected, state => {
      state.delete.fetchStatus = FetchStatus.failed;
    });
  },
});

export default groupsSlice.reducer;
