import apiRoutes from 'src/routes/apiRoutes';
import { SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';
import { Task } from 'src/types/resources/Task';

export type CreateParams = {
  message: string;
  lastSearch: string;
};

export default {
  create(params: CreateParams): Promise<SingleResponse<Task>> {
    const url = apiRoutes.manager.talentNotFoundFeedbackPath();
    return Fetcher.post(url, params);
  },
};
