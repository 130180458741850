import { KnowledgeCenterFAQ } from 'src/types/resources/KnowledgeCenter';
import { ListResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  page?: number;
};

export default {
  index(params: IndexParams): Promise<ListResponse<KnowledgeCenterFAQ>> {
    const url = apiRoutes.talent.knowledgeCenterFAQPath();
    return Fetcher.get(url, params);
  },
};
