import { forwardRef } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useSnackbar, SnackbarContent, OptionsObject, SnackbarKey } from 'notistack';
import WarningIcon from '@mui/icons-material/Warning';

import { ReactComponent as CrossIcon } from 'src/assets/images/crossIcon.svg';
import { ReactComponent as SuccessIcon } from 'src/assets/images/iconSuccessConfirm.svg';
import { capitalize } from 'src/utils/string';
import Box from 'src/components/Box';

import styles from './styles';

type ToastProps = {
  id: SnackbarKey;
  options: OptionsObject & {
    message: string;
  };
};

const variantMapping = {
  success: {
    title: 'Success',
    icon: <SuccessIcon />,
  },
  error: {
    title: 'Error',
    icon: <CrossIcon />,
  },
  warning: {
    title: 'Warning',
    icon: <WarningIcon />,
  },
};

const Snackbar = forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
  const { id, options } = props;
  const { message, variant = 'success' } = options;

  const { closeSnackbar } = useSnackbar();
  const iconStyle = `icon${capitalize(variant)}`;

  const handleCloseSnackbar = () => closeSnackbar(id);

  return (
    <SnackbarContent ref={ref}>
      <Box sx={styles.root}>
        <Box sx={styles[iconStyle]}>{variantMapping[variant].icon}</Box>
        <Box sx={styles.message}>
          <Typography sx={styles.title} variant="h4" component="p">
            {variantMapping[variant].title}
          </Typography>
          <Typography sx={styles.subtitle} variant="caption" component="p" color="COLORS.black40">
            {message}
          </Typography>
        </Box>
        <Box sx={styles.action}>
          <IconButton aria-label="close" onClick={handleCloseSnackbar}>
            <CrossIcon />
          </IconButton>
        </Box>
      </Box>
    </SnackbarContent>
  );
});
Snackbar.displayName = 'Snackbar';

export default Snackbar;
