import { isNil, omit } from 'ramda';
import * as yup from 'yup';

import { ValidationError } from 'src/enums/Validation';
import { ExternalLink } from 'src/types/resources/ExternalLink';

export type ExternalLinkFormData = {
  link: string;
  description: string;
};

export const validationFields = {
  link: yup.string().default('').url().required(ValidationError.default),
  description: yup.string().default('').required(ValidationError.default),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (externalLink: ExternalLink): ExternalLinkFormData => {
  if (isNil(externalLink)) {
    return validationSchema.getDefault();
  }

  const updatedExternalLink = omit(['id'], externalLink);

  return {
    ...updatedExternalLink,
  };
};

export const attributesToSubmit = (values: ExternalLinkFormData): ExternalLinkFormData => {
  return values;
};
