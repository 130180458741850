import useOrganizationsUsers from 'src/hooks/superAdmin/useOrganizationsUsers';
import { OrganizationUser } from 'src/types/resources/Organization';

import Form from '../Form';

type SuperAdminFormProps = {
  user: OrganizationUser;
};

const SuperAdminForm: React.FC<SuperAdminFormProps> = props => {
  const { user } = props;

  const { loadOrganizationUsers, partialUpdateOrganizationUser } = useOrganizationsUsers();

  return <Form user={user} onLoadUsers={loadOrganizationUsers} onUpdateUser={partialUpdateOrganizationUser} />;
};

export default SuperAdminForm;
