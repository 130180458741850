import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const styles: SxStyles = {
  wrapper: {
    width: '520px',
    minHeight: '250px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiInputLabel-root': {
      color: COLORS.black100,
    },
  },
  row: {
    marginTop: '23px',
  },
  title: {
    color: COLORS.black100,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 'auto',
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  buttonDelete: {
    marginLeft: 'auto',
  },
  inputLabel: {
    fontSize: '1em',
  },
};

export default styles;
