import { useState } from 'react';
import { FormikHelpers, useFormik, getIn } from 'formik';
import { Typography, TextField, Button, Alert } from '@mui/material';

import {
  KnowledgeCenterFAQFeedbackFormData,
  initialValues,
  validationSchema,
  attributesToSubmit,
} from 'src/forms/knowledgeCenterFAQFeedback';
import FAQFeedbackRepository from 'src/repositories/FAQFeedbackRepository';
import { CERTIFICATE_VALID_FILE_TYPES } from 'src/utils/constants';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useModals from 'src/hooks/useModals';
import useSnackbar from 'src/hooks/useSnackbar';
import Box from 'src/components/Box';
import AttachedFileLink from 'src/components/AttachedFileLink';
import FileField from 'src/components/FileField';

import styles from './styles';

const KnowledgeCenterFAQFeedbackForm: React.FC = () => {
  const [formError, setFormError] = useState<string | null>(null);
  const { hideModal } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();

  const handleSubmit = async (
    formData: KnowledgeCenterFAQFeedbackFormData,
    { setErrors }: FormikHelpers<KnowledgeCenterFAQFeedbackFormData>,
  ) => {
    const params = attributesToSubmit(formData);
    setFormError(null);
    try {
      await FAQFeedbackRepository.create(params);
      hideModal();
      enqueueSuccessSnackbar('Your message has been successfully sent');
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(error.response.data.errors.nonFieldErrors);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    dirty,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    handleSubmit: handleFormikSubmit,
  } = useFormik<KnowledgeCenterFAQFeedbackFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files[0] || null;

    if (selectedFile) {
      await setFieldTouched('file.source', true);
      await setFieldValue('file', {
        source: selectedFile,
        url: null,
      });
    }
  };

  const handleDeleteFile = async () => {
    await setFieldValue('file', {
      source: null,
      url: null,
    });
  };

  const renderFileField = () => {
    const isFileFieldError = getIn(touched, 'file.source') && getIn(errors, 'file.source');
    const isFileExist = getIn(values, 'file.source') || getIn(values, 'file.url');
    const isVisibleSelectButton = isFileFieldError || !isFileExist;

    return (
      isVisibleSelectButton && (
        <Box sx={styles.fileButtonWrapper}>
          <Box sx={styles.fileButton}>
            <FileField
              id="file"
              name="file"
              buttonText="Upload a file"
              accept={CERTIFICATE_VALID_FILE_TYPES.join(',')}
              onChange={handleFileChange}
              fullWidth
              error={touched.file && Boolean(errors.file)}
            />
          </Box>
          <Typography variant="caption" sx={styles.fileButtonDescription}>
            Please upload a file.
          </Typography>
        </Box>
      )
    );
  };

  const renderPreviewFileLink = () => {
    const isFileFieldError = getIn(touched, 'file.source') && getIn(errors, 'file.source');
    const isFileExist = getIn(values, 'file.source') || getIn(values, 'file.url');

    if (isFileFieldError) {
      return (
        <Typography sx={styles.documentHelperErrorText} component="p" color="error">
          {errors.file?.source}
        </Typography>
      );
    }

    return (
      isFileExist && (
        <AttachedFileLink sx={styles.documentAttachedLink} file={values.file} onDelete={handleDeleteFile} />
      )
    );
  };

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.wrapper}>
          <Typography variant="h3" sx={styles.title}>
            Do you have a question? Let us know.
          </Typography>

          <Box sx={styles.row}>
            <TextField
              id="message"
              type="textarea"
              label="Message"
              multiline
              rows={3}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              error={touched.message && Boolean(errors.message)}
              helperText={touched.message && errors.message}
            />
          </Box>

          <Box sx={styles.row}>
            {renderFileField()}
            {renderPreviewFileLink()}
          </Box>

          <Box sx={styles.row}>
            <Typography sx={styles.caption} variant="caption">
              By filling out this form, an email will be sent to Revuud. We will respond by email within 24-48 hours.
            </Typography>
          </Box>

          <Box sx={styles.footer}>
            <Box sx={styles.row}>
              <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting || !dirty}>
                Send
              </Button>
              <Button variant="outlined" onClick={hideModal} sx={styles.button}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default KnowledgeCenterFAQFeedbackForm;
