import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import CommentsRepository, { CreateParams, LinkToProfileParams } from 'src/repositories/CommentsRepository';
import { isAxiosError } from 'src/utils/responseErrors';
import apiRoutes from 'src/routes/apiRoutes';
import { camelizeKeys } from 'src/utils/keysConverter';
import { Comment, ProfileComment } from 'src/types/resources/Comment';
import { SingleResponse } from 'src/types/utils';

import type { RootState } from '.';

export const createCommentThunkAction = (
  type: string,
  addCommentAction: ReturnType<typeof createAction>,
  isSuperAdminSlice?: boolean,
) =>
  createAsyncThunk(type, async (params: CreateParams, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const profileId = isSuperAdminSlice ? state.superAdminTalents.talent.id : state.talents.talent.id;

      const createResponse = await CommentsRepository.create(params);
      const commentId = createResponse.data.id;
      const linkResponse = await CommentsRepository.linkToProfile({ commentId, profileId });
      thunkAPI.dispatch(addCommentAction(linkResponse.data));
      return null;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.config.url === apiRoutes.organization.linkedToProfileComment()) {
          const meta: LinkToProfileParams = camelizeKeys(JSON.parse(error.config.data));
          await CommentsRepository.delete(meta.commentId);
        }
      }
      return thunkAPI.rejectWithValue(error);
    }
  });

export const updateCommentReducer = (comments: ProfileComment[], payload: SingleResponse<Comment>) => {
  const editedCommentIndex = comments.findIndex(comment => comment.comment.id === payload.data.id);
  if (editedCommentIndex !== -1) {
    const editedComment = comments.splice(editedCommentIndex, 1)[0];
    editedComment.comment = payload.data;
    comments.unshift(editedComment);
  }
  return comments;
};
