import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import TimeSheetsRepository, {
  IndexParams,
  PartialUpdateParams,
} from 'src/repositories/organizationAdmin/TimeSheetsAdjustmentsRepository';
import { Meta } from 'src/types/meta';
import { AdjustedTimeSheet } from 'src/types/resources/TimeSheet';
import { ListResponse, SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';

export type TimeSheetsAdjustmentsSliceStateType = {
  meta: Meta;
  timeSheetsAdjustments: AdjustedTimeSheet[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  partialUpdate: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type TimeSheetsAdjustmentsSliceActionsType = {
  loadTimeSheetsAdjustments: (params?: IndexParams) => {
    unwrap: () => Promise<ListResponse<AdjustedTimeSheet>>;
  };
  partialUpdateTimeSheetsAdjustment: (params: PartialUpdateParams) => {
    unwrap: () => Promise<SingleResponse<AdjustedTimeSheet>>;
  };
  resetTimeSheetsAdjustments: () => void;
};

const initialState: TimeSheetsAdjustmentsSliceStateType = {
  meta: {} as Meta,
  timeSheetsAdjustments: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  partialUpdate: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadTimeSheetsAdjustments = createAsyncAction(
  'organizationAdmin/timeSheetsAdjustments/index',
  TimeSheetsRepository.index,
);
export const partialUpdateTimeSheetsAdjustment = createAsyncAction(
  'organizationAdmin/timeSheetsAdjustments/partialUpdate',
  TimeSheetsRepository.partialUpdate,
);

const TimeSheetsAdjustmentsSlice = createSlice({
  name: 'organizationAdmin/timeSheetsAdjustments',
  initialState,
  reducers: {
    resetTimeSheetsAdjustments(state) {
      state.timeSheetsAdjustments = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTimeSheetsAdjustments.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTimeSheetsAdjustments.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.timeSheetsAdjustments = payload.results;
    });
    builder.addCase(loadTimeSheetsAdjustments.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(partialUpdateTimeSheetsAdjustment.pending, state => {
      state.partialUpdate.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(partialUpdateTimeSheetsAdjustment.fulfilled, state => {
      state.partialUpdate.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(partialUpdateTimeSheetsAdjustment.rejected, state => {
      state.partialUpdate.fetchStatus = FetchStatus.failed;
    });
  },
});

const {
  actions: { resetTimeSheetsAdjustments },
} = TimeSheetsAdjustmentsSlice;

export { resetTimeSheetsAdjustments };

export default TimeSheetsAdjustmentsSlice.reducer;
