import apiRoutes from 'src/routes/apiRoutes';
import SavedSearch, { SavedSearchParam } from 'src/types/resources/SavedSearch';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';

export type CreateParams = {
  name: string;
  params: SavedSearchParam[];
};

export type DeleteParams = {
  id: number;
};

export default {
  index(): Promise<ListResponse<SavedSearch>> {
    const url = apiRoutes.manager.savedSearchesPath();
    return Fetcher.get(url);
  },
  create(params: CreateParams): Promise<SingleResponse<SavedSearch>> {
    const url = apiRoutes.manager.savedSearchesPath();
    return Fetcher.post(url, params);
  },
  delete({ id }: DeleteParams): Promise<void> {
    const url = apiRoutes.manager.savedSearchPath(id);
    return Fetcher.delete(url);
  },
};
