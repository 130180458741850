import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiMenuType = {
  defaultProps: ComponentsProps['MuiMenu'];
  styleOverrides: ComponentsOverrides['MuiMenu'];
};

const MuiMenu: MuiMenuType = {
  styleOverrides: {
    root: {
      '& .MuiMenu-paper': {
        marginTop: '16px',
        borderRadius: '6px',
        boxShadow: '0px 6px 20px -6px rgba(27, 30, 32, 0.25), 0px 0px 1px rgba(27, 30, 32, 0.3)',
      },
    },
    list: {
      maxHeight: '40vh',
    },
  },
  defaultProps: {},
};

export default MuiMenu;
