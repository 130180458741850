import { isNil } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { Typography, TypographyProps, Tooltip } from '@mui/material';

import { TooltipVariant } from 'src/types/theme';
import clsx from 'src/utils/clsx';
import Box from 'src/components/Box';

import styles from './styles';

export type TypographyNoWrapProps = TypographyProps & {
  component?: React.ElementType;
  lineClamp?: 1 | 2 | null;
  tooltipVariant?: TooltipVariant;
  tooltipExtraText?: string | number;
};

const TypographyNoWrap: React.FC<TypographyNoWrapProps> = props => {
  const {
    component = 'p',
    lineClamp = null,
    children,
    tooltipVariant = 'light',
    sx = {},
    tooltipExtraText,
    ...restProps
  } = props;
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  const typographyRef = useRef<HTMLElement>();

  const typographyStyles = clsx(sx, [
    [styles.multilineTextCrop, !isNil(lineClamp)],
    [styles[`lineClamp${lineClamp}`], !isNil(lineClamp)],
  ]);

  const renderTitle = () => {
    if (!children) return '';

    return (
      <>
        <Box sx={styles.title}>{children}</Box>
        {tooltipExtraText && <Box sx={styles.title}>{tooltipExtraText}</Box>}
      </>
    );
  };

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const overflowCondition = isNil(lineClamp)
        ? typographyRef.current?.scrollWidth > typographyRef.current?.clientWidth
        : typographyRef.current?.scrollHeight > typographyRef.current?.clientHeight;
      setIsOverflowed(overflowCondition);
    });

    if (typographyRef.current) {
      observer.observe(typographyRef.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, [typographyRef.current]);

  return (
    <Tooltip title={renderTitle()} arrow placement="top" disableHoverListener={!isOverflowed} variant={tooltipVariant}>
      <Typography
        sx={typographyStyles}
        ref={typographyRef}
        variant="body1"
        noWrap={isNil(lineClamp)}
        component={component}
        {...restProps}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default TypographyNoWrap;
