import { isEmpty, isNil } from 'ramda';

import { UserRole } from 'src/enums/UserRole';
import { User } from 'src/types/resources/User';

const UsersPresenter = {
  fullName: (user: User): string => {
    return `${user.firstName} ${user.lastName}`;
  },
  hasSuperAdminAccess: (user: User): boolean => user.role === UserRole.SuperAdmin,
  hasManagerAccess: (user: User): boolean => user.role === UserRole.Manager,
  hasOrganizationAdminAccess: (user: User): boolean => user.role === UserRole.OrganizationAdmin,
  hasTalentAccess: (user: User): boolean => user.role === UserRole.Talent,
  isOrganizationUser: (user: User): boolean =>
    user.role === UserRole.Manager || user.role === UserRole.OrganizationAdmin,
  hasPermissionViewRate: (user: User): boolean =>
    UsersPresenter.hasOrganizationAdminAccess(user) ||
    Boolean(UsersPresenter.hasManagerAccess(user) && user.hasPermissionViewRate),
  hasPermissionSendEngagement: (user: User): boolean =>
    UsersPresenter.hasOrganizationAdminAccess(user) ||
    Boolean(UsersPresenter.hasManagerAccess(user) && user.hasPermissionSendEngagement),
  isPhoneNumberExist: (user: User): boolean => !isNil(user.phone) && !isEmpty(user.phone),
  isTrackedViaPendo: (user: User): boolean =>
    user.role !== UserRole.SuperAdmin && !user.isInternal && !user.isImpersonate,
};

export default UsersPresenter;
