import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  root: {
    position: 'relative',
  },
  counter: {
    display: 'block',
    marginTop: '3px',
    color: COLORS.black40,
    fontSize: '8px',
    textAlign: 'right',
  },
  absolute: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  formHelperText: {
    maxWidth: '85%',
  },
};

export default styles;
