import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DialogProps } from '@mui/material';

import { ModalLayout, ModalType } from 'src/enums/ModalType';
import { useAppDispatch } from 'src/hooks/useRedux';

export type Modal = {
  type: ModalType;
  props?: Record<string, unknown>;
  modalLayout?: ModalLayout;
  dialogSx?: DialogProps['sx'];
  isCloseButtonVisible?: boolean;
  isCloseOnClickOutside?: boolean;
};

export type ModalsSliceStateType = {
  modals: Modal[];
};

export type ModalsSliceActionsType = {
  showModal: (modal: Modal) => void;
  hideModal: () => void;
  hideAllModals: () => void;
};

const initialState: ModalsSliceStateType = {
  modals: [],
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (state, { payload }: PayloadAction<Modal>) => {
      state.modals.push(payload);
    },
    hideModal: state => {
      state.modals.splice(-1);
    },
    hideAllModals: () => {
      return initialState;
    },
  },
});

const { actions } = modalsSlice;

export const useModalsActions = (): ModalsSliceActionsType => {
  const dispatch = useAppDispatch();

  const showModal = (modal: Modal) => dispatch(actions.showModal(modal));
  const hideModal = () => dispatch(actions.hideModal());
  const hideAllModals = () => dispatch(actions.hideAllModals());

  return {
    showModal,
    hideModal,
    hideAllModals,
  };
};

export default modalsSlice.reducer;
