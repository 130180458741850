import { ReactNode } from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import clsx from 'src/utils/clsx';

import styles from './styles';

type AvatarInfoProps = {
  src?: string;
  avatar?: ReactNode | null;
  hideAvatar?: boolean;
  notBold?: boolean;
  primaryText?: ReactNode | null;
  secondaryText?: ReactNode | null;
};

const AvatarInfo: React.FC<AvatarInfoProps> = props => {
  const { avatar = null, src = '', primaryText = null, secondaryText = null, hideAvatar, notBold } = props;

  return (
    <ListItem sx={styles.root} component="div">
      {!hideAvatar && (
        <ListItemAvatar>{avatar || <Avatar variant="circular" sx={styles.avatar} src={src} />}</ListItemAvatar>
      )}
      <ListItemText
        primary={primaryText}
        primaryTypographyProps={{ sx: clsx(styles.primaryText, [[styles.notBold, notBold]]), variant: 'button' }}
        secondary={secondaryText}
        secondaryTypographyProps={{ sx: styles.secondaryText, variant: 'caption' }}
      />
    </ListItem>
  );
};

export default AvatarInfo;
