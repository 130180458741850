export enum TimeTrackingApproveModalType {
  ENTIRE_DAY = 'entire day',
  ENTIRE_ENGAGEMENT = 'entire engagment',
  ALL_ENGAGEMENTS = 'all engagments',
  ALL_ENGAGEMENTS_ACTION_ITEM = 'all engagments action item',
}

export const modalText = {
  title: 'Are you sure?',
  upperText: {
    [TimeTrackingApproveModalType.ENTIRE_DAY]: `You're about to approve multiple time entries for a selected day.`,
    [TimeTrackingApproveModalType.ENTIRE_ENGAGEMENT]: `You're about to approve multiple time entries for a selected Engagement.`,
    [TimeTrackingApproveModalType.ALL_ENGAGEMENTS]: `You're about to approve multiple time entries for a selected pay period.`,
    [TimeTrackingApproveModalType.ALL_ENGAGEMENTS_ACTION_ITEM]: `You're about to approve all time entries for all Engagements that you manage.`,
  },
  lowerText:
    'All submitted time entries within the selected pay period plus any applicable adjustments for the filters applied will be approved.',
  additionaLowerTextForAllEngCustomPeriod:
    'The adjustments are not filtered by the custom date range but the other filters will be applied.',
  actionItemText: 'All submitted time entries including adjustments for the filters applied will be approved.',
};
