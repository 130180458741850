import { useEffect } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Button, TextField, Typography } from '@mui/material';
import { isEmpty } from 'ramda';

import { Address } from 'src/types/address';
import { User } from 'src/types/resources/User';
import { attributesToSubmit, ContactInfoFormData, initialValues, validationSchema } from 'src/forms/contactInfoForm';
import UsersPresenter from 'src/presenters/UsersPresenter';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useUsers from 'src/hooks/useUsers';
import useModals from 'src/hooks/useModals';
import useEngagements from 'src/hooks/talents/useEngagements';
import Box from 'src/components/Box';
import PhoneNumberField from 'src/components/PhoneNumberField';
import AddressAutocomplete from 'src/components/AddressAutocomplete';

import styles from './styles';

type ContactInfoFormProps = {
  autoFocusFieldName?: keyof User;
};

const ContactInfoForm: React.FC<ContactInfoFormProps> = props => {
  const { autoFocusFieldName } = props;

  const { partialUpdateUser, currentUser } = useUsers();
  const { hideModal } = useModals();
  const { loadEngagements, isEngagementsLoadFinished } = useEngagements();

  const hasTalentAccess = UsersPresenter.hasTalentAccess(currentUser);

  const handleSubmit = async (formData: ContactInfoFormData, { setErrors }: FormikHelpers<ContactInfoFormData>) => {
    const params = attributesToSubmit(formData);
    try {
      await partialUpdateUser(params).unwrap();
      hideModal();
    } catch (error) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    submitForm,
    setValues,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
  } = useFormik<ContactInfoFormData>({
    initialValues: initialValues(currentUser),
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: true,
  });

  const handleAddressChange = (address: Address) => {
    setValues({
      ...values,
      address1: address.streetLine,
      address2: address.secondary,
      city: address.city,
      state: address.state,
      zipCode: address.zipcode,
    });
  };

  useEffect(() => {
    if (hasTalentAccess) {
      loadEngagements()
        .unwrap()
        .then(response => {
          setFieldValue('isTalentEngagementsEmpty', isEmpty(response.results));
        })
        .catch(() => {
          setFieldValue('isTalentEngagementsEmpty', false);
        });
    }
  }, []);

  return (
    <>
      <Box sx={styles.wrapper}>
        <Typography variant="h2" sx={styles.title}>
          Contact Info
        </Typography>
        <Box sx={styles.row}>
          <TextField
            id="firstName"
            label="First Name"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            error={touched.firstName && Boolean(errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
        </Box>
        <Box sx={styles.row}>
          <TextField
            id="lastName"
            label="Last Name"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            error={touched.lastName && Boolean(errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
        </Box>
        <Box sx={styles.row}>
          <PhoneNumberField
            id="phone"
            label="Phone"
            value={values.phone}
            onChange={handleChange}
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
            autoFocus={Boolean(autoFocusFieldName === 'phone')}
            isCaptionVisible={!UsersPresenter.hasSuperAdminAccess(currentUser)}
          />
        </Box>
        <Box sx={styles.row}>
          <AddressAutocomplete
            initialValue={values.address1}
            onChangeAddress={handleAddressChange}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            setFieldTouched={setFieldTouched}
            error={touched.address1 && errors.address1}
          />
        </Box>
        <Box sx={styles.row}>
          <TextField
            id="address2"
            label="Address Line 2"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address2}
            error={touched.address2 && Boolean(errors.address2)}
            helperText={touched.address2 && errors.address2}
          />
        </Box>
        <Box sx={styles.row}>
          <TextField
            id="city"
            label="City"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            error={touched.city && Boolean(errors.city)}
            helperText={touched.city && errors.city}
          />
        </Box>
        <Box sx={styles.row}>
          <TextField
            id="state"
            label="State"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.state}
            error={touched.state && Boolean(errors.state)}
            helperText={touched.state && errors.state}
          />
        </Box>
        <Box sx={styles.row}>
          <TextField
            id="zipCode"
            label="ZIP"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.zipCode}
            error={touched.zipCode && Boolean(errors.zipCode)}
            helperText={touched.zipCode && errors.zipCode}
          />
        </Box>
        <Box sx={styles.buttons}>
          <Button
            variant="contained"
            onClick={submitForm}
            sx={styles.button}
            disabled={isSubmitting || (hasTalentAccess && !isEngagementsLoadFinished)}
          >
            Save
          </Button>
          <Button variant="outlined" onClick={hideModal} sx={styles.button}>
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ContactInfoForm;
