import { makeStyles } from 'src/utils/makeStyles';

export default makeStyles({
  modalWrapper: {
    width: '600px',
    padding: 3,
  },
  buttons: {
    display: 'flex',
    gap: 1,
  },
  button: {
    paddingY: 1,
    paddingX: 6,
  },
});
