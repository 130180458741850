import {
  SpecializationsSliceStateType,
  SpecializationsSliceActionsType,
  loadSpecializations,
  createSpecialization,
  updateSpecialization,
  deleteSpecialization,
} from 'src/store/talents/specializationsSlice';
import { CreateParams, UpdateParams, DeleteParams } from 'src/repositories/talents/SpecializationsRepository';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';

type UseSpecializationsType = Pick<SpecializationsSliceStateType, 'specializations'> &
  SpecializationsSliceActionsType & {
    isSpecializationsLoadFinished: boolean;
  };

const useTalentSpecializations = (): UseSpecializationsType => {
  const dispatch = useAppDispatch();
  const {
    specializations,
    index: { fetchStatus: loadFetchStatus },
  } = useAppSelector(state => state.talentsSpecializations);

  const isSpecializationsLoadFinished = getFetchStatus(loadFetchStatus).isFinished;

  return {
    specializations,
    isSpecializationsLoadFinished,
    loadSpecializations: () => dispatch(loadSpecializations()),
    createSpecialization: (args: CreateParams) => dispatch(createSpecialization(args)),
    updateSpecialization: (args: UpdateParams) => dispatch(updateSpecialization(args)),
    deleteSpecialization: (args: DeleteParams) => dispatch(deleteSpecialization(args)),
  };
};

export default useTalentSpecializations;
