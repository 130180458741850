import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiInputType = {
  defaultProps: ComponentsProps['MuiInput'];
  styleOverrides: ComponentsOverrides['MuiInput'];
};

const MuiInput: MuiInputType = {
  styleOverrides: {
    root: {
      position: 'relative',
      color: COLORS.black100,
      border: 'none',
      boxShadow: `0 0 0 1px ${COLORS.black20}`,
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '10px 12px',
      height: '40px',
      backgroundColor: COLORS.white,
      '&&': {
        margin: 0,
      },
      '&.Mui-focused': {
        boxShadow: `0 0 0 2px ${COLORS.black60}`,
        '&.Mui-error': {
          boxShadow: `0 0 0 2px ${COLORS.red100}`,
          borderRadius: '4px',
        },
      },
      '&&:disabled': {
        color: COLORS.black60,
      },
      '&.Mui-error': {
        boxShadow: `0 0 0 1px ${COLORS.red100}`,
        borderRadius: '4px',
      },
      '&.Mui-disabled': {
        backgroundColor: COLORS.black02,
      },
      '[type="number"]': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: '0',
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
      },
    },
    multiline: {
      height: 'auto',
    },
  },
  defaultProps: {
    disableUnderline: true,
    autoComplete: 'none',
  },
};

export default MuiInput;
