import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider, GlobalStyles } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { HelmetProvider } from 'react-helmet-async';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

import { AdapterDateFnsWithYearsReverse } from 'src/utils/date';
import { store } from 'src/store';
import SnackbarProvider from 'src/contexts/SnackbarContext';
import { FeatureFlagsProvider } from 'src/contexts/FeatureFlagsContext';

import theme from './theme';
import App from './App';
import { globalStyles } from './styles';

const tagManagerArgs: TagManagerArgs = {
  gtmId: 'GTM-5F9BTFL',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFnsWithYearsReverse}>
        <ThemeProvider theme={theme}>
          <FeatureFlagsProvider>
            <SnackbarProvider>
              <CssBaseline />
              <GlobalStyles styles={globalStyles} />
              <App />
            </SnackbarProvider>
          </FeatureFlagsProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root'),
);
