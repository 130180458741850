import { getYearMonthByDate } from 'src/utils/date';
import WorkExperience from 'src/types/resources/WorkExperience';

export const workPeriod = (workExperience: WorkExperience): string => {
  const { startPeriodDate, endPeriodDate } = workExperience;
  const endDate = endPeriodDate ? getYearMonthByDate(endPeriodDate) : 'Present';

  return `${getYearMonthByDate(startPeriodDate)} – ${endDate}`;
};

export const workPeriodWithOrganizationName = (workExperience: WorkExperience): string => {
  const { organizationName } = workExperience;
  return `${organizationName} | ${workPeriod(workExperience)}`;
};
