import { SxStyles } from 'src/types/theme';
import { makeStyles } from 'src/utils/makeStyles';

const styles: SxStyles = makeStyles({
  wrapper: {
    minHeight: '250px',
    padding: {
      tablet: '33px 40px 40px',
    },
    width: { tablet: '700px', desktop: '620px' },
  },
  title: {
    boxShadow: 'inset 0px -1px 0px rgba(97, 148, 185, 0.25)',
    paddingBottom: '12px',
  },
  subTitle: {
    marginTop: '12px',
  },
  button: {
    padding: '7px 47px',
    marginRight: '10px',
  },
});

export default styles;
