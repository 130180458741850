import { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { isNil } from 'ramda';

import TalentsRepository from 'src/repositories/manager/TalentsRepository';
import { TalentSearch } from 'src/types/resources/TalentSearch';
import useModals from 'src/hooks/useModals';
import useLoading from 'src/hooks/useLoading';
import SearchForm from 'src/components/SearchForm';
import Loader from 'src/components/Loader';
import Box from 'src/components/Box';

import TalentsList, { TalentsListProps } from './TalentsList';
import styles from './styles';

type SelectTalentProps = {
  onSelectTalent: (talent: TalentSearch) => void;
};

const SelectTalent: React.FC<SelectTalentProps> = props => {
  const { onSelectTalent } = props;
  const { hideModal } = useModals();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedTalent, setSelectedTalent] = useState<TalentSearch | null>(null);
  const [fetchedTalents, setFetchedTalents] = useState<TalentSearch[]>([]);
  const { funcWithLoading: fetchTalents, isFinished: isTalentsFetchingFinished } = useLoading(TalentsRepository.index);

  const handleListItemClick: TalentsListProps['onTalentClick'] = (event, talent) => {
    setSelectedTalent(talent);
  };

  const handleSelectTalent = () => {
    onSelectTalent(selectedTalent);
    hideModal();
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    (async () => {
      const response = await fetchTalents(
        searchValue
          ? { search: searchValue, ordering: 'first_name,last_name' }
          : {
              ordering: 'first_name,last_name',
            },
      );
      setFetchedTalents(response.results);
    })();
  }, [searchValue]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.header}>
        <Typography variant="h3" sx={styles.title}>
          Select Talent
        </Typography>
        <SearchForm placeholder="Search by name" onSearch={handleSearch} searchEntityType="engagementTalent" />
      </Box>
      <Box sx={styles.body}>
        {!isTalentsFetchingFinished ? (
          <Loader />
        ) : (
          <TalentsList
            talents={fetchedTalents}
            selectedTalentId={selectedTalent?.id}
            onTalentClick={handleListItemClick}
          />
        )}
      </Box>
      <Box sx={styles.footer}>
        <Button variant="contained" sx={styles.button} disabled={isNil(selectedTalent)} onClick={handleSelectTalent}>
          Add
        </Button>
        <Button variant="outlined" onClick={hideModal} sx={styles.button}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default SelectTalent;
