import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  boldText: {
    fontWeight: 500,
  },
  errorText: {
    color: COLORS.red100,
  },
};

export default styles;
