import apiRoutes from 'src/routes/apiRoutes';
import { FeatureFlagsFromApi } from 'src/types/featureFlags';
import Fetcher from 'src/utils/fetcher';

const fetchFeatureFlags = () => {
  return Fetcher.get<FeatureFlagsFromApi>(apiRoutes.featureFlags());
};

export const featureFlagsApi = {
  fetchFeatureFlags,
};
