import { makeStyles } from 'src/utils/makeStyles';

export default makeStyles({
  modalWrapper: {
    width: '512px',
    padding: 3,
  },
  buttons: {
    display: 'flex',
    gap: '12px',
  },
  button: {
    width: '140px',
  },
});
