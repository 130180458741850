import { alpha } from '@mui/system';

import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    width: '520px',
    minHeight: '250px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    marginBottom: '24px',
    '&:last-of-type': {
      margin: 0,
    },
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  declineButton: {
    backgroundColor: COLORS.red100,
    color: COLORS.white,
    '&:hover, &:focus': {
      backgroundColor: alpha(COLORS.red100, 0.85),
    },
  },
  talentComment: {
    marginTop: '8px',
  },
  talentCommentLabel: {
    marginBottom: '4px',
  },
};

export default styles;
