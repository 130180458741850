import { isEmpty, isNil, min } from 'ramda';
import * as yup from 'yup';

import { TimeSheetState } from 'src/enums/TimeSheet';
import { ValidationError } from 'src/enums/Validation';
import EngagementTimeSheet from 'src/types/resources/EngagementTimeSheet';
import { NestedTimeSheet } from 'src/types/resources/TimeSheet';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';

const MAX_HOURS_PER_DATE = 24;

export type TimeSheetsFormData = {
  talentComment: string;
  maxHours: number;
  hours: number;
  minutes?: number;
  purchaseOrderId: ID;
  isPurchaseOrderIdRequired: boolean;
};

export type TimeSheetsFormDataToSubmit = Omit<
  TimeSheetsFormData,
  'hours' | 'maxHours' | 'isPurchaseOrderIdRequired'
> & {
  engagementId: number;
  parentId: number;
  timekeepingDate: string;
  hours: string;
};

export const validationSchema = yup.object({
  talentComment: yup.string().max(500, getMaxFieldLengthErrorMessage(500)).default('').label('Comment'),
  maxHours: yup.number().positive().default(MAX_HOURS_PER_DATE).required(ValidationError.default),
  hours: yup
    .number()
    .positive()
    .required()
    .nullable()
    .default(null)
    .min(0)
    .when(['maxHours'], (maxHours, schema) => {
      const maxHoursPerDay = min(maxHours, MAX_HOURS_PER_DATE);
      return schema.max(maxHoursPerDay || MAX_HOURS_PER_DATE);
    })
    .label('Hours'),
  minutes: yup.number().nullable().min(0).default(null),
  purchaseOrderId: yup
    .number()
    .nullable()
    .default(null)
    .when('isPurchaseOrderIdRequired', {
      is: (isPurchaseOrderIdRequired: boolean) => isPurchaseOrderIdRequired,
      then: schema => schema.required(),
    })
    .label('PO'),
  isPurchaseOrderIdRequired: yup.boolean().default(false),
});

export const initialValues = (
  engagementTimeSheet: EngagementTimeSheet,
  timeSheet?: NestedTimeSheet,
): TimeSheetsFormData => {
  const { maxHoursPerWeek, hoursNotToExceed, po } = engagementTimeSheet;
  const isPurchaseOrderIdRequired = !isEmpty(po);
  const isSinglePO = po.length === 1;

  if (isNil(timeSheet) || timeSheet?.state === TimeSheetState.open) {
    return {
      ...validationSchema.getDefault(),
      purchaseOrderId: isSinglePO ? po[0]?.id : null,
      isPurchaseOrderIdRequired,
    };
  }

  const { talentComment, hours, purchaseOrder } = timeSheet;
  const [hoursValue, minutesValue] = hours.split('.');

  return {
    maxHours: maxHoursPerWeek || hoursNotToExceed || MAX_HOURS_PER_DATE,
    hours: Number(hoursValue),
    minutes: Number(minutesValue) / 100,
    talentComment: talentComment ?? '',
    purchaseOrderId: purchaseOrder?.id ?? null,
    isPurchaseOrderIdRequired,
  };
};

export const attributesToSubmit = (
  engagementId: number,
  timekeepingDate: string,
  values: TimeSheetsFormData,
  parentTimeSheetId?: number,
): TimeSheetsFormDataToSubmit => {
  const { hours, minutes, talentComment, purchaseOrderId } = values;

  return {
    engagementId,
    timekeepingDate,
    purchaseOrderId,
    parentId: parentTimeSheetId,
    hours: String(hours + minutes),
    talentComment: isEmpty(talentComment) ? null : talentComment,
  };
};
