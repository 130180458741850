import { Organization, OrganizationUserCount, OrganizationEngagementsCount } from 'src/types/resources/Organization';
import { SingleResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type LoadUsersCountParams = {
  id: number;
  params?: {
    departmentId: number;
  };
};

export type ShowParams = {
  id: number;
};

export type UpdateParams = {
  id: number;
  params: Partial<Omit<Organization, 'logo'>> & {
    logo?: File | string | null;
  };
};

export default {
  show({ id }: ShowParams): Promise<SingleResponse<Organization>> {
    const url = apiRoutes.organizationAdmin.organizationPath(id);
    return Fetcher.get(url);
  },
  loadUsersCount({ id, params }: LoadUsersCountParams): Promise<SingleResponse<OrganizationUserCount>> {
    const url = apiRoutes.organizationAdmin.organizationUsersCountPath(id);
    return Fetcher.get(url, params);
  },
  loadEngagementsCount(id: ID): Promise<SingleResponse<OrganizationEngagementsCount>> {
    const url = apiRoutes.organizationAdmin.organizationEngagementsCountPath(id);
    return Fetcher.get(url);
  },
  update({ id, params }: UpdateParams): Promise<SingleResponse<Organization>> {
    const url = apiRoutes.organizationAdmin.organizationPath(id);
    return Fetcher.putFormData(url, params);
  },
};
