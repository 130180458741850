import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    padding: '5px',
    border: `1px solid ${COLORS.black20}`,
    marginBottom: '-1px',
    borderRadius: '4px 4px 0 0',
    counterReset: 'wrapper',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
  },
};

export default styles;
