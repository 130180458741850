import { ExpertiseType } from 'src/enums/Specialization';

export type SearchSpecializationFormData = {
  expertises: ExpertiseType[];
  roles: number[];
  skills: number[];
};

export const initialValues = (): SearchSpecializationFormData => {
  return {
    expertises: [],
    roles: [],
    skills: [],
  };
};
