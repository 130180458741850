import { TimeSheetState } from 'src/enums/TimeSheet';
import { TimeSheetsFormDataToSubmit } from 'src/forms/talents/timeSheet';
import apiRoutes from 'src/routes/apiRoutes';
import { TotalEngagementTimeSheet } from 'src/types/resources/EngagementTimeSheet';
import TalentEngagementTimeSheetDates from 'src/types/resources/TalentEngagementTimeSheetDates';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  page?: number;
  ordering?: string;
  payPeriodAfter?: string;
  payPeriodBefore?: string;
};

export type CreateParams = TimeSheetsFormDataToSubmit & {
  state?: TimeSheetState;
};

export type UpdateParams = {
  id: number;
  params: CreateParams & {
    state?: TimeSheetState;
  };
};

export default {
  index(params: IndexParams = { page: 1 }): Promise<ListResponse<TalentEngagementTimeSheetDates>> {
    const url = apiRoutes.talent.timeSheetsPath();
    return Fetcher.get(url, params);
  },
  total(params: IndexParams): Promise<SingleResponse<TotalEngagementTimeSheet>> {
    const url = apiRoutes.talent.timeSheetsTotalPath();
    return Fetcher.get(url, params);
  },
  create(params: CreateParams): Promise<SingleResponse<TalentEngagementTimeSheetDates>> {
    const url = apiRoutes.talent.timeSheetsPath();
    return Fetcher.post(url, params);
  },
  update({ id, params }: UpdateParams): Promise<SingleResponse<TalentEngagementTimeSheetDates>> {
    const url = apiRoutes.talent.timeSheetPath(id);
    return Fetcher.patch(url, params);
  },
  delete(id: number): Promise<void> {
    const url = apiRoutes.talent.timeSheetPath(id);
    return Fetcher.delete(url);
  },
};
