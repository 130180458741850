import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  root: {
    width: {
      mobile: '100%',
      tablet: '512px',
    },
    padding: '24px',
  },
  row: {
    marginBottom: '20px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  button: {
    padding: '8px 48px',
  },
};

export default styles;
