import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const styles: SxStyles = {
  placeholder: {
    color: COLORS.black40,
  },
  menuList: {
    maxHeight: '40vh',
  },
  menuItem: {
    paddingLeft: '16px',
    paddingRight: '8px',
  },
  selectItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    width: '100%',
  },
  renderValue: {
    color: 'inherit',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100% - 30px)',
  },
  deleteButton: {
    '& svg': {
      fill: COLORS.red100,
    },
  },
};

export default styles;
