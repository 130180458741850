import { useEffect, useState } from 'react';

import { PayPeriodType } from 'src/enums/PayPeriod';
import { DAY_SECONDS, differenceInDays, getFormattedDateString, addSeconds } from 'src/utils/date';
import {
  getPayPeriodForCurrentDay,
  isCurrentPayPeriod,
  isUserGracePeriod,
  isPreviousPayPeriod,
  getPayPeriodCountdownTimerEndDate,
} from 'src/utils/engagementTimeSheets';
import useUsers from 'src/hooks/useUsers';

type UsePayPeriodCountdownTimer = {
  remainingTime: number;
  daysDuration: number;
  payPeriodType: PayPeriodType;
  endDate: string;
};

const usePayPeriodCountdownTimer = (payPeriodDate: Date): UsePayPeriodCountdownTimer => {
  const [today, setToday] = useState<Date>(new Date());

  const { currentUser } = useUsers();

  const isNowCurrentPayPeriod = isCurrentPayPeriod(payPeriodDate);
  const isNowGracePayPeriod = isUserGracePeriod(currentUser) && isPreviousPayPeriod(payPeriodDate);

  let payPeriodType = PayPeriodType.close;
  if (isNowCurrentPayPeriod) payPeriodType = PayPeriodType.current;
  if (isNowGracePayPeriod) payPeriodType = PayPeriodType.grace;

  const currentPayPeriod = getPayPeriodForCurrentDay();
  const endDate = getPayPeriodCountdownTimerEndDate(payPeriodDate, currentPayPeriod, currentUser);
  const endDateString = getFormattedDateString(addSeconds(endDate, 1), 'EEEE, MMMM dd, h:mm aaaa');

  const startTime = today.getTime() / 1000;
  const endTime = endDate.getTime() / 1000;

  const remainingTime = endTime - startTime;
  const daysDuration = differenceInDays(endDate, currentPayPeriod.start) * DAY_SECONDS;

  useEffect(() => {
    const handlePageVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setToday(new Date());
      }
    };

    document.addEventListener('visibilitychange', handlePageVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handlePageVisibilityChange);
    };
  }, []);

  return { remainingTime, daysDuration, payPeriodType, endDate: endDateString };
};

export default usePayPeriodCountdownTimer;
