import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const icon = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  width: '80px',
  margin: 0,
  '& svg': {
    width: '34px',
    height: '34px',
    fill: COLORS.white,
  },
};

const styles: SxStyles = {
  root: {
    display: 'flex',
    width: '412px',
    padding: 0,
    borderRadius: '8px',
    backgroundColor: COLORS.white,
    boxShadow: '0px 8px 32px rgba(27, 30, 32, 0.2), 0px 0px 1px rgba(27, 30, 32, 0.3)',
    overflow: 'hidden',
  },
  iconSuccess: {
    ...icon,
    backgroundColor: COLORS.green100,
  },
  iconError: {
    ...icon,
    backgroundColor: COLORS.red100,
  },
  iconWarning: {
    ...icon,
    backgroundColor: COLORS.orange,
  },
  message: {
    flexGrow: 1,
    padding: '14px 20px 18px',
    overflow: 'hidden',
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    padding: '12px',
    margin: 0,
    borderLeft: `1px solid ${COLORS.black10}`,
    '& svg': {
      width: '15px',
      height: '15px',
      fill: COLORS.black10,
    },
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  subtitle: {
    color: COLORS.black40,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
};

export default styles;
