import { Typography, Theme, Link } from '@mui/material';
import { SxProps } from '@mui/system';
import { isNil } from 'ramda';

import { ReactComponent as ClipIcon } from 'src/assets/images/clipIcon.svg';
import CertificateType from 'src/types/resources/CertificateType';
import CertificatePresenter from 'src/presenters/CertificatePresenter';
import Box from 'src/components/Box';

import styles from './styles';

type EntryProps = {
  certificate: CertificateType;
  sx?: SxProps<Theme>;
};

const Entry: React.FC<EntryProps> = props => {
  const { certificate, sx = {} } = props;

  const hasFile = !isNil(certificate.file);
  const filename = CertificatePresenter.filename(certificate);

  return (
    <Box sx={sx}>
      <Typography variant="button" className="title">
        {certificate.documentName}
      </Typography>
      <Typography variant="body1" className="subTitle">
        {certificate.schoolOrUniversity}
      </Typography>
      <Typography variant="body1" className="subTitle">
        {certificate.fieldOfStudy}
      </Typography>

      {hasFile && (
        <Link
          href={certificate.file}
          sx={styles.link}
          underline="hover"
          download={filename}
          rel="noreferrer"
          target="_blank"
        >
          <ClipIcon className="icon" />
          <Typography variant="body1" color="inherit" component="span">
            {filename}
          </Typography>
        </Link>
      )}
    </Box>
  );
};

export default Entry;
