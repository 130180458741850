import { Box, Button, Typography } from '@mui/material';

import { TalentOpportunityApplyInfo } from 'src/features/opportunities/types/talentOpportunityApply';
import { useModals } from 'src/hooks';
import TextEditor from 'src/components/TextEditor';
import { convertFromHtmlFormat } from 'src/utils/textEditor';

import styles from './styles';

export type AppliedMessageModalContentProps = TalentOpportunityApplyInfo & { talentFullName: string };

const AppliedMessageModalContent: React.FunctionComponent<AppliedMessageModalContentProps> = props => {
  const { experience, skillsAndQualifications, applyMessage, talentFullName } = props;

  const { hideModal } = useModals();

  return (
    <Box sx={styles.modalWrapper}>
      <Typography variant="h3" mb={3}>
        {talentFullName || ''} Qualifications
      </Typography>

      {skillsAndQualifications && (
        <Box mb={2}>
          <Typography fontWeight="600" mb={1}>
            Skills and Qualifications
          </Typography>
          <TextEditor readOnly value={convertFromHtmlFormat(skillsAndQualifications)} />
        </Box>
      )}

      {experience && (
        <Box mb={2}>
          <Typography fontWeight="600" mb={1}>
            Experience
          </Typography>
          <TextEditor readOnly value={convertFromHtmlFormat(experience)} />
        </Box>
      )}

      {applyMessage && (
        <Box mb={3}>
          <Typography fontWeight="600" mb={1}>
            More Details
          </Typography>
          <TextEditor readOnly value={convertFromHtmlFormat(applyMessage)} />
        </Box>
      )}

      <Button variant="outlined" onClick={() => hideModal()}>
        Close
      </Button>
    </Box>
  );
};

export default AppliedMessageModalContent;
