import { UserRole } from 'src/enums/UserRole';
import { OrganizationUser } from 'src/types/resources/Organization';
import { IndexParams as SuperAdminIndexParams } from 'src/repositories/superAdmin/OrganizationsUsersRepository';
import useUsers from 'src/hooks/useUsers';

import SuperAdminForm from './components/SuperAdminForm';
import OrganizationAdminForm from './components/OrganizationAdminForm';

type CreateOrganizationUserProps = {
  organizationId: number;
  user?: OrganizationUser;
  searchParams?: SuperAdminIndexParams;
};

const CreateOrganizationUser: React.FC<CreateOrganizationUserProps> = props => {
  const { organizationId, user, searchParams } = props;
  const { currentUser } = useUsers();

  const formsByUserRole = {
    [UserRole.OrganizationAdmin]: <OrganizationAdminForm organizationId={currentUser.organization?.id} user={user} />,
    [UserRole.SuperAdmin]: <SuperAdminForm user={user} organizationId={organizationId} searchParams={searchParams} />,
  };

  return formsByUserRole[currentUser.role];
};

export default CreateOrganizationUser;
