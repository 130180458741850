import { Button, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';

import { attributesToSubmit, ResetPasswordFormData, initialValues, validationSchema } from 'src/forms/resetPassword';
import useUsers from 'src/hooks/useUsers';
import useModals from 'src/hooks/useModals';
import useScreen from 'src/hooks/useScreen';
import Box from 'src/components/Box';

import styles from './styles';

const ResetPassword: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const { hideModal } = useModals();
  const { resetPassword } = useUsers();
  const { lessThanTablet } = useScreen();

  const handleSubmit = (formData: ResetPasswordFormData) => {
    const params = attributesToSubmit(formData);
    resetPassword(params).unwrap();
    setIsSubmitted(true);
  };

  const {
    errors,
    handleSubmit: handleFormikSubmit,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
  } = useFormik<ResetPasswordFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const description = isSubmitted
    ? 'If an account with your email address exists, an email with the reset link has been sent to you. Please check your inbox.'
    : 'If an account with your email address exists, an email with the reset link will be sent to you. Please check your inbox.';

  return (
    <form noValidate onSubmit={handleFormikSubmit}>
      <Box sx={styles.box}>
        <Typography sx={styles.title} variant="h3">
          Reset Password
        </Typography>
        <Box sx={styles.row}>
          <Typography sx={styles.description} variant="button">
            {description}
          </Typography>
        </Box>
        {isSubmitted && lessThanTablet && (
          <Button variant="outlined" onClick={hideModal} sx={styles.button}>
            Close
          </Button>
        )}
        {!isSubmitted && (
          <>
            <Box sx={styles.row}>
              <TextField
                id="email"
                label="Email address"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Box>
            <Box sx={styles.buttons}>
              <Button variant="contained" type="submit" sx={styles.button} disabled={isSubmitting}>
                Send
              </Button>
              <Button variant="outlined" onClick={hideModal} sx={styles.button}>
                Cancel
              </Button>
            </Box>
          </>
        )}
      </Box>
    </form>
  );
};

export default ResetPassword;
