import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& .icon': {
      flexShrink: 0,
      width: '20px',
      height: '20px',
      marginRight: '8px',
      fill: 'currentColor',
    },
  },
  text: {
    color: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  deleteButton: {
    marginLeft: '10px',
    position: 'relative',
    '& svg': {
      fill: COLORS.red100,
    },
  },
};

export default styles;
