import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: '2px',
    overflow: 'hidden',
  },
  footer: {
    borderTop: `1px solid ${COLORS.black10}`,
    padding: '24px',
  },
  button: {
    padding: '7px 47px',
    marginRight: '10px',
  },
  buttons: {
    marginTop: '40px',
  },
  search: {
    margin: '0 24px 24px',
  },
  avatar: {
    width: '40px',
    height: '40px',
    backgroundColor: COLORS.black20,
    '& svg': {
      fontSize: '2rem',
      fill: COLORS.white,
    },
  },
};

export default styles;
