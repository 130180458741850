import { isEmpty, isNil } from 'ramda';
import { useEffect, useState } from 'react';
import {
  Button,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  FormHelperText,
} from '@mui/material';

import { ReactComponent as SearchIcon } from 'src/assets/images/searchIcon.svg';
import { ExpertiseType } from 'src/enums/Specialization';
import SpecializationsRepository from 'src/repositories/SpecializationsRepository';
import Specialization from 'src/types/resources/Specialization';
import { SearchSpecializationFormData } from 'src/forms/searchSpecialization';
import useLoading from 'src/hooks/useLoading';
import useOrganizationAdminTalents from 'src/hooks/organizationAdmin/useOrganizationAdminTalents';
import useModals from 'src/hooks/useModals';
import ToggleTagGroup from 'src/components/Talents/Specialization/components/ToggleTagGroup';
import Loader from 'src/components/Loader';
import MatchedResourcesCount from 'src/components/MatchedResourcesCount';
import Box from 'src/components/Box';

import styles from './styles';

const MIN_TAGS_FOR_SEARCH_INPUT = 15;

type StepFormProps = {
  commonSpecialization: Specialization;
  onChangeId: (id: number) => void;
  onShowResources: (id: number) => void;
  onExpertisesSet: (
    expertises: ExpertiseType[],
    parentSpecializationId: number,
    selectedSpecialization?: Specialization,
  ) => void;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => Promise<unknown>;
  values: SearchSpecializationFormData;
  isLoading: boolean;
  disableSearchFunctionality?: boolean;
};

const StepForm: React.FC<StepFormProps> = props => {
  const {
    values,
    commonSpecialization,
    onChangeId,
    onShowResources,
    onExpertisesSet,
    setFieldValue,
    isLoading,
    disableSearchFunctionality,
  } = props;

  const [filter, setFilter] = useState<string>('');
  const [selectedTagId, setSelectedTagId] = useState<number | null>(null);
  const [selectedSpecialization, setSelectedSpecialization] = useState<Specialization | null>(null);
  const [expertiseLevelError, setExpertiseLevelError] = useState(true);

  const { hideModal } = useModals();
  const { matchedTalentsCount, isLoadMatchedTalentsCountPending } = useOrganizationAdminTalents();
  const { funcWithLoading: fetchSpecializationWithLoading, isFinished: isFetchSpecializationFinished } = useLoading(
    SpecializationsRepository.show,
  );
  const tags = commonSpecialization?.children || [];
  const filteredTags = tags.filter(tag => tag.name.toLowerCase().includes(filter.toLowerCase()) || filter === '');
  const isShowSearchInput = tags.length > MIN_TAGS_FOR_SEARCH_INPUT;

  const handleChangeTag = async (event: React.MouseEvent<HTMLElement, MouseEvent>, value: number) => {
    setSelectedTagId(value);
    await setFieldValue('parentSpecialization', value, true);
    onExpertisesSet([], value);
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleSubmit = () => {
    if (!isFetchSpecializationFinished) return;
    if (
      !isNil(selectedSpecialization) &&
      isEmpty(selectedSpecialization?.children) &&
      !isNil(selectedTagId) &&
      disableSearchFunctionality &&
      values.expertises.length === 0
    ) {
      setExpertiseLevelError(true);
      return;
    }
    onChangeId(selectedTagId);
    setSelectedTagId(null);
    setFilter('');
    setExpertiseLevelError(false);
  };

  const handleShowResources = () => {
    onShowResources(selectedTagId);
  };

  const handleExpertiseLevelChange = async (event: React.MouseEvent<HTMLElement>, newValue: ExpertiseType[]) => {
    await onExpertisesSet(newValue, null, selectedSpecialization);
    setExpertiseLevelError(false);
  };

  const fetchSpecialization = async (id: number) => {
    const response = await fetchSpecializationWithLoading(id);
    setSelectedSpecialization(response.data);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        {isShowSearchInput && (
          <Box sx={styles.row}>
            <TextField
              id="search"
              sx={styles.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                placeholder: 'Search',
              }}
              value={filter}
              onChange={handleChangeFilter}
            />
          </Box>
        )}

        <Box sx={styles.row}>
          <ToggleTagGroup exclusive tags={filteredTags} value={selectedTagId} onChange={handleChangeTag} />
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (selectedTagId) {
      fetchSpecialization(selectedTagId);
    }
  }, [selectedTagId]);

  return (
    <Box sx={styles.form}>
      <Typography sx={styles.title} variant="h3">
        Choose {commonSpecialization?.name} Platforms
      </Typography>

      {renderContent()}

      {!isNil(selectedSpecialization) && isEmpty(selectedSpecialization?.children) && !isNil(selectedTagId) && (
        <Box sx={styles.row}>
          <Typography sx={styles.label} variant="caption" component="p">
            Level of expertise
          </Typography>
          <ToggleButtonGroup value={values.expertises} onChange={handleExpertiseLevelChange}>
            <ToggleButton value={ExpertiseType.novice} sx={styles.expertiseButton}>
              {ExpertiseType.novice}
            </ToggleButton>
            <ToggleButton value={ExpertiseType.intermediate} sx={styles.expertiseButton}>
              {ExpertiseType.intermediate}
            </ToggleButton>
            <ToggleButton value={ExpertiseType.expert} sx={styles.expertiseButton}>
              {ExpertiseType.expert}
            </ToggleButton>
          </ToggleButtonGroup>
          {disableSearchFunctionality && (
            <FormHelperText error={expertiseLevelError}>
              {expertiseLevelError ? 'This field is required' : ' '}
            </FormHelperText>
          )}
          {!disableSearchFunctionality && (
            <Typography sx={styles.caption} variant="caption" component="p">
              Pro Tip - Not selecting any level of expertise will allow you to view all levels in your search results.
            </Typography>
          )}
        </Box>
      )}

      <Box sx={styles.row}>
        <Box sx={styles.buttons}>
          <Button variant="contained" sx={styles.button} onClick={handleSubmit} disabled={!selectedTagId}>
            Next
          </Button>
          {!disableSearchFunctionality && (
            <Button variant="contained" sx={styles.button} onClick={handleShowResources} disabled={!selectedTagId}>
              Show Talent
            </Button>
          )}
          <Button variant="outlined" onClick={hideModal} sx={styles.button}>
            Cancel
          </Button>
          {!disableSearchFunctionality && (
            <Box sx={styles.matchedResourcesCountContainer}>
              <MatchedResourcesCount count={matchedTalentsCount} isLoading={isLoadMatchedTalentsCountPending} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default StepForm;
