import { Button, Typography } from '@mui/material';

import { OrganizationUser } from 'src/types/resources/Organization';
import { CreateOrganizationUserFormData } from 'src/forms/createOrganizationUser';
import { IndexParams } from 'src/repositories/superAdmin/OrganizationsUsersRepository';
import useModals from 'src/hooks/useModals';
import Box from 'src/components/Box';

import styles from './styles';

type OnUpdateUserParams = {
  id: number;
  params: Partial<CreateOrganizationUserFormData>;
};

type FormProps = {
  user: OrganizationUser;
  onLoadUsers: (params: IndexParams) => void;
  onUpdateUsers: (params: OnUpdateUserParams) => void;
};

const Form: React.FC<FormProps> = props => {
  const { user, onLoadUsers, onUpdateUsers } = props;
  const { id, isDeactivated, organizationId } = user;
  const { hideModal } = useModals();

  const formText = isDeactivated
    ? 'You are about to reactivate this Organization User. The User will get back access to their account.'
    : 'You are about to deactivate the Organization User. The User will lose access to their account.';
  const buttonText = isDeactivated ? 'Reactivate' : 'Deactivate';

  const handleSubmit = async () => {
    await onUpdateUsers({ id, params: { isDeactivated: !isDeactivated } });
    onLoadUsers({ organizationId });
    hideModal();
  };

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h2" sx={styles.title}>
        Are you sure?
      </Typography>
      <Typography variant="body1" sx={styles.text}>
        {formText}
      </Typography>
      <Box>
        <Button variant="contained" sx={styles.button} onClick={handleSubmit}>
          {buttonText}
        </Button>
        <Button variant="outlined" sx={styles.button} onClick={hideModal}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default Form;
