import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    width: '632px',
    minHeight: '612px',
    padding: '2em',
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLORS.black10}`,
  },
  row: {
    marginTop: '2em',
    paddingBottom: '1em',
    gap: '1.5em',
    '&:not(:only-of-type)': {
      borderBottom: `1px solid ${COLORS.black10}`,
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '3.5em',
  },
  button: {
    marginRight: '1em',
    paddingLeft: '3.5em',
    paddingRight: '3.5em',
  },
  removeButton: {
    alignSelf: 'flex-end',
  },
  additionalButton: {
    alignSelf: 'flex-start',
    marginTop: '1em',
  },
  inputCheckIcon: {
    lineHeight: 0,
    color: COLORS.green100,
  },
};

export default styles;
