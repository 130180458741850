import { COLORS } from 'src/theme/palette';
import { TODAY, getShortWeekdayNames } from 'src/utils/date';

const createMuiCalendarLabels = () => {
  const currentDayIndex = TODAY.getDay();
  const weekdayShortNames = getShortWeekdayNames();

  return weekdayShortNames.reduce((acc, weekday, index) => {
    const isCurrentDay = currentDayIndex === index;
    const labelStyles = {
      content: `"${weekday}"`,
      position: 'relative',
      left: '-4px',
      fontWeight: isCurrentDay ? 700 : 500,
      color: isCurrentDay ? COLORS.black100 : COLORS.black80,
      background: COLORS.white,
    };

    return { ...acc, [`&:nth-of-type(${index + 1})::after`]: labelStyles };
  }, {});
};

const MuiCalendarPicker = {
  styleOverrides: {
    root: {
      '.MuiPickersDay-root': {
        fontSize: '14px',
        lineHeight: '20px',
        '&.Mui-selected': {
          backgroundColor: COLORS.orange5,
          borderRadius: '4px',
          '&:hover, &:focus': {
            backgroundColor: COLORS.orange4,
          },
          '&.MuiPickersDay-today': {
            color: COLORS.white,
          },
        },
        '&.MuiPickersDay-today': {
          color: COLORS.black100,
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
      },
      '.MuiCalendarPicker-viewTransitionContainer > div > div > span': {
        color: 'transparent',
        ...createMuiCalendarLabels(),
      },
    },
  },
  defaultProps: {},
};

export default MuiCalendarPicker;
