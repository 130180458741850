import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  input: {
    paddingRight: 0,
  },
  visibilityButton: {
    '& svg': {
      fill: COLORS.black40,
    },
  },
};

export default styles;
