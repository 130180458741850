import { Typography, List, ListItemButton } from '@mui/material';
import { isEmpty } from 'ramda';

import { TalentSearch } from 'src/types/resources/TalentSearch';
import TalentPersonalInfo from 'src/components/TalentPersonalInfo';

import styles from './styles';

export type TalentsListProps = {
  talents: TalentSearch[];
  selectedTalentId: TalentSearch['id'];
  onTalentClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, talent: TalentSearch) => void;
};

const TalentsList: React.FunctionComponent<TalentsListProps> = props => {
  const { talents, selectedTalentId, onTalentClick } = props;

  if (isEmpty(talents)) {
    return (
      <Typography sx={styles.noResults} variant="caption">
        No results found...
      </Typography>
    );
  }

  return (
    <>
      <Typography sx={styles.listTitle} variant="h6">
        Select Talent
      </Typography>
      <List sx={styles.talentsList} component="nav" aria-label="main mailbox folders">
        {talents.map(talent => (
          <ListItemButton
            key={talent.id}
            sx={styles.talentsListItem}
            selected={talent.id === selectedTalentId}
            onClick={event => onTalentClick(event, talent)}
          >
            <TalentPersonalInfo
              avatar={talent.avatarPicture}
              state={talent.state}
              name={talent.fullName}
              city={talent.city}
            />
          </ListItemButton>
        ))}
      </List>
    </>
  );
};

export default TalentsList;
