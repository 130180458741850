/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import camelcaseKeys from 'camelcase-keys';
import { is, split, join, toLower } from 'ramda';

const isObject = <T>(object: T): boolean => is(Object, object);

export const isFile = <T>(object: T): boolean => object instanceof File;

const isArray = <T>(object: T): boolean => object instanceof Array;

export const decamelize = (string: string): string => toLower(join('_', split(/(?=[A-Z])|(?=\d{2,})/, string)));

export const camelizeKeys = <T>(object: T) => camelcaseKeys(object, { deep: true });

export const decamelizeKeys = (object: any): any => {
  if (isArray(object)) {
    return object.map((item: any) => decamelizeKeys(item));
  }
  if (isObject(object) && !isFile(object)) {
    return Object.keys(object).reduce(
      (acc, next) => ({
        ...acc,
        [decamelize(next)]: decamelizeKeys(object[next]),
      }),
      {},
    );
  }
  return object;
};

export const getQueryString = (object: Record<string, string>): string => {
  const query = Object.entries(object)
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join('&');

  return `?${query}`;
};

export const replaceNullKeys = <T>(object: T, placeholder: string): T => {
  return Object.entries(object).reduce((acc, [key, value]) => ({ ...acc, [key]: value ?? placeholder }), <T>{});
};

export const isLastArrayIndex = (index: number, array: Array<any>) => index === array.length - 1;

export const sortByIds = <T extends { id?: number }>(items: T[], ids: number[]) => {
  return [...items.filter(item => ids.includes(item.id)), ...items.filter(item => !ids.includes(item.id))];
};
