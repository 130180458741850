import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    padding: { tablet: '33px 40px 40px' },
    width: { tablet: '760px' },
    minHeight: '300px',
  },
  title: {
    boxShadow: 'inset 0px -1px 0px rgba(97, 148, 185, 0.25)',
    paddingBottom: '12px',
  },
  list: {
    marginTop: '40px',
  },
  addButton: {
    '& svg': {
      fill: 'currentcolor',
    },
  },
  buttons: {
    marginTop: '40px',
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
};

export default styles;
