import { is } from 'ramda';

import { IMAGE_VALID_FILE_TYPES } from 'src/utils/constants';

export const isString = (value: unknown): value is string => is(String, value);

export const stringHasWhitespace = (str: string): boolean => {
  return str.indexOf(' ') >= 0;
};

export const getFilename = (url: string): string => {
  // for links like https://revuud-qa-storage-private.s3.amazonaws.com/users/10/degrees/s3code-filename.png?params
  return url.split('/').at(-1).split('?').slice(0, -1).join('?').split('-').slice(1).join('-');
};

export const capitalize = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

export const capitalizeAll = (value: string): string =>
  value
    .split(' ')
    .map(item => item.charAt(0).toUpperCase() + item.slice(1))
    .join(' ');

export const removeExtraSpacesFromString = (text: string): string => {
  return text?.replace(/&nbsp;+/g, '')?.replace(/\s+/g, ' ');
};

export const removeNonCurrencyChars = (value: string): string => {
  // If the value is just a dot, replace it with '0.'
  if (value === '.') {
    return '0.';
  }

  // Remove all non-digit and non-dot characters
  const onlyDigitsAndDot = value.replace(/[^0-9.]/g, '');

  // Remove multiple dots, leaving only the first one
  return onlyDigitsAndDot.replace(/^(\d*\.)(.*)\./g, '$1$2');
};

export const joinTruthy = (array: unknown[], separator = ', '): string => array.filter(Boolean).join(separator);

export const isImage = (value: string): boolean => {
  return IMAGE_VALID_FILE_TYPES.some(validFileType => value.endsWith(validFileType));
};

export const encodeURIParams = (string: string): string => {
  try {
    return encodeURIComponent(string).replace(/[!'()*]/g, symbol => {
      return `%${symbol.charCodeAt(0).toString(16)}`;
    });
  } catch (error: unknown) {
    return string;
  }
};

export const decodeURIParams = (string: string): string => {
  try {
    return decodeURIComponent(string);
  } catch (error: unknown) {
    return string;
  }
};
