import { IndexParams } from 'src/repositories/organizationAdmin/DepartmentsRepository';
import {
  DepartmentsSliceStateType,
  DepartmentsSliceActionsType,
  loadDepartments,
} from 'src/store/organizationAdmin/departmentsSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';

type UseDepartmentsType = Pick<DepartmentsSliceStateType, 'departments' | 'meta'> &
  DepartmentsSliceActionsType & {
    isDepartmentsLoadPending: boolean;
    isDepartmentsLoadFinished: boolean;
  };

const useDepartments = (): UseDepartmentsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    departments,
    index: { fetchStatus: departmentsIndexFetchStatus },
  } = useAppSelector(state => state.superAdminDepartments);

  const isDepartmentsLoadPending = getFetchStatus(departmentsIndexFetchStatus).isPending;
  const isDepartmentsLoadFinished = getFetchStatus(departmentsIndexFetchStatus).isFinished;

  return {
    meta,
    departments,
    isDepartmentsLoadPending,
    isDepartmentsLoadFinished,
    loadDepartments: (params?: IndexParams) => dispatch(loadDepartments(params)),
  };
};

export default useDepartments;
