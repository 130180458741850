import { AxiosResponse } from 'axios';

import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  isRoot?: boolean;
};

export default {
  index(params: IndexParams = { isRoot: true }): Promise<{ results: [] }> {
    const url = apiRoutes.specializationsPath();
    return Fetcher.get(url, {
      root: params.isRoot,
    });
  },
  show(id: number): Promise<AxiosResponse> {
    const url = apiRoutes.specializationPath(id);
    return Fetcher.get(url);
  },
};
