import { mergeRight } from 'ramda';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type ClsxPair = [styles: SxProps<Theme>, applicable: boolean];

const clsx = (baseSx: SxProps<Theme>, conditionalSx: ClsxPair[]): SxProps<Theme> => {
  const mergedStyles: SxProps<Theme> = conditionalSx.reduce((acc, item) => {
    const [styles, applicable] = item;

    return applicable ? mergeRight(acc, styles) : acc;
  }, baseSx);

  return mergedStyles;
};

// clsx util doesn't work for the next case

// const style = makeStyles({
//   elementStyle: theme => ({ backgroundColor: theme.palette.green100 }),
// });
// const mergedStyles = clsx({ backgroundColor: 'white' }, [[style.elementStyle, true]]);

// style.elementStyle is function and not an array. TypeScript doesn't see error here. This type fulfills the requirements for the type SxProps<Theme>

export default clsx;
