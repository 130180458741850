import * as yup from 'yup';

import { ValidationError } from 'src/enums/Validation';
import { TimeSheetState } from 'src/enums/TimeSheet';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';

export type TimeSheetDeclineFormData = {
  clientComment: string;
};

export const validationFields = {
  clientComment: yup
    .string()
    .trim()
    .default('')
    .max(500, getMaxFieldLengthErrorMessage(500))
    .required(ValidationError.default)
    .label('Comment'),
  state: yup.string().default(TimeSheetState.declined),
};

export const validationSchema = yup.object(validationFields);

export const initialValues: TimeSheetDeclineFormData = validationSchema.getDefault();

export const attributesToSubmit = (values: TimeSheetDeclineFormData): TimeSheetDeclineFormData => {
  return values;
};
