import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  root: {
    padding: 0,
  },
  avatar: {
    width: '48px',
    height: '48px',
    border: `1px solid ${COLORS.black10}`,
    minWidth: 0,
    marginRight: '10px',
    backgroundColor: COLORS.black20,
    '& svg': {
      fontSize: '2rem',
      fill: COLORS.white,
    },
  },
  primaryText: {
    lineHeight: '20px',
  },
  notBold: {
    fontWeight: 400,
  },
  secondaryText: {
    marginTop: '3px',
    color: COLORS.black40,
  },
};

export default styles;
