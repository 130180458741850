import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material';
import { isNil } from 'ramda';

import { RadioItem } from 'src/utils/users';

import styles from './styles';

type UserReassignmentRadioControlProps = RadioGroupProps & {
  title?: string | null;
  radioItems: RadioItem[];
};

const UserReassignmentRadioControl: React.FC<UserReassignmentRadioControlProps> = props => {
  const { title = null, radioItems, ...restProps } = props;

  return (
    <FormControl component="fieldset" variant="standard">
      {!isNil(title) && (
        <FormLabel component="legend" sx={styles.radioGroupLabel}>
          {title}
        </FormLabel>
      )}
      <RadioGroup {...restProps}>
        {radioItems.map(radioItem => (
          <FormControlLabel
            sx={styles.radioControlLabel}
            key={radioItem.value}
            value={radioItem.value}
            control={<Radio />}
            label={radioItem.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default UserReassignmentRadioControl;
