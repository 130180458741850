import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  textFieldRemoveButton: {
    '& svg': {
      fill: 'currentColor',
    },
  },
};

export default styles;
