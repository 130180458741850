import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { Comment } from 'src/types/resources/Comment';
import CommentsRepository, { CreateParams, LinkToProfileParams } from 'src/repositories/CommentsRepository';
import { Meta } from 'src/types/meta';

export type CommentsSliceStateType = {
  comments: Comment[];
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  linkToProfile: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type CommentsSliceActionsType = {
  loadComments: () => { unwrap: () => void };
  createComment: (params: CreateParams) => { unwrap: () => void };
  linkCommentToProfile: (params: LinkToProfileParams) => { unwrap: () => void };
};

const initialState: CommentsSliceStateType = {
  comments: [],
  meta: {} as Meta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  linkToProfile: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadComments = createAsyncAction('manager/comments/index', CommentsRepository.index);
export const createComment = createAsyncAction('manager/comments/create', CommentsRepository.create);
export const linkCommentToProfile = createAsyncAction('manager/comments/link', CommentsRepository.linkToProfile);

const commentsSlice = createSlice({
  name: 'manager/comments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadComments.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadComments.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.comments = payload.results;
    });
    builder.addCase(loadComments.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(createComment.fulfilled, (state, { payload }) => {
      state.comments = [payload.data, ...state.comments];
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
  },
});

export default commentsSlice.reducer;
