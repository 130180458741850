import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Typography, TextField, Button, Alert } from '@mui/material';

import {
  attributesToSubmit,
  initialValues,
  SaveResourcesSearchFormData,
  validationSchema,
} from 'src/forms/saveResourcesSearch';
import { SearchResourcesFormData } from 'src/forms/SearchResources';
import { CreateParams } from 'src/repositories/manager/SavedSearchRepository';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useSnackbar from 'src/hooks/useSnackbar';
import useModals from 'src/hooks/useModals';
import Box from 'src/components/Box';

import styles from './styles';

type SaveResourcesSearchFormProps = {
  params: SearchResourcesFormData;
  onLoadSavedSearches: () => void;
  onCreateSavedSearch: (params: CreateParams) => void;
};

const SaveResourcesSearchForm: React.FC<SaveResourcesSearchFormProps> = props => {
  const { params, onLoadSavedSearches, onCreateSavedSearch } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { hideModal } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();

  const handleSubmit = async (
    formData: SaveResourcesSearchFormData,
    { setErrors }: FormikHelpers<SaveResourcesSearchFormData>,
  ) => {
    const paramsToSubmit = attributesToSubmit(formData, params);
    setFormError(null);
    try {
      await onCreateSavedSearch(paramsToSubmit);
      onLoadSavedSearches();
      enqueueSuccessSnackbar('Search was saved');
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(error.response.data.errors.nonFieldErrors);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit: handleFormikSubmit,
  } = useFormik<SaveResourcesSearchFormData>({
    initialValues: initialValues(),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.wrapper}>
          <Typography variant="h3" sx={styles.title}>
            Add New Search
          </Typography>

          <Box sx={styles.row}>
            <TextField
              id="name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              placeholder="Enter name of search"
            />
          </Box>

          <Box sx={styles.footer}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
              Add
            </Button>
            <Button variant="outlined" onClick={hideModal} sx={styles.button}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default SaveResourcesSearchForm;
