import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import { SingleResponse } from 'src/types/utils';
import { Task } from 'src/types/resources/Task';

export type ExportParams = {
  payPeriodAfterDate: string;
  payPeriodBeforeDate: string;
};

export default {
  create(params: ExportParams): Promise<SingleResponse<Task>> {
    const url = apiRoutes.superAdmin.accountingExportPath();
    return Fetcher.post(url, params);
  },
};
