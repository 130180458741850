import { CircularProgress, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import clsx from 'src/utils/clsx';
import Box from 'src/components/Box';

import styles from './styles';

type LoaderProps = {
  sx?: SxProps<Theme>;
};

const Loader: React.FC<LoaderProps> = props => {
  const { sx = {} } = props;

  return (
    <Box sx={clsx(styles.wrapper, [[sx, true]])}>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
