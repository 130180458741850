import { isNil } from 'ramda';
import * as yup from 'yup';

import CertificateType from 'src/types/resources/CertificateType';
import { ValidationError } from 'src/enums/Validation';
import { getValidFileTypeTest, VALID_FILE_SIZE } from 'src/forms/customRules';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';
import { CERTIFICATE_VALID_FILE_TYPES } from 'src/utils/constants';
import { getFilePayload } from 'src/utils/filePayload';
import { CreateParams } from 'src/repositories/organizationAdmin/PurchaseOrderRepository';

import { DocumentFile } from './documents';

export type CertificateFormData = {
  documentName: string;
  schoolOrUniversity: string;
  fieldOfStudy: string;
  file?: DocumentFile | null;
};

const MAX_FIELD_LENGTH = 150;

export const validationFields = {
  documentName: yup
    .string()
    .trim()
    .max(MAX_FIELD_LENGTH, getMaxFieldLengthErrorMessage(MAX_FIELD_LENGTH))
    .required(ValidationError.default)
    .default(''),
  schoolOrUniversity: yup
    .string()
    .trim()
    .max(MAX_FIELD_LENGTH, getMaxFieldLengthErrorMessage(MAX_FIELD_LENGTH))
    .nullable()
    .default(''),
  fieldOfStudy: yup
    .string()
    .trim()
    .max(MAX_FIELD_LENGTH, getMaxFieldLengthErrorMessage(MAX_FIELD_LENGTH))
    .nullable()
    .default(''),
  file: yup
    .object()
    .nullable()
    .shape({
      source: yup
        .mixed()
        .nullable()
        .test(getValidFileTypeTest(CERTIFICATE_VALID_FILE_TYPES))
        .test(VALID_FILE_SIZE)
        .default(null),
      url: yup.string().nullable().default(null),
    }),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (degree: CertificateType | null): CertificateFormData => {
  if (isNil(degree)) {
    return validationSchema.getDefault();
  }

  const { documentName, ...restDegree } = degree;

  return {
    ...restDegree,
    file: {
      source: null,
      url: degree.file,
      name: degree.originalFileName,
    },
    documentName,
  };
};

export const attributesToSubmit = (values: CertificateFormData): CertificateFormData => {
  const { file, ...restValues } = values;
  const filePayload = getFilePayload(file, 'file') as Pick<CreateParams, 'fileName' | 'originalFileName'>;

  return {
    ...restValues,
    ...filePayload,
  };
};
