import { createTheme, ThemeOptions, Theme } from '@mui/material/styles';

import palette from './palette';
import overrides from './overrides';
import typography from './typography';
import breakpoints from './breakpoints';

const createAppTheme = (themeOptions: ThemeOptions): Theme => createTheme(themeOptions);

const theme: Theme = createAppTheme({
  palette,
  typography,
  breakpoints,
  ...overrides,
});

export default theme;
