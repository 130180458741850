import * as yup from 'yup';

import { ValidationError } from 'src/enums/Validation';
import { UserInvitationType } from 'src/enums/UserInvitationType';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';

export type InviteUserFormData = {
  email: string;
  firstName: string;
  lastName: string;
  inviteType: UserInvitationType;
  doResend?: boolean;
  message?: string;
  organizationName?: string;
};

export const validationFields = {
  email: yup.string().email(ValidationError.email).default('').required(ValidationError.default),
  firstName: yup
    .string()
    .default('')
    .required(ValidationError.default)
    .max(30, getMaxFieldLengthErrorMessage(30))
    .label('First Name'),
  lastName: yup
    .string()
    .default('')
    .required(ValidationError.default)
    .max(30, getMaxFieldLengthErrorMessage(30))
    .label('Last Name'),
  doResend: yup.boolean().default(false),
  inviteType: yup
    .mixed<UserInvitationType>()
    .required(ValidationError.default)
    .oneOf([UserInvitationType.Talent, UserInvitationType.Organization])
    .label('Role'),
  organizationName: yup
    .string()
    .default('')
    .label('Organization Name')
    .when('inviteType', {
      is: UserInvitationType.Organization,
      then: yup.string().required(ValidationError.default).default('').label('Organization Name'),
    }),
  message: yup.string().max(500, getMaxFieldLengthErrorMessage(500)).default('').label('Message'),
};

export const validationSchema = yup.object(validationFields);

export const initialValues: InviteUserFormData = validationSchema.getDefault();

export const attributesToSubmit = (values: InviteUserFormData): InviteUserFormData => values;
