import { AxiosResponse } from 'axios';

import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import { KnowledgeCenterFAQFeedbackFormDataToSubmit } from 'src/forms/knowledgeCenterFAQFeedback';

export default {
  create(params: KnowledgeCenterFAQFeedbackFormDataToSubmit): Promise<AxiosResponse> {
    const url = apiRoutes.faqFeedbackPath();
    return Fetcher.postFormData(url, params);
  },
};
