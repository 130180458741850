import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    padding: '24px',
    width: '512px',
  },
  title: {
    paddingBottom: '12px',
  },
  row: {
    margin: '16px 0 24px',
  },
  button: {
    padding: '7px 47px',
    marginRight: '10px',
  },
  buttons: {
    marginTop: '40px',
  },
};

export default styles;
