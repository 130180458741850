import { PaletteOptions } from '@mui/material/styles';
import { alpha } from '@mui/system';

const COLORS = {
  black: '#000000',
  black02: '#F2F2F3',
  black05: '#EAEEF4',
  black10: '#E0E0E1',
  black20: '#CACBCC',
  black40: '#9FA0A1',
  black60: '#737576',
  black80: '#474A4B',
  black100: '#1B1E20',

  grey40: '#98A2B3',
  grey50: '#667085',

  red100: '#DA2F0A',
  green100: '#72C040',

  white: '#ffffff',
  orange: '#FF9100',
  teal: '#47AFD0',
  layoutBackground: '#F7F9Fe',
  organizationName: '#205391',

  blue01: '#FBFBFD',
  blue02: '#EFF8FF',
  blue05: '#E2F2FF',
  blue10: '#D6EDFF',
  blue15: '#A0D7FF',
  blue20: '#39A2FF',
  blue40: '#1D8EFF',
  blue60: '#1A80E5',
  blue80: '#1772CC',
  blue100: '#054DD0',

  orange1: '#BF360C',
  orange2: '#D84315',
  orange3: '#E64A19',
  orange4: '#F4511E',
  orange5: '#FF5722',
  orange6: '#FF7043',
  orange7: '#FF8A65',
  orange8: '#FFAB91',
  orange9: '#FFCCBC',
  orange10: '#FBE9E7',
  orange11: '#FFF3F0',
  orange12: '#F7DFD1',

  lightBlue: '#F4F6FA',
  lightBluePressed: '#EAEFFB',
  darkGrey: '#EAEEF4',
  lightGrey: '#F7F9FE',
  logoText: '#204A6F',
  halfTransparentGray: '#e0e0e0ff',

  signInBackground: '#F8FDFF',
  signInHeaderBorder: '#7DD2ED27',

  tomato: '#FA5656',
};

const GRADIENTS = {
  main: 'linear-gradient(176.3deg, rgba(27, 54, 93, 0.5) 17.27%, rgba(52, 160, 187, 0.5) 58.51%, rgba(33, 222, 255, 0.5) 91.55%), #1B365D',
  orange: `linear-gradient(to top, ${alpha(COLORS.orange, 0.1)}, ${alpha(COLORS.orange, 0)})`,
  orange5: `linear-gradient(to top, ${alpha(COLORS.orange5, 0.1)}, ${alpha(COLORS.orange5, 0)})`,
  green100: `linear-gradient(to top, ${alpha(COLORS.green100, 0.1)}, ${alpha(COLORS.green100, 0)})`,
};

const palette: PaletteOptions = {
  primary: {
    main: COLORS.black80,
  },
  secondary: {
    main: COLORS.orange5,
  },
  ...COLORS,
};

export { COLORS, GRADIENTS };
export default palette;
