import {
  TimeSheetsAdjustmentsSliceStateType,
  TimeSheetsAdjustmentsSliceActionsType,
  loadTimeSheetsAdjustments,
  partialUpdateTimeSheetsAdjustment,
  resetTimeSheetsAdjustments,
} from 'src/store/organizationAdmin/timeSheetsAdjustmentsSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { IndexParams, PartialUpdateParams } from 'src/repositories/organizationAdmin/TimeSheetsAdjustmentsRepository';

type UseTimeSheetsAdjustmentsType = Pick<TimeSheetsAdjustmentsSliceStateType, 'timeSheetsAdjustments' | 'meta'> &
  TimeSheetsAdjustmentsSliceActionsType & {
    isTimeSheetsAdjustmentsFinished: boolean;
    isTimeSheetsAdjustmentPartialUpdatePending: boolean;
  };

const useTimeSheetsAdjustments = (): UseTimeSheetsAdjustmentsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    timeSheetsAdjustments,
    index: { fetchStatus: timeSheetsAdjustmentsIndexStatus },
    partialUpdate: { fetchStatus: timeSheetsAdjustmentPartialUpdateStatus },
  } = useAppSelector(state => state.organizationAdminTimeSheetsAdjustments);

  const isTimeSheetsAdjustmentsFinished = getFetchStatus(timeSheetsAdjustmentsIndexStatus).isFinished;
  const isTimeSheetsAdjustmentPartialUpdatePending = getFetchStatus(timeSheetsAdjustmentPartialUpdateStatus).isPending;

  return {
    meta,
    timeSheetsAdjustments,
    isTimeSheetsAdjustmentsFinished,
    isTimeSheetsAdjustmentPartialUpdatePending,
    loadTimeSheetsAdjustments: (params?: IndexParams) => dispatch(loadTimeSheetsAdjustments(params)),
    partialUpdateTimeSheetsAdjustment: (params: PartialUpdateParams) =>
      dispatch(partialUpdateTimeSheetsAdjustment(params)),
    resetTimeSheetsAdjustments: () => dispatch(resetTimeSheetsAdjustments()),
  };
};

export default useTimeSheetsAdjustments;
