import React, { ChangeEvent, useState } from 'react';
import { Typography, Button, Alert, Divider, Link, FormControlLabel, Checkbox } from '@mui/material';
import { FormikHelpers, getIn, useFormik } from 'formik';
import { head, isNil } from 'ramda';

import { LibraryDocumentType } from 'src/enums/DocumentLibrary';
import {
  validationSchema,
  EngagementDocumentFileUploadData,
  initialValues,
  attributesToSubmit,
} from 'src/forms/engagementDocumentFileUpload';
import useDocuments from 'src/hooks/talents/useDocuments';
import useEngagements from 'src/hooks/talents/useEngagements';
import useLoading from 'src/hooks/useLoading';
import RequiredDocumentPresenter from 'src/presenters/RequiredDocumentPresenter';
import FilledDocumentsRepository from 'src/repositories/talents/FilledDocumentsRepository';
import { ManagerRequiredDocument } from 'src/types/resources/RequiredDocument';
import { DOCUMENT_LIBRARY_VALID_FILE_TYPES } from 'src/utils/constants';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useModals from 'src/hooks/useModals';
import FileField from 'src/components/FileField';
import AttachedFileLink from 'src/components/AttachedFileLink';
import Box from 'src/components/Box';

import styles from './styles';

const documentTypeMapping = {
  [LibraryDocumentType.signAndReturn]: {
    title: 'Sign and Return a Document',
    downloadButtonText: 'Download Template',
    downloadButtonDescription: 'Print this template, sign it and fill out the fields if needed.',
    uploadButtonText: 'Upload a file',
    uploadButtonDescription: 'Please upload a signed scan.',
    submitButtonText: 'Add',
  },
  [LibraryDocumentType.consentForm]: {
    title: 'Read and Accept a Document',
    downloadButtonText: 'Download Document',
    downloadButtonDescription: 'Please download the document, read it and come back here to check the box',
    uploadButtonText: 'Select file',
    uploadButtonDescription: 'Upload your document here',
    submitButtonText: 'Submit',
  },
  [LibraryDocumentType.upload]: {
    title: 'Upload a Document',
    downloadButtonText: 'Download file',
    downloadButtonDescription: 'Organization provides an example or instructions here',
    uploadButtonText: 'Select file',
    uploadButtonDescription: 'Upload your document here.',
    submitButtonText: 'Add',
  },
};

type FormProps = {
  engagementId: number;
  document: ManagerRequiredDocument;
};

const UploadEngagementRequiredDocumentForm: React.FC<FormProps> = props => {
  const { engagementId, document } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const [isFileRead, setIsFileRead] = useState<boolean>(Boolean(document.filled?.isChecked));
  const { hideModal } = useModals();
  const { showEngagement } = useEngagements();
  const { loadDocuments } = useDocuments();

  const { funcWithLoading: createDocumentWithLoading } = useLoading(FilledDocumentsRepository.create);
  const { funcWithLoading: partialUpdateDocumentWithLoading } = useLoading(FilledDocumentsRepository.partialUpdate);

  const {
    title: formTitle,
    submitButtonText,
    downloadButtonText,
    downloadButtonDescription,
    uploadButtonText,
    uploadButtonDescription,
  } = documentTypeMapping[document.answerType];

  const isAddForm = isNil(document.filled);
  const isReadAndAcceptFile = RequiredDocumentPresenter.isReadAndAcceptFile(document);
  const isDownloadFileExist = RequiredDocumentPresenter.isDownloadFileExist(document);

  const documentName = document.name;
  const documentDescription = document.description;
  const currentSubmitButtonText = isAddForm ? submitButtonText : 'Save';

  const handleSubmit = async (
    values: EngagementDocumentFileUploadData,
    { setErrors }: FormikHelpers<EngagementDocumentFileUploadData>,
  ) => {
    const params = attributesToSubmit(values, engagementId, document.id);
    setFormError(null);
    try {
      if (isAddForm) {
        await createDocumentWithLoading(params);
      } else {
        await partialUpdateDocumentWithLoading({ id: document.filled?.id, params });
      }
      hideModal();
      showEngagement({ id: engagementId });
      loadDocuments();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(head(error.response.data.errors.nonFieldErrors));
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit: handleFormikSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik<EngagementDocumentFileUploadData>({
    initialValues: initialValues(document),
    validationSchema,
    onSubmit: handleSubmit,
  });

  const isDisabledSubmitButton = isSubmitting || (isReadAndAcceptFile && !values.isChecked);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files[0] || null;

    if (selectedFile) {
      await setFieldTouched('file.source', true);
      await setFieldValue('file', {
        source: selectedFile,
        url: null,
      });
    }
  };

  const handleDeleteFile = async () => {
    await setFieldValue('file', {
      source: null,
      url: null,
    });
  };

  const handleFileRead = () => {
    setIsFileRead(true);
  };

  const renderFileField = () => {
    const isFileFieldError = getIn(touched, 'file.source') && getIn(errors, 'file.source');
    const isFileExist = getIn(values, 'file.source') || getIn(values, 'file.url');
    const isVisibleSelectButton = isFileFieldError || !isFileExist;

    return (
      isVisibleSelectButton && (
        <Box sx={styles.fileButtonWrapper}>
          <Box sx={styles.fileButton}>
            <FileField
              id={document.name}
              buttonText={uploadButtonText}
              accept={DOCUMENT_LIBRARY_VALID_FILE_TYPES.join(',')}
              onChange={handleFileChange}
              fullWidth
            />
          </Box>
          <Typography variant="caption" sx={styles.fileButtonDescription}>
            {uploadButtonDescription}
          </Typography>
        </Box>
      )
    );
  };

  const renderPreviewFileLink = () => {
    const isFileFieldError = getIn(touched, 'file.source') && getIn(errors, 'file.source');
    const isFileExist = getIn(values, 'file.source') || getIn(values, 'file.url');

    if (isFileFieldError) {
      return (
        <Typography sx={styles.documentHelperErrorText} component="p" color="error">
          {errors.file.source}
        </Typography>
      );
    }

    return (
      isFileExist && (
        <AttachedFileLink sx={styles.documentAttachedLink} file={values.file} onDelete={handleDeleteFile} />
      )
    );
  };

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.box}>
          <Typography variant="h3" sx={styles.heading}>
            {formTitle}
          </Typography>
          <Typography variant="h5" sx={styles.documentName}>
            {documentName}
          </Typography>
          {documentDescription && (
            <Typography variant="body1" sx={styles.documentDescription}>
              {documentDescription}
            </Typography>
          )}
          <Divider sx={styles.divider} />
          {isDownloadFileExist && (
            <Box sx={styles.row}>
              <Box sx={styles.fileButtonWrapper}>
                <Button
                  sx={styles.fileButton}
                  variant="outlined"
                  component={Link}
                  onClick={handleFileRead}
                  href={document.file}
                  rel="noreferrer"
                  target="_blank"
                  download={document.name}
                >
                  {downloadButtonText}
                </Button>
                <Typography variant="caption" sx={styles.fileButtonDescription}>
                  {downloadButtonDescription}
                </Typography>
              </Box>
            </Box>
          )}
          {!isReadAndAcceptFile && (
            <Box sx={styles.row}>
              {renderFileField()}
              {renderPreviewFileLink()}
            </Box>
          )}
          {isReadAndAcceptFile && (
            <Box sx={styles.row}>
              <FormControlLabel
                sx={styles.checkboxControl}
                control={
                  <Checkbox
                    name="isChecked"
                    onChange={handleChange}
                    checked={Boolean(values.isChecked)}
                    disabled={!isFileRead}
                  />
                }
                label="By checking this box, I confirm that I have read the document and I agree with it and its statements"
              />
            </Box>
          )}
          <Box sx={styles.footer}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isDisabledSubmitButton}>
              {currentSubmitButtonText}
            </Button>
            <Button variant="outlined" sx={styles.button} onClick={hideModal}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default UploadEngagementRequiredDocumentForm;
