import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';
import { TooltipVariant } from 'src/types/theme';
import { isString } from 'src/utils/string';

type MuiTooltipType = {
  defaultProps: ComponentsProps['MuiTooltip'] & {
    variant?: TooltipVariant;
  };
  styleOverrides: ComponentsOverrides['MuiTooltip'];
};

const isLightVariant = (variant: TooltipVariant): boolean => variant === 'light';

const MuiTooltip: MuiTooltipType = {
  styleOverrides: {
    tooltip: props => {
      const { title, variant } = props.ownerState;

      return {
        maxWidth: '450px',
        fontSize: '12px',
        lineHeight: 1.33,
        letterSpacing: 0.01,
        padding: isString(title) ? '8px 12px' : 0,
        ...(isLightVariant(variant) && {
          backgroundColor: COLORS.white,
          boxShadow: `0px 0px 1px rgba(27, 30, 32, 0.3), 0px 6px 20px rgba(27, 30, 32, 0.25)`,
          color: COLORS.black80,
        }),
      };
    },
    arrow: props => {
      const { variant } = props.ownerState;

      return {
        ...(isLightVariant(variant) && {
          color: COLORS.white,
        }),
      };
    },
  },
  defaultProps: {
    variant: 'dark',
  },
};

export default MuiTooltip;
