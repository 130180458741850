import { parseISO } from 'date-fns';
import { isNil } from 'ramda';

import { TimeSheetState } from 'src/enums/TimeSheet';
import { ChildrenTimeSheet, NestedTimeSheet } from 'src/types/resources/TimeSheet';
import { EngagementPO } from 'src/types/resources/EngagementPO';
import {
  DEFAULT_DATE_FORMAT_SHORT_YEAR,
  WEEKDAY_FORMAT_TWO_SYMBOLS,
  DEFAULT_DATE_TIME_FORMAT,
  getFullDate,
  getWeekdayName,
} from 'src/utils/date';
import { formatCurrency } from 'src/utils/formatCurrency';
import { isOriginalTimeSheet } from 'src/utils/engagementTimeSheets';
import { capitalize } from 'src/utils/string';

const NestedTimeSheetPresenter = {
  cost: (nestedTimeSheet: NestedTimeSheet | ChildrenTimeSheet, useTalentPayRate: boolean): string => {
    const currencyOptions: Intl.NumberFormatOptions = {
      // @ts-ignore
      signDisplay: isOriginalTimeSheet(nestedTimeSheet) ? 'never' : 'exceptZero',
    };

    const cost = useTalentPayRate ? nestedTimeSheet.costTalentFeeIncluded : nestedTimeSheet.cost;
    return isNil(cost) ? '-' : formatCurrency(Number(cost), currencyOptions);
  },
  canBeEdited: (nestedTimeSheet: NestedTimeSheet): boolean => {
    const canEditStatuses = [TimeSheetState.approved, TimeSheetState.declined];

    return canEditStatuses.includes(nestedTimeSheet?.state);
  },
  weekdayName: (nestedTimeSheet: NestedTimeSheet, defaultDate: Date): string => {
    const currentDate = nestedTimeSheet?.timekeepingDate ? parseISO(nestedTimeSheet.timekeepingDate) : defaultDate;
    return getWeekdayName(currentDate, WEEKDAY_FORMAT_TWO_SYMBOLS);
  },
  timekeepingDate: (nestedTimeSheet: NestedTimeSheet, defaultDate?: Date): string => {
    return nestedTimeSheet?.timekeepingDate
      ? getFullDate(nestedTimeSheet.timekeepingDate, DEFAULT_DATE_FORMAT_SHORT_YEAR)
      : getFullDate(defaultDate.toISOString(), DEFAULT_DATE_FORMAT_SHORT_YEAR);
  },
  reviewedAtDate: (nestedTimeSheet: NestedTimeSheet): string => {
    return nestedTimeSheet?.reviewedAt ? getFullDate(nestedTimeSheet.reviewedAt, DEFAULT_DATE_TIME_FORMAT) : '-';
  },
  reviewedBy: (nestedTimeSheet: NestedTimeSheet): string => {
    return nestedTimeSheet?.reviewedBy?.fullName ?? '-';
  },
  state: (nestedTimeSheet: NestedTimeSheet): string => {
    let currentState = TimeSheetState.open;

    if (nestedTimeSheet?.state) {
      const isPendingState = nestedTimeSheet.state === TimeSheetState.pending;
      currentState = isPendingState ? TimeSheetState.submitted : nestedTimeSheet.state;
    }

    const capitalizedCurrentState = capitalize(currentState);
    return nestedTimeSheet?.isAdjustive ? `Adj. ${capitalizedCurrentState}` : capitalizedCurrentState;
  },
  poNumber: (nestedTimeSheet: NestedTimeSheet): string => {
    const poNumber = nestedTimeSheet?.purchaseOrder?.purchaseOrder?.name;
    return poNumber || 'PO not assigned';
  },
  poDescription: (nestedTimeSheet: NestedTimeSheet): string => {
    const poDescription = nestedTimeSheet?.purchaseOrder?.purchaseOrder?.description;
    return poDescription || 'PO not assigned';
  },
  poLabel: (engagementPO: EngagementPO) => {
    return engagementPO
      ? `${engagementPO.purchaseOrder?.name}-${engagementPO.purchaseOrder?.description}`
      : 'PO not assigned';
  },
  isOpen: (nestedTimeSheet: NestedTimeSheet): boolean => {
    return nestedTimeSheet?.state === TimeSheetState.open;
  },
  isPending: (nestedTimeSheet: NestedTimeSheet): boolean => {
    return nestedTimeSheet?.state === TimeSheetState.pending;
  },
  isApproved: (nestedTimeSheet: NestedTimeSheet): boolean => {
    return nestedTimeSheet?.state === TimeSheetState.approved;
  },
  isDeclined: (nestedTimeSheet: NestedTimeSheet): boolean => {
    return nestedTimeSheet?.state === TimeSheetState.declined;
  },
};

export default NestedTimeSheetPresenter;
