import { AxiosResponse } from 'axios';

import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import { InviteUserFormData } from 'src/forms/inviteUser';
import { InviteTalentFormData } from 'src/forms/talents/inviteTalent';

export default {
  createOld(params: InviteTalentFormData): Promise<AxiosResponse> {
    const url = apiRoutes.inviteTalentPath();
    return Fetcher.post(url, params);
  },
  create(params: InviteUserFormData & { invitedBy: ID }): Promise<AxiosResponse> {
    const url = apiRoutes.inviteUserPath();
    return Fetcher.post(url, params);
  },
};
