import { Environment } from 'src/enums/Environment';

const googleAnalyticsIDs: Record<Environment, string> = {
  prod: 'G-V04JHSDQC9',
  qa: 'G-15C2YHJY5C',
  dev: 'G-15C2YHJY5C',
  uat: 'G-ZG1NNB9WCS',
};

export const getGoogleAnalyticsMeasurementID = () => {
  const envVariables = { ...(process.env || {}) };
  const appEnv: Environment = envVariables.REACT_APP_ENV ?? Environment.dev;

  return googleAnalyticsIDs[appEnv];
};
