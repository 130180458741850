import { AxiosResponse } from 'axios';

import { SignInFormData } from 'src/forms/signInForm';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export default {
  create(params: SignInFormData): Promise<AxiosResponse> {
    const url = apiRoutes.signInPath();
    return Fetcher.post(url, params);
  },
  delete(): Promise<AxiosResponse> {
    const url = apiRoutes.signInPath();
    return Fetcher.delete(url);
  },
};
