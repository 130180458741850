import { Typography, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import clsx from 'src/utils/clsx';
import { formatTime } from 'src/utils/date';

import styles from './styles';

type TimeTrackingTotalTimeProps = {
  time: string;
  totalText?: string;
  isSmall?: boolean;
  isTotalVisible?: boolean;
  isPrefixVisible?: boolean;
  sx?: SxProps<Theme>;
};

const TimeTrackingTotalTime: React.FC<TimeTrackingTotalTimeProps> = props => {
  const { time, totalText = 'Total', isSmall = false, isTotalVisible = true, isPrefixVisible = false, sx = {} } = props;

  const formattedTime = formatTime(time, { padStartHours: 1, isPrefixVisible });
  const [hours, minutes] = formattedTime.split(':');

  let totalTimeColorStyles = {};
  if (Number(time) > 0) totalTimeColorStyles = styles.totalTimePositive;
  if (Number(time) < 0) totalTimeColorStyles = styles.totalTimeNegative;

  const totalTimeStyles = clsx(styles.totalTime, [
    [sx, true],
    [totalTimeColorStyles, isPrefixVisible],
  ]);

  const renderTime = () => {
    return (
      <Typography sx={totalTimeStyles} variant={isSmall ? 'button' : 'h5'} component="span">
        {hours}
        <Typography sx={styles.totalTimeDivider} variant="body1" component="span">
          :
        </Typography>
        {minutes}
      </Typography>
    );
  };

  return isTotalVisible ? (
    <Typography sx={styles.total} variant="button">
      {totalText}: {renderTime()}
    </Typography>
  ) : (
    renderTime()
  );
};

export default TimeTrackingTotalTime;
