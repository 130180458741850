import { TextField, TextFieldProps, Typography, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { isEmpty, isNil } from 'ramda';

import clsx from 'src/utils/clsx';
import Box from 'src/components/Box';

import styles from './styles';

type TextFieldWithCounterProps = TextFieldProps & {
  value: string;
  rootSx?: SxProps<Theme>;
  maxValue?: number;
};

const TextFieldWithCounter: React.FC<TextFieldWithCounterProps> = props => {
  const { value, rootSx = {}, maxValue = 500, helperText, FormHelperTextProps, ...restProps } = props;

  const isHelperTextExist = !isNil(helperText) && !isEmpty(helperText);

  return (
    <Box sx={clsx(styles.root, [[rootSx, true]])}>
      <TextField
        value={value}
        helperText={helperText}
        FormHelperTextProps={{ ...FormHelperTextProps, sx: styles.formHelperText }}
        {...restProps}
      />
      <Typography sx={clsx(styles.counter, [[styles.absolute, isHelperTextExist]])} variant="caption">
        {`${value.length} / ${maxValue}`}
      </Typography>
    </Box>
  );
};

export default TextFieldWithCounter;
