import {
  AvailabilitiesSliceStateType,
  AvailabilitiesSliceActionsType,
  loadAvailabilities,
  showAvailability,
  createAvailability,
  updateAvailability,
  deleteAvailability,
} from 'src/store/talents/availabilitiesSlice';
import {
  ShowParams,
  CreateParams,
  UpdateParams,
  DeleteParams,
} from 'src/repositories/talents/AvailabilitiesRepository';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';

type UseAvailabilitiesType = Pick<AvailabilitiesSliceStateType, 'availabilities' | 'availability' | 'meta'> &
  AvailabilitiesSliceActionsType & {
    isLoadAvailabilityFinished: boolean;
    isLoadAvailabilitiesFinished: boolean;
  };

const useAvailabilities = (): UseAvailabilitiesType => {
  const dispatch = useAppDispatch();
  const {
    availabilities,
    availability,
    meta,
    index: { fetchStatus: availabilitiesIndexFetchStatus },
    show: { fetchStatus: availabilityShowFetchStatus },
  } = useAppSelector(state => state.talentsAvailabilities);

  const isLoadAvailabilityFinished = getFetchStatus(availabilityShowFetchStatus).isFinished;
  const isLoadAvailabilitiesFinished = getFetchStatus(availabilitiesIndexFetchStatus).isFinished;

  return {
    meta,
    availabilities,
    availability,
    isLoadAvailabilityFinished,
    isLoadAvailabilitiesFinished,
    loadAvailabilities: () => dispatch(loadAvailabilities()),
    showAvailability: (params: ShowParams) => dispatch(showAvailability(params)),
    createAvailability: (params: CreateParams) => dispatch(createAvailability(params)),
    updateAvailability: (params: UpdateParams) => dispatch(updateAvailability(params)),
    deleteAvailability: (params: DeleteParams) => dispatch(deleteAvailability(params)),
  };
};

export default useAvailabilities;
