import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const styles: SxStyles = {
  wrapper: {
    padding: { tablet: '33px 40px 40px' },
    width: { tablet: '760px' },
    minHeight: { tablet: '640px' },
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    boxShadow: 'inset 0px -1px 0px rgba(97, 148, 185, 0.25)',
    paddingBottom: '12px',
  },
  list: {
    marginTop: { mobile: '1.5em', tablet: '40px' },
  },
  addButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
    marginTop: '40px',
  },
  addButton: {
    padding: 0,
    marginBottom: '10px',
    border: 0,
    color: COLORS.black100,
    '&:hover': {
      background: 'none',
    },
    '& svg': {
      fill: COLORS.orange5,
    },
  },
  buttons: {
    marginTop: '40px',
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  caption: {
    color: COLORS.black40,
  },
  itemTitle: {
    marginBottom: '5px',
  },
};

export default styles;
