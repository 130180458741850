import {
  CertificatesSliceStateType,
  CertificatesSliceActionsType,
  loadCertificates,
  createCertificate,
  updateCertificate,
  deleteCertificate,
} from 'src/store/talents/certificatesSlice';
import { CreateParams, UpdateParams, DeleteParams } from 'src/repositories/talents/CertificatesRepository';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';

type UseCertificateType = Pick<CertificatesSliceStateType, 'certificates' | 'meta'> &
  CertificatesSliceActionsType & {
    isLoadCertificatesFinished: boolean;
  };

const useCertificates = (): UseCertificateType => {
  const dispatch = useAppDispatch();
  const {
    certificates,
    meta,
    index: { fetchStatus: loadFetchStatus },
  } = useAppSelector(state => state.talentsCertificates);

  const isLoadCertificatesFinished = getFetchStatus(loadFetchStatus).isFinished;

  return {
    meta,
    certificates,
    isLoadCertificatesFinished,
    loadCertificates: () => dispatch(loadCertificates()),
    createCertificate: (args: CreateParams) => dispatch(createCertificate(args)),
    updateCertificate: (args: UpdateParams) => dispatch(updateCertificate(args)),
    deleteCertificate: (args: DeleteParams) => dispatch(deleteCertificate(args)),
  };
};

export default useCertificates;
