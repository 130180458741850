import { useEffect, useState } from 'react';
import { OwnUserResponse, StreamChat, TokenOrProvider, UserResponse } from 'stream-chat';

import useMessenger from './useMessenger';
import useSnackbar from './useSnackbar';

export const useCreateChatClient = ({
  tokenOrProvider,
  userData,
}: {
  tokenOrProvider: TokenOrProvider;
  userData: OwnUserResponse | UserResponse;
}) => {
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const [chatStatus, setChatStatus] = useState<'connected' | 'disconnected' | 'error'>('disconnected');
  const { setUnreadMessagesCount, setIsMessengerInstanceReady } = useMessenger();
  const { enqueueErrorSnackbar } = useSnackbar();

  useEffect(() => {
    const client = new StreamChat(process.env.REACT_APP_GETSTREAM_TOKEN ?? '');
    let didUserConnectInterrupt = false;

    client.on(event => {
      if (event.total_unread_count !== undefined) {
        setUnreadMessagesCount(event.total_unread_count);
      }
    });

    const connectionPromise = client
      .connectUser(userData, tokenOrProvider)
      .then(response => {
        if (!didUserConnectInterrupt) {
          setChatClient(client);
          setChatStatus('connected');
          // @ts-ignore
          setUnreadMessagesCount(response.me.total_unread_count);
          setIsMessengerInstanceReady(true);
        }
      })
      .catch(error => {
        setChatStatus('error');
        setIsMessengerInstanceReady(false);
        enqueueErrorSnackbar(error?.message);
      });

    return () => {
      didUserConnectInterrupt = true;
      setChatClient(null);
      connectionPromise
        .then(() => {
          setIsMessengerInstanceReady(false);
          return client.disconnectUser();
        })
        .then(() => {
          setChatStatus('disconnected');
        });
    };
  }, [userData.id, tokenOrProvider]);

  return { chatClient, status: chatStatus };
};
