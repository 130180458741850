import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiCheckboxType = {
  defaultProps: ComponentsProps['MuiCheckbox'];
  styleOverrides: ComponentsOverrides['MuiCheckbox'];
};

const MuiCheckbox: MuiCheckboxType = {
  styleOverrides: {
    root: {
      color: COLORS.black40,
      padding: '0',
      marginRight: '9px',
      '& svg': {
        borderWidth: '2px',
      },
      '&.Mui-checked': {
        color: COLORS.orange5,
      },
      '&.Mui-disabled': {
        color: COLORS.black40,
      },
    },
  },
  defaultProps: {},
};

export default MuiCheckbox;
