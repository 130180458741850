import { Department } from 'src/types/resources/Department';
import { ListResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  page?: number;
  ordering?: string;
  organizationId?: number;
};

export default {
  index(params: IndexParams = { page: 1 }): Promise<ListResponse<Department>> {
    const url = apiRoutes.organizationAdmin.departmentsPath();
    return Fetcher.get(url, params);
  },
};
