import { useState, useEffect, ReactElement } from 'react';
import { Link, Typography, Theme, IconButton } from '@mui/material';
import { SxProps } from '@mui/system';
import { isNil } from 'ramda';

import { ReactComponent as ClipIcon } from 'src/assets/images/clipIcon.svg';
import { ReactComponent as CrossIcon } from 'src/assets/images/crossIcon.svg';
import { DocumentFile } from 'src/forms/talents/documents';
import clsx from 'src/utils/clsx';
import { getFilename } from 'src/utils/string';
import Box from 'src/components/Box';

import styles from './styles';

type AttachedFileLinkProps = {
  file: DocumentFile | null;
  sx?: SxProps<Theme>;
  onDelete?: () => void | null;
  deleteIcon?: ReactElement;
  hideFileName?: boolean;
};

const AttachedFileLink: React.FC<AttachedFileLinkProps> = props => {
  const { file, sx = {}, onDelete = null, deleteIcon = <CrossIcon />, hideFileName } = props;
  const { source, url, name } = file;
  const [objectUrl, setObjectUrl] = useState<string | null>(null);

  const linkText = source?.name || name || (url && getFilename(url));

  useEffect(() => {
    if (isNil(source)) {
      setObjectUrl(url);
    } else {
      const currentObjectURL = URL.createObjectURL(source);
      setObjectUrl(currentObjectURL);
    }

    return () => {
      URL.revokeObjectURL(objectUrl);
      setObjectUrl(null);
    };
  }, [source, url]);

  const boxStyles = clsx(styles.root, [
    [sx, true],
    [{ width: 'auto' }, hideFileName],
  ]);

  return (
    <Box sx={boxStyles}>
      <Link
        href={objectUrl}
        sx={styles.link}
        underline="hover"
        download={linkText}
        rel="noreferrer"
        target="_blank"
        title={linkText}
      >
        <ClipIcon className="icon" />
        {!hideFileName && (
          <Typography variant="body1" sx={styles.text} component="span">
            {linkText}
          </Typography>
        )}
      </Link>
      {onDelete && (
        <IconButton sx={styles.deleteButton} onClick={onDelete}>
          {deleteIcon}
        </IconButton>
      )}
    </Box>
  );
};

export default AttachedFileLink;
