import { createSlice } from '@reduxjs/toolkit';
import { isNil, omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { OrganizationUser } from 'src/types/resources/Organization';
import OrganizationUsersRepository, {
  IndexParams,
  PatchParams,
  UpdateParams,
  ReassignParams,
} from 'src/repositories/organizationAdmin/OrganizationUsersRepository';
import { CreateOrganizationUserFormData } from 'src/forms/createOrganizationUser';

export type DepartmentUserSlice = { meta: Meta; users: OrganizationUser[] };

export type OrganizationUsersSliceStateType = {
  meta: Meta;
  organizationUsers: OrganizationUser[];
  departmentsUsers: Record<number, DepartmentUserSlice>;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type OrganizationsSliceActionsType = {
  createOrganizationUser: (params: CreateOrganizationUserFormData) => { unwrap: () => void };
  reassignOrganizationUser: (params: ReassignParams) => { unwrap: () => void };
  updateOrganizationUser: (params: UpdateParams) => { unwrap: () => void };
  partialUpdateOrganizationUser: (params: PatchParams) => { unwrap: () => void };
  loadOrganizationUsers: (params?: IndexParams) => Promise<unknown>;
};

const initialState: OrganizationUsersSliceStateType = {
  meta: {} as Meta,
  organizationUsers: [],
  departmentsUsers: {},
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const createOrganizationUser = createAsyncAction(
  'organizations/users/create',
  OrganizationUsersRepository.create,
);
export const reassignOrganizationUser = createAsyncAction(
  'organizations/users/reassign',
  OrganizationUsersRepository.reassign,
);
export const updateOrganizationUser = createAsyncAction(
  'organizations/users/update',
  OrganizationUsersRepository.update,
);
export const partialUpdateOrganizationUser = createAsyncAction(
  'organizations/users/partialUpdate',
  OrganizationUsersRepository.partialUpdate,
);
export const loadOrganizationUsers = createAsyncAction('organizations/users/index', OrganizationUsersRepository.index);

const organizationsSlice = createSlice({
  name: 'organizations/users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createOrganizationUser.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createOrganizationUser.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createOrganizationUser.rejected, state => {
      state.create.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadOrganizationUsers.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadOrganizationUsers.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const isDepartmentsUsers = !isNil(meta.arg?.departmentId);

      state.index.fetchStatus = FetchStatus.fulfilled;
      if (isDepartmentsUsers) {
        state.departmentsUsers[meta.arg?.departmentId] = {
          meta: omit(['results'], payload),
          users: payload.results,
        };
      } else {
        state.meta = omit(['results'], payload);
        state.organizationUsers = payload.results;
      }
    });
    builder.addCase(loadOrganizationUsers.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
  },
});

export default organizationsSlice.reducer;
