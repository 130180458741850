import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  box: {
    display: 'flex',
    width: '512px',
    padding: '40px 24px 32px',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: COLORS.orange11,
    marginRight: 3,
    '.iconSuccess': {
      fill: COLORS.orange5,
    },
    '.iconError': {
      fill: COLORS.red100,
    },
  },
  contentBox: {
    paddingTop: 1,
  },
  title: {
    marginBottom: '8px',
  },
  description: {
    marginBottom: '31px',
    fontWeight: '600',
  },
  button: {
    padding: '8px 48px',
  },
};

export default styles;
