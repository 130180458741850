import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import { SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import TalentNotFoundFeedbackRepository, {
  CreateParams,
} from 'src/repositories/manager/TalentNotFoundFeedbackRepository';
import { Task } from 'src/types/resources/Task';

export type TalentNotFoundFeedbackSliceStateType = {
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type TalentNotFoundFeedbackSliceActionsType = {
  createTalentNotFoundFeedback: (params: CreateParams) => { unwrap: () => Promise<SingleResponse<Task>> };
};

const initialState: TalentNotFoundFeedbackSliceStateType = {
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const createTalentNotFoundFeedback = createAsyncAction(
  'manager/talentNotFoundFeedback/create',
  TalentNotFoundFeedbackRepository.create,
);

const TalentNotFoundFeedbackSlice = createSlice({
  name: 'manager/talentNotFoundFeedback',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createTalentNotFoundFeedback.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createTalentNotFoundFeedback.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createTalentNotFoundFeedback.rejected, state => {
      state.create.fetchStatus = FetchStatus.failed;
    });
  },
});

export default TalentNotFoundFeedbackSlice.reducer;
