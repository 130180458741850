import * as yup from 'yup';

import { ValidationError } from 'src/enums/Validation';

export type ResetPasswordFormData = {
  email: string;
};

export const validationFields = {
  email: yup.string().email(ValidationError.email).default('').required(ValidationError.default),
};

export const validationSchema = yup.object(validationFields);

export const initialValues: ResetPasswordFormData = validationSchema.getDefault();

export const attributesToSubmit = (values: ResetPasswordFormData): ResetPasswordFormData => {
  return values;
};
