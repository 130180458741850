import { Button } from '@mui/material';

import clsx from 'src/utils/clsx';

import styles from './styles';

type ToolButtonProps = {
  label: string;
  onClick: () => void;
  Icon: JSX.Element;
  active?: boolean;
};

const ToolButton: React.FC<ToolButtonProps> = props => {
  const { onClick, Icon, label, active = false } = props;

  const buttonStyle = clsx(styles.button, [
    [styles.activeButton, active],
    [styles.withCounter, true],
  ]);

  return (
    <Button sx={buttonStyle} onClick={onClick} variant="text">
      {Icon || label}
    </Button>
  );
};

export default ToolButton;
