import apiRoutes from 'src/routes/apiRoutes';
import { AdjustedTimeSheet, NestedTimeSheet } from 'src/types/resources/TimeSheet';
import { TimeTrackingFilters } from 'src/types/resources/TimeTracking';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  ordering?: string;
  page?: number;
} & Partial<TimeTrackingFilters>;

export type PartialUpdateParams = {
  id: number;
  params: Partial<Pick<NestedTimeSheet, 'state' | 'clientComment'>>;
};

export default {
  index(params: IndexParams): Promise<ListResponse<AdjustedTimeSheet>> {
    const url = apiRoutes.organizationAdmin.timeSheetsAdjustmentsPath();
    return Fetcher.get(url, params);
  },
  partialUpdate({ id, params }: PartialUpdateParams): Promise<SingleResponse<AdjustedTimeSheet>> {
    const url = apiRoutes.organizationAdmin.timeSheetsAdjustmentPath(id);
    return Fetcher.patch(url, params);
  },
};
