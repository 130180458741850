import { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, FormControlLabel, FormHelperText, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';

import {
  attributesToSubmit,
  ManageSpecializationsFormData,
  initialValues,
  validationSchema,
} from 'src/forms/superAdmin/manageSpecializations';
import { Department } from 'src/types/resources/Department';
import TalentSpecialization from 'src/types/resources/TalentSpecialization';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useDepartments from 'src/hooks/superAdmin/useDepartments';
import useSpecializations from 'src/hooks/useSpecializations';
import useModals from 'src/hooks/useModals';
import useSnackbar from 'src/hooks/useSnackbar';
import Loader from 'src/components/Loader';
import Box from 'src/components/Box';

import styles from './styles';

type ManageSpecializationsFormProps = {
  organizationId: number;
  department?: Department;
};

const ManageSpecializationsForm: React.FC<ManageSpecializationsFormProps> = props => {
  const { organizationId, department } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { loadDepartments, updateDepartment } = useDepartments();
  const { specializations, isSpecializationsLoadFinished, loadSpecializations } = useSpecializations();
  const { hideModal } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();

  const handleSubmit = async (
    formData: ManageSpecializationsFormData,
    { setErrors }: FormikHelpers<ManageSpecializationsFormData>,
  ) => {
    setFormError(null);
    const params = attributesToSubmit(formData);
    try {
      await updateDepartment({ id: department.id, params }).unwrap();
      loadDepartments({ organizationId });
      enqueueSuccessSnackbar('Taxonomies was updated');
      hideModal();
    } catch (error) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(error.response.data.errors.nonFieldErrors);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleSubmit: handleFormikSubmit,
  } = useFormik<ManageSpecializationsFormData>({
    initialValues: initialValues(department, specializations),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
    enableReinitialize: true,
  });

  const handleCheckboxChange =
    (specialization: TalentSpecialization) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue('specializations', {
        ...values.specializations,
        [specialization.id]: { name: specialization.name, value: event.target.checked },
      });
    };

  const renderSpecializationCheckboxes = () => {
    return (
      <>
        {specializations.map(specialization => {
          const { id, name } = specialization;
          const currentCheckboxValue = values.specializations[id]?.value || false;
          const isDisabled = Boolean(
            department.specializations?.find(currentSpecialization => currentSpecialization.id === id),
          );

          return (
            <FormControlLabel
              key={id}
              control={
                <Checkbox name={name} onChange={handleCheckboxChange(specialization)} checked={currentCheckboxValue} />
              }
              label={name}
              sx={styles.controlLabel}
              disabled={isDisabled}
            />
          );
        })}
        {touched.specializations && Boolean(errors.specializations) && (
          <FormHelperText error>{errors.specializations}</FormHelperText>
        )}
      </>
    );
  };

  useEffect(() => {
    loadSpecializations({ isRoot: true });
  }, []);

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <Box sx={styles.modalWrapper}>
        <Typography variant="h3" sx={styles.title}>
          Manage Taxonomies
        </Typography>
        <Typography variant="body1" sx={styles.description}>
          You are managing a Taxonomies to {department.name} department.
        </Typography>
        <form noValidate onSubmit={handleFormikSubmit}>
          <Box sx={styles.checkboxList}>
            {isSpecializationsLoadFinished ? renderSpecializationCheckboxes() : <Loader />}
          </Box>
          <Box sx={styles.buttons}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
              Apply
            </Button>
            <Button variant="outlined" onClick={hideModal} sx={styles.button}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ManageSpecializationsForm;
