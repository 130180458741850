import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  modalWrapper: {
    padding: '25px',
    width: '512px',
    boxSizing: 'border-box',
  },
  row: {
    margin: '16px 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  title: {
    marginBottom: '8px',
  },
  subtitle: {
    color: COLORS.black60,
  },
  departmentId: {
    width: '124px',
  },
  description: {
    minHeight: '60px',
    '& textarea': {
      height: 'initial',
    },
  },
  buttons: {
    marginTop: '40px',
  },
  button: {
    minWidth: '130px',
    padding: '8px 32px',
    marginRight: '10px',
  },
};

export default styles;
