import { isNil } from 'ramda';

import Availability from 'src/types/resources/Availability';
import { EmploymentType } from 'src/enums/Availability';
import { joinTruthy } from 'src/utils/string';
import { getShortMonthAndDate, getLongMonthAndDate } from 'src/utils/date';
import { formatCurrency } from 'src/utils/formatCurrency';

const AvailabilityPresenter = {
  availableDate: (availability: Availability): string => {
    const { availableFromDate, availableToDate } = availability;

    return isNil(availableToDate)
      ? `From ${getLongMonthAndDate(availableFromDate)}`
      : `${getShortMonthAndDate(availableFromDate)} — ${getShortMonthAndDate(availableToDate)}`;
  },
  employmentTypes: (availability: Availability): string => {
    return Object.keys(EmploymentType)
      .filter(employmentType => availability[employmentType])
      .map(employmentType => EmploymentType[employmentType])
      .join(' / ');
  },
  description: (availability: Availability, showHourlyWage: boolean): string => {
    const { hoursPerWeek, hourlyWage } = availability;

    const availableEmploymentTypes = AvailabilityPresenter.employmentTypes(availability);
    const availabilityDetails = [availableEmploymentTypes, `${hoursPerWeek} H/W`, `$${hourlyWage}/hour`];
    if (!showHourlyWage) {
      availabilityDetails.pop();
    }

    return joinTruthy(availabilityDetails, ' • ');
  },
  organizationDescription: (availability: Availability): string => {
    const { hoursPerWeek } = availability;
    const availableEmploymentTypes = AvailabilityPresenter.employmentTypes(availability);

    return joinTruthy([availableEmploymentTypes, `${hoursPerWeek} H/W`], ' • ');
  },
  rates: (availability: Availability, billableRateIsHidden?: boolean): string => {
    const { hourlyWage, billableRate } = availability;

    return `$${hourlyWage} Talent Rate ${!billableRateIsHidden ? `• $${billableRate} Bill Rate` : ''}`;
  },
  hourlyWage: (availability: Availability): string => {
    return `${formatCurrency(Number(availability.hourlyWage))}/hour`;
  },
  hourlyBillRate: (availability: Availability): string => {
    return `${formatCurrency(Number(availability.billableRate))}/hour`;
  },
  hoursPerWeek: (availability: Availability): string => {
    return `${availability.hoursPerWeek} H/W`;
  },
};

export default AvailabilityPresenter;
