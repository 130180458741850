import { Group } from 'src/types/resources/Group';
import { Subgroup } from 'src/types/resources/Subgroup';

type SelectItem = {
  value: string | number;
  label: string;
};

export const createGroupsForSelect = (groups: Group[]): SelectItem[] =>
  groups?.map(group => ({
    value: group.id,
    label: group.name,
  }));

export const createSubgroupsForSelect = (subgroups: Subgroup[]): SelectItem[] => {
  return (
    subgroups?.map(subgroup => ({
      value: subgroup.id,
      label: subgroup.name,
    })) || []
  );
};
