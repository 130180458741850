import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { isEmpty, isNil } from 'ramda';
import { useFormik } from 'formik';

import { ExpertiseType } from 'src/enums/Specialization';
import Specialization, { ShortSpecialization } from 'src/types/resources/Specialization';
import SpecializationsRepository from 'src/repositories/SpecializationsRepository';
import SpecializationPresenter from 'src/presenters/SpecializationPresenter';
import { createRootSpecialization } from 'src/forms/talents/specialization';
import { SearchResourcesSpecialization } from 'src/forms/SearchResources';
import { SearchSpecializationFormData, initialValues } from 'src/forms/searchSpecialization';
import useLoading from 'src/hooks/useLoading';
import Loader from 'src/components/Loader';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Box from 'src/components/Box';

import StepForm from './components/StepForm';
import FinalForm from './components/FinalForm';
import styles from './styles';

type SpecializationSelectFormProps = {
  onSubmitSelection: (formData: SearchResourcesSpecialization) => void;
  onShowResources: (formData: SearchResourcesSpecialization) => void;
  onChangeSpecialization?: (formData: SearchResourcesSpecialization) => void;
  formError?: string | null;
  disableSearchFunctionality?: boolean;
};

const createParentSpecialization = (id: number | null): Specialization | null => {
  if (isNil(id)) return null;

  return {
    id,
    name: '',
    numchild: 0,
    roles: [],
    skills: [],
    ancestors: [],
    children: [],
  };
};

const SpecializationSelectForm: React.FC<SpecializationSelectFormProps> = props => {
  const {
    onSubmitSelection,
    onShowResources,
    onChangeSpecialization: onChangeSpecializationFromProps,
    formError,
    disableSearchFunctionality,
  } = props;
  const [isFinalStep, setIsFinalStep] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [currentSpecialization, setCurrentSpecialization] = useState<Specialization | null>(null);
  const { funcWithLoading: loadSpecializationsWithLoading, isPending: isLoadSpecializationsPending } = useLoading(
    SpecializationsRepository.index,
  );
  const { funcWithLoading: showSpecializationWithLoading, isPending: isShowSpecializationPending } = useLoading(
    SpecializationsRepository.show,
  );

  const onChangeSpecialization = (formData: SearchResourcesSpecialization) => {
    if (onChangeSpecializationFromProps) {
      onChangeSpecializationFromProps(formData);
    }
  };

  const submitReturnValue = (
    formData: SearchSpecializationFormData,
    submitSpecialization: Specialization,
    parentSpecialization?: Specialization,
    selectedSpecialization = currentSpecialization,
  ) => {
    const { roles, skills, expertises } = formData;
    const { roles: rolesList, skills: skillsList } = submitSpecialization;

    const currentRoles = rolesList.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item,
      };
    }, {});

    const currentSkills = skillsList.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item,
      };
    }, {});

    const selectedRoles = roles.map(roleId => ({
      id: roleId,
      name: currentRoles[roleId]?.name,
    }));

    const selectedSkills = skills.map(skillId => ({
      id: skillId,
      name: currentSkills[skillId]?.name,
    }));

    return {
      specialization: parentSpecialization ? null : selectedSpecialization,
      roles: selectedRoles,
      skills: selectedSkills,
      expertises,
      parentSpecialization,
    };
  };

  const handleSubmit = (formData: SearchSpecializationFormData) => {
    const value = submitReturnValue(formData, currentSpecialization);
    onSubmitSelection(value);
  };

  const {
    values,
    initialValues: formikInitialValues,
    isSubmitting,
    handleSubmit: handleFormikSubmit,
    setFieldValue,
  } = useFormik<SearchSpecializationFormData>({
    initialValues: initialValues(),
    onSubmit: handleSubmit,
  });

  const loadSpecializations = async () => {
    const response = await loadSpecializationsWithLoading();
    setCurrentSpecialization(createRootSpecialization(response.results));
  };

  const showSpecialization = async (id: number) => {
    const response = await showSpecializationWithLoading(id);
    setCurrentSpecialization(response.data);
    if (isEmpty(response.data.children)) {
      setIsFinalStep(true);
    }
    return response.data;
  };

  const handleChangeSelectedId = (id: number) => {
    setSelectedId(id);
  };

  const handleClickBreadcrumb = (id: number) => () => {
    const submitValue = submitReturnValue(formikInitialValues, currentSpecialization, { ...currentSpecialization, id });
    onChangeSpecialization(submitValue);

    setFieldValue('roles', []);
    setFieldValue('skills', []);
    setCurrentSpecialization(null);
    setSelectedId(id);
    setIsFinalStep(false);
  };

  const handleShowResources = async (id: number) => {
    const selectedSpecialization = await showSpecialization(id);
    const value = submitReturnValue(values, currentSpecialization, selectedSpecialization);
    onShowResources(value);
  };

  const handleSetSpecialization = async (field: string, value: unknown, shouldValidate?: boolean): Promise<unknown> => {
    const submitValue = submitReturnValue({ ...values, [field]: value }, currentSpecialization);
    onChangeSpecialization(submitValue);
    return setFieldValue(field, value, shouldValidate);
  };

  const handleSetSpecializationLevel = async (field: string, value: unknown, shouldValidate?: boolean) => {
    const parentSpecialization = isNil(value) ? currentSpecialization : createParentSpecialization(value as number);
    const submitValue = submitReturnValue(values, currentSpecialization, parentSpecialization);

    await setFieldValue(field, isNil(value) ? currentSpecialization?.id : value, shouldValidate);
    onChangeSpecialization(submitValue);
  };

  const handleExpertiseLevelSet = (
    expertises: ExpertiseType[],
    parentSpecializationId?: number,
    selectedSpecialization?: Specialization,
  ) => {
    const submitValue = submitReturnValue(
      {
        ...values,
        expertises,
      },
      currentSpecialization,
      isNil(parentSpecializationId) ? null : createParentSpecialization(parentSpecializationId),
      selectedSpecialization,
    );
    setFieldValue('expertises', expertises, true);
    onChangeSpecialization(submitValue);
  };

  useEffect(() => {
    if (selectedId) {
      showSpecialization(selectedId);
    } else {
      loadSpecializations();
    }
  }, [selectedId]);

  if (isNil(currentSpecialization)) {
    return (
      <Box sx={styles.wrapper}>
        <Loader />
      </Box>
    );
  }

  const ancestorElements = SpecializationPresenter.fullAncestorElements(currentSpecialization);

  const renderBreadcrumbs = () => {
    const crumbs = ancestorElements.map((ancestor: ShortSpecialization) => ({
      id: ancestor.id,
      label: ancestor.name,
      path: '#',
    }));

    return (
      <Breadcrumbs
        crumbs={crumbs}
        renderItem={crumb => (
          <Button sx={styles.breadcrumbsButton} onClick={handleClickBreadcrumb(crumb.id)}>
            {crumb.label}
          </Button>
        )}
      />
    );
  };

  return (
    <form onSubmit={handleFormikSubmit}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.breadcrumbs}>{currentSpecialization && renderBreadcrumbs()}</Box>
        {isFinalStep ? (
          <FinalForm
            commonSpecialization={currentSpecialization}
            setFieldValue={handleSetSpecialization}
            formError={formError}
            isSubmitting={isSubmitting}
            values={values}
          />
        ) : (
          <StepForm
            commonSpecialization={currentSpecialization}
            onChangeId={handleChangeSelectedId}
            onShowResources={handleShowResources}
            setFieldValue={handleSetSpecializationLevel}
            onExpertisesSet={handleExpertiseLevelSet}
            values={values}
            isLoading={isLoadSpecializationsPending || isShowSpecializationPending}
            disableSearchFunctionality={disableSearchFunctionality}
          />
        )}
      </Box>
    </form>
  );
};

export default SpecializationSelectForm;
