import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { emptyApi } from 'src/services/emptyApi';

import opportunitiesSlice from '../features/opportunities/opportunitiesSlice';

import usersSlice from './usersSlice';
import modalsSlice from './modalsSlice';
import settingsSlice from './settingsSlice';
import groupsSlice from './groupsSlice';
import specializationsSlice from './specializationsSlice';
import subgroupsSlice from './subgroupsSlice';
import messengerSlice from './messengerSlice';
import knowledgeCenterFAQSlice from './knowledgeCenterFAQSlice';
import knowledgeCenterVideosSlice from './knowledgeCenterVideosSlice';
import talentsSlice from './talentsSlice';
import talentWorkExperiencesSlice from './talents/workExperiencesSlice';
import talentExternalLinksSlice from './talents/externalLinksSlice';
import talentAvailabilitiesSlice from './talents/availabilitiesSlice';
import talentCertificatesSlice from './talents/certificatesSlice';
import talentDocumentsSlice from './talents/documentsSlice';
import talentSpecializationsSlice from './talents/specializationsSlice';
import talentEngagementSlice from './talents/engagementSlice';
import talentTimeSheetsSlice from './talents/timeSheetsSlice';
import talentTimeSheetsAdjustmentsSlice from './talents/timeSheetsAdjustmentsSlice';
import talentOrganizationsSlice from './talents/organizationsSlice';
import managerTimeSheetsSlice from './manager/timeSheetsSlice';
import managerTimeSheetsAdjustmentsSlice from './manager/timeSheetsAdjustmentsSlice';
import managerEngagementsSlice from './manager/engagementsSlice';
import managerRequiredDocumentsSlice from './manager/requiredDocumentsSlice';
import managerSavedSearchesSlice from './manager/savedSearchesSlice';
import managerTalentNotFoundFeedbackSlice from './manager/talentNotFoundFeedbackSlice';
import managerPurchaseOrdersSlice from './manager/purchaseOrdersSlice';
import { engagementPOsReducer as managerEngagementPOsSlice } from './manager/engagementPOsSlice';
import managerCommentsTalentProfileSlice from './manager/commentsSlice';
import organizationAdminOrganizationUsersSlice from './organizationAdmin/organizationUsersSlice';
import organizationAdminOrganizationSlice from './organizationAdmin/organizationSlice';
import organizationAdminTalentsSlice from './organizationAdmin/talentsSlice';
import organizationAdminTimeSheetsSlice from './organizationAdmin/timeSheetsSlice';
import organizationAdminTimeSheetsAdjustmentsSlice from './organizationAdmin/timeSheetsAdjustmentsSlice';
import organizationAdminGroupMembersSlice from './organizationAdmin/groupMembersSlice';
import organizationAdminSavedSearchesSlice from './organizationAdmin/savedSearchesSlice';
import superAdminOrganizationsSlice from './superAdmin/organizationsSlice';
import superAdminOrganizationsUsersSlice from './superAdmin/organizationsUsersSlice';
import superAdminTalentsSlice from './superAdmin/talentsSlice';
import superAdminDepartmentsSlice from './superAdmin/departmentsSlice';
import superAdminRequiredDocumentsSlice from './superAdmin/requiredDocumentsSlice';
import superAdminTimeTrackingSlice from './superAdmin/timeTrackingSlice';
import superAdminAccountingExportSlice from './superAdmin/accountingExportSlice';
import superAdminTaskSlice from './superAdmin/taskSlice';

export const store = configureStore({
  reducer: {
    users: usersSlice,
    modals: modalsSlice,
    settings: settingsSlice,

    talents: talentsSlice,
    talentWorkExperiences: talentWorkExperiencesSlice,
    talentsExternalLinks: talentExternalLinksSlice,
    talentsAvailabilities: talentAvailabilitiesSlice,
    talentsCertificates: talentCertificatesSlice,
    talentsDocuments: talentDocumentsSlice,
    talentsSpecializations: talentSpecializationsSlice,
    talentsEngagements: talentEngagementSlice,
    talentsTimeSheets: talentTimeSheetsSlice,
    talentsTimeSheetsAdjustments: talentTimeSheetsAdjustmentsSlice,
    talentsOrganizations: talentOrganizationsSlice,

    managerEngagements: managerEngagementsSlice,
    managerTimeSheets: managerTimeSheetsSlice,
    managerTimeSheetsAdjustments: managerTimeSheetsAdjustmentsSlice,
    managerSavedSearches: managerSavedSearchesSlice,
    managerRequiredDocuments: managerRequiredDocumentsSlice,
    managerTalentNotFoundFeedback: managerTalentNotFoundFeedbackSlice,
    managerPurchaseOrders: managerPurchaseOrdersSlice,
    managerEngagementPOs: managerEngagementPOsSlice,
    managerCommentsTalentProfile: managerCommentsTalentProfileSlice,

    opportunities: opportunitiesSlice,

    organizationAdminOrganization: organizationAdminOrganizationSlice,
    organizationAdminTalents: organizationAdminTalentsSlice,
    organizationAdminOrganizationUsers: organizationAdminOrganizationUsersSlice,
    organizationAdminTimeSheets: organizationAdminTimeSheetsSlice,
    organizationAdminTimeSheetsAdjustments: organizationAdminTimeSheetsAdjustmentsSlice,
    organizationAdminGroupMembers: organizationAdminGroupMembersSlice,
    organizationAdminSavedSearches: organizationAdminSavedSearchesSlice,

    superAdminOrganizations: superAdminOrganizationsSlice,
    superAdminOrganizationsUsers: superAdminOrganizationsUsersSlice,
    superAdminTalents: superAdminTalentsSlice,
    superAdminTimeTracking: superAdminTimeTrackingSlice,
    superAdminDepartments: superAdminDepartmentsSlice,
    superAdminRequiredDocuments: superAdminRequiredDocumentsSlice,
    superAdminAccountingExport: superAdminAccountingExportSlice,
    superAdminTask: superAdminTaskSlice,

    knowledgeCenterFAQ: knowledgeCenterFAQSlice,
    knowledgeCenterVideos: knowledgeCenterVideosSlice,
    specializations: specializationsSlice,
    groups: groupsSlice,
    subgroups: subgroupsSlice,
    messenger: messengerSlice,

    [emptyApi.reducerPath]: emptyApi.reducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(emptyApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
