import { UserRole } from 'src/enums/UserRole';

const UserRolePresenter = {
  description: (role: UserRole): string => {
    const labels = {
      [UserRole.Manager]: 'Manager',
      [UserRole.OrganizationAdmin]: 'Admin',
      [UserRole.SuperAdmin]: 'Super Admin',
      [UserRole.Talent]: 'Talent',
      [UserRole.Contact]: 'Contact',
    };

    return labels[role];
  },
  isAdminRole: (role: UserRole): boolean => {
    return role === UserRole.OrganizationAdmin;
  },
  isManagerRole: (role: UserRole): role is UserRole.Manager => {
    return role === UserRole.Manager;
  },
  isTalentRole: (role: UserRole): boolean => {
    return role === UserRole.Talent;
  },
  isContactRole: (role: UserRole): role is UserRole.Contact => {
    return role === UserRole.Contact;
  },
  isOrganizationRole: (role: UserRole): boolean => {
    return role === UserRole.OrganizationAdmin || role === UserRole.Manager;
  },
  isSuperAdminRole: (role: UserRole): boolean => {
    return role === UserRole.SuperAdmin;
  },
};

export default UserRolePresenter;
