import Engagement, { CreateEngagementParams, EngagementsListItemOrgSide } from 'src/types/resources/Engagement';
import { User } from 'src/types/resources/User';
import { Department } from 'src/types/resources/Department';
import { ListResponse, SingleResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  page?: number;
  search?: string;
  status?: string;
  ordering?: string;
  payPeriodAfter?: string;
  payPeriodBefore?: string;
};

export type ShowParams = {
  id: ID;
};

export type CreateParams = CreateEngagementParams;

export type UpdateParams = {
  id: ID;
  params: Partial<CreateEngagementParams>;
};

export type DeleteParams = {
  id: number;
};

export type ValidateNameParams = {
  name: Engagement['name'];
  excludeId?: Engagement['id'];
};

export default {
  index(
    params: IndexParams = { page: 1, search: '', ordering: '' },
  ): Promise<ListResponse<EngagementsListItemOrgSide>> {
    const url = apiRoutes.manager.engagementsPath();
    return Fetcher.get(url, params);
  },
  show({ id }: ShowParams): Promise<SingleResponse<Engagement>> {
    const url = apiRoutes.manager.engagementPath(id);
    return Fetcher.get(url);
  },
  create(params: CreateParams): Promise<SingleResponse<Engagement>> {
    const url = apiRoutes.manager.engagementsPath();
    return Fetcher.post(url, params);
  },
  update({ id, params }: UpdateParams): Promise<SingleResponse<Engagement>> {
    const url = apiRoutes.manager.engagementPath(id);
    return Fetcher.patch(url, params);
  },
  delete({ id }: DeleteParams): Promise<void> {
    const url = apiRoutes.manager.engagementPath(id);
    return Fetcher.delete(url);
  },
  loadEngagementTalents(params: IndexParams): Promise<ListResponse<User>> {
    const url = apiRoutes.manager.engagementTalentsPath();
    return Fetcher.get(url, params);
  },
  loadEngagementOwners(params: IndexParams): Promise<ListResponse<User>> {
    const url = apiRoutes.manager.engagementOwnersPath();
    return Fetcher.get(url, params);
  },
  loadEngagementDepartments(params: IndexParams): Promise<ListResponse<Department>> {
    const url = apiRoutes.manager.engagementDepartmentsPath();
    return Fetcher.get(url, params);
  },
  validateEngagementName(params: ValidateNameParams): Promise<SingleResponse<string>> {
    const url = apiRoutes.manager.engagementNameValidation();
    return Fetcher.post(url, params);
  },
};
