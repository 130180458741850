import { createSlice } from '@reduxjs/toolkit';
import { isNil, omit } from 'ramda';

import { CreateOrganizationFormDataToSubmit } from 'src/forms/createOrganization';
import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { SingleResponse, ListResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { Organization, OrganizationUserCount, OrganizationsCount } from 'src/types/resources/Organization';
import OrganizationsRepository, {
  IndexParams,
  LoadUsersCountParams,
  ShowParams,
  UpdateParams,
} from 'src/repositories/superAdmin/OrganizationsRepository';

export type OrganizationsSliceStateType = {
  meta: Meta;
  organizations: Organization[];
  organization: Organization;
  organizationUsersCount: OrganizationUserCount;
  departmentUsersCount: Record<number, OrganizationUserCount>;
  organizationsCount: null | OrganizationsCount;
  loadOrganizationsCount: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  loadUsersCount: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type OrganizationsSliceActionsType = {
  loadOrganizations: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<Organization>> };
  loadOrganizationsCounts: (params: Pick<IndexParams, 'accountType' | 'isActive' | 'isHiddenOnSuperadminSide'>) => {
    unwrap: () => Promise<SingleResponse<OrganizationsCount>>;
  };
  loadOrganizationUsersCount: (params: LoadUsersCountParams) => {
    unwrap: () => Promise<SingleResponse<OrganizationUserCount>>;
  };
  showOrganization: (params: ShowParams) => { unwrap: () => Promise<SingleResponse<Organization>> };
  createOrganization: (params: CreateOrganizationFormDataToSubmit) => {
    unwrap: () => Promise<SingleResponse<Organization>>;
  };
  updateOrganization: (params: UpdateParams) => { unwrap: () => Promise<SingleResponse<Organization>> };
};

const initialState: OrganizationsSliceStateType = {
  meta: {} as Meta,
  organizations: [],
  organization: null,
  organizationUsersCount: {} as OrganizationUserCount,
  departmentUsersCount: {},
  organizationsCount: null,
  loadOrganizationsCount: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  loadUsersCount: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadOrganizations = createAsyncAction('superAdmin/organizations/index', OrganizationsRepository.index);
export const loadOrganizationsCounts = createAsyncAction(
  'superAdmin/organizationsCounts/index',
  OrganizationsRepository.counts,
);
export const showOrganization = createAsyncAction('superAdmin/organizations/show', OrganizationsRepository.show);
export const createOrganization = createAsyncAction('superAdmin/organizations/create', OrganizationsRepository.create);
export const updateOrganization = createAsyncAction('superAdmin/organizations/update', OrganizationsRepository.update);
export const loadOrganizationUsersCount = createAsyncAction(
  'organizations/loadUsersCount',
  OrganizationsRepository.loadUsersCount,
);

const organizationsSlice = createSlice({
  name: 'superAdmin/organizations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadOrganizations.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadOrganizations.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.organizations = payload.results;
    });
    builder.addCase(loadOrganizations.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(showOrganization.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showOrganization.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.organization = payload.data;
    });
    builder.addCase(showOrganization.rejected, state => {
      state.show.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadOrganizationUsersCount.pending, state => {
      state.loadUsersCount.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadOrganizationUsersCount.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const departmentId = meta.arg?.params?.departmentId;
      const isDepartmentsUsersCount = !isNil(departmentId);

      state.loadUsersCount.fetchStatus = FetchStatus.fulfilled;
      if (isDepartmentsUsersCount) {
        state.departmentUsersCount[departmentId] = payload.data;
      } else {
        state.organizationUsersCount = payload.data;
      }
    });
    builder.addCase(loadOrganizationUsersCount.rejected, state => {
      state.loadUsersCount.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadOrganizationsCounts.pending, state => {
      state.loadOrganizationsCount.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadOrganizationsCounts.rejected, (state, action) => {
      const requestError = (action.payload as { response?: { data?: unknown } })?.response?.data;
      if (requestError) {
        state.loadOrganizationsCount.error = requestError;
      }
      state.loadOrganizationsCount.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(loadOrganizationsCounts.fulfilled, (state, action) => {
      state.loadOrganizationsCount.fetchStatus = FetchStatus.fulfilled;
      state.organizationsCount = action.payload.data;
    });
  },
});

export default organizationsSlice.reducer;
