const BASE_URL = 'https://www.revuud.com';

const externalRoutes = {
  revuud: {
    root: (): string => `${BASE_URL}/`,
    termsAndConditions: (): string => `${BASE_URL}/terms-conditions`,
    forHealthcareSystems: (): string => `${BASE_URL}/healthcare-systems`,
    forTalent: (): string => `${BASE_URL}/talent`,
    whyRevuud: (): string => `${BASE_URL}/why-revuud`,
    resources: (): string => `${BASE_URL}/resources`,
  },
};

export default externalRoutes;
