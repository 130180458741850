import { OrganizationUser } from 'src/types/resources/Organization';
import apiRoutes from 'src/routes/apiRoutes';
import { ListResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  organizationId?: number;
  page?: number;
  ordering?: string;
  firstName?: string;
};

export default {
  index(params: IndexParams): Promise<ListResponse<OrganizationUser>> {
    const url = apiRoutes.manager.organizationUsersPath();
    return Fetcher.get(url, params);
  },
};
