import * as yup from 'yup';

import { ValidationError } from 'src/enums/Validation';
import { Organization } from 'src/types/resources/Organization';

const DEFAULT_RATE_VALUE = 20;
export const DEFAULT_TIER_ONE_RATE_VALUE = 26;

export type ChangeRateFormData = Pick<Organization, 'rate' | 'tierRate'>;
export type ChangeRateFormDataToSubmit = Pick<Organization, 'name' | 'rate'>;

export const buildValidationSchema = (feature: boolean) =>
  yup.object({
    rate: yup.number().min(0).required(ValidationError.default),
    tierRate: yup.number().when('rate', {
      is: (rate: number) => !feature || rate,
      then: schema => schema.notRequired(),
      otherwise: schema => schema.min(0).required(ValidationError.default),
    }),
  });

export const initialValues = (organization: Organization, featureEnabled?: boolean): ChangeRateFormData => {
  return {
    rate: organization.rate || organization.rate === 0 ? organization.rate : DEFAULT_RATE_VALUE,
    ...(featureEnabled && {
      tierRate: organization.tierRate === 0 ? 0 : organization.tierRate || DEFAULT_TIER_ONE_RATE_VALUE,
    }),
  };
};

export const attributesToSubmit = (values: ChangeRateFormData, name: string): ChangeRateFormDataToSubmit => {
  const data = {
    rate: values.rate || (!values.tierRate ? 0 : values.rate),
    tierRate: values.rate ? 0 : values.tierRate,
  };

  return { name, ...data };
};
