import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState, convertToRaw } from 'draft-js';

export const convertFromHtmlFormat = (html: string): EditorState => {
  if (!html) {
    return EditorState.createEmpty();
  }

  return EditorState.createWithContent(convertFromHTML(html));
};

export const convertToHtmlFormat = (editorState: EditorState): string => {
  return convertToHTML(editorState.getCurrentContent());
};

export const isEmptyTextEditor = (editorState: EditorState): boolean => {
  return !editorState.getCurrentContent().hasText();
};

export const getRawTextEditorValue = (editorState: EditorState): string => {
  const { blocks } = convertToRaw(editorState.getCurrentContent());
  return blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
};

export const stringIsEmptyTextEditorState = (value: string): boolean => {
  if (!value) {
    return true;
  }

  return isEmptyTextEditor(convertFromHtmlFormat(value));
};
