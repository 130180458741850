import * as yup from 'yup';
import { isNil } from 'ramda';

import { ValidationError } from 'src/enums/Validation';
import { Group } from 'src/types/resources/Group';

export type GroupFormData = {
  name: string;
};

export const validationFields = {
  name: yup.string().required(ValidationError.default).default(''),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (group: Group): GroupFormData => {
  if (isNil(group)) {
    return validationSchema.getDefault();
  }

  return {
    name: group.name,
  };
};

export const attributesToSubmit = (values: GroupFormData): GroupFormData => {
  return values;
};
