import { Tooltip } from '@mui/material';

import { ReactComponent as RevuudApprovedIcon } from 'src/assets/images/revuudApprovedIcon.svg';
import Box from 'src/components/Box';

import styles from './styles';

const RevuudApproved: React.FC = () => {
  return (
    <Tooltip title="Revuud Approved" variant="light" placement="top" arrow>
      <Box sx={styles.root}>
        <RevuudApprovedIcon />
      </Box>
    </Tooltip>
  );
};

export default RevuudApproved;
