import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    width: '512px',
    padding: '24px',
  },
  title: {
    marginBottom: '24px',
  },
  text: {
    margin: '24px 0',
  },
  button: {
    padding: '7px 48px',
    marginRight: '10px',
  },
};

export default styles;
