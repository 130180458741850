import {
  DepartmentUserSlice,
  OrganizationsUsersSliceStateType,
  OrganizationsSliceActionsType,
  createOrganizationUser,
  reassignOrganizationUser,
  loadOrganizationUsers,
  updateOrganizationUser,
  partialUpdateOrganizationUser,
} from 'src/store/superAdmin/organizationsUsersSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { CreateOrganizationUserFormData } from 'src/forms/createOrganizationUser';
import {
  IndexParams,
  ReassignParams,
  PatchParams,
  UpdateParams,
} from 'src/repositories/superAdmin/OrganizationsUsersRepository';

type UseOrganizationsUsersType = Pick<
  OrganizationsUsersSliceStateType,
  'meta' | 'organizationUsers' | 'departmentsUsers'
> &
  OrganizationsSliceActionsType & {
    isOrganizationsUsersLoadFinished: boolean;
    getUsersByDepartmentId: (id: number) => DepartmentUserSlice;
  };

const useOrganizationsUsers = (): UseOrganizationsUsersType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    organizationUsers,
    departmentsUsers,
    index: { fetchStatus: organizationsIndexFetchStatus },
  } = useAppSelector(state => state.superAdminOrganizationsUsers);

  const isOrganizationsUsersLoadFinished = getFetchStatus(organizationsIndexFetchStatus).isFinished;
  const getUsersByDepartmentId = (id: number) => departmentsUsers[id] || ({} as DepartmentUserSlice);

  return {
    meta,
    organizationUsers,
    departmentsUsers,
    isOrganizationsUsersLoadFinished,
    getUsersByDepartmentId,
    createOrganizationUser: (params: CreateOrganizationUserFormData) => dispatch(createOrganizationUser(params)),
    reassignOrganizationUser: (params: ReassignParams) => dispatch(reassignOrganizationUser(params)),
    updateOrganizationUser: (params: UpdateParams) => dispatch(updateOrganizationUser(params)),
    partialUpdateOrganizationUser: (params: PatchParams) => dispatch(partialUpdateOrganizationUser(params)),
    loadOrganizationUsers: (params?: IndexParams) => dispatch(loadOrganizationUsers(params)),
  };
};

export default useOrganizationsUsers;
