import { CommentPermissionId } from 'src/types/resources/Comment';

const CommentPresenter = {
  calculatePermissionId: (isShared: boolean, isRevuudCommentForm: boolean) => {
    if (!isRevuudCommentForm) {
      return CommentPermissionId.orgUserPublic;
    }

    return isShared ? CommentPermissionId.superAdminPublic : CommentPermissionId.superAdminPrivate;
  },
};

export default CommentPresenter;
