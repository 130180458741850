import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiBreadcrumbsType = {
  defaultProps: ComponentsProps['MuiBreadcrumbs'];
  styleOverrides: ComponentsOverrides['MuiBreadcrumbs'];
};

const MuiBreadcrumbs: MuiBreadcrumbsType = {
  styleOverrides: {
    root: {
      marginBottom: '2px',
      fontWeight: 600,
      color: COLORS.black40,
    },
    separator: {
      '& svg': {
        width: '6px',
        height: '10px',
        fill: COLORS.orange5,
      },
    },
  },
  defaultProps: {},
};

export default MuiBreadcrumbs;
