import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  avatar: {
    borderRadius: '4px',
    marginRight: '12px',
  },
  content: {
    overflow: 'hidden',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  link: {
    overflow: 'hidden',
  },
};

export default styles;
