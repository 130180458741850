import { ContentState, convertToRaw } from 'draft-js';

export function areContentStatesEqual(contentState1: ContentState, contentState2: ContentState) {
  const rawContentState1 = convertToRaw(contentState1);
  const rawContentState2 = convertToRaw(contentState2);

  if (rawContentState1.blocks.length !== rawContentState2.blocks.length) {
    return false;
  }

  for (let i = 0; i < rawContentState1.blocks.length; i += 1) {
    const block1 = rawContentState1.blocks[i];
    const block2 = rawContentState2.blocks[i];

    if (block1.text !== block2.text) {
      return false;
    }
    if (block1.type !== block2.type) {
      return false;
    }

    if (block1.inlineStyleRanges.length !== block2.inlineStyleRanges.length) {
      return false;
    }

    for (let j = 0; j < block1.inlineStyleRanges.length; j += 1) {
      const styleRange1 = block1.inlineStyleRanges[j];
      const styleRange2 = block2.inlineStyleRanges[j];

      if (
        styleRange1.offset !== styleRange2.offset ||
        styleRange1.length !== styleRange2.length ||
        styleRange1.style !== styleRange2.style
      ) {
        return false;
      }
    }
  }

  return true;
}
