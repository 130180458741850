import { AxiosResponse } from 'axios';

import Documents from 'src/types/resources/Documents';
import TalentProfile from 'src/types/resources/TalentProfile';
import apiRoutes from 'src/routes/apiRoutes';
import { TalentSearch } from 'src/types/resources/TalentSearch';
import { ListResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';
import { SearchResourcesFormDataToSubmit } from 'src/forms/SearchResources';

export type IndexParams = {
  search?: string;
  ordering?: string;
};

export type PatchParams = {
  id: number;
  params: {
    isDeactivated: boolean;
  };
};

export default {
  index(params: Partial<SearchResourcesFormDataToSubmit & { search: string }>): Promise<ListResponse<TalentSearch>> {
    const url = apiRoutes.manager.talentsPath();
    return Fetcher.get(url, params);
  },
  show(id: number): Promise<AxiosResponse<TalentProfile>> {
    const url = apiRoutes.manager.talentPath(id);
    return Fetcher.get(url);
  },
  showDocumentSet(id: number): Promise<{ data: Documents }> {
    const url = apiRoutes.manager.talentDocumentSetPath(id);
    return Fetcher.get(url);
  },
  partialUpdate({ id, params }: PatchParams): Promise<AxiosResponse<TalentProfile>> {
    const url = apiRoutes.manager.talentPath(id);
    return Fetcher.patch(url, params);
  },
  loadMatchedCount(params: Partial<SearchResourcesFormDataToSubmit>): Promise<{ data: { count: number } }> {
    const url = apiRoutes.manager.talentMatchedCountPath();
    return Fetcher.get(url, params);
  },
};
