import { head, isNil, pick } from 'ramda';
import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Alert as MuiAlert, Button, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { TimeSheetState as TimeSheetStateEnum } from 'src/enums/TimeSheet';
import { attributesToSubmit, initialValues, TimeSheetsFormData, validationSchema } from 'src/forms/talents/timeSheet';
import EngagementTimeSheetPresenter from 'src/presenters/EngagementTimeSheetPresenter';
import NestedTimeSheetPresenter from 'src/presenters/NestedTimeSheetPresenter';
import { IndexParams } from 'src/repositories/talents/TimeSheetsRepository';
import EngagementTimeSheet from 'src/types/resources/EngagementTimeSheet';
import { NestedTimeSheet } from 'src/types/resources/TimeSheet';
import clsx from 'src/utils/clsx';
import { getTimeSheetLongDate, MAX_HOURS_PER_DAY } from 'src/utils/date';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useModals from 'src/hooks/useModals';
import useTimeSheets from 'src/hooks/talents/useTimeSheets';
import Box from 'src/components/Box';
import PurchaseOrderSelect from 'src/components/TimeTracking/components/PurchaseOrderSelect';
import TimeFields from 'src/components/TimeTracking/components/TimeFields';
import Alert from 'src/components/TimeTracking/components/Alert';

import styles from './styles';

type CreateTimeSheetEntryFormProps = {
  engagementTimeSheet?: EngagementTimeSheet;
  timeSheet?: NestedTimeSheet;
  timekeepingDate: string;
  searchParams: IndexParams;
};

const CreateTimeSheetEntryForm: React.FC<CreateTimeSheetEntryFormProps> = props => {
  const { engagementTimeSheet, timeSheet, timekeepingDate, searchParams } = props;

  const [formError, setFormError] = useState<string | null>(null);
  const [focusedTimeField, setFocusedTimeField] = useState<'hours' | 'minutes' | null>(null);

  const { hideModal } = useModals();
  const {
    loadEngagementTimeSheets,
    loadTotalEngagementTimeSheet,
    createEngagementTimeSheet,
    updateEngagementTimeSheet,
  } = useTimeSheets();

  const isAddFormState = isNil(timeSheet);
  const isTimeSheetPOExist = !isNil(timeSheet?.purchaseOrder);

  const isTimeSheetOpen = NestedTimeSheetPresenter.isOpen(timeSheet);
  const isTimeSheetDeclined = NestedTimeSheetPresenter.isDeclined(timeSheet);

  const isEmptyPO = EngagementTimeSheetPresenter.isEmptyPO(engagementTimeSheet);
  const isSinglePO = EngagementTimeSheetPresenter.isSinglePO(engagementTimeSheet);
  const isMultiplePO = EngagementTimeSheetPresenter.isMultiplePO(engagementTimeSheet);

  const isSelectPOReadOnly = isEmptyPO || (isSinglePO && (isTimeSheetOpen || isTimeSheetPOExist));

  const handleSubmit = async (formData: TimeSheetsFormData, { setErrors }: FormikHelpers<TimeSheetsFormData>) => {
    const params = attributesToSubmit(engagementTimeSheet.id, timekeepingDate, formData);
    setFormError(null);
    try {
      if (isAddFormState) {
        await createEngagementTimeSheet(params).unwrap();
      } else {
        await updateEngagementTimeSheet({
          id: timeSheet.id,
          params: { ...params, state: isTimeSheetDeclined ? TimeSheetStateEnum.pending : undefined },
        }).unwrap();
      }
      loadTotalEngagementTimeSheet(pick(['payPeriodAfter', 'payPeriodBefore'], searchParams)).unwrap();
      await loadEngagementTimeSheets(searchParams).unwrap();
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setErrors(parseToFormikErrors(errors));
        setFormError(head(error.response.data.errors?.nonFieldErrors || []));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit: handleFormikSubmit,
    setFieldValue,
  } = useFormik<TimeSheetsFormData>({
    initialValues: initialValues(engagementTimeSheet, timeSheet),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const purchaseOrderSelectValues = engagementTimeSheet.po?.map(engagementPO => ({
    value: engagementPO?.id,
    label: NestedTimeSheetPresenter.poLabel(engagementPO),
  }));

  const handleHoursFieldChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    const parsedValue = parseInt(value, 10);
    if (parsedValue > MAX_HOURS_PER_DAY) return;
    await setFieldValue('hours', Number.isNaN(parsedValue) ? 0 : parsedValue);
    if (!values.minutes) {
      await setFieldValue('minutes', 0);
    }
  };

  const handleMinutesChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    await setFieldValue('minutes', value);
    if (!values.hours) {
      await setFieldValue('hours', 0);
    }
  };

  const handleTimeFieldFocus = (fieldName: 'hours' | 'minutes') => () => {
    setFocusedTimeField(fieldName);
  };

  const handleTimeFieldBlur = async () => {
    setFocusedTimeField(null);
  };

  const handlePOChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newPurchaseOrderId = event.target.value;
    await setFieldValue('purchaseOrderId', newPurchaseOrderId);
  };

  return (
    <>
      {formError && <MuiAlert severity="error">{formError}</MuiAlert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.wrapper}>
          <Typography variant="h3" sx={styles.title}>
            New Entry: {getTimeSheetLongDate(timekeepingDate)}
          </Typography>
          <Box sx={styles.row}>
            <Alert iconVariant="error">
              <Typography sx={styles.alertText}>
                Pay Period is closed. Your request will be reviewed by a Manager.
              </Typography>
            </Alert>
          </Box>
          <Stack sx={clsx(styles.row, [[styles.rowWithColumn, true]])} direction="row">
            <Box sx={styles.column}>
              <Stack sx={styles.formFieldContainer} direction="row" alignItems="center">
                <Typography variant="button" component="span">
                  PO:
                </Typography>
                <Box sx={styles.poContainer}>
                  <PurchaseOrderSelect
                    onChange={handlePOChange}
                    value={values.purchaseOrderId || ''}
                    values={purchaseOrderSelectValues}
                    error={touched.purchaseOrderId && Boolean(errors.purchaseOrderId)}
                    placeholder={isEmptyPO ? 'PO not assigned' : 'Choose PO'}
                    isReadOnly={isSelectPOReadOnly}
                    isDisabled={!isMultiplePO && isTimeSheetOpen}
                  />
                </Box>
              </Stack>
              {touched.purchaseOrderId && Boolean(errors.purchaseOrderId) && (
                <FormHelperText error>{errors.purchaseOrderId}</FormHelperText>
              )}
            </Box>
            <Box sx={styles.column}>
              <Stack sx={styles.formFieldContainer} direction="row" alignItems="center">
                <Typography variant="button" component="span">
                  Worked Hours:
                </Typography>
                <TimeFields
                  HoursFieldProps={{
                    value: values.hours ?? '',
                    error: touched.hours && Boolean(errors.hours),
                    onChange: handleHoursFieldChange,
                    onBlur: handleTimeFieldBlur,
                    onFocus: handleTimeFieldFocus('hours'),
                  }}
                  MinutesFieldProps={{
                    value: values.minutes ?? '',
                    error: touched.hours && Boolean(errors.hours),
                    onChange: handleMinutesChange,
                    onFocus: handleTimeFieldFocus('minutes'),
                  }}
                  focusedTimeField={focusedTimeField}
                />
              </Stack>
              {touched.hours && Boolean(errors.hours) && <FormHelperText error>{errors.hours}</FormHelperText>}
            </Box>
          </Stack>
          <Box sx={styles.row}>
            <TextField
              id="talentComment"
              name="talentComment"
              label="Description"
              type="textarea"
              multiline
              rows={2}
              sx={styles.textarea}
              value={values.talentComment}
              onChange={handleChange}
              error={touched.talentComment && Boolean(errors.talentComment)}
              helperText={touched.talentComment && errors.talentComment}
            />
          </Box>
          <Box sx={styles.footer}>
            <LoadingButton type="submit" variant="contained" sx={styles.button} loading={isSubmitting}>
              Save
            </LoadingButton>
            <Button variant="outlined" onClick={hideModal} sx={styles.button}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default CreateTimeSheetEntryForm;
