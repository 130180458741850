import { ModalLayout } from 'src/enums/ModalType';
import { makeStyles } from 'src/utils/makeStyles';

const styles = makeStyles({
  [ModalLayout.default]: {},
  [ModalLayout.talentModals]: {
    '& .MuiDialog-paper': {
      margin: { tablet: '85px 24px 75px' },
      maxWidth: 'none',
    },
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      display: 'flex',
    },
  },
});

export default styles;
