import { createSlice, createAction } from '@reduxjs/toolkit';

import { ConfirmPasswordFormDataToSubmit } from 'src/forms/confirmPassword';
import { ResetPasswordFormData } from 'src/forms/resetPassword';
import SessionRepository from 'src/repositories/SessionRepository';
import { FetchStatus } from 'src/enums/FetchStatus';
import UsersRepository, { ConfirmParams, PartialUpdateParams } from 'src/repositories/UsersRepository';
import { User } from 'src/types/resources/User';
import { SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { clearPendoInstance } from 'src/utils/pendo';
import UsersPresenter from 'src/presenters/UsersPresenter';

export type UsersSliceStateType = {
  currentUser: User | null;
  isLoggedOutOfTrackedViaPendoUserBefore: boolean;
  showForceTalentHourlyRateModal: boolean;
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  confirm: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  confirmPassword: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  validatePassword: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  partialUpdate: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

const initialState: UsersSliceStateType = {
  currentUser: null,
  isLoggedOutOfTrackedViaPendoUserBefore: false,
  showForceTalentHourlyRateModal: false,
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  confirm: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  confirmPassword: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  validatePassword: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  partialUpdate: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadCurrentUser = createAsyncAction('users/current', UsersRepository.show);
export const confirmUserEmail = createAsyncAction('users/confirm', UsersRepository.confirm);
export const destroyCurrentUser = createAsyncAction('users/destroy', SessionRepository.delete);
export const confirmPassword = createAsyncAction('users/confirmPassword', UsersRepository.confirmPassword);
export const resetPassword = createAsyncAction('users/resetPassword', UsersRepository.resetPassword);
export const validatePassword = createAsyncAction('users/validatePassword', UsersRepository.validatePassword);
export const partialUpdateUser = createAsyncAction('users/partialUpdate', UsersRepository.partialUpdate);

export const forceTalentToInputHourlyRate = createAction<boolean>('users/forceTalentToInputHourlyRate');

export type UsersSliceActionsType = {
  loadCurrentUser: () => { unwrap: () => Promise<SingleResponse<User>> };
  confirmUserEmail: (params: ConfirmParams) => { unwrap: () => void };
  destroyCurrentUser: () => { unwrap: () => void };
  confirmPassword: (params: ConfirmPasswordFormDataToSubmit) => { unwrap: () => Promise<SingleResponse<void>> };
  resetPassword: (params: ResetPasswordFormData) => { unwrap: () => void };
  validatePassword: (params: ConfirmParams) => { unwrap: () => void };
  partialUpdateUser: (params: PartialUpdateParams) => { unwrap: () => Promise<SingleResponse<User>> };
  forceTalentToInputHourlyRate: (param: boolean) => ReturnType<typeof forceTalentToInputHourlyRate>;
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadCurrentUser.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadCurrentUser.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.currentUser = payload.data;
    });
    builder.addCase(loadCurrentUser.rejected, state => {
      state.show.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(partialUpdateUser.pending, state => {
      state.partialUpdate.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(partialUpdateUser.fulfilled, (state, { payload }) => {
      state.partialUpdate.fetchStatus = FetchStatus.fulfilled;
      state.currentUser = payload.data;
    });
    builder.addCase(partialUpdateUser.rejected, state => {
      state.partialUpdate.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(confirmUserEmail.pending, state => {
      state.confirm.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(confirmUserEmail.fulfilled, state => {
      state.confirm.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(confirmUserEmail.rejected, state => {
      state.confirm.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(confirmPassword.pending, state => {
      state.confirm.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(confirmPassword.fulfilled, state => {
      state.confirm.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(confirmPassword.rejected, state => {
      state.confirm.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(validatePassword.pending, state => {
      state.validatePassword.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(validatePassword.fulfilled, state => {
      state.validatePassword.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(validatePassword.rejected, state => {
      state.validatePassword.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(destroyCurrentUser.fulfilled, state => {
      const wasTrackedViaPendoUser = UsersPresenter.isTrackedViaPendo(state.currentUser);
      if (wasTrackedViaPendoUser) {
        clearPendoInstance();
      }
      state.currentUser = null;
      state.isLoggedOutOfTrackedViaPendoUserBefore = wasTrackedViaPendoUser;
    });

    builder.addCase(forceTalentToInputHourlyRate, (state, action) => {
      state.showForceTalentHourlyRateModal = action.payload;
    });
  },
});

export default usersSlice.reducer;
