export default {
  values: {
    // default material-ui breakpoints
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    // custom breakpoints
    mobile: 0,
    fablet: 375,
    tablet: 768,
    chat: 960,
    desktop: 1024,
    largeScreen: 1440,
  },
};
