import * as yup from 'yup';

import { MAX_PASSWORD_LENGTH, ValidationError } from 'src/enums/Validation';
import { STRONG_PASSWORD } from 'src/forms/customRules';

export type ConfirmPasswordFormData = {
  password: string;
  confirmPassword: string;
};

export type ConfirmPasswordFormDataToSubmit = {
  password: string;
  uuid: string;
  token: string;
};

export const validationFields = {
  password: yup
    .string()
    .required(ValidationError.default)
    .test(STRONG_PASSWORD)
    .max(MAX_PASSWORD_LENGTH, ValidationError.passwordMaxLength)
    .default(''),
  confirmPassword: yup
    .string()
    .required(ValidationError.default)
    .oneOf([yup.ref('password'), null], ValidationError.confirmPassword)
    .default(''),
};

export const validationSchema = yup.object(validationFields);

export const initialValues: ConfirmPasswordFormData = validationSchema.getDefault();

export const attributesToSubmit = (
  values: ConfirmPasswordFormData,
  uuid: string,
  token: string,
): ConfirmPasswordFormDataToSubmit => {
  const { password } = values;

  return { password, uuid, token };
};
