import {
  WorkExperiencesSliceStateType,
  WorkExperiencesSliceActionsType,
  loadWorkExperiences,
  createWorkExperience,
  updateWorkExperience,
  deleteWorkExperience,
} from 'src/store/talents/workExperiencesSlice';
import { CreateParams, UpdateParams, DeleteParams } from 'src/repositories/talents/WorkExperienceRepository';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';

type UseWorkExperiencesType = Pick<WorkExperiencesSliceStateType, 'workExperiences'> &
  WorkExperiencesSliceActionsType & {
    isWorkExperiencesLoadFinished: boolean;
  };

const useWorkExperiences = (): UseWorkExperiencesType => {
  const dispatch = useAppDispatch();
  const {
    workExperiences,
    index: { fetchStatus: loadFetchStatus },
  } = useAppSelector(state => state.talentWorkExperiences);

  const isWorkExperiencesLoadFinished = getFetchStatus(loadFetchStatus).isFinished;

  return {
    workExperiences,
    isWorkExperiencesLoadFinished,
    loadWorkExperiences: () => dispatch(loadWorkExperiences()),
    createWorkExperience: (args: CreateParams) => dispatch(createWorkExperience(args)),
    updateWorkExperience: (args: UpdateParams) => dispatch(updateWorkExperience(args)),
    deleteWorkExperience: (args: DeleteParams) => dispatch(deleteWorkExperience(args)),
  };
};

export default useWorkExperiences;
