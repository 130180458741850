import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import UsersPresenter from 'src/presenters/UsersPresenter';
import appRoutes from 'src/routes/appRoutes';
import lazyWithRetry from 'src/utils/lazyWithRetry';
import useUsers from 'src/hooks/useUsers';
import Modal from 'src/components/Modal';

const PublicRouter = lazyWithRetry(() => import('./components/PublicRouter'));
const TalentRouter = lazyWithRetry(() => import('./components/TalentsRouter'));
const SuperAdminRouter = lazyWithRetry(() => import('./components/SuperAdminRouter'));
const ManagerRouter = lazyWithRetry(() => import('./components/ManagerRouter'));
const OrganizationAdminRouter = lazyWithRetry(() => import('./components/OrganizationAdminRouter'));

const MainRouter: React.FC = () => {
  const { currentUser } = useUsers();

  const options = [
    {
      key: 'superAdmin',
      access: currentUser && UsersPresenter.hasSuperAdminAccess(currentUser),
      path: appRoutes.rootPath(),
      router: <SuperAdminRouter />,
    },
    {
      key: 'talent',
      access: currentUser && UsersPresenter.hasTalentAccess(currentUser),
      path: appRoutes.rootPath(),
      router: <TalentRouter />,
    },
    {
      key: 'organizationAdmin',
      access: currentUser && UsersPresenter.hasOrganizationAdminAccess(currentUser),
      path: appRoutes.rootPath(),
      router: <OrganizationAdminRouter />,
    },
    {
      key: 'manager',
      access: currentUser && UsersPresenter.hasManagerAccess(currentUser),
      path: appRoutes.rootPath(),
      router: <ManagerRouter />,
    },
    {
      key: 'public',
      access: true,
      path: appRoutes.rootPath(),
      router: <PublicRouter />,
    },
  ];

  return (
    <Router>
      <Switch>
        {options.map(option => {
          if (!option.access) return null;
          return (
            <Route key={option.key} path={option.path}>
              {option.router}
            </Route>
          );
        })}
      </Switch>
      <Modal />
    </Router>
  );
};

export default MainRouter;
