import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  total: {
    textTransform: 'uppercase',
    color: COLORS.black80,
  },
  totalTime: {
    marginLeft: '8px',
    color: COLORS.black100,
  },
  totalTimeDivider: {
    padding: '0 1px',
    color: COLORS.black40,
  },
  totalTimePositive: {
    color: COLORS.green100,
  },
  totalTimeNegative: {
    color: COLORS.red100,
  },
};

export default styles;
