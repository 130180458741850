import React, { ChangeEvent, useState } from 'react';
import { FormikHelpers, getIn, useFormik } from 'formik';
import {
  Typography,
  Button,
  TextField,
  Alert,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Divider,
  FormHelperText,
} from '@mui/material';
import { isNil, head } from 'ramda';

import { LibraryDocumentType, LibraryDocumentTypeLabel } from 'src/enums/DocumentLibrary';
import { EngagementPlaceLabel } from 'src/enums/Engagement';
import {
  validationSchema,
  DocumentLibraryFormData,
  initialValues,
  attributesToSubmit,
} from 'src/forms/createLibraryDocument';
import useRequiredDocuments from 'src/hooks/manager/useRequiredDocuments';
import { ManagerRequiredDocument } from 'src/types/resources/RequiredDocument';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useModals from 'src/hooks/useModals';
import AttachedFileLink from 'src/components/AttachedFileLink';
import SelectFile from 'src/components/DocumentLibraryTable/components/SelectFile';
import Box from 'src/components/Box';

import styles from './styles';

type CheckboxType = {
  fieldName: string;
  value: boolean;
  label: string;
};

type FormProps = {
  document?: ManagerRequiredDocument;
};

const CreateLibraryDocumentForm: React.FC<FormProps> = props => {
  const { document } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { hideModal } = useModals();
  const { createRequiredDocument, updateRequiredDocument } = useRequiredDocuments();

  const isAddForm = isNil(document);
  const formTitle = isAddForm ? 'Add Document' : 'Edit Document';

  const handleSubmit = async (
    values: DocumentLibraryFormData,
    { setErrors }: FormikHelpers<DocumentLibraryFormData>,
  ) => {
    const params = attributesToSubmit(values);
    setFormError(null);
    try {
      if (isAddForm) {
        await createRequiredDocument(params).unwrap();
      } else {
        await updateRequiredDocument({ id: document.id, params }).unwrap();
      }
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(head(error.response.data.errors.nonFieldErrors));
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit: handleFormikSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
  } = useFormik<DocumentLibraryFormData>({
    initialValues: initialValues(document),
    validationSchema,
    onSubmit: handleSubmit,
  });

  const placeCheckboxValues: CheckboxType[] = [
    { fieldName: 'remote', value: values.places.remote, label: EngagementPlaceLabel.remote },
    {
      fieldName: 'onSite',
      value: values.places.onSite,
      label: EngagementPlaceLabel.onSite,
    },
  ];

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files[0] || null;

    if (selectedFile) {
      await setFieldTouched('file.source', true);
      await setFieldValue('file', {
        source: selectedFile,
        url: null,
      });
    }
  };

  const handleDeleteFile = async () => {
    await setFieldValue('file', {
      source: null,
      url: null,
    });
  };

  const handleCheckboxChange = (fieldValuePath: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFieldTouched('places', true);
    setFieldValue(fieldValuePath, checked);
  };

  const renderFileField = () => {
    const isFileFieldError = getIn(touched, 'file.source') && getIn(errors, 'file.source');
    const isFileExist = getIn(values, 'file.source') || getIn(values, 'file.url');
    const isVisibleSelectButton = isFileFieldError || !isFileExist;
    const isUploadDocumentType = values.answerType === LibraryDocumentType.upload;

    const descriptionText =
      isUploadDocumentType && 'Optionally, you can attach an example of the required file for a Talent to see.';

    return (
      isVisibleSelectButton && (
        <SelectFile id="createLibraryDocument" description={descriptionText} onChange={handleFileChange} />
      )
    );
  };

  const renderCheckbox = (checkbox: CheckboxType, fieldValuePath = '') => {
    return (
      <FormControlLabel
        key={checkbox.fieldName}
        label={checkbox.label}
        sx={styles.checkboxLabel}
        control={
          <Checkbox
            name={checkbox.fieldName}
            onChange={handleCheckboxChange(fieldValuePath)}
            checked={checkbox.value}
          />
        }
      />
    );
  };

  const renderPreviewFileLink = () => {
    const isFileFieldError = getIn(touched, 'file.source') && getIn(errors, 'file.source');
    const isFileExist = getIn(values, 'file.source') || getIn(values, 'file.url');

    if (isFileFieldError) {
      return (
        <Typography sx={styles.documentHelperErrorText} component="p" color="error">
          {errors.file.source}
        </Typography>
      );
    }

    return (
      isFileExist && (
        <AttachedFileLink sx={styles.documentAttachedLink} file={values.file} onDelete={handleDeleteFile} />
      )
    );
  };

  return (
    <form noValidate onSubmit={handleFormikSubmit}>
      <Box sx={styles.box}>
        <Typography variant="h3" align="center" sx={styles.heading}>
          {formTitle}
        </Typography>
        <Box sx={styles.row}>
          <TextField
            id="name"
            label="Document Name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Box>
        <Box sx={styles.row}>
          <FormLabel component="legend">Description</FormLabel>
          <TextField
            id="description"
            multiline
            sx={styles.description}
            value={values.description}
            onChange={handleChange}
            placeholder="Optional"
          />
        </Box>
        <Box sx={styles.rowGroup}>
          <FormLabel component="legend">Internal Comment</FormLabel>
          <TextField
            id="internalComment"
            multiline
            sx={styles.textarea}
            value={values.internalComment}
            onChange={handleChange}
            placeholder="Optional"
          />
          <Typography variant="caption" sx={styles.commentCaption}>
            Only your Organization can see this comment
          </Typography>
        </Box>
        <Box sx={styles.rowGroup}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend" sx={styles.groupLabel}>
              Work Location
            </FormLabel>
            <FormGroup row sx={styles.checkboxGroup}>
              {placeCheckboxValues.map(checkbox => renderCheckbox(checkbox, `places.${checkbox.fieldName}`))}
            </FormGroup>
          </FormControl>
          {touched.places && Boolean(errors.places) && <FormHelperText error>{errors.places}</FormHelperText>}
        </Box>
        <Box sx={styles.rowGroup}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend" sx={styles.groupLabel}>
              Document Type
            </FormLabel>
            <RadioGroup row name="answerType" value={values.answerType} onChange={handleChange} sx={styles.radioGroup}>
              {Object.entries(LibraryDocumentType).map(([key, value]) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={LibraryDocumentTypeLabel[key]}
                  sx={styles.radioLabel}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider sx={styles.rowGroup} />
        <Box sx={styles.row}>
          <Box sx={styles.fileContainer}>
            <Box sx={styles.fileFieldWrapper}>{renderFileField()}</Box>
          </Box>
          {renderPreviewFileLink()}
        </Box>
        {formError && <Alert severity="error">{formError}</Alert>}
        <Box sx={styles.footer}>
          <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
            {isAddForm ? 'Add' : 'Save'}
          </Button>
          <Button variant="outlined" sx={styles.button} onClick={hideModal}>
            Cancel
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CreateLibraryDocumentForm;
