import {
  RequiredDocumentsSliceStateType,
  RequiredDocumentsSliceActionsType,
  loadRequiredDocuments,
  createRequiredDocument,
  updateRequiredDocument,
  partialUpdateRequiredDocument,
  deactivateRequiredDocument,
  deleteRequiredDocument,
} from 'src/store/manager/requiredDocumentsSlice';
import {
  IndexParams,
  CreateParams,
  UpdateParams,
  DeleteParams,
} from 'src/repositories/manager/RequiredDocumentsRepository';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';

type UseRequiredDocumentsType = Pick<RequiredDocumentsSliceStateType, 'requiredDocuments' | 'meta'> &
  RequiredDocumentsSliceActionsType & {
    isLoadRequirementDocumentsFinished: boolean;
  };

const useRequiredDocuments = (): UseRequiredDocumentsType => {
  const dispatch = useAppDispatch();

  const {
    meta,
    requiredDocuments,
    index: { fetchStatus: requiredDocumentsIndexFetchStatus },
  } = useAppSelector(state => state.managerRequiredDocuments);

  const isLoadRequirementDocumentsFinished = getFetchStatus(requiredDocumentsIndexFetchStatus).isFinished;

  return {
    meta,
    requiredDocuments,
    isLoadRequirementDocumentsFinished,
    loadRequiredDocuments: (args?: IndexParams) => dispatch(loadRequiredDocuments(args)),
    createRequiredDocument: (args: CreateParams) => dispatch(createRequiredDocument(args)),
    updateRequiredDocument: (args: UpdateParams) => dispatch(updateRequiredDocument(args)),
    partialUpdateRequiredDocument: (args: UpdateParams) => dispatch(partialUpdateRequiredDocument(args)),
    deactivateRequiredDocument: (args: UpdateParams) => dispatch(deactivateRequiredDocument(args)),
    deleteRequiredDocument: (args: DeleteParams) => dispatch(deleteRequiredDocument(args)),
  };
};

export default useRequiredDocuments;
