import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    width: '560px',
    minHeight: '250px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    marginTop: '24px',
  },
  rowWithColumn: {
    gap: '8px',
  },
  column: {
    flex: '1 1 50%',
  },
  formFieldContainer: {
    gap: '8px',
  },
  poContainer: {
    width: '145px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '48px',
  },
  alertText: {
    color: COLORS.red100,
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
};

export default styles;
