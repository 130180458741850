import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  uploadFileCaption: {
    maxWidth: '300px',
    color: COLORS.black40,
  },
  fileFieldWrapper: {
    flexShrink: 0,
    marginRight: '20px',
  },
};

export default styles;
