import {
  GroupsSliceStateType,
  GroupsSliceActionsType,
  loadGroups,
  createGroup,
  updateGroup,
  deleteGroup,
} from 'src/store/groupsSlice';
import { GroupFormData } from 'src/forms/organizationGroup';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { IndexParams, DeleteParams, UpdateParams } from 'src/repositories/organizationAdmin/GroupsRepository';

type UseGroupsType = Pick<GroupsSliceStateType, 'groups' | 'meta'> &
  GroupsSliceActionsType & {
    isGroupsLoadFinished: boolean;
    isGroupsDeletePending: boolean;
  };

const useGroups = (): UseGroupsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    groups,
    index: { fetchStatus: groupsIndexFetchStatus },
    delete: { fetchStatus: groupsDeleteFetchStatus },
  } = useAppSelector(state => state.groups);

  const isGroupsLoadFinished = getFetchStatus(groupsIndexFetchStatus).isFinished;
  const isGroupsDeletePending = getFetchStatus(groupsDeleteFetchStatus).isPending;

  return {
    meta,
    groups,
    isGroupsLoadFinished,
    isGroupsDeletePending,
    loadGroups: (args?: IndexParams) => dispatch(loadGroups(args)),
    createGroup: (args: GroupFormData) => dispatch(createGroup(args)),
    updateGroup: (args: UpdateParams) => dispatch(updateGroup(args)),
    deleteGroup: (args: DeleteParams) => dispatch(deleteGroup(args)),
  };
};

export default useGroups;
