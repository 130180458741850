import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { ListResponse, SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { GroupMember } from 'src/types/resources/GroupMember';
import GroupMembersRepository, {
  IndexParams,
  ShowParams,
  CreateParams,
  DeleteParams,
} from 'src/repositories/manager/GroupMembersRepository';

export type GroupMembersSliceStateType = {
  meta: Meta;
  groupMembers: GroupMember[];
  groupMember: GroupMember;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type GroupMembersSliceActionsType = {
  loadGroupMembers: (params: IndexParams) => { unwrap: () => Promise<ListResponse<GroupMember>> };
  showGroupMember: (params: ShowParams) => { unwrap: () => Promise<SingleResponse<GroupMember>> };
  createGroupMember: (params: CreateParams) => { unwrap: () => Promise<SingleResponse<GroupMember>> };
  deleteGroupMember: (params: DeleteParams) => { unwrap: () => Promise<void> };
};

const initialState: GroupMembersSliceStateType = {
  meta: {} as Meta,
  groupMembers: [],
  groupMember: {} as GroupMember,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadGroupMembers = createAsyncAction('organizationAdminGroupMembers/index', GroupMembersRepository.index);
export const showGroupMember = createAsyncAction('organizationAdminGroupMembers/show', GroupMembersRepository.show);
export const createGroupMember = createAsyncAction(
  'organizationAdminGroupMembers/create',
  GroupMembersRepository.create,
);
export const deleteGroupMember = createAsyncAction(
  'organizationAdminGroupMembers/delete',
  GroupMembersRepository.delete,
);

const GroupMembersSlice = createSlice({
  name: 'organizationAdminGroupMembers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadGroupMembers.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadGroupMembers.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.groupMembers = payload.results;
    });
    builder.addCase(loadGroupMembers.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(showGroupMember.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showGroupMember.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.groupMember = payload.data;
    });
    builder.addCase(showGroupMember.rejected, state => {
      state.show.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(deleteGroupMember.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteGroupMember.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteGroupMember.rejected, state => {
      state.delete.fetchStatus = FetchStatus.failed;
    });
  },
});

export default GroupMembersSlice.reducer;
