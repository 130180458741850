import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const styles: SxStyles = {
  editableBox: {
    position: 'relative',
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover .editableBoxMain': {
      borderColor: COLORS.orange9,
    },
    '&:hover .editableBoxAside': {
      opacity: 1,
    },
    marginBottom: {
      mobile: '50px',
      tablet: 0,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  editableBoxMain: {
    flexGrow: 1,
    paddingX: 3,
    paddingY: 2,
    background: COLORS.orange11,
    borderRadius: '8px',
    border: '1px solid transparent',
    marginRight: {
      mobile: 0,
      tablet: '48px',
    },
  },
  editableBoxAside: {
    position: 'absolute',
    top: {
      mobile: '-32px',
      tablet: 0,
    },
    right: 0,
    display: 'flex',
    flexDirection: {
      mobile: 'row',
      tablet: 'column',
    },
    gap: {
      mobile: '8px',
      tablet: '6px',
    },
    opacity: {
      mobile: 1,
      tablet: 0,
    },
    transition: 'opacity 0.3s ease',
    '& .actionButton': {
      minWidth: 'auto',
      width: {
        mobile: '24px',
        tablet: '40px',
      },
      height: {
        mobile: '24px',
        tablet: '40px',
      },
      padding: {
        mobile: '4px',
        tablet: '8px',
      },
      '& > svg': {
        fill: 'currentColor',
      },
    },
  },
};

export default styles;
