import apiRoutes from 'src/routes/apiRoutes';
import { TotalEngagementTimeSheet } from 'src/types/resources/EngagementTimeSheet';
import { ManagerEngagementTimeSheetDates } from 'src/types/resources/ManagerEngagementTimeSheetDates';
import { NestedTimeSheet } from 'src/types/resources/TimeSheet';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  payPeriodAfter: string;
  payPeriodBefore: string;
  ordering?: string;
  talentIds?: string;
  page?: number;
};

export type PartialUpdateParams = {
  id: number;
  params: Partial<Pick<NestedTimeSheet, 'state' | 'clientComment'>>;
};

type MultipleUpdateParamsBase =
  | {
      approvalType: 'entire_day';
      engagementId: number;
      timekeepingDate: string;
    }
  | {
      approvalType: 'entire_engagement';
      engagementId: number;
      payPeriodBefore: string;
      payPeriodAfter: string;
    }
  | {
      approvalType: 'all_engagements';
      payPeriodBefore: string;
      payPeriodAfter: string;
    };

export type MultipleUpdateParams = MultipleUpdateParamsBase & Omit<IndexParams, 'payPeriodBefore' | 'payPeriodAfter'>;

export default {
  index(params: IndexParams): Promise<ListResponse<ManagerEngagementTimeSheetDates>> {
    const url = apiRoutes.manager.timeSheetsPath();
    return Fetcher.get(url, params);
  },
  total(params: IndexParams): Promise<SingleResponse<TotalEngagementTimeSheet>> {
    const url = apiRoutes.manager.timeSheetsTotalPath();
    return Fetcher.get(url, params);
  },
  adjustmentsTotal(params: Partial<IndexParams>): Promise<SingleResponse<TotalEngagementTimeSheet>> {
    const url = apiRoutes.manager.timeSheetsAdjustmentsTotalPath();
    return Fetcher.get(url, params);
  },
  partialUpdate({ id, params }: PartialUpdateParams): Promise<SingleResponse<ManagerEngagementTimeSheetDates>> {
    const url = apiRoutes.manager.timeSheetPath(id);
    return Fetcher.patch(url, params);
  },
  multipleUpdate(params: MultipleUpdateParams): Promise<ListResponse<void>> {
    const url = apiRoutes.manager.timeSheetsPath();
    return Fetcher.patch(url, params);
  },
  checkPendingTimeSheets(params: IndexParams): Promise<SingleResponse<{ exists: boolean }>> {
    const url = apiRoutes.manager.checkPendingTimeSheets();
    return Fetcher.get(url, params);
  },
};
