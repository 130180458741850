export enum LibraryDocumentType {
  signAndReturn = 'sign_and_return',
  consentForm = 'consent_form',
  upload = 'upload',
}

export enum LibraryDocumentTypeLabel {
  signAndReturn = 'Sign & Return',
  consentForm = 'Consent Form',
  readAndAccept = 'Read & Accept',
  upload = 'Upload',
}
