import {
  loadEngagementTimeSheets,
  loadTotalEngagementTimeSheet,
  createEngagementTimeSheet,
  updateEngagementTimeSheet,
  deleteEngagementTimeSheet,
  EngagementTimeSheetsSliceStateType,
  EngagementTimeSheetsSliceActionsType,
} from 'src/store/talents/timeSheetsSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { IndexParams, CreateParams, UpdateParams } from 'src/repositories/talents/TimeSheetsRepository';

type UseEngagementTimeSheetsType = Pick<
  EngagementTimeSheetsSliceStateType,
  'engagementTimeSheets' | 'totalEngagementTimeSheet' | 'isAdjustableTimesheets' | 'meta'
> &
  EngagementTimeSheetsSliceActionsType & {
    isEngagementTimeSheetsLoadFinished: boolean;
    isEngagementTimeSheetsCreatePending: boolean;
    isEngagementTimeSheetsDeletePending: boolean;
    isTotalEngagementTimeSheetLoadFinished: boolean;
  };

const useTimeSheets = (): UseEngagementTimeSheetsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    totalEngagementTimeSheet,
    engagementTimeSheets,
    isAdjustableTimesheets,
    index: { fetchStatus: timeSheetsIndexStatus },
    create: { fetchStatus: timeSheetsCreateStatus },
    delete: { fetchStatus: timeSheetsDeleteStatus },
    total: { fetchStatus: timeSheetsTotalStatus },
  } = useAppSelector(state => state.talentsTimeSheets);

  const isEngagementTimeSheetsLoadFinished = getFetchStatus(timeSheetsIndexStatus).isFinished;
  const isEngagementTimeSheetsCreatePending = getFetchStatus(timeSheetsCreateStatus).isPending;
  const isEngagementTimeSheetsDeletePending = getFetchStatus(timeSheetsDeleteStatus).isPending;
  const isTotalEngagementTimeSheetLoadFinished = getFetchStatus(timeSheetsTotalStatus).isFinished;

  return {
    meta,
    engagementTimeSheets,
    totalEngagementTimeSheet,
    isAdjustableTimesheets,
    isEngagementTimeSheetsLoadFinished,
    isEngagementTimeSheetsCreatePending,
    isEngagementTimeSheetsDeletePending,
    isTotalEngagementTimeSheetLoadFinished,
    loadEngagementTimeSheets: (params?: IndexParams) => dispatch(loadEngagementTimeSheets(params)),
    loadTotalEngagementTimeSheet: (params?: IndexParams) => dispatch(loadTotalEngagementTimeSheet(params)),
    createEngagementTimeSheet: (params: CreateParams) => dispatch(createEngagementTimeSheet(params)),
    updateEngagementTimeSheet: (params: UpdateParams) => dispatch(updateEngagementTimeSheet(params)),
    deleteEngagementTimeSheet: (id: number) => dispatch(deleteEngagementTimeSheet(id)),
  };
};

export default useTimeSheets;
