import { AxiosResponse } from 'axios';

import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import { ShareGroupFormDataToSubmit } from 'src/forms/shareGroup';

export default {
  create(params: ShareGroupFormDataToSubmit): Promise<AxiosResponse> {
    const url = apiRoutes.manager.shareGroupPath();
    return Fetcher.post(url, params);
  },
};
