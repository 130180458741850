import apiRoutes from 'src/routes/apiRoutes';
import { SettingsData, SettingsParams } from 'src/types/settings';
import Fetcher from 'src/utils/fetcher';

export default {
  index(params: SettingsParams): Promise<SettingsData> {
    const url = apiRoutes.settings();
    return Fetcher.get(url, params);
  },
};
