import { Button, Typography } from '@mui/material';

import { OrganizationTalent } from 'src/types/resources/Organization';
import useModals from 'src/hooks/useModals';
import useSnackbar from 'src/hooks/useSnackbar';
import useTalents from 'src/hooks/superAdmin/useTalents';
import OrganizationTalentPresenter from 'src/presenters/OrganizationTalentPresenter';
import Box from 'src/components/Box';

import styles from './styles';

type ToggleTalentActivationFormProps = {
  talent: OrganizationTalent;
};

const ToggleTalentActivationForm: React.FC<ToggleTalentActivationFormProps> = props => {
  const { talent } = props;
  const { id } = talent;
  const isDeactivated = OrganizationTalentPresenter.isDeactivated(talent);

  const { loadTalents, partialUpdateTalent } = useTalents();
  const { hideModal } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();

  const formText = isDeactivated
    ? 'You are about to reactivate this Talent. The Talent will get back access to their account.'
    : 'You are about to deactivate the Talent. The Talent will lose access to their account.';
  const buttonText = isDeactivated ? 'Reactivate' : 'Deactivate';
  const toasterMessage = isDeactivated ? 'The Talent was reactivated' : 'The Talent was deactivated';

  const handleSubmit = async () => {
    await partialUpdateTalent({ id, params: { isDeactivated: !isDeactivated } }).unwrap();
    loadTalents();
    hideModal();
    enqueueSuccessSnackbar(toasterMessage);
  };

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h2" sx={styles.title}>
        Are you sure?
      </Typography>
      <Typography variant="body1" sx={styles.text}>
        {formText}
      </Typography>
      <Box>
        <Button variant="contained" sx={styles.button} onClick={handleSubmit}>
          {buttonText}
        </Button>
        <Button variant="outlined" sx={styles.button} onClick={hideModal}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default ToggleTalentActivationForm;
