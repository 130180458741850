export enum TimeSheetState {
  open = 'open',
  pending = 'pending',
  submitted = 'submitted',
  approved = 'approved',
  declined = 'declined',
}

export enum TimeSheetSort {
  name = 'name',
  cost = 'cost',
  state = 'state',
  hours = 'hours',
  hourlyFee = 'hourly_fee',
  reviewedAt = 'reviewed_at',
  timekeepingDate = 'timekeeping_date',
  organizationName = 'organization__name',
  reviewedByFullName = 'reviewed_by__full_name',
  talentName = 'talent_name',
  engagementName = 'engagement__name',
  departmentName = 'department__name',
  reviewedByName = 'reviewed_by_name',
  managerName = 'manager_name',
  poNumber = 'po_number',
}

export enum TimeSheetSubmitVariant {
  duplicate = 'duplicate',
  delete = 'delete',
  default = 'default',
}
