import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import CertificatesRepository, {
  CreateParams,
  DeleteParams,
  UpdateParams,
} from 'src/repositories/talents/CertificatesRepository';
import { Meta } from 'src/types/meta';
import CertificateType, { CertificateResources } from 'src/types/resources/CertificateType';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { SingleResponse } from 'src/types/utils';

export type CertificatesSliceStateType = {
  certificates: CertificateType[];
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type CertificatesSliceActionsType = {
  loadCertificates: () => { unwrap: () => Promise<CertificateResources> };
  createCertificate: (params: CreateParams) => { unwrap: () => Promise<SingleResponse<CertificateType>> };
  updateCertificate: (params: UpdateParams) => { unwrap: () => Promise<SingleResponse<CertificateType>> };
  deleteCertificate: (params: DeleteParams) => { unwrap: () => Promise<void> };
};

const initialState: CertificatesSliceStateType = {
  certificates: [],
  meta: {} as Meta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadCertificates = createAsyncAction('talents/certificates/load', CertificatesRepository.index);
export const createCertificate = createAsyncAction('talents/certificates/create', CertificatesRepository.create);
export const updateCertificate = createAsyncAction('talents/certificates/update', CertificatesRepository.update);
export const deleteCertificate = createAsyncAction('talents/certificates/delete', CertificatesRepository.delete);

const CertificatesSlice = createSlice({
  name: 'talents/certificates',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadCertificates.pending, state => {
      state.index.error = null;
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadCertificates.fulfilled, (state, { payload }) => {
      const { count, next, previous, results } = payload;
      state.certificates = results;
      state.meta = { count, next, previous };
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadCertificates.rejected, (state, { error }) => {
      state.index.error = error;
      state.index.fetchStatus = FetchStatus.failed;
    });
  },
});

export default CertificatesSlice.reducer;
