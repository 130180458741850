import { createSlice } from '@reduxjs/toolkit';
import { eachDayOfInterval, parseISO } from 'date-fns';
import { isNil, omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import EngagementTimeSheetsRepository, {
  CreateParams,
  IndexParams,
  UpdateParams,
} from 'src/repositories/talents/TimeSheetsRepository';
import { Meta } from 'src/types/meta';
import { TotalEngagementTimeSheet } from 'src/types/resources/EngagementTimeSheet';
import { TimeSheetDates } from 'src/types/resources/TimeSheet';
import TalentEngagementTimeSheetDates from 'src/types/resources/TalentEngagementTimeSheetDates';
import { ListResponse, SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { createEmptyEngagementTimeSheetDates, getEngagementTimeSheetsPeriod } from 'src/utils/engagementTimeSheets';

export type EngagementTimeSheetsSliceStateType = {
  meta: Meta;
  totalEngagementTimeSheet: TotalEngagementTimeSheet;
  engagementTimeSheets: TalentEngagementTimeSheetDates[];
  isAdjustableTimesheets: boolean;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  total: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type EngagementTimeSheetsSliceActionsType = {
  loadEngagementTimeSheets: (params?: IndexParams) => {
    unwrap: () => Promise<ListResponse<TalentEngagementTimeSheetDates>>;
  };
  loadTotalEngagementTimeSheet: (params?: IndexParams) => {
    unwrap: () => Promise<SingleResponse<TotalEngagementTimeSheet>>;
  };
  createEngagementTimeSheet: (params: CreateParams) => { unwrap: () => void };
  updateEngagementTimeSheet: (params: UpdateParams) => { unwrap: () => void };
  deleteEngagementTimeSheet: (id: number) => { unwrap: () => void };
};

const initialState: EngagementTimeSheetsSliceStateType = {
  meta: {} as Meta,
  totalEngagementTimeSheet: {} as TotalEngagementTimeSheet,
  engagementTimeSheets: [],
  isAdjustableTimesheets: false,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  total: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadEngagementTimeSheets = createAsyncAction(
  'talents/engagementTimeSheets/index',
  EngagementTimeSheetsRepository.index,
);
export const loadTotalEngagementTimeSheet = createAsyncAction(
  'talents/engagementTimeSheets/total',
  EngagementTimeSheetsRepository.total,
);
export const createEngagementTimeSheet = createAsyncAction(
  'talents/engagementTimeSheets/create',
  EngagementTimeSheetsRepository.create,
);
export const updateEngagementTimeSheet = createAsyncAction(
  'talents/engagementTimeSheets/update',
  EngagementTimeSheetsRepository.update,
);
export const deleteEngagementTimeSheet = createAsyncAction(
  'talents/engagementTimeSheets/delete',
  EngagementTimeSheetsRepository.delete,
);

const TimeSheetsSlice = createSlice({
  name: 'talents/engagementTimeSheets',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadEngagementTimeSheets.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadEngagementTimeSheets.fulfilled, (state, action) => {
      const { payload, meta } = action;
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);

      const preparedResults = payload.results.map(engagementTimeSheet => {
        const engagementTimeSheetDateMap = engagementTimeSheet.timesheetDates.reduce((acc, timesheetDate) => {
          const date = parseISO(timesheetDate.timekeepingDate).getTime();
          return { ...acc, [date]: timesheetDate };
        }, {} as Record<number, TimeSheetDates>);

        const engagementTimeSheetsPeriod = getEngagementTimeSheetsPeriod(
          engagementTimeSheet,
          parseISO(meta.arg.payPeriodAfter),
        );

        const initialPayPeriodDates = eachDayOfInterval({
          start: engagementTimeSheetsPeriod.startDate,
          end: engagementTimeSheetsPeriod.endDate,
        });

        const resources = initialPayPeriodDates?.map(date => {
          const currentTime = date.getTime();
          const foundTimeSheetDates = engagementTimeSheetDateMap[currentTime];
          const preparedFoundTimeSheetDates = { id: currentTime, ...foundTimeSheetDates };
          return isNil(foundTimeSheetDates) ? createEmptyEngagementTimeSheetDates(date) : preparedFoundTimeSheetDates;
        });

        return { ...engagementTimeSheet, timesheetDates: resources };
      });

      state.engagementTimeSheets = preparedResults;
      state.isAdjustableTimesheets = payload.results.some(engagement => engagement.isAdjustableTimesheets === true);
    });
    builder.addCase(loadEngagementTimeSheets.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadTotalEngagementTimeSheet.pending, state => {
      state.total.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTotalEngagementTimeSheet.fulfilled, (state, { payload }) => {
      state.total.fetchStatus = FetchStatus.fulfilled;
      state.totalEngagementTimeSheet = payload.data;
    });
    builder.addCase(loadTotalEngagementTimeSheet.rejected, state => {
      state.total.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(createEngagementTimeSheet.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createEngagementTimeSheet.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createEngagementTimeSheet.rejected, state => {
      state.create.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(updateEngagementTimeSheet.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateEngagementTimeSheet.fulfilled, state => {
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateEngagementTimeSheet.rejected, state => {
      state.update.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(deleteEngagementTimeSheet.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteEngagementTimeSheet.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteEngagementTimeSheet.rejected, state => {
      state.delete.fetchStatus = FetchStatus.failed;
    });
  },
});

export default TimeSheetsSlice.reducer;
