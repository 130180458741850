import { FormHelperText, TextFieldProps, Button, ButtonProps } from '@mui/material';

type FileFieldProps = TextFieldProps & {
  accept?: string;
  buttonText?: string;
  startIcon?: ButtonProps['startIcon'];
};

const FileField: React.FC<FileFieldProps> = props => {
  const {
    id,
    name,
    error,
    helperText,
    onChange,
    fullWidth,
    disabled,
    startIcon,
    accept = '',
    buttonText = 'Select file',
  } = props;

  return (
    <label htmlFor={id}>
      <input type="file" accept={accept} id={id} name={name} onChange={onChange} hidden disabled={disabled} />
      <Button startIcon={startIcon} variant="outlined" fullWidth={fullWidth} component="span" disabled={disabled}>
        {buttonText}
      </Button>
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </label>
  );
};

export default FileField;
