import { AxiosResponse } from 'axios';

import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import TalentSpecialization from 'src/types/resources/TalentSpecialization';
import { SpecializationFormDataToSubmit } from 'src/forms/talents/specialization';

export type CreateParams = SpecializationFormDataToSubmit;
export type UpdateParams = {
  id: number;
  params: SpecializationFormDataToSubmit;
};
export type DeleteParams = {
  id: number;
};

export default {
  index(): Promise<{ results: TalentSpecialization[] }> {
    const url = apiRoutes.talent.specializationsPath();
    return Fetcher.get(url);
  },
  create(params: CreateParams): Promise<AxiosResponse> {
    const url = apiRoutes.talent.specializationsPath();
    return Fetcher.post(url, params);
  },
  read(id: number): Promise<AxiosResponse> {
    const url = apiRoutes.talent.specializationPath(id);
    return Fetcher.get(url);
  },
  update({ id, params }: UpdateParams): Promise<AxiosResponse> {
    const url = apiRoutes.talent.specializationPath(id);
    return Fetcher.patch(url, params);
  },
  delete({ id }: DeleteParams): Promise<AxiosResponse> {
    const url = apiRoutes.talent.specializationPath(id);
    return Fetcher.delete(url);
  },
};
