import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  root: {
    alignSelf: 'flex-start',
    flexShrink: 0,
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
};

export default styles;
