import { last } from 'ramda';

import { ModalsSliceStateType, ModalsSliceActionsType, useModalsActions, Modal } from 'src/store/modalsSlice';
import { useAppSelector } from 'src/hooks/useRedux';

type UseModalsType = ModalsSliceStateType &
  ModalsSliceActionsType & {
    currentModal: Modal;
  };

const useModals = (): UseModalsType => {
  const { modals } = useAppSelector(state => state.modals);

  const currentModal = last(modals);

  const { showModal, hideModal, hideAllModals } = useModalsActions();

  return {
    currentModal,
    modals,
    showModal,
    hideModal,
    hideAllModals,
  };
};

export default useModals;
