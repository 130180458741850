import { COLORS } from 'src/theme/palette';
import { makeStyles } from 'src/utils/makeStyles';

const styles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: {
      mobile: '100%',
      tablet: '520px',
    },
    minHeight: {
      tablet: '640px',
    },
    padding: {
      mobile: '0 17px',
      tablet: '24px 40px 40px',
    },
    maxWidth: {
      mobile: '100%',
      tablet: '520px',
    },
  },
  heading: {
    marginBottom: {
      mobile: '16px',
      tablet: '32px',
    },
    paddingBottom: {
      mobile: '8px',
      tablet: 0,
    },
    borderBottom: {
      mobile: `1px solid ${COLORS.black10}`,
      tablet: 0,
    },
    textAlign: 'left',
  },
  row: {
    display: 'flex',
    gap: '24px',
    marginBottom: {
      mobile: '20px',
      tablet: '24px',
    },
  },
  dateRow: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: {
      mobile: '20px',
      tablet: '24px',
    },
    gap: {
      mobile: 0,
      tablet: '24px',
    },
    '&:before': {
      content: '"—"',
      position: 'absolute',
      top: '48px',
      left: '50%',
      lineHeight: 0,
      transform: 'translateX(-50%)',
      display: {
        mobile: 'none',
        tablet: 'block',
      },
    },
    '.availableToDateField': {
      marginTop: '28px',
    },
  },
  paper: {
    overflow: 'visible',
    margin: 0,
    transform: 'translateY(-8px)',
    borderRadius: '8px',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
    marginTop: 'auto',
  },
  button: {
    width: {
      mobile: '100%',
      tablet: 'auto',
    },
    padding: '7px 47px',
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '8px',
  },
  column: {
    flexDirection: 'column',
    gap: {
      mobile: '16px',
      tablet: 0,
    },
  },
  checkboxRow: {
    display: 'block',
  },
});

export default styles;
