import { isNil } from 'ramda';

import { LibraryDocumentType } from 'src/enums/DocumentLibrary';
import { DocumentStatus, DocumentStatusLabel } from 'src/enums/ProfileDocument';
import { RequiredDocument } from 'src/types/resources/RequiredDocument';

const statusTextMapping = {
  [LibraryDocumentType.signAndReturn]: (isFileExist: boolean) =>
    isFileExist ? DocumentStatusLabel.uploaded : DocumentStatusLabel.signAndReturn,
  [LibraryDocumentType.consentForm]: (isChecked: boolean) =>
    isChecked ? DocumentStatusLabel.accepted : DocumentStatusLabel.readAndAccept,
  [LibraryDocumentType.upload]: (isFileExist: boolean) =>
    isFileExist ? DocumentStatusLabel.uploaded : DocumentStatusLabel.notUploaded,
};

const RequiredDocumentPresenter = {
  status: (document: RequiredDocument): DocumentStatus => {
    if (RequiredDocumentPresenter.isReadAndAcceptFile(document)) {
      return document.filled?.isChecked ? DocumentStatus.uploaded : DocumentStatus.notUploaded;
    }

    return isNil(document.filled?.file) ? DocumentStatus.notUploaded : DocumentStatus.uploaded;
  },

  statusText: (document: RequiredDocument): DocumentStatusLabel => {
    if (RequiredDocumentPresenter.isReadAndAcceptFile(document)) {
      return statusTextMapping[document.answerType]?.(document.filled?.isChecked);
    }

    const isFileExist = !isNil(document.filled?.file);

    return statusTextMapping[document.answerType]?.(isFileExist);
  },
  isDownloadFileExist: (document: RequiredDocument): boolean => !isNil(document.file),
  isUploadFileExist: (document: RequiredDocument): boolean => !isNil(document.filled),
  isReadAndAcceptFile: (document: RequiredDocument): boolean => document.answerType === LibraryDocumentType.consentForm,
};

export default RequiredDocumentPresenter;
