import { isEmpty, isNil, omit } from 'ramda';
import * as yup from 'yup';

import { ValidationError } from 'src/enums/Validation';
import { ContactType } from 'src/enums/ContactType';
import { UserRole } from 'src/enums/UserRole';
import { OrganizationUser } from 'src/types/resources/Organization';
import { isCustomContactType } from 'src/utils/contactType';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';
import { formatPhoneNumberForForm, formatPhoneNumberForSubmit } from 'src/utils/phoneNumber';

export type CreateOrganizationUserFormData = {
  firstName: string;
  lastName: string;
  isSystemUser: boolean;
  role: UserRole;
  title: string;
  email: string;
  additionalEmailList: string[];
  phone: string;
  additionalPhoneList: string[];
  isDeactivated: boolean;
  hasTimeSheetApproval: boolean;
  organizationId: number;
  departmentId: number;
  contactType: ContactType | string;
  customContactType?: string;
  reassignedUserId?: number;
  hasPermissionViewRate?: boolean;
  hasPermissionSendEngagement?: boolean;
  isEngagementsOnly?: boolean;
};

export const validationFields = {
  firstName: yup.string().required(ValidationError.default).default('').label('First Name'),
  lastName: yup.string().required(ValidationError.default).default('').label('Last Name'),
  isSystemUser: yup.boolean().required(ValidationError.default).default(true),
  role: yup.string().required(ValidationError.default).default(UserRole.OrganizationAdmin).label('Role'),
  title: yup.string().nullable().default('').label('Title'),
  email: yup.string().required(ValidationError.default).email(ValidationError.email).default('').label('Email'),
  additionalEmailList: yup.array().of(yup.string().email(ValidationError.email).default('').label('Email')).default([]),
  phone: yup.string().nullable().default('').max(25, getMaxFieldLengthErrorMessage(25)).label('Phone number'),
  additionalPhoneList: yup
    .array()
    .of(yup.string().default('').max(25, getMaxFieldLengthErrorMessage(25)).label('Phone number'))
    .default([]),
  isDeactivated: yup.boolean().required(ValidationError.default).default(false),
  hasTimeSheetApproval: yup.boolean().required(ValidationError.default).default(false),
  organizationId: yup.number().required(ValidationError.default).default(0),
  departmentId: yup
    .number()
    .nullable()
    .when('role', {
      is: (value: UserRole) => value === UserRole.Manager,
      then: schema => schema.required(ValidationError.default),
    })
    .default(null)
    .label('Assigned Department'),
  contactType: yup.string().default(ContactType.billing).label('Contact type'),
  customContactType: yup
    .string()
    .when('contactType', {
      is: (value: ContactType) => isCustomContactType(value),
      then: schema => schema.required(ValidationError.default),
    })
    .default(''),
  reassignedUserId: yup.number().nullable().default(null),
  hasPermissionViewRate: yup.boolean().default(true),
  hasPermissionSendEngagement: yup.boolean().default(true),
  isEngagementsOnly: yup.boolean().default(false),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (user: OrganizationUser | null = null): CreateOrganizationUserFormData => {
  if (isNil(user)) {
    return {
      ...validationSchema.getDefault(),
      role: UserRole.OrganizationAdmin,
      contactType: ContactType.billing,
    };
  }

  const isPredefinedContactType = ContactType[user.contactType] && !isCustomContactType(user.contactType);
  const phone = user.phone ? formatPhoneNumberForForm(user.phone) : '';
  const additionalPhoneList = user.additionalPhoneList.map(formatPhoneNumberForForm);

  if (isPredefinedContactType) {
    return {
      ...omit(['department'], user),
      departmentId: user.department?.id,
      contactType: user.contactType,
      customContactType: '',
      reassignedUserId: null,
      isEngagementsOnly: false,
      phone,
      additionalPhoneList,
    };
  }

  return {
    ...omit(['department'], user),
    departmentId: user.department?.id,
    contactType: isNil(user.contactType) ? ContactType.billing : ContactType.custom,
    customContactType: user.contactType || '',
    reassignedUserId: null,
    isEngagementsOnly: false,
    phone,
    additionalPhoneList,
  };
};

export const attributesToSubmit = (values: CreateOrganizationUserFormData): CreateOrganizationUserFormData => {
  const { additionalEmailList, additionalPhoneList, contactType, customContactType, phone, ...restValues } = values;

  const clearedEmailList = additionalEmailList.filter(Boolean);
  const clearedPhoneList = additionalPhoneList.filter(Boolean);

  return {
    ...omit(['reassignedUserId'], restValues),
    additionalEmailList: clearedEmailList,
    phone: formatPhoneNumberForSubmit(phone),
    additionalPhoneList: clearedPhoneList.map(formatPhoneNumberForSubmit),
    contactType: isEmpty(customContactType) ? contactType : customContactType,
  };
};
