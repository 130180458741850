import { UserRole } from 'src/enums/UserRole';
import { OrganizationUser } from 'src/types/resources/Organization';
import { User } from 'src/types/resources/User';

import UserRolePresenter from './UserRolePresenter';

const OrganizationUserPresenter = {
  fullName: (user: OrganizationUser): string => {
    return `${user.firstName} ${user.lastName}`;
  },
  isOrganizationUser: (user: OrganizationUser): boolean =>
    user.role === UserRole.Manager || user.role === UserRole.OrganizationAdmin,
  isManager: (user: OrganizationUser): boolean => user.role === UserRole.Manager,
  emailList: (user: OrganizationUser): string => {
    const { email, additionalEmailList } = user;
    const emailList = [email, ...additionalEmailList].join('\n');

    return emailList;
  },
  canSeeSavings: (user: Pick<User, 'role' | 'hasPermissionSendEngagement'>) => {
    if (UserRolePresenter.isAdminRole(user.role)) {
      return true;
    }
    if (UserRolePresenter.isManagerRole(user.role) && user.hasPermissionSendEngagement) {
      return true;
    }

    return false;
  },
};

export default OrganizationUserPresenter;
