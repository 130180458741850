import { createAction, createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { SuperAdminTalent } from 'src/types/resources/SuperAdminTalent';
import { ProfileComment } from 'src/types/resources/Comment';
import TalentProfile from 'src/types/resources/TalentProfile';
import { ListResponse, SingleResponse } from 'src/types/utils';
import TalentsRepository, { IndexParams, PartialUpdateParams } from 'src/repositories/superAdmin/TalentsRepository';
import CommentsRepository, { CreateParams, UpdateParams } from 'src/repositories/CommentsRepository';
import { createAsyncAction } from 'src/utils/createAsyncAction';

import { createCommentThunkAction, updateCommentReducer } from '../common';

export type TalentsSliceStateType = {
  meta: Meta;
  talents: SuperAdminTalent[];
  talent: TalentProfile;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type TalentsSliceActionsType = {
  loadTalents: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<SuperAdminTalent>> };
  showTalent: (id: number) => { unwrap: () => Promise<SingleResponse<TalentProfile>> };
  partialUpdateTalent: (params: PartialUpdateParams) => { unwrap: () => Promise<SingleResponse<TalentProfile>> };
  createComment: (params: CreateParams) => { unwrap: () => void };
  partialUpdateComment: (params: UpdateParams) => { unwrap: () => void };
};

const initialState: TalentsSliceStateType = {
  meta: {} as Meta,
  talents: [],
  talent: {} as TalentProfile,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadTalents = createAsyncAction('superAdmin/talents/index', TalentsRepository.index);
export const showTalent = createAsyncAction('superAdmin/talents/show', TalentsRepository.show);
export const partialUpdateTalent = createAsyncAction(
  'superAdmin/talents/partialUpdate',
  TalentsRepository.partialUpdate,
);
export const addComment = createAction<ProfileComment>('superAdmin/talents/comments/add');
export const createComment = createCommentThunkAction('superAdmin/talents/comments/create', addComment, true);
export const partialUpdateComment = createAsyncAction(
  'superadmin/talents/comments/edit',
  CommentsRepository.partialUpdate,
);

const talentsSlice = createSlice({
  name: 'superAdmin/talents',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadTalents.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTalents.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.talents = payload.results;
    });
    builder.addCase(loadTalents.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(showTalent.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showTalent.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.talent = payload.data;
    });
    builder.addCase(showTalent.rejected, state => {
      state.show.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(addComment, (state, { payload }) => {
      state.talent.revuudComments = [payload, ...state.talent.revuudComments];
    });
    builder.addCase(partialUpdateComment.fulfilled, (state, { payload }) => {
      state.talent.revuudComments = updateCommentReducer(state.talent.revuudComments, payload);
    });
  },
});

export default talentsSlice.reducer;
