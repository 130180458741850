import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

type ToggleTag = {
  id: number;
  name: string;
};

type ToggleTagGroupProps = ToggleButtonGroupProps & {
  tags: ToggleTag[];
  isDisabled?: boolean;
};

const ToggleTagGroup: React.FC<ToggleTagGroupProps> = props => {
  const { tags = [], isDisabled = false, size = 'small', value, onChange, ...restProps } = props;

  return (
    <ToggleButtonGroup value={value} size={size} onChange={onChange} {...restProps}>
      {tags.map(tag => {
        return (
          <ToggleButton key={tag.id} value={tag.id} disabled={isDisabled}>
            {tag.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default ToggleTagGroup;
