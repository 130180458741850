import { makeStyles } from 'src/utils/makeStyles';
import { COLORS } from 'src/theme/palette';

export default makeStyles({
  modalWrapper: {
    width: '600px',
    padding: 3,
  },
  buttons: {
    display: 'flex',
    gap: 1,
  },
  button: {
    paddingY: 1,
    paddingX: 6,
  },
  applicantStatusIcon: {
    display: 'inline-flex',
  },
  approved: {
    color: COLORS.orange5,
  },
  invited: {
    color: COLORS.green100,
  },
  match: {
    color: COLORS.logoText,
  },
});
