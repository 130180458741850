import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import WorkExperienceRepository, {
  CreateParams,
  DeleteParams,
  UpdateParams,
} from 'src/repositories/talents/WorkExperienceRepository';
import WorkExperience from 'src/types/resources/WorkExperience';
import { createAsyncAction } from 'src/utils/createAsyncAction';

export type WorkExperiencesSliceStateType = {
  workExperiences: WorkExperience[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type WorkExperiencesSliceActionsType = {
  loadWorkExperiences: () => { unwrap: () => void };
  createWorkExperience: (params: CreateParams) => { unwrap: () => void };
  updateWorkExperience: (params: UpdateParams) => { unwrap: () => void };
  deleteWorkExperience: (params: DeleteParams) => { unwrap: () => void };
};

const initialState: WorkExperiencesSliceStateType = {
  workExperiences: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadWorkExperiences = createAsyncAction('talents/workExperiences/index', WorkExperienceRepository.index);
export const createWorkExperience = createAsyncAction(
  'talents/workExperiences/create',
  WorkExperienceRepository.create,
);
export const updateWorkExperience = createAsyncAction(
  'talents/workExperiences/update',
  WorkExperienceRepository.update,
);
export const deleteWorkExperience = createAsyncAction(
  'talents/workExperiences/delete',
  WorkExperienceRepository.delete,
);

const WorkExperiencesSlice = createSlice({
  name: 'talents/workExperiences',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadWorkExperiences.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadWorkExperiences.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.workExperiences = payload.results;
    });
    builder.addCase(loadWorkExperiences.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
  },
});

export default WorkExperiencesSlice.reducer;
