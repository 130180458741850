import { Typography, CircularProgress } from '@mui/material';
import React from 'react';

import Box from 'src/components/Box';

import styles from './styles';

type MatchedResourcesCountProps = {
  count: number;
  isLoading: boolean;
};

const MatchedResourcesCount: React.FC<MatchedResourcesCountProps> = props => {
  const { count, isLoading } = props;

  return (
    <Box sx={styles.root}>
      <Typography variant="body1" component="span">
        Number of Talent matching your criteria: {count}
      </Typography>
      {isLoading && <CircularProgress size={20} sx={styles.circularProgress} />}
    </Box>
  );
};

export default MatchedResourcesCount;
