import { ReactComponent as IconExpertiseNovice } from 'src/assets/images/iconExpertiseNovice.svg';
import { ReactComponent as IconExpertiseIntermediate } from 'src/assets/images/iconExpertiseIntermediate.svg';
import { ReactComponent as IconExpertiseExpert } from 'src/assets/images/iconExpertiseExpert.svg';

export enum ExpertiseType {
  novice = 'novice',
  intermediate = 'intermediate',
  expert = 'expert',
}

export const iconMapping = {
  [ExpertiseType.novice]: <IconExpertiseNovice />,
  [ExpertiseType.intermediate]: <IconExpertiseIntermediate />,
  [ExpertiseType.expert]: <IconExpertiseExpert />,
};
