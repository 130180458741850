import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  box: {
    width: '512px',
    padding: '24px 24px 36px',
  },
  link: {
    lineHeight: 'inherit',
    marginLeft: '4px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default styles;
