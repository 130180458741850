import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import SpecializationRepository, { IndexParams } from 'src/repositories/SpecializationsRepository';
import TalentSpecialization from 'src/types/resources/TalentSpecialization';
import { createAsyncAction } from 'src/utils/createAsyncAction';

export type SpecializationsSliceStateType = {
  specializations: TalentSpecialization[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type SpecializationsSliceActionsType = {
  loadSpecializations: (params?: IndexParams) => { unwrap: () => void };
};

const initialState: SpecializationsSliceStateType = {
  specializations: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadSpecializations = createAsyncAction('specializations/index', SpecializationRepository.index);

const SpecializationsSlice = createSlice({
  name: 'specializations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSpecializations.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSpecializations.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.specializations = payload.results;
    });
    builder.addCase(loadSpecializations.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
  },
});

export default SpecializationsSlice.reducer;
