import {
  OrganizationUsersSliceStateType,
  OrganizationsSliceActionsType,
  loadOrganizationUsers,
} from 'src/store/manager/managerOrganizationUsersSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { IndexParams } from 'src/repositories/manager/OrganizationUsersRepository';

type UseOrganizationsUsersType = Pick<OrganizationUsersSliceStateType, 'organizationUsers' | 'meta'> &
  OrganizationsSliceActionsType & {
    isOrganizationsUsersLoadFinished: boolean;
  };

const useOrganizationsUsers = (): UseOrganizationsUsersType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    organizationUsers,
    index: { fetchStatus: organizationsIndexFetchStatus },
  } = useAppSelector(state => state.organizationAdminOrganizationUsers);

  const isOrganizationsUsersLoadFinished = getFetchStatus(organizationsIndexFetchStatus).isFinished;

  return {
    meta,
    organizationUsers,
    isOrganizationsUsersLoadFinished,
    loadOrganizationUsers: (params?: IndexParams) => dispatch(loadOrganizationUsers(params)),
  };
};

export default useOrganizationsUsers;
