import { SxStyles } from 'src/types/theme';
import { getEnvironment } from 'src/utils/environment';

const isQaEnvironment = getEnvironment(process.env.REACT_APP_ENV).isQa;

const placeholderOffset = isQaEnvironment ? '8px' : '4px';

const styles: SxStyles = {
  fieldInput: {
    '& input': {
      '&[value=""]': {
        paddingLeft: placeholderOffset,
      },
      '&::placeholder': {
        transform: `translateX(-${placeholderOffset})`,
      },
    },
  },
  captionWrapper: {
    marginTop: '8px',
  },
};

export default styles;
