import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiLinkType = {
  defaultProps: ComponentsProps['MuiLink'];
  styleOverrides: ComponentsOverrides['MuiLink'];
};

const MuiLink: MuiLinkType = {
  styleOverrides: {},
  defaultProps: {
    color: COLORS.orange5,
    variant: 'button',
  },
};

export default MuiLink;
