import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 40px 45px',
    boxSizing: 'border-box',
    width: { desktop: '1080px', largeScreen: '1400px' },
    minHeight: '450px',
  },
  breadcrumbs: {
    marginBottom: '5px',
  },
  breadcrumbsButton: {
    minWidth: 0,
    padding: 0,
    border: 0,
    '&:hover': {
      background: 'none',
    },
  },
};

export default styles;
