import { isEmpty, isNil } from 'ramda';

import { getEnvironment } from 'src/utils/environment';

export const formatPhoneNumberForForm = (value: string): string => {
  if (isNil(value)) {
    return '';
  }

  const isQaEnvironment = getEnvironment(process.env.REACT_APP_ENV).isQa;

  return isQaEnvironment ? value : value.slice(2);
};

export const formatPhoneNumberForSubmit = (value: string): string => {
  if (isEmpty(value)) {
    return null;
  }

  const isQaEnvironment = getEnvironment(process.env.REACT_APP_ENV).isQa;
  const formattedPhoneValue = value.replace(/[()\-\s)]/g, '');

  return isQaEnvironment ? formattedPhoneValue : `+1${formattedPhoneValue}`;
};
