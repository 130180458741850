import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiSelectType = {
  defaultProps: ComponentsProps['MuiSelect'];
  styleOverrides: ComponentsOverrides['MuiSelect'];
};

const MuiSelect: MuiSelectType = {
  styleOverrides: {
    select: {
      '&:focus': {
        background: 'none',
      },
    },
    icon: {
      width: '20px',
      right: '10px',
      top: '17px',
      fill: COLORS.black80,
      '&.Mui-disabled': {
        fill: COLORS.black40,
      },
    },
    iconOpen: {
      top: '17px',
    },
  },
  defaultProps: {},
};

export default MuiSelect;
