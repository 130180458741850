import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  root: {
    display: 'inline-flex',
    color: COLORS.orange5,
  },
};

export default styles;
