import { Alert, IconButton, List, ListItem, Typography } from '@mui/material';
import { head, isEmpty, isNil, last } from 'ramda';
import { useState } from 'react';

import { ReactComponent as EditIcon } from 'src/assets/images/editIcon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/deleteIcon.svg';
import { createRootSpecialization } from 'src/forms/talents/specialization';
import SpecializationsRepository from 'src/repositories/SpecializationsRepository';
import TalentSpecialization from 'src/types/resources/TalentSpecialization';
import { ModalLayout, ModalType } from 'src/enums/ModalType';
import SpecializationPresenter from 'src/presenters/SpecializationPresenter';
import { isAxiosError, isUnprocessedEntityError } from 'src/utils/responseErrors';
import useLoading from 'src/hooks/useLoading';
import useModals from 'src/hooks/useModals';
import useTalentSpecializations from 'src/hooks/talents/useSpecializations';
import Box from 'src/components/Box';

import styles from './styles';

type ItemProps = {
  specialization: TalentSpecialization;
};

const Item: React.FC<ItemProps> = props => {
  const { specialization } = props;
  const [errors, setErrors] = useState<string | null>(null);
  const { showModal } = useModals();
  const { deleteSpecialization } = useTalentSpecializations();
  const { funcWithLoading: fetchRootSpecializationsWithLoading } = useLoading(SpecializationsRepository.index);
  const { funcWithLoading: fetchParentSpecializationWithLoading } = useLoading(SpecializationsRepository.show);

  const { id, roles, skills } = specialization;
  const specializationPath = SpecializationPresenter.ancestors(specialization);
  const expertise = SpecializationPresenter.expertise(specialization);
  const roleNames = SpecializationPresenter.roleNames(specialization);
  const skillNames = SpecializationPresenter.skillNames(specialization);

  const fetchParentSpecialization = async (parentSpecializationId?: number | null) => {
    let parentSpecialization = null;
    if (isNil(parentSpecializationId)) {
      const response = await fetchRootSpecializationsWithLoading();
      parentSpecialization = createRootSpecialization(response.results);
    } else {
      const response = await fetchParentSpecializationWithLoading(parentSpecializationId);
      parentSpecialization = response.data;
    }

    return parentSpecialization;
  };

  const handleDelete = async () => {
    try {
      await deleteSpecialization({ id }).unwrap();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        setErrors(head(error.response.data.errors.nonFieldErrors));
      }
    }
  };

  const handleEditSpecializationFormShow = async () => {
    const lastAncestor = last(specialization.ancestors);
    const parentSpecialization = await fetchParentSpecialization(lastAncestor?.id);
    showModal({
      type: ModalType.TalentSpecializationForm,
      modalLayout: ModalLayout.talentModals,
      props: {
        parentTalentSpecialization: parentSpecialization,
        talentSpecialization: specialization,
      },
    });
  };

  const renderExpertise = (expertiseLevel: string): JSX.Element => {
    return (
      <Typography variant="body1" sx={styles.listTitle}>
        <Typography sx={styles.listTitleTerm} component="span">
          Level of expertise:
        </Typography>
        {expertiseLevel}
      </Typography>
    );
  };

  const renderRoles = (role: string): JSX.Element => {
    return (
      <Typography variant="body1" sx={styles.listTitle}>
        <Typography sx={styles.listTitleTerm} component="span">
          Roles:
        </Typography>
        {role}
      </Typography>
    );
  };

  const renderSkills = (list: string[], title?: string): JSX.Element => {
    return (
      <Box>
        <Typography variant="body1" sx={styles.listTitleTerm}>
          {title}:
        </Typography>
        <List sx={styles.list}>
          {list.map(listItem => (
            <ListItem key={listItem} sx={styles.listItem}>
              {listItem}
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Box sx={styles.wrapper}>
      {errors && <Alert severity="error">{errors}</Alert>}
      <Box sx={styles.description}>
        <Typography variant="h5" sx={styles.title} component="span">
          {specializationPath}
        </Typography>
        {!isNil(expertise) && renderExpertise(expertise)}
        {!isEmpty(roles) && renderRoles(roleNames)}
        {!isEmpty(skills) && renderSkills(skillNames, 'Skills')}
      </Box>
      <Box sx={styles.buttons}>
        <IconButton sx={styles.button} onClick={handleEditSpecializationFormShow} data-testid="specialization-edit-btn">
          <EditIcon />
        </IconButton>
        <IconButton sx={styles.button} onClick={handleDelete} data-testid="specialization-delete-btn">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Item;
