import { head } from 'ramda';
import { FormikHelpers, useFormik } from 'formik';
import {
  Alert,
  Button,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import { useState } from 'react';

import InviteUserRepository from 'src/repositories/InviteUserRepository';
import useModals from 'src/hooks/useModals';
import useSnackbar from 'src/hooks/useSnackbar';
import { attributesToSubmit, initialValues, InviteUserFormData, validationSchema } from 'src/forms/inviteUser';
import {
  extractResponseErrors,
  isAxiosError,
  isTeaPotError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import Box from 'src/components/Box';
import Select from 'src/components/Select';
import { UserInvitationType } from 'src/enums/UserInvitationType';
import externalRoutes from 'src/routes/externalRoutes';
import inviteUserDictionary from 'src/dictionary/inviteUser';

import styles from './styles';

type InviteUserProps = {
  currentUserId: ID;
};

const SELECT_ITEMS = [
  { value: UserInvitationType.Talent, label: 'Talent' },
  { value: UserInvitationType.Organization, label: 'Organization' },
];

enum ErrorType {
  exists = 'exists',
  invited = 'invited',
}

const InviteUser: React.FC<InviteUserProps> = ({ currentUserId }) => {
  const [formError, setFormError] = useState<string | null>(null);
  const [errorType, setErrorType] = useState<ErrorType>(null);
  const { hideModal } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();

  const handleSubmit = async (
    formData: InviteUserFormData,
    { setErrors, setFieldValue }: FormikHelpers<InviteUserFormData>,
  ) => {
    const params = attributesToSubmit(formData);
    setFormError(null);
    try {
      await InviteUserRepository.create({ ...params, invitedBy: currentUserId });
      hideModal();
      enqueueSuccessSnackbar('The invite was sent');
    } catch (error: unknown) {
      if (isAxiosError(error) && (isUnprocessedEntityError(error) || isTeaPotError(error))) {
        const errors = extractResponseErrors(error);
        setFormError(head(error.response.data.errors.nonFieldErrors));
        error.response.data.errors.nonFieldErrors.forEach((errorText: string) => {
          if (errorText.includes('Want to resend?')) {
            setFieldValue('doResend', true);
          }
        });
        setErrors(parseToFormikErrors(errors));
        if (isUnprocessedEntityError(error)) {
          setErrorType(ErrorType.exists);
        }
        if (isTeaPotError(error)) {
          setErrorType(ErrorType.invited);
        }
      }
    }
  };

  const { values, errors, submitForm, touched, handleChange, handleBlur } = useFormik<InviteUserFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const submitButtonText = values.doResend ? 'Resend' : 'Invite';

  const renderMainContent = () => (
    <>
      <Box>
        <Typography mb={1} variant="h2">
          {inviteUserDictionary.title}
        </Typography>
        <Typography variant="subtitle2">{inviteUserDictionary.subTitle}</Typography>
      </Box>
      <Stack direction="row">
        <TextField
          id="firstName"
          label="First Name"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.firstName && Boolean(errors.firstName)}
          helperText={touched.firstName && errors.firstName}
        />
        <TextField
          id="lastName"
          label="Last Name"
          type="text"
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.lastName && Boolean(errors.lastName)}
          helperText={touched.lastName && errors.lastName}
        />
      </Stack>
      <TextField
        id="email"
        label="Email address"
        type="email"
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />
      <Select
        name="inviteType"
        label="Who are you referring?"
        values={SELECT_ITEMS}
        onChange={handleChange}
        value={values.inviteType}
        error={touched.inviteType && Boolean(errors.inviteType)}
        helperText={touched.email && errors.email}
      />
      {values.inviteType === UserInvitationType.Organization && (
        <TextField
          id="organizationName"
          label="Organization Name"
          onChange={handleChange}
          error={touched.organizationName && Boolean(errors.organizationName)}
          helperText={touched.organizationName && errors.organizationName}
        />
      )}
      <Box>
        <InputLabel>Message</InputLabel>
        <FormHelperText sx={({ palette }) => ({ mt: 0, mb: 0.5, color: palette.black100 })}>
          What message would you like to send to your colleague about Revuud?
        </FormHelperText>
        <TextField
          id="message"
          onChange={handleChange}
          error={Boolean(errors.message)}
          helperText={errors.message}
          multiline
        />
      </Box>

      <Box>
        <Button variant="contained" onClick={submitForm} sx={styles.button}>
          {submitButtonText}
        </Button>
        <Button variant="outlined" onClick={hideModal} sx={styles.button}>
          Cancel
        </Button>
      </Box>
    </>
  );

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <Stack sx={styles.wrapper}>
        {errorType === ErrorType.invited && (
          <Typography fontWeight={600}>
            Please ask {values.firstName} {values.lastName} to go to{' '}
            <MuiLink href={externalRoutes.revuud.root()} target="_blank" underline="hover">
              Revuud.com
            </MuiLink>{' '}
            and click the sign up button to get started.
          </Typography>
        )}
        {errorType === ErrorType.exists && (
          <Typography fontWeight={600}>
            Please ask {values.firstName} {values.lastName} to log in to{' '}
            <MuiLink href={externalRoutes.revuud.root()} target="_blank" underline="hover">
              Revuud.com
            </MuiLink>{' '}
            and make sure their Profile is up to date.
          </Typography>
        )}
        {errorType && (
          <Button sx={styles.closeButton} variant="contained" onClick={hideModal}>
            Close
          </Button>
        )}
        {errorType === null && renderMainContent()}
      </Stack>
    </>
  );
};

export default InviteUser;
