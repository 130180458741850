export enum EngagementStatus {
  pending = 'pending',
  accepted = 'accepted',
  declined = 'declined',
  active = 'active',
  terminated = 'terminated',
  expired = 'expired',
  withdrawn = 'withdrawn',
  draft = 'draft',
}

export enum EngagementAction {
  created = 'created',
  updatedAccepted = 'updated_accepted',
  updatedPending = 'updated_pending',
  statusChange = 'status_change',
  draftCreated = 'draft_created',
  updatedDraft = 'updated_draft',
}

export enum EngagementStatusLabel {
  pending = 'Pending',
  accepted = 'Accepted',
  declined = 'Declined',
  active = 'Active',
  terminated = 'Terminated',
  expired = 'Expired',
  withdrawn = 'Withdrawn',
  draft = 'Draft',
  all = 'All',
}

export enum EngagementScope {
  generalIt = 'general_it',
  build = 'build',
  reportWriting = 'report_writing',
  integration = 'integration',
  security = 'security',
  managementConsulting = 'management_consulting',
  projectManagement = 'project_management',
  training = 'training',
  curriculumDevelopment = 'curriculum_development',
  documentationAnalysis = 'documentation_analysis',
  ticketResolution = 'ticket_resolution',
  staffAugmentation = 'staff_augmentation',
  goLiveSupport = 'go_live_support',
  other = 'other',
}

export enum EngagementScopeLabel {
  generalIt = 'General IT / EMR Support',
  build = 'Build',
  reportWriting = 'Report Writing',
  integration = 'Integration',
  security = 'Security',
  managementConsulting = 'Management Consulting',
  projectManagement = 'Project Management',
  training = 'Training',
  curriculumDevelopment = 'Curriculum development',
  documentationAnalysis = 'Documentation Analysis',
  ticketResolution = 'Ticket resolution',
  staffAugmentation = 'Staff Augmentation',
  goLiveSupport = 'Go Live Support',
  other = 'Other',
}

export enum EngagementPlace {
  remote = 'remote',
  onSite = 'on-site',
}

export enum EngagementPlaceLabel {
  remote = 'Remote',
  onSite = 'On-Site',
  onSiteWithDescription = 'On-Site (Client agrees to reimburse for travel related expenses)',
}
