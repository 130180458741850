import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const styles: SxStyles = {
  uploadBox: {
    '& input[type="file"]': {
      display: 'none',
    },
  },
  uploadPreviewBox: {
    borderRadius: '4px',
    overflow: 'hidden',
    width: '200px',
    height: '136px',
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '& button': {
      position: 'absolute',
      top: '4px',
      right: '4px',
      width: '28px',
      height: '28px',
      padding: '4px',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      borderRadius: '4px',
      '&:hover, &:active': {
        backgroundColor: 'rgba(0, 0, 0, 1)',
      },
      '& svg': {
        fill: COLORS.white,
      },
    },
  },
  uploadButton: {
    '& svg': {
      fill: 'currentColor',
    },
  },
  documentIcon: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${COLORS.black40}`,
    '& svg': {
      width: '50%',
      height: '50%',
      fill: COLORS.black60,
    },
  },
};

export default styles;
