import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiTablePaginationType = {
  defaultProps: ComponentsProps['MuiTablePagination'];
  styleOverrides: ComponentsOverrides['MuiTablePagination'];
};

const MuiTablePagination: MuiTablePaginationType = {
  styleOverrides: {
    root: {
      borderTop: `1px solid ${COLORS.black10}`,
      borderBottom: 0,
    },
    toolbar: {
      paddingTop: '24px',
      justifyContent: 'space-between',
      '@media(min-width: 600px)': {
        paddingLeft: 0,
      },
    },
    spacer: {
      display: 'none',
    },
    selectLabel: {
      display: 'none',
    },
    selectRoot: {
      display: 'none',
    },
    displayedRows: {
      margin: 0,
      marginRight: '24px',
      color: COLORS.black40,
    },
  },
  defaultProps: {},
};

export default MuiTablePagination;
