import * as yup from 'yup';
import { isNil } from 'ramda';

import { ValidationError } from 'src/enums/Validation';
import { PurchaseOrder } from 'src/types/resources/PurchaseOrders';
import { Organization } from 'src/types/resources/Organization';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';
import { REQUIRED_DOCUMENT_VALID_FILE_TYPES } from 'src/utils/constants';
import { CreateParams } from 'src/repositories/organizationAdmin/PurchaseOrderRepository';
import { getFilePayload } from 'src/utils/filePayload';

import { getValidFileTypeTest, VALID_FILE_SIZE } from './customRules';
import { DocumentFile } from './talents/documents';

export type CreatePurchaseOrderFormData = {
  poNumber: PurchaseOrder['name'];
  poDescription: PurchaseOrder['description'];
  poNote?: PurchaseOrder['notes'];
  file?: DocumentFile | null;
}[];

export const createPurchaseOrderForSubmit = (
  values: CreatePurchaseOrderFormData[number],
  organizationId: Organization['id'],
): CreateParams => {
  const { poNumber, poDescription, file, poNote } = values;
  const filePayload = getFilePayload(file, 'fileName') as Pick<CreateParams, 'fileName' | 'originalFileName'>;

  return {
    name: poNumber,
    description: poDescription,
    notes: poNote || null,
    ...filePayload,
    isActive: true,
    organizationId,
  };
};

export const validationFields = yup.object({
  poNumber: yup
    .string()
    .trim()
    .max(15, getMaxFieldLengthErrorMessage(15))
    .required(ValidationError.default)
    .default(''),
  poDescription: yup
    .string()
    .trim()
    .max(50, getMaxFieldLengthErrorMessage(50))
    .required(ValidationError.default)
    .default(''),
  poNote: yup.string().max(500, getMaxFieldLengthErrorMessage(500)).default(''),
  file: yup
    .object()
    .nullable()
    .shape({
      source: yup
        .mixed()
        .nullable()
        .test(getValidFileTypeTest(REQUIRED_DOCUMENT_VALID_FILE_TYPES))
        .test(VALID_FILE_SIZE)
        .default(null),
      url: yup.string().nullable().default(null),
    }),
});

export const validationSchema = yup.array().of(validationFields).default([validationFields.getDefault()]);

export const createInitialValues = (purchaseOrder?: PurchaseOrder): CreatePurchaseOrderFormData => {
  if (isNil(purchaseOrder)) {
    return validationSchema.getDefault();
  }
  return [
    {
      poNumber: purchaseOrder.name,
      poDescription: purchaseOrder.description,
      poNote: purchaseOrder.notes || '',
      file: {
        source: null,
        url: purchaseOrder.fileName,
        name: purchaseOrder.originalFileName,
      },
    },
  ];
};
