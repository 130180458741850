export enum BlockType {
  h2 = 'header-two',
  list = 'unordered-list-item',
  orderList = 'ordered-list-item',
}

export enum InlineStyle {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
}
