import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import TitleIcon from '@mui/icons-material/Title';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import { EditorState, RichUtils } from 'draft-js';

import Box from 'src/components/Box';
import { BlockType, InlineStyle } from 'src/enums/TextEditor';

import ToolButton from './components/ToolButton';
import styles from './styles';

const icons: Record<Partial<BlockType | InlineStyle>, JSX.Element> = {
  [BlockType.h2]: <TitleIcon />,
  [BlockType.list]: <FormatListBulletedIcon />,
  [BlockType.orderList]: <FormatListNumberedIcon />,

  [InlineStyle.BOLD]: <FormatBoldIcon />,
  [InlineStyle.ITALIC]: <FormatItalicIcon />,
  [InlineStyle.UNDERLINE]: <FormatUnderlinedIcon />,
};

type ToolPanelProps = {
  editorState: EditorState;
  onChange: (editorState: EditorState) => void;
  currentBlockType: BlockType;
  hasInlineStyle: (style: InlineStyle) => boolean;
};

const ToolPanel: React.FC<ToolPanelProps> = props => {
  const { editorState, onChange, currentBlockType, hasInlineStyle } = props;

  const toggleBlockType = (blockType: BlockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };
  const toggleInlineStyle = (inlineStyle: InlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const blockButtons = Object.entries(BlockType).map(([key, value]) => {
    const isActive = value === currentBlockType;

    return (
      <ToolButton key={key} onClick={() => toggleBlockType(value)} label={key} Icon={icons[value]} active={isActive} />
    );
  });
  const inlineButtons = Object.values(InlineStyle).map(value => {
    const isActive = hasInlineStyle(value);
    return (
      <ToolButton
        key={value}
        onClick={() => toggleInlineStyle(value)}
        label={value}
        Icon={icons[value]}
        active={isActive}
      />
    );
  });

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.box}>
        {blockButtons}
        {inlineButtons}
      </Box>
    </Box>
  );
};

export default ToolPanel;
