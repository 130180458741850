import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  box: {
    maxWidth: '900px',
    '& img': {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      borderRadius: '4px',
    },
  },
};

export default styles;
