import { useCallback } from 'react';

type UseObjectURLType = {
  downloadFile: (url: string) => void;
};

const useDownloadFile = (): UseObjectURLType => {
  const downloadFile = useCallback((url: string) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('rel', 'noopener noreferrer');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, []);

  return {
    downloadFile,
  };
};

export default useDownloadFile;
