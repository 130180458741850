import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiPaginationItemType = {
  defaultProps: ComponentsProps['MuiPaginationItem'];
  styleOverrides: ComponentsOverrides['MuiPaginationItem'];
};

const MuiPaginationItem: MuiPaginationItemType = {
  styleOverrides: {
    root: {
      borderRadius: '4px',
      color: COLORS.orange5,
      '&.Mui-selected': {
        background: COLORS.orange5,
        color: COLORS.white,
        pointerEvents: 'none',
      },
    },
  },
  defaultProps: {},
};

export default MuiPaginationItem;
