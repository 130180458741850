import * as yup from 'yup';

import { MAX_DEFAULT_TEXTAREA_LENGTH, ValidationError } from 'src/enums/Validation';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';
import { EngagementTerm } from 'src/types/resources/EngagementTerms';

const formSchema = yup.object({
  text: yup
    .string()
    .max(MAX_DEFAULT_TEXTAREA_LENGTH, getMaxFieldLengthErrorMessage(MAX_DEFAULT_TEXTAREA_LENGTH))
    .default(''),
  isAcknowledgementStatementAccepted: yup.boolean().default(false).oneOf([true], ValidationError.mustAccepted),
});

export const getFormDefaultValues = (mode: 'edit' | 'create', term: EngagementTerm) => {
  return mode === 'create'
    ? formSchema.getDefault()
    : {
        text: term.text,
        isAcknowledgementStatementAccepted: false,
      };
};

export default formSchema;
