export enum EmploymentType {
  doOnSiteContract = 'On Site',
  doRemoteContract = 'Remote',
  doToHireContract = 'Hire',
}

export enum EmploymentTypeSearchLabel {
  doOnSiteContract = 'On Site Contract',
  doRemoteContract = 'Remote Contract',
  doToHireContract = 'I’m interested in Contract to Hire',
}
