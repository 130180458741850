import { omit, isNil } from 'ramda';
import * as yup from 'yup';
import { EditorState } from 'draft-js';

import WorkExperience from 'src/types/resources/WorkExperience';
import { ValidationError } from 'src/enums/Validation';
import { convertFromHtmlFormat, convertToHtmlFormat } from 'src/utils/textEditor';
import { convertDateToServerFormat } from 'src/utils/date';

import { GetMaxLengthTest } from '../customRules';

export const TEXT_SEPARATOR = '\n\n';

export type WorkExperienceFormData = {
  position: string;
  organizationName: string;
  startPeriodDate: string;
  endPeriodDate: string;
  responsibilities: EditorState;
  isPresent: boolean;
};

export type WorkExperienceFormDataToSubmit = Omit<WorkExperienceFormData, 'responsibilities' | 'isPresent'> & {
  responsibilities: string;
  afterId: number | null;
};

const emptyEditorState = EditorState.createEmpty();

export const validationFields = {
  position: yup.string().default('').required(ValidationError.default),
  organizationName: yup.string().default('').required(ValidationError.default),
  isPresent: yup.boolean().default(false),
  startPeriodDate: yup.string().default('').required(ValidationError.default).label('From'),
  endPeriodDate: yup
    .string()
    .default('')
    .when('isPresent', {
      is: (value: boolean) => value,
      then: yup.string(),
      otherwise: yup.string(),
    }),
  responsibilities: yup.mixed().test(GetMaxLengthTest(2500)).default(emptyEditorState),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (workExperience?: WorkExperience): WorkExperienceFormData => {
  if (isNil(workExperience)) {
    return validationSchema.getDefault();
  }

  const values = omit(['id', 'sortIndex', 'responsibilities', 'isPresent'], workExperience);
  const { endPeriodDate, responsibilities } = workExperience;

  const convertedResponsibilities = convertFromHtmlFormat(responsibilities);
  const endDate = endPeriodDate || '';
  const isPresent = !endPeriodDate;

  return {
    ...values,
    responsibilities: convertedResponsibilities,
    endPeriodDate: endDate,
    isPresent,
  };
};

export const attributesToSubmit = (values: WorkExperienceFormData): WorkExperienceFormDataToSubmit => {
  const { responsibilities, isPresent, startPeriodDate, endPeriodDate } = values;
  const updatedValues = omit(['responsibilities', 'isPresent'], values);

  const convertedStartDate = convertDateToServerFormat(new Date(startPeriodDate));
  const convertedEndDate = endPeriodDate ? convertDateToServerFormat(new Date(endPeriodDate)) : null;
  const convertedResponsibilities = convertToHtmlFormat(responsibilities);
  const endDate = isPresent ? null : convertedEndDate;

  return {
    ...updatedValues,
    startPeriodDate: convertedStartDate,
    endPeriodDate: endDate,
    responsibilities: convertedResponsibilities,
    afterId: null,
  };
};
