export enum ProfileDocument {
  multipanelDrugScreen = 'Multipanel Drug Screen',
  backgroundCheck = 'Background Check',
  fluShot = 'Flu shot',
  mmrVax = 'MMR',
  tuberculosisVax = 'TB',
  covid19Vax = 'COVID-19 Vaccination',
}

export enum DocumentStatus {
  uploaded = 'uploaded',
  notUploaded = 'notUploaded',
  expiresSoon = 'expiresSoon',
  expired = 'expired',
}

export enum DocumentStatusLabel {
  uploaded = 'Uploaded',
  notUploaded = 'Not uploaded',
  expiresSoon = 'Expires Soon',
  expired = 'Expired',
  accepted = 'Accepted',
  signAndReturn = 'Need to Sign & Return',
  readAndAccept = 'Need to Read & Accept',
}
