import { CircularProgress, MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';
import { isNil } from 'ramda';

import { ReactComponent as SelectArrow } from 'src/assets/icons/selectArrow.svg';
import { Department } from 'src/types/resources/Department';

import styles from './styles';

type AssignedDepartmentFieldProps = TextFieldProps & {
  departments: Department[];
  isContactRole: boolean;
  isDepartmentsLoadFinished: boolean;
  isAssignedDepartmentDisabled: boolean;
};

const AssignedDepartmentField: React.FC<AssignedDepartmentFieldProps> = props => {
  const { departments, isContactRole, isDepartmentsLoadFinished, isAssignedDepartmentDisabled, ...restProps } = props;

  const departmentSelectRenderValue = (value: unknown) => {
    if (isNil(value)) {
      const placeholder = isContactRole ? 'No Department' : 'Select Department';
      return <Typography variant="body2">{placeholder}</Typography>;
    }

    const currentDepartment = departments?.find(department => department.id === value);
    return (
      <Typography sx={styles.selectedMenuItem} variant="body2">
        {currentDepartment?.name}
      </Typography>
    );
  };

  return (
    <TextField
      id="departmentId"
      name="departmentId"
      label="Assigned Department"
      SelectProps={{
        IconComponent: isDepartmentsLoadFinished && SelectArrow,
        endAdornment: !isDepartmentsLoadFinished && <CircularProgress size={20} />,
        displayEmpty: true,
        renderValue: departmentSelectRenderValue,
      }}
      select
      disabled={!isDepartmentsLoadFinished || isAssignedDepartmentDisabled}
      {...restProps}
    >
      {isContactRole && (
        <MenuItem value={null}>
          <Typography variant="body2">No department</Typography>
        </MenuItem>
      )}
      {departments?.map(department => (
        <MenuItem key={department.id} value={department.id}>
          {department.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default AssignedDepartmentField;
