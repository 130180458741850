import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  link: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    marginTop: '10px',
    '& svg': {
      flexShrink: 0,
      width: '20px',
      height: '20px',
      marginRight: '8px',
      fill: 'currentColor',
    },
  },
};

export default styles;
