import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import Engagement, { ShortEngagement, TalentEngagement } from 'src/types/resources/Engagement';
import { SingleResponse, ListResponse } from 'src/types/utils';
import { EngagementStatus } from 'src/enums/Engagement';

export type ShowParams = {
  id: number;
};

export type AcceptParams = {
  id: number;
  params: TalentEngagement;
};

export type DeclineParams = {
  id: number;
  params: TalentEngagement;
};

export type IndexParams = {
  status?: EngagementStatus;
  page?: number;
  ordering?: string;
};

const defaultIndexParams = {
  status: null,
  page: 1,
  ordering: '',
} as IndexParams;

export default {
  index(params: IndexParams = defaultIndexParams): Promise<ListResponse<Engagement>> {
    const url = apiRoutes.talent.engagementsPath();
    return Fetcher.get(url, params);
  },
  short(params: IndexParams = defaultIndexParams): Promise<ListResponse<ShortEngagement>> {
    const url = apiRoutes.talent.engagementsShortPath();
    return Fetcher.get(url, params);
  },
  show({ id }: ShowParams): Promise<SingleResponse<TalentEngagement>> {
    const url = apiRoutes.talent.engagementPath(id);
    return Fetcher.get(url);
  },
  accept({ id, params }: AcceptParams): Promise<SingleResponse<TalentEngagement>> {
    const url = apiRoutes.talent.acceptEngagementPath(id);
    return Fetcher.post(url, params);
  },
  decline({ id, params }: DeclineParams): Promise<SingleResponse<TalentEngagement>> {
    const url = apiRoutes.talent.declineEngagementPath(id);
    return Fetcher.post(url, params);
  },
};
