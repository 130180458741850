import { Chat } from 'src/types/resources/Chat';
import { SingleResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type CreateParams = {
  talentId: ID;
};

export default {
  create(params: CreateParams): Promise<SingleResponse<Chat>> {
    const url = apiRoutes.manager.chatsPath();
    return Fetcher.post(url, params);
  },
};
