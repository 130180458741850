import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import { SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { Task } from 'src/types/resources/Task';
import AccountingRepository, { ExportParams } from 'src/repositories/superAdmin/AccountingRepository';

export type AccountingExportSliceStateType = {
  task: Task;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type AccountingExportSliceActionsType = {
  createAccountingExport: (params: ExportParams) => { unwrap: () => Promise<SingleResponse<Task>> };
};

const initialState: AccountingExportSliceStateType = {
  task: {} as Task,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const createAccountingExport = createAsyncAction('export/create', AccountingRepository.create);

const accountingExportSlice = createSlice({
  name: 'accountingExport',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createAccountingExport.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createAccountingExport.fulfilled, state => {
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createAccountingExport.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
  },
});

export default accountingExportSlice.reducer;
