import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import SpecializationRepository, {
  CreateParams,
  DeleteParams,
  UpdateParams,
} from 'src/repositories/talents/SpecializationsRepository';
import TalentSpecialization from 'src/types/resources/TalentSpecialization';
import { createAsyncAction } from 'src/utils/createAsyncAction';

export type SpecializationsSliceStateType = {
  specializations: TalentSpecialization[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type SpecializationsSliceActionsType = {
  loadSpecializations: () => { unwrap: () => void };
  createSpecialization: (params: CreateParams) => { unwrap: () => void };
  updateSpecialization: (params: UpdateParams) => { unwrap: () => void };
  deleteSpecialization: (params: DeleteParams) => { unwrap: () => void };
};

const initialState: SpecializationsSliceStateType = {
  specializations: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadSpecializations = createAsyncAction('talents/specializations/index', SpecializationRepository.index);
export const createSpecialization = createAsyncAction(
  'talents/specializations/create',
  SpecializationRepository.create,
);
export const updateSpecialization = createAsyncAction(
  'talents/specializations/update',
  SpecializationRepository.update,
);
export const deleteSpecialization = createAsyncAction(
  'talents/specializations/delete',
  SpecializationRepository.delete,
);

const SpecializationsSlice = createSlice({
  name: 'talents/specializations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSpecializations.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSpecializations.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.specializations = payload.results;
    });
    builder.addCase(loadSpecializations.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(deleteSpecialization.fulfilled, (state, { meta }) => {
      state.specializations = state.specializations.filter(spec => spec.id !== meta.arg.id);
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateSpecialization.fulfilled, (state, { payload }) => {
      state.specializations = state.specializations.map(spec => (spec.id === payload.data.id ? payload.data : spec));
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createSpecialization.fulfilled, (state, { payload }) => {
      state.specializations = state.specializations.concat([payload.data]);
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
  },
});

export default SpecializationsSlice.reducer;
