import { isEmpty, isNil } from 'ramda';

import { PayPeriodType } from 'src/enums/PayPeriod';
import { TimeSheetState } from 'src/enums/TimeSheet';
import EngagementTimeSheet from 'src/types/resources/EngagementTimeSheet';
import { NestedTimeSheet, TimeSheetDates, TimeSheetWithAdjustment } from 'src/types/resources/TimeSheet';
import { User } from 'src/types/resources/User';
import UsersPresenter from 'src/presenters/UsersPresenter';
import {
  addDays,
  subDays,
  lastDayOfMonth,
  formatTime,
  getDateByYear,
  endOfDay,
  isWithinInterval,
  parseISO,
  subMinutes,
} from 'src/utils/date';

export const FIRST_PAY_PERIOD_START_DATE = 1;
export const FIRST_PAY_PERIOD_END_DATE = 15;
export const SECOND_PAY_PERIOD_START_DATE = FIRST_PAY_PERIOD_END_DATE + 1;

export const TALENT_GRACE_PERIOD = 4;
export const ORGANIZATION_GRACE_PERIOD = 7;

type DatePeriod = {
  start: Date;
  end: Date;
};

const createDateInUtc = (...args: Parameters<typeof Date.UTC>) => new Date(Date.UTC(...args));

export const getPayPeriodByDate = (date: Date, calculateForUtc?: boolean): DatePeriod => {
  const dayOfMonth = calculateForUtc ? date.getUTCDate() : date.getDate();
  const month = calculateForUtc ? date.getUTCMonth() : date.getMonth();
  const year = calculateForUtc ? date.getUTCFullYear() : date.getFullYear();

  if (dayOfMonth <= FIRST_PAY_PERIOD_END_DATE) {
    // first half of the month
    return {
      start: new Date(year, month, FIRST_PAY_PERIOD_START_DATE),
      end: new Date(year, month, FIRST_PAY_PERIOD_END_DATE),
    };
  }

  // second half of the month
  return {
    start: new Date(year, month, FIRST_PAY_PERIOD_END_DATE + 1),
    end: lastDayOfMonth(new Date(year, month, dayOfMonth)),
  };
};

export const getPayPeriodForCurrentDay = (): DatePeriod => {
  return getPayPeriodByDate(new Date(), true);
};

export const getPreviousPayPeriod = (): { start: Date; end: Date } => {
  const { start } = getPayPeriodForCurrentDay();
  const end = subDays(start, 1);

  return getPayPeriodByDate(end);
};

const isGracePayPeriod = (graceDayCount: number): boolean => {
  const { start } = getPayPeriodForCurrentDay();
  const end = addDays(start, graceDayCount);

  const gracePeriodByUtc = {
    start: createDateInUtc(start.getFullYear(), start.getMonth(), start.getDate()),
    end: createDateInUtc(end.getFullYear(), end.getMonth(), end.getDate()),
  };

  return isWithinInterval(new Date(), gracePeriodByUtc);
};

const isTalentGracePayPeriod = (): boolean => isGracePayPeriod(TALENT_GRACE_PERIOD);
const isOrganizationGracePayPeriod = (): boolean => isGracePayPeriod(ORGANIZATION_GRACE_PERIOD);

export const isCurrentPayPeriod = (date: Date): boolean => {
  return isWithinInterval(date, getPayPeriodForCurrentDay());
};

export const isPreviousPayPeriod = (date: Date): boolean => isWithinInterval(date, getPreviousPayPeriod());

export const isUserGracePeriod = (currentUser: User): boolean => {
  let isNowGracePayPeriod = false;

  if (UsersPresenter.isOrganizationUser(currentUser)) isNowGracePayPeriod = isOrganizationGracePayPeriod();
  return isNowGracePayPeriod;
};

export const getEngagementTimeSheetsPeriod = (
  engagementTimeSheet: EngagementTimeSheet,
  payPeriodDate: Date,
): { startDate: Date; endDate: Date } => {
  const {
    startDate: engagementStartDate,
    endDate: engagementEndDate,
    terminatedByManagerAt: engagementTerminatedDate,
  } = engagementTimeSheet;
  const { start: payPeriodStartDate, end: payPeriodEndDate } = getPayPeriodByDate(payPeriodDate);
  const finalEngagementEndDate = isNil(engagementTerminatedDate) ? engagementEndDate : engagementTerminatedDate;

  const engagementTimeSheetPeriodStartDate = isWithinInterval(parseISO(engagementStartDate), {
    start: payPeriodStartDate,
    end: payPeriodEndDate,
  })
    ? parseISO(engagementStartDate)
    : payPeriodStartDate;

  const engagementTimeSheetPeriodEndDate = isWithinInterval(parseISO(finalEngagementEndDate), {
    start: payPeriodStartDate,
    end: payPeriodEndDate,
  })
    ? parseISO(finalEngagementEndDate)
    : payPeriodEndDate;

  return {
    startDate: engagementTimeSheetPeriodStartDate,
    endDate: engagementTimeSheetPeriodEndDate,
  };
};

export const createEmptyTimeSheetEntry = (timekeepingDate: string, index: number): TimeSheetWithAdjustment => {
  return {
    id: 1000 + index,
    state: TimeSheetState.open,
    timekeepingDate,
    hours: '0.00',
    talentComment: null,
    clientComment: null,
    cost: null,
    costTalentFeeIncluded: null,
    enteredAt: null,
    reviewedAt: null,
    reviewedBy: null,
    purchaseOrder: null,
    children: null,
    isAdjustive: false,
  };
};

export const createEmptyEngagementTimeSheetDates = (date: Date): TimeSheetDates => {
  const timekeepingDate = getDateByYear(date.toISOString());
  const isEditableTalentPayPeriod = isCurrentPayPeriod(date) || (isTalentGracePayPeriod() && isPreviousPayPeriod(date));

  return {
    id: date.getTime(),
    timekeepingDate,
    totalApprovedHours: null,
    totalAdjustedHours: null,
    timesheets: isEditableTalentPayPeriod ? [createEmptyTimeSheetEntry(timekeepingDate, 0)] : [],
  };
};

export const addIdToEngagementTimeSheetDates = (engagementTimeSheetDates: TimeSheetDates[]): TimeSheetDates[] => {
  return engagementTimeSheetDates.map(engagementTimeSheetDate => {
    return {
      id: new Date(engagementTimeSheetDate.timekeepingDate).getTime(),
      ...engagementTimeSheetDate,
    };
  });
};

export const getNextEmptyHoursInputElementIndex = (
  hoursInputElements: HTMLInputElement[],
  currentActiveIndex: number,
): number => {
  let nextActiveEmptyHoursElementIndex: number | null = null;

  hoursInputElements.forEach((hoursInputElement, index) => {
    const isNextActiveIndex =
      index > currentActiveIndex && isEmpty(hoursInputElement.value) && isNil(nextActiveEmptyHoursElementIndex);
    if (!isNextActiveIndex) return;
    nextActiveEmptyHoursElementIndex = index;
  });

  return nextActiveEmptyHoursElementIndex ?? -1;
};

export const getTimeSheetPendingHours = (timeSheets: NestedTimeSheet[]): string => {
  const totalPendingHours = timeSheets
    .filter(timeSheet => timeSheet.state === TimeSheetState.pending)
    .reduce((acc, item) => acc + Number(item.hours), 0);

  return formatTime(String(totalPendingHours), { padStartHours: 1 });
};

export const isOriginalTimeSheet = (timeSheet: NestedTimeSheet): boolean => {
  return !('costDelta' in timeSheet && 'hoursDelta' in timeSheet);
};

export const getPayPeriodType = (
  payPeriodType: PayPeriodType,
): { isCurrentType: boolean; isGraceType: boolean; isCloseType: boolean } => {
  const isCurrentType = payPeriodType === PayPeriodType.current;
  const isGraceType = payPeriodType === PayPeriodType.grace;
  const isCloseType = payPeriodType === PayPeriodType.close;

  return { isCurrentType, isGraceType, isCloseType };
};

export const getPayPeriodCountdownTimerEndDate = (
  selectedDate: Date,
  currentPayPeriod: { start: Date; end: Date },
  currentUser: User,
): Date => {
  const { start: startDate, end: endDate } = currentPayPeriod;
  const isSelectedCurrentPayPeriod = isCurrentPayPeriod(selectedDate);

  const isNowGracePayPeriod = isUserGracePeriod(currentUser) && isPreviousPayPeriod(selectedDate);

  let localEnd = endDate;

  if (UsersPresenter.hasTalentAccess(currentUser)) {
    if (isSelectedCurrentPayPeriod) localEnd = endOfDay(endDate);

    if (isNowGracePayPeriod) localEnd = endOfDay(addDays(startDate, TALENT_GRACE_PERIOD - 1));
  }

  if (UsersPresenter.isOrganizationUser(currentUser)) {
    if (isSelectedCurrentPayPeriod) localEnd = endOfDay(endDate);
    if (isNowGracePayPeriod) localEnd = endOfDay(addDays(startDate, ORGANIZATION_GRACE_PERIOD - 1));
  }

  return subMinutes(localEnd, localEnd.getTimezoneOffset());
};
