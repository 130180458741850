import { Typography, TypographyProps, Link as MuiLink } from '@mui/material';

import externalRoutes from 'src/routes/externalRoutes';
import clsx from 'src/utils/clsx';
import { useAppSelector } from 'src/hooks/useRedux';
import { UserRole } from 'src/enums/UserRole';

import styles from './styles';

type PhoneNumberCaptionType = TypographyProps & {
  component?: React.ElementType;
};

const PhoneNumberCaption: React.FC<PhoneNumberCaptionType> = props => {
  const { component = 'p', sx = {}, ...restProps } = props;

  const currentUserIsOrgUser = useAppSelector(state => {
    const userRole = state.users.currentUser?.role;
    return userRole === UserRole.OrganizationAdmin || userRole === UserRole.Manager;
  });

  const agreementLabel = currentUserIsOrgUser ? (
    'License Agreement'
  ) : (
    <MuiLink href={externalRoutes.revuud.termsAndConditions()} target="_blank" underline="hover" sx={styles.link}>
      Terms and Conditions
    </MuiLink>
  );

  return (
    <Typography variant="caption" component={component} sx={clsx(styles.root, [[sx, true]])} {...restProps}>
      By entering my phone number, I agree to receive recurring messages from Revuud to the provided mobile number and
      also agree to the Revuud {agreementLabel}. Message & data rates may apply. Notification preferences can be updated
      in your user profile. Reply STOP to cancel.
    </Typography>
  );
};

export default PhoneNumberCaption;
