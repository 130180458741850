import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { SingleResponse, ListResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { Department } from 'src/types/resources/Department';
import DepartmentsRepository, {
  IndexParams,
  CreateParams,
  UpdateParams,
} from 'src/repositories/superAdmin/DepartmentsRepository';

export type DepartmentsSliceStateType = {
  meta: Meta;
  departments: Department[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type DepartmentsSliceActionsType = {
  loadDepartments: (params: IndexParams) => { unwrap: () => Promise<ListResponse<Department>> };
  createDepartment: (params: CreateParams) => { unwrap: () => Promise<SingleResponse<Department>> };
  updateDepartment: (params: UpdateParams) => { unwrap: () => Promise<SingleResponse<Department>> };
};

const initialState: DepartmentsSliceStateType = {
  meta: {} as Meta,
  departments: null,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadDepartments = createAsyncAction('departments/index', DepartmentsRepository.index);
export const createDepartment = createAsyncAction('departments/create', DepartmentsRepository.create);
export const updateDepartment = createAsyncAction('departments/update', DepartmentsRepository.update);

const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDepartments.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadDepartments.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.departments = payload.results;
    });
    builder.addCase(loadDepartments.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
  },
});

export default departmentsSlice.reducer;
