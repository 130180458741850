import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useModals } from 'src/hooks';

import { FilterLabelsType, TimeTrackingDatePeriodType } from '../../types';

import styles from './styles';
import TimeTrackingModalFilterList from './TimeTrackingModalFilterList';
import { TimeTrackingApproveModalType, modalText } from './constants/TimeTrackingModalConstatants';

interface TimeTrackingBulkApproveModalProps {
  fullStartDate: string;
  fullEndDate: string;
  engagementsTotal: number;
  engagementTitle?: string;
  talentFullName?: string;
  date?: string;
  totalHours?: string;
  datePeriodType?: TimeTrackingDatePeriodType;
  filterLabels: FilterLabelsType;
  type: TimeTrackingApproveModalType;
  onConfirm: () => Promise<void>;
}

const DataItem = (props: { dataKey: string; value: string | number }) => {
  const { dataKey, value } = props;

  return (
    <Box display="flex">
      <Typography variant="body1" fontWeight="bold" mr="4px">
        {dataKey}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
};

export const TimeTrackingBulkApproveModalContent = (props: TimeTrackingBulkApproveModalProps) => {
  const {
    fullStartDate,
    fullEndDate,
    filterLabels,
    engagementsTotal,
    type,
    engagementTitle,
    date,
    totalHours,
    datePeriodType,
    talentFullName,
    onConfirm,
  } = props;
  const { hideModal } = useModals();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isEntireDay = type === TimeTrackingApproveModalType.ENTIRE_DAY;
  const isAllEngagement = type === TimeTrackingApproveModalType.ALL_ENGAGEMENTS;
  const isActionItem = type === TimeTrackingApproveModalType.ALL_ENGAGEMENTS_ACTION_ITEM;

  const confirm = async () => {
    setIsLoading(true);
    await onConfirm();
    hideModal();
    setIsLoading(false);
  };

  return (
    <Box sx={styles.modalWrapper}>
      <Box>
        <Typography variant="h3" mb={3}>
          {modalText.title}
        </Typography>

        <Box mb={3}>
          <Typography variant="body1">{modalText.upperText?.[type]}</Typography>
        </Box>

        <Box mb={3}>
          {isEntireDay && <DataItem dataKey="Date:" value={date} />}
          {!isEntireDay && !isActionItem && (
            <DataItem dataKey="Pay Period:" value={`${fullStartDate} - ${fullEndDate}`} />
          )}
          {isActionItem && <DataItem dataKey="Submitted Hours:" value={totalHours} />}
          {engagementsTotal > 0 && <DataItem dataKey="Engagements:" value={engagementsTotal} />}
          {!isAllEngagement && !isActionItem && (
            <DataItem dataKey="Engagement:" value={`${engagementTitle} - ${talentFullName || ''}`} />
          )}
          {isEntireDay && <DataItem dataKey="Total Hours:" value={totalHours} />}
        </Box>

        <Box mb={3}>
          <TimeTrackingModalFilterList filterLabels={filterLabels} modalType={type} />
        </Box>

        <Box mb={6}>
          {isActionItem ? (
            <Typography variant="body1">{modalText.actionItemText}</Typography>
          ) : (
            <Typography variant="body1">
              {modalText.lowerText} {datePeriodType === 'custom' && modalText.additionaLowerTextForAllEngCustomPeriod}
            </Typography>
          )}
        </Box>

        <Box sx={styles.buttons}>
          <LoadingButton sx={styles.button} loading={isLoading} variant="contained" onClick={confirm}>
            Approve
          </LoadingButton>
          <Button sx={styles.button} variant="outlined" onClick={() => hideModal()}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
