import { AxiosResponse } from 'axios';

import { ExternalLinkFormData } from 'src/forms/talents/externalLink';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import { ExternalLink } from 'src/types/resources/ExternalLink';

export type CreateParams = ExternalLinkFormData;
export type UpdateParams = {
  id: number;
  params: ExternalLinkFormData;
};
export type DeleteParams = {
  id: number;
};

export default {
  index(): Promise<{ results: ExternalLink[] }> {
    const url = apiRoutes.talent.externalLinksPath();
    return Fetcher.get(url);
  },
  create(params: CreateParams): Promise<AxiosResponse<ExternalLink>> {
    const url = apiRoutes.talent.externalLinksPath();
    return Fetcher.post(url, params);
  },
  update({ id, params }: UpdateParams): Promise<AxiosResponse> {
    const url = apiRoutes.talent.externalLinkPath(id);
    return Fetcher.patch(url, params);
  },
  delete({ id }: DeleteParams): Promise<AxiosResponse> {
    const url = apiRoutes.talent.externalLinkPath(id);
    return Fetcher.delete(url);
  },
};
