import {
  UsersSliceStateType,
  UsersSliceActionsType,
  loadCurrentUser,
  confirmUserEmail,
  destroyCurrentUser,
  confirmPassword,
  resetPassword,
  validatePassword,
  partialUpdateUser,
  forceTalentToInputHourlyRate,
} from 'src/store/usersSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { ConfirmParams, PartialUpdateParams } from 'src/repositories/UsersRepository';
import { FetchStatus } from 'src/enums/FetchStatus';
import { ConfirmPasswordFormDataToSubmit } from 'src/forms/confirmPassword';
import { ResetPasswordFormData } from 'src/forms/resetPassword';
import UserRolePresenter from 'src/presenters/UserRolePresenter';

type UseUsersType = Pick<
  UsersSliceStateType,
  'currentUser' | 'isLoggedOutOfTrackedViaPendoUserBefore' | 'showForceTalentHourlyRateModal'
> &
  UsersSliceActionsType & {
    isCurrentUserLoadFinished: boolean;
    isCurrentUserUpdatePending: boolean;
    isConfirmUserEmailFinished: boolean;
    isValidatePasswordFinished: boolean;
    isValidatePasswordFailed: boolean;
    isCurrentOrgRateIsZero: boolean;
    currentUserIsOrgAdmin: boolean;
    currentUserIsTalent: boolean;
    confirmFetchStatus: FetchStatus;
  };

const useUsers = (): UseUsersType => {
  const dispatch = useAppDispatch();
  const {
    currentUser,
    isLoggedOutOfTrackedViaPendoUserBefore,
    show: { fetchStatus: showFetchStatus },
    partialUpdate: { fetchStatus: partialUpdateFetchStatus },
    confirm: { fetchStatus: confirmFetchStatus },
    validatePassword: { fetchStatus: validatePasswordFetchStatus },
    showForceTalentHourlyRateModal,
  } = useAppSelector(state => state.users);

  const isCurrentUserLoadFinished = getFetchStatus(showFetchStatus).isFinished;
  const isCurrentUserUpdatePending = getFetchStatus(partialUpdateFetchStatus).isPending;
  const isConfirmUserEmailFinished = getFetchStatus(confirmFetchStatus).isFinished;
  const isValidatePasswordFinished = getFetchStatus(validatePasswordFetchStatus).isFinished;
  const isValidatePasswordFailed = getFetchStatus(validatePasswordFetchStatus).isFailed;
  const isCurrentOrgRateIsZero = currentUser?.organization?.rate === 0;

  const role = currentUser?.role;

  const currentUserIsOrgAdmin = UserRolePresenter.isOrganizationRole(role);
  const currentUserIsTalent = UserRolePresenter.isTalentRole(role);

  return {
    currentUser,
    isCurrentUserLoadFinished,
    isCurrentUserUpdatePending,
    isConfirmUserEmailFinished,
    isValidatePasswordFailed,
    isLoggedOutOfTrackedViaPendoUserBefore,
    isCurrentOrgRateIsZero,
    currentUserIsOrgAdmin,
    currentUserIsTalent,
    confirmFetchStatus,
    isValidatePasswordFinished,
    showForceTalentHourlyRateModal,
    confirmUserEmail: (params: ConfirmParams) => dispatch(confirmUserEmail(params)),
    loadCurrentUser: () => dispatch(loadCurrentUser()),
    confirmPassword: (params: ConfirmPasswordFormDataToSubmit) => dispatch(confirmPassword(params)),
    resetPassword: (params: ResetPasswordFormData) => dispatch(resetPassword(params)),
    validatePassword: (params: ConfirmParams) => dispatch(validatePassword(params)),
    partialUpdateUser: (params: PartialUpdateParams) => dispatch(partialUpdateUser(params)),
    forceTalentToInputHourlyRate: (force: boolean) => dispatch(forceTalentToInputHourlyRate(force)),
    destroyCurrentUser: () => dispatch(destroyCurrentUser()),
  };
};

export default useUsers;
