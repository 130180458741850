import {
  MessengerSliceActionsType,
  MessengerSliceStateType,
  setUnreadMessagesCount,
  setIsMessengerInstanceReady,
  resetChannelId,
  setChannelId,
  createTalentChat,
  createManagerChat,
  createSuperAdminChat,
} from 'src/store/messengerSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { UserRole } from 'src/enums/UserRole';

type UseMessengerType = Pick<
  MessengerSliceStateType,
  'channelId' | 'unreadMessagesCount' | 'isMessengerInstanceReady'
> &
  MessengerSliceActionsType & {
    isChatCreatePending: boolean;
  };

const ACTIONS_MAPPING = (id: ID) => ({
  [UserRole.Talent]: createTalentChat({ userId: id }),
  [UserRole.Manager]: createManagerChat({ talentId: id }),
  [UserRole.OrganizationAdmin]: createManagerChat({ talentId: id }),
  [UserRole.SuperAdmin]: createSuperAdminChat({ userId: id }),
});

const useMessenger = (): UseMessengerType => {
  const {
    channelId,
    unreadMessagesCount,
    isMessengerInstanceReady,
    create: { fetchStatus: chatCreateStatus },
  } = useAppSelector(state => state.messenger);
  const dispatch = useAppDispatch();

  const isChatCreatePending = getFetchStatus(chatCreateStatus).isPending;

  return {
    channelId,
    unreadMessagesCount,
    isMessengerInstanceReady,
    isChatCreatePending,
    setUnreadMessagesCount: (count: number) => dispatch(setUnreadMessagesCount(count)),
    setIsMessengerInstanceReady: (status: boolean) => dispatch(setIsMessengerInstanceReady(status)),
    createManagerChat: params => dispatch(createManagerChat(params)),
    createTalentChat: params => dispatch(createTalentChat(params)),
    createSuperAdminChat: params => dispatch(createSuperAdminChat(params)),
    createChat: params => dispatch(ACTIONS_MAPPING(params.id)[params.role]),
    resetChannelId: () => dispatch(resetChannelId()),
    setChannelId: (id: string) => dispatch(setChannelId(id)),
  };
};

export default useMessenger;
