import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import CertificateType from 'src/types/resources/CertificateType';
import clsx from 'src/utils/clsx';
import Box from 'src/components/Box';

import styles from './styles';

type EntryProps = {
  certificate: CertificateType;
  sx?: SxProps<Theme>;
};

const Entry: React.FC<EntryProps> = props => {
  const { certificate, sx = {} } = props;

  const boxStyles = clsx(styles.box, [[sx, true]]);

  return (
    <Box sx={boxStyles}>
      <img src={certificate.file} alt={certificate.schoolOrUniversity} />
    </Box>
  );
};

export default Entry;
