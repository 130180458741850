import {} from 'src/store/usersSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { loadSettings, selectSettingsTalentUsageFee, selectSettingsLoading } from 'src/store/settingsSlice';
import { SettingsParams } from 'src/types/settings';

type UseSettingsType = {
  talentUsageFee: number;
  isLoading: boolean;
  getSettings: (params: SettingsParams) => { unwrap: () => void };
};

const useSettings = (): UseSettingsType => {
  const dispatch = useAppDispatch();
  const talentUsageFee = useAppSelector(state => selectSettingsTalentUsageFee(state));
  const isLoading = useAppSelector(state => selectSettingsLoading(state));
  const getSettings = (params: SettingsParams) => dispatch(loadSettings(params));

  return {
    talentUsageFee,
    isLoading,
    getSettings,
  };
};

export default useSettings;
