import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiFormHelperTextType = {
  defaultProps: ComponentsProps['MuiFormHelperText'];
  styleOverrides: ComponentsOverrides['MuiFormHelperText'];
};

const MuiFormHelperText: MuiFormHelperTextType = {
  styleOverrides: {
    root: {
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  },
  defaultProps: {},
};

export default MuiFormHelperText;
