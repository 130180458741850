import { Typography, Button, Stack } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';

import { PhoneNumberFormData, initialValues, validationSchema, attributesToSubmit } from 'src/forms/phoneNumberForm';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useUsers from 'src/hooks/useUsers';
import useModals from 'src/hooks/useModals';
import Box from 'src/components/Box';
import PhoneNumberField from 'src/components/PhoneNumberField';

import styles from './styles';

const PhoneNumberForm: React.FC = () => {
  const { hideModal } = useModals();
  const { partialUpdateUser } = useUsers();

  const handleSubmit = async (formData: PhoneNumberFormData, { setErrors }: FormikHelpers<PhoneNumberFormData>) => {
    const params = attributesToSubmit(formData);
    try {
      await partialUpdateUser(params).unwrap();
      hideModal();
    } catch (error) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    handleSubmit: handleFormikSubmit,
  } = useFormik<PhoneNumberFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return (
    <Box sx={styles.root}>
      <form noValidate onSubmit={handleFormikSubmit}>
        <Typography variant="h3" marginBottom="16px">
          Phone Number
        </Typography>
        <Typography variant="body2" component="p" marginBottom="20px">
          To accept an engagement, a mobile phone number is required.
        </Typography>
        <Box sx={styles.row}>
          <PhoneNumberField
            id="phone"
            label="Mobile Phone Number"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
            autoFocus
            required
          />
        </Box>
        <Stack direction="row" gap="10px" marginTop="30px">
          <Button type="submit" variant="contained" sx={styles.button} disabled={!values.phone || isSubmitting}>
            Submit
          </Button>
          <Button variant="outlined" onClick={hideModal} sx={styles.button} disabled={isSubmitting}>
            Cancel
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default PhoneNumberForm;
