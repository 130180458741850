import { isEmpty, isNil } from 'ramda';

import EngagementTimeSheet from 'src/types/resources/EngagementTimeSheet';
import { formatCurrency } from 'src/utils/formatCurrency';

const EngagementTimeSheetPresenter = {
  hourlyFee: (engagementTimeSheet: EngagementTimeSheet, useTalentPayRate: boolean) => {
    const hourlyFee = useTalentPayRate ? engagementTimeSheet.talentPayRate : engagementTimeSheet.hourlyFee;
    return !isNil(hourlyFee) ? `${formatCurrency(parseFloat(hourlyFee))}/hr` : '-';
  },
  isEmptyPO: (engagementTimeSheet: EngagementTimeSheet): boolean => {
    return isEmpty(engagementTimeSheet.po);
  },
  isSinglePO: (engagementTimeSheet: EngagementTimeSheet): boolean => {
    return engagementTimeSheet.po.length === 1;
  },
  isMultiplePO: (engagementTimeSheet: EngagementTimeSheet): boolean => {
    return engagementTimeSheet.po.length > 1;
  },
};

export default EngagementTimeSheetPresenter;
