import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiMenuItemType = {
  defaultProps: ComponentsProps['MuiMenuItem'];
  styleOverrides: ComponentsOverrides['MuiMenuItem'];
};

const MuiMenuItem: MuiMenuItemType = {
  styleOverrides: {
    root: {
      padding: '8px 24px',
    },
  },
  defaultProps: {},
};

export default MuiMenuItem;
