import {
  EngagementsStateType,
  EngagementsActionsType,
  loadEngagements,
  loadShortEngagements,
  showEngagement,
  acceptEngagement,
  declineEngagement,
} from 'src/store/talents/engagementSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { ShowParams, AcceptParams, DeclineParams, IndexParams } from 'src/repositories/talents/EngagementsRepository';
import Engagement, { ShortEngagement } from 'src/types/resources/Engagement';

type UseEngagementsType = Pick<EngagementsStateType, 'engagements' | 'engagement' | 'meta'> &
  EngagementsActionsType & {
    isEngagementLoadFinished: boolean;
    isEngagementsLoadFinished: boolean;
    isEngagementsShortFinished: boolean;
    isEngagementAcceptPending: boolean;
    isEngagementDeclinePending: boolean;
    selectEngagementById: (id: number) => Engagement | ShortEngagement;
  };

const useEngagements = (): UseEngagementsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    engagements,
    engagement,
    index: { fetchStatus: engagementsIndexStatus },
    short: { fetchStatus: engagementsShortStatus },
    show: { fetchStatus: engagementShowFetchStatus },
    accept: { fetchStatus: engagementAcceptFetchStatus },
    decline: { fetchStatus: engagementDeclineFetchStatus },
  } = useAppSelector(state => state.talentsEngagements);

  const isEngagementLoadFinished = getFetchStatus(engagementShowFetchStatus).isFinished;
  const isEngagementsLoadFinished = getFetchStatus(engagementsIndexStatus).isFinished;
  const isEngagementsShortFinished = getFetchStatus(engagementsShortStatus).isFinished;
  const isEngagementAcceptPending = getFetchStatus(engagementAcceptFetchStatus).isPending;
  const isEngagementDeclinePending = getFetchStatus(engagementDeclineFetchStatus).isPending;

  const selectEngagementById = (engagementId: number) => {
    const selectedEngagement = engagements.find(({ id }) => id === engagementId);

    return selectedEngagement;
  };

  return {
    meta,
    engagements,
    engagement,
    isEngagementLoadFinished,
    isEngagementsLoadFinished,
    isEngagementsShortFinished,
    isEngagementAcceptPending,
    isEngagementDeclinePending,
    loadEngagements: (args?: IndexParams) => dispatch(loadEngagements(args)),
    loadShortEngagements: (args?: IndexParams) => dispatch(loadShortEngagements(args)),
    showEngagement: (args: ShowParams) => dispatch(showEngagement(args)),
    acceptEngagement: (args: AcceptParams) => dispatch(acceptEngagement(args)),
    declineEngagement: (args: DeclineParams) => dispatch(declineEngagement(args)),
    selectEngagementById,
  };
};

export default useEngagements;
