import {
  useSnackbar as useNotistackSnackbar,
  SnackbarMessage,
  OptionsObject,
  ProviderContext,
  SnackbarKey,
} from 'notistack';

const DEFAULT_SUCCESS_MESSAGE = 'Everything went as planned';
const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

type UseSnackbar = ProviderContext & {
  enqueueSuccessSnackbar: (message?: string) => SnackbarKey;
  enqueueErrorSnackbar: (message?: string, option?: OptionsObject) => SnackbarKey;
};

const useSnackbar = (): UseSnackbar => {
  const { enqueueSnackbar: enqueueNotistackSnackbar, ...restSnackbarParams } = useNotistackSnackbar();

  const enqueueSnackbar = (message: SnackbarMessage, options?: OptionsObject) => {
    return enqueueNotistackSnackbar({ message, ...options }, options);
  };

  const enqueueSuccessSnackbar = (message = DEFAULT_SUCCESS_MESSAGE) => {
    return enqueueSnackbar(message);
  };

  const enqueueErrorSnackbar = (message = DEFAULT_ERROR_MESSAGE, options: OptionsObject = {}) => {
    return enqueueSnackbar(message, { variant: 'error', ...options });
  };

  return { enqueueSnackbar, enqueueSuccessSnackbar, enqueueErrorSnackbar, ...restSnackbarParams };
};

export default useSnackbar;
