import useOrganization from 'src/hooks/organizationAdmin/useOrganization';
import OrganizationUsersRepository, {
  IndexParams,
} from 'src/repositories/organizationAdmin/OrganizationUsersRepository';
import { OrganizationUser } from 'src/types/resources/Organization';
import useDepartments from 'src/hooks/organizationAdmin/useDepartments';
import useOrganizationsUsers from 'src/hooks/organizationAdmin/useOrganizationsUsers';

import Form from '../Form';

type OrganizationAdminFormProps = {
  organizationId: number;
  user?: OrganizationUser;
  searchParams?: IndexParams;
};

const OrganizationAdminForm: React.FC<OrganizationAdminFormProps> = props => {
  const { organizationId, user, searchParams } = props;
  const { departments, isDepartmentsLoadFinished, loadDepartments } = useDepartments();
  const { loadOrganizationUsersCount } = useOrganization();
  const { createOrganizationUser, reassignOrganizationUser, loadOrganizationUsers, updateOrganizationUser } =
    useOrganizationsUsers();

  return (
    <Form
      organizationId={organizationId}
      user={user}
      createUser={createOrganizationUser}
      reassignUser={reassignOrganizationUser}
      updateUser={updateOrganizationUser}
      loadUsers={loadOrganizationUsers}
      loadUsersForReassign={OrganizationUsersRepository.index}
      loadOrganizationUsersCount={loadOrganizationUsersCount}
      departments={departments}
      isDepartmentsLoadFinished={isDepartmentsLoadFinished}
      loadDepartments={loadDepartments}
      searchParams={searchParams}
    />
  );
};

export default OrganizationAdminForm;
