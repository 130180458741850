import { PurchaseOrder } from 'src/types/resources/PurchaseOrders';
import { ListResponse, SingleResponse } from 'src/types/utils';
import { Organization } from 'src/types/resources/Organization';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  page?: number;
  isActive?: boolean;
};

export type POListRequestParams = IndexParams & {
  timesheetPayPeriodAfter?: string;
  timesheetPayPeriodBefore?: string;
  timesheetState?: string;
  engagementTalentIds?: string;
  engagementOwnerIds?: string;
  engagementDepartmentIds?: string;
};

export type CreateParams = Pick<
  PurchaseOrder,
  'name' | 'description' | 'notes' | 'isActive' | 'fileName' | 'originalFileName'
> & {
  organizationId: Organization['id'];
};

export type UpdateParams = {
  id: ID;
  params: CreateParams;
};

export type PartialUpdateParams = {
  id: ID;
  params: Pick<PurchaseOrder, 'isActive'>;
};

export default {
  async index(params: POListRequestParams): Promise<ListResponse<PurchaseOrder>> {
    const url = apiRoutes.organizationAdmin.purchaseOrderPath();
    return Fetcher.get(url, params);
  },
  create(params: CreateParams): Promise<SingleResponse<PurchaseOrder>> {
    const url = apiRoutes.organizationAdmin.purchaseOrderPath();
    return Fetcher.postFormData(url, params);
  },
  update({ id, params }: PartialUpdateParams): Promise<SingleResponse<PurchaseOrder>> {
    const url = apiRoutes.organizationAdmin.purchaseOrderPath(id);
    return Fetcher.putFormData(url, params);
  },
  partialUpdate({ id, params }: PartialUpdateParams): Promise<SingleResponse<PurchaseOrder>> {
    const url = apiRoutes.organizationAdmin.purchaseOrderPath(id);
    return Fetcher.patch(url, params);
  },
};
