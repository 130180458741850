import React, { useEffect, useState } from 'react';

type UseObjectURLType = {
  object: File | null;
  objectURL: string | null;
  setObject: React.Dispatch<React.SetStateAction<File>>;
  setObjectURL: React.Dispatch<React.SetStateAction<string>>;
};

const useObjectURL = (initialURL: string | null, initialObject: File | null): UseObjectURLType => {
  const [object, setObject] = useState<File | null>(initialObject);
  const [objectURL, setObjectURL] = useState<string | null>(initialURL);

  useEffect(() => {
    if (object) {
      const currentObjectURL = URL.createObjectURL(object);
      setObjectURL(currentObjectURL);
    }

    return () => {
      URL.revokeObjectURL(objectURL);
      setObjectURL(null);
    };
  }, [object]);

  useEffect(() => {
    if (initialURL) {
      setObjectURL(initialURL);
    }
  }, [initialURL]);

  return {
    object,
    objectURL,
    setObject,
    setObjectURL,
  };
};

export default useObjectURL;
