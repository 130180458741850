import { OrganizationUser } from 'src/types/resources/Organization';
import { UserRole } from 'src/enums/UserRole';
import useUsers from 'src/hooks/useUsers';

import SuperAdminForm from './components/SuperAdminForm';
import OrganizationAdminForm from './components/OrganizationAdminForm';

type ToggleOrgUserActivationFormProps = {
  user: OrganizationUser;
};

const ToggleOrgUserActivationForm: React.FC<ToggleOrgUserActivationFormProps> = props => {
  const { user } = props;
  const { currentUser } = useUsers();

  const formsByUserRole = {
    [UserRole.OrganizationAdmin]: <OrganizationAdminForm user={user} />,
    [UserRole.SuperAdmin]: <SuperAdminForm user={user} />,
  };

  return formsByUserRole[currentUser.role];
};

export default ToggleOrgUserActivationForm;
