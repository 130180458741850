import apiRoutes from 'src/routes/apiRoutes';
import TalentFilledDocument from 'src/types/resources/TalentFilledDocument';
import { ListResponse, SingleResponse } from 'src/types/utils';
import { RequiredDocument } from 'src/types/resources/RequiredDocument';
import Fetcher from 'src/utils/fetcher';

export type CreateParams = Partial<{
  engagementId: number;
  typeId: number;
  file: File | null;
  originalFileName: RequiredDocument['originalFileName'];
  isChecked: boolean;
}>;

export type UpdateParams = {
  id: number;
  params: CreateParams;
};

export default {
  index(): Promise<ListResponse<TalentFilledDocument[]>> {
    const url = apiRoutes.talent.filledDocumentsPath();
    return Fetcher.get(url);
  },
  create(params: CreateParams): Promise<SingleResponse<TalentFilledDocument>> {
    const url = apiRoutes.talent.filledDocumentsPath();
    return Fetcher.postFormData(url, params);
  },
  partialUpdate({ id, params }: UpdateParams): Promise<SingleResponse<TalentFilledDocument>> {
    const url = apiRoutes.talent.filledDocumentPath(id);
    return Fetcher.partialUpdateFormData(url, params);
  },
};
