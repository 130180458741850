import { path } from 'ramda';
import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';

import { HttpStatusCode } from 'src/enums/HttpStatusCode';

export const getErrorStatusCode = (error: unknown): number => path(['response', 'status'], error);

export const isBadRequest = (error: unknown): boolean => {
  return getErrorStatusCode(error) === HttpStatusCode.badRequest;
};

export const isUnauthorizedError = (error: AxiosError): boolean =>
  getErrorStatusCode(error) === HttpStatusCode.unauthorized;

export const isAccessDenied = (error: unknown): boolean => {
  return getErrorStatusCode(error) === HttpStatusCode.forbidden;
};

export const isNotFoundError = (error: AxiosError): boolean => getErrorStatusCode(error) === HttpStatusCode.notFound;

export const isUnprocessedEntityError = (error: AxiosError): boolean =>
  getErrorStatusCode(error) === HttpStatusCode.unprocessedEntity;

export const isTeaPotError = (error: AxiosError): boolean => getErrorStatusCode(error) === HttpStatusCode.teaPot;

export const isAxiosError = (error: unknown | AxiosError): error is AxiosError => axios.isAxiosError(error);

export const extractResponseErrors = (response: unknown): unknown => {
  return path(['response', 'data', 'errors'], response);
};

export const parseToFormikErrors = (errors: unknown): unknown => {
  const camelizedErrors = camelcaseKeys(errors);
  return Object.keys(camelizedErrors).reduce((acc, key) => ({ ...acc, [key]: camelizedErrors[key].join(', ') }), {});
};
