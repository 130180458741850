import { OrganizationUser } from 'src/types/resources/Organization';
import useUsers from 'src/hooks/useUsers';
import { UserRole } from 'src/enums/UserRole';

import SuperAdminForm from './components/SuperAdminForm';
import OrganizationAdminForm from './components/OrganizationAdminForm';

type ToggleOrgUserTSApprovalFormProps = {
  user: OrganizationUser;
};

const ToggleOrgUserTSApprovalForm: React.FC<ToggleOrgUserTSApprovalFormProps> = props => {
  const { user } = props;

  const { currentUser } = useUsers();

  const formsByUserRole = {
    [UserRole.OrganizationAdmin]: <OrganizationAdminForm user={user} />,
    [UserRole.SuperAdmin]: <SuperAdminForm user={user} />,
  };

  return formsByUserRole[currentUser.role];
};

export default ToggleOrgUserTSApprovalForm;
