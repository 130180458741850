import {
  OrganizationSliceStateType,
  OrganizationSliceActionsType,
  loadOrganizationUsersCount,
  loadOrganizationEngagementsCount,
  showOrganization,
  updateOrganization,
} from 'src/store/organizationAdmin/organizationSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import {
  LoadUsersCountParams,
  ShowParams,
  UpdateParams,
} from 'src/repositories/organizationAdmin/OrganizationRepository';

type UseOrganizationDetailsType = Pick<
  OrganizationSliceStateType,
  'meta' | 'organization' | 'organizationUsersCount' | 'organizationEngagementsCount' | 'departmentUsersCount'
> &
  OrganizationSliceActionsType & {
    isOrganizationLoadFinished: boolean;
    isOrganizationUsersCountLoadFinished: boolean;
    isOrganizationEngagementsCountLoadFinished: boolean;
  };

const useOrganization = (): UseOrganizationDetailsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    organization,
    organizationUsersCount,
    organizationEngagementsCount,
    departmentUsersCount,
    show: { fetchStatus: organizationShowFetchStatus },
    loadUsersCount: { fetchStatus: organizationsLoadUsersCountFetchStatus },
    loadEngagementsCount: { fetchStatus: organizationsLoadEngagementsCountFetchStatus },
  } = useAppSelector(state => state.organizationAdminOrganization);

  const isOrganizationLoadFinished = getFetchStatus(organizationShowFetchStatus).isFinished;
  const isOrganizationUsersCountLoadFinished = getFetchStatus(organizationsLoadUsersCountFetchStatus).isFinished;
  const isOrganizationEngagementsCountLoadFinished = getFetchStatus(
    organizationsLoadEngagementsCountFetchStatus,
  ).isFinished;

  return {
    meta,
    organization,
    organizationUsersCount,
    organizationEngagementsCount,
    departmentUsersCount,
    isOrganizationLoadFinished,
    isOrganizationUsersCountLoadFinished,
    isOrganizationEngagementsCountLoadFinished,
    loadOrganizationUsersCount: (params: LoadUsersCountParams) => dispatch(loadOrganizationUsersCount(params)),
    loadOrganizationEngagementsCount: (id: ID) => dispatch(loadOrganizationEngagementsCount(id)),
    showOrganization: (params: ShowParams) => dispatch(showOrganization(params)),
    updateOrganization: (params: UpdateParams) => dispatch(updateOrganization(params)),
  };
};

export default useOrganization;
