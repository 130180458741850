import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  box: {
    width: {
      mobile: '100%',
      tablet: '480px',
    },
    padding: '24px',
  },
  row: {
    margin: '16px 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '40px',
    flexWrap: 'wrap',
  },
  button: {
    width: {
      mobile: '100%',
      tablet: 'auto',
    },
    padding: '8px 48px',
  },
};

export default styles;
