import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { ReactComponent as SortIcon } from 'src/assets/icons/sortArrow.svg';
import { COLORS } from 'src/theme/palette';

type MuiTableSortLabelType = {
  defaultProps: ComponentsProps['MuiTableSortLabel'];
  styleOverrides: ComponentsOverrides['MuiTableSortLabel'];
};

const MuiTableSortLabel: MuiTableSortLabelType = {
  styleOverrides: {
    root: {
      color: COLORS.black40,
    },
    icon: {
      fill: COLORS.orange5,
      marginLeft: '10px',
      flexShrink: 0,
    },
  },
  defaultProps: {
    IconComponent: SortIcon,
  },
};

export default MuiTableSortLabel;
