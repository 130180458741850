import { Stack } from '@mui/material';

import { ReactComponent as AlertTriangleFilledIcon } from 'src/assets/images/alertTriangleFilledIcon.svg';
import clsx from 'src/utils/clsx';
import Box from 'src/components/Box';

import styles from './styles';

type AlertVariant = 'default' | 'error' | 'disabled';

type AlertProps = {
  variant?: AlertVariant;
  iconVariant?: AlertVariant;
};

const Alert: React.FC<AlertProps> = props => {
  const { variant = 'default', iconVariant, children } = props;
  const currentIconVariant = iconVariant ?? variant;

  return (
    <Stack sx={clsx(styles.root, [[styles[variant], true]])}>
      <Box sx={clsx(styles.icon, [[styles[`icon_${currentIconVariant}`], true]])}>
        <AlertTriangleFilledIcon />
      </Box>
      <Box sx={styles.content}>{children}</Box>
    </Stack>
  );
};

export default Alert;
