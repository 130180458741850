/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { ListResponse, SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import Engagement, { EngagementsListItemOrgSide, EngagementsListOrgSide } from 'src/types/resources/Engagement';
import { Meta } from 'src/types/meta';
import EngagementsRepository, {
  IndexParams,
  ShowParams,
  CreateParams,
  DeleteParams,
  UpdateParams,
  ValidateNameParams,
} from 'src/repositories/manager/EngagementsRepository';
import { User } from 'src/types/resources/User';
import { Department, ShortDepartment } from 'src/types/resources/Department';

export type EngagementSliceStateType = {
  meta: Meta;
  engagements: EngagementsListOrgSide;
  engagementTalents: User[];
  engagementOwners: User[];
  engagementDepartments: ShortDepartment[];
  engagement: Engagement;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  loadEngagementTalents: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  loadEngagementOwners: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  loadEngagementDepartments: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type EngagementsSliceActionsType = {
  loadEngagements: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<EngagementsListItemOrgSide>> };
  showEngagement: (params: ShowParams) => { unwrap: () => Promise<SingleResponse<Engagement>> };
  createEngagement: (params: CreateParams) => { unwrap: () => Promise<SingleResponse<Engagement>> };
  updateEngagement: (params: UpdateParams) => { unwrap: () => Promise<SingleResponse<Engagement>> };
  deleteEngagement: (params: DeleteParams) => { unwrap: () => Promise<void> };
  loadEngagementTalents: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<User>> };
  loadEngagementOwners: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<User>> };
  loadEngagementDepartments: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<Department>> };
  validateEngagementName: (params: ValidateNameParams) => { unwrap: () => Promise<SingleResponse<string>> };
  resetEngagement: () => void;
};

const initialState: EngagementSliceStateType = {
  meta: {} as Meta,
  engagements: [],
  engagementTalents: [],
  engagementOwners: [],
  engagementDepartments: [],
  engagement: {} as Engagement,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  loadEngagementTalents: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  loadEngagementOwners: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  loadEngagementDepartments: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadEngagements = createAsyncAction('managerEngagements/index', EngagementsRepository.index);
export const showEngagement = createAsyncAction('managerEngagements/show', EngagementsRepository.show);
export const createEngagement = createAsyncAction('managerEngagements/create', EngagementsRepository.create);
export const updateEngagement = createAsyncAction('managerEngagements/update', EngagementsRepository.update);
export const deleteEngagement = createAsyncAction('managerEngagements/delete', EngagementsRepository.delete);
export const loadEngagementTalents = createAsyncAction(
  'managerEngagements/loadEngagementTalents',
  EngagementsRepository.loadEngagementTalents,
);
export const loadEngagementOwners = createAsyncAction(
  'managerEngagements/loadEngagementOwners',
  EngagementsRepository.loadEngagementOwners,
);
export const loadEngagementDepartments = createAsyncAction(
  'managerEngagements/loadEngagementDepartments',
  EngagementsRepository.loadEngagementDepartments,
);
export const validateEngagementName = createAsyncAction(
  'managerEngagements/validateName',
  EngagementsRepository.validateEngagementName,
);

const EngagementsSlice = createSlice({
  name: 'organizationAdminEngagements',
  initialState,
  reducers: {
    resetEngagement(state) {
      state.show.fetchStatus = FetchStatus.idle;
      state.engagement = {} as Engagement;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadEngagements.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadEngagements.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.engagements = payload.results;
    });
    builder.addCase(loadEngagements.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(showEngagement.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showEngagement.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.engagement = payload.data;
    });
    builder.addCase(showEngagement.rejected, state => {
      state.show.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(deleteEngagement.fulfilled, (state, { meta }) => {
      state.engagements = state.engagements.filter(engagement => engagement.id !== meta.arg.id);
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });

    builder.addCase(loadEngagementTalents.pending, state => {
      state.loadEngagementTalents.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadEngagementTalents.fulfilled, (state, { payload }) => {
      state.loadEngagementTalents.fetchStatus = FetchStatus.fulfilled;
      state.engagementTalents = payload.results;
    });
    builder.addCase(loadEngagementTalents.rejected, state => {
      state.loadEngagementTalents.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadEngagementOwners.pending, state => {
      state.loadEngagementOwners.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadEngagementOwners.fulfilled, (state, { payload }) => {
      state.loadEngagementOwners.fetchStatus = FetchStatus.fulfilled;
      state.engagementOwners = payload.results;
    });
    builder.addCase(loadEngagementOwners.rejected, state => {
      state.loadEngagementOwners.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadEngagementDepartments.pending, state => {
      state.loadEngagementDepartments.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadEngagementDepartments.fulfilled, (state, { payload }) => {
      state.loadEngagementDepartments.fetchStatus = FetchStatus.fulfilled;
      state.engagementDepartments = payload.results;
    });
    builder.addCase(loadEngagementDepartments.rejected, state => {
      state.loadEngagementDepartments.fetchStatus = FetchStatus.failed;
    });
  },
});

const {
  actions: { resetEngagement },
} = EngagementsSlice;

export { resetEngagement };

export default EngagementsSlice.reducer;
