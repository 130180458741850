import { Typography, Button } from '@mui/material';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconSuccessConfirm } from 'src/assets/images/iconSuccessConfirm.svg';
import { ReactComponent as IconErrorConfirm } from 'src/assets/images/iconErrorConfirm.svg';
import getFetchStatus from 'src/utils/fetchStatus';
import useUsers from 'src/hooks/useUsers';
import Loader from 'src/components/Loader';
import Box from 'src/components/Box';

import styles from './styles';

type NewUserProps = {
  uuid: string;
  token: string;
};

const NewUser: React.FC<NewUserProps> = props => {
  const { uuid, token } = props;
  const history = useHistory();
  const { isConfirmUserEmailFinished, confirmUserEmail, confirmFetchStatus } = useUsers();

  useEffect(() => {
    confirmUserEmail({ uuid, token });
  }, []);

  if (!isConfirmUserEmailFinished) {
    return (
      <Box sx={styles.box}>
        <Loader />
      </Box>
    );
  }

  if (getFetchStatus(confirmFetchStatus).isFailed) {
    return (
      <Box sx={styles.box}>
        <Box sx={styles.iconBox}>
          <IconErrorConfirm className="iconError" />
        </Box>
        <Box sx={styles.contentBox}>
          <Typography variant="h3" component="p" mb={3}>
            An error has occurred. Please resend the confirmation email.
          </Typography>
          <Button variant="contained" sx={styles.button}>
            Resend
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={styles.box}>
      <Box sx={styles.iconBox}>
        <IconSuccessConfirm className="iconSuccess" />
      </Box>
      <Box sx={styles.contentBox}>
        <Typography variant="h3" component="p" sx={styles.title}>
          Your email address has been confirmed, thank you!
        </Typography>
        <Typography variant="body1" component="p" sx={styles.description}>
          Now you can log in using your credentials.
        </Typography>
        <Button variant="contained" onClick={() => history.push('/')} sx={styles.button}>
          Proceed
        </Button>
      </Box>
    </Box>
  );
};

export default NewUser;
