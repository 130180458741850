import { OrganizationUser } from 'src/types/resources/Organization';
import OrganizationsUsersRepository, { IndexParams } from 'src/repositories/superAdmin/OrganizationsUsersRepository';
import useOrganizations from 'src/hooks/superAdmin/useOrganizations';
import useDepartments from 'src/hooks/superAdmin/useDepartments';
import useOrganizationsUsers from 'src/hooks/superAdmin/useOrganizationsUsers';

import Form from '../Form';

type SuperAdminFormProps = {
  organizationId: number;
  user?: OrganizationUser;
  searchParams?: IndexParams;
};

const SuperAdminForm: React.FC<SuperAdminFormProps> = props => {
  const { organizationId, user, searchParams } = props;
  const { departments, isDepartmentsLoadFinished, loadDepartments } = useDepartments();
  const { loadOrganizationUsersCount } = useOrganizations();
  const { createOrganizationUser, reassignOrganizationUser, loadOrganizationUsers, updateOrganizationUser } =
    useOrganizationsUsers();

  return (
    <Form
      organizationId={organizationId}
      user={user}
      createUser={createOrganizationUser}
      reassignUser={reassignOrganizationUser}
      updateUser={updateOrganizationUser}
      loadUsers={loadOrganizationUsers}
      loadUsersForReassign={OrganizationsUsersRepository.index}
      loadOrganizationUsersCount={loadOrganizationUsersCount}
      departments={departments}
      isDepartmentsLoadFinished={isDepartmentsLoadFinished}
      loadDepartments={loadDepartments}
      searchParams={searchParams}
    />
  );
};

export default SuperAdminForm;
