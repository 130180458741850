import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  radioControlLabel: {
    maxWidth: '48%',
    width: '100%',
    wordBreak: 'break-all',
  },
};

export default styles;
