import { Typography, Link as MuiLink, LinkProps } from '@mui/material';

import Box from 'src/components/Box';
import { useLoading, useSnackbar } from 'src/hooks';
import UserRepository from 'src/repositories/UsersRepository';

import styles from './styles';

interface SentEmailConfirmationProps {
  userEmail?: string;
}

const SentEmailConfirmation: React.FC<SentEmailConfirmationProps> = props => {
  const { userEmail } = props;

  const { funcWithLoading: resendVerificationEmail, isPending: resendIsPending } = useLoading(
    UserRepository.resendVerificationEmail,
  );
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const handleClickTryAgain: LinkProps['onClick'] = async () => {
    try {
      if (resendIsPending) {
        return;
      }

      if (!userEmail) {
        throw new Error();
      }

      await resendVerificationEmail({ email: userEmail });
      enqueueSuccessSnackbar('An email was sent');
    } catch (error) {
      enqueueErrorSnackbar();
    }
  };

  return (
    <Box sx={styles.box}>
      <Typography variant="h3" component="p" mb={3}>
        Thanks for Registering on Revuud!
      </Typography>
      <Typography variant="button" component="p" mb={1}>
        An email has been sent to your email address for verification.
      </Typography>
      <Typography variant="body1" component="p" sx={styles.footer}>
        Did not receive an email?
        <MuiLink component="button" underline="hover" sx={styles.link} onClick={handleClickTryAgain}>
          Try again
        </MuiLink>
      </Typography>
    </Box>
  );
};

export default SentEmailConfirmation;
