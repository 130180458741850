import { SxStyles } from 'src/types/theme';
import { makeStyles } from 'src/utils/makeStyles';

const styles: SxStyles = makeStyles({
  wrapper: {
    padding: '24px 40px 24px',
    boxSizing: 'border-box',
    width: '530px',
    gap: '1.5em',
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  closeButton: {
    width: '40%',
    alignSelf: 'center',
  },
});

export default styles;
