import { SxStyles } from 'src/types/theme';
import { makeStyles } from 'src/utils/makeStyles';

const styles: SxStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75em',
    width: '512px',
    minHeight: '250px',
    padding: '24px',
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    marginTop: 'auto',
  },
  button: {
    padding: '7px 48px',
  },
});

export default styles;
