import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    padding: {
      tablet: '33px 40px 40px',
    },
    width: { chat: '800px' },
  },
  title: {
    boxShadow: 'inset 0px -1px 0px rgba(97, 148, 185, 0.25)',
    paddingBottom: '12px',
  },
  textarea: {
    margin: '43px 0',
    root: {
      padding: '8px 12px 13px',
    },
  },
  button: {
    padding: '7px 47px',
    marginRight: '10px',
  },
};

export default styles;
