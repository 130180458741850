import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    width: '100%',
    height: `100%`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
  },
};

export default styles;
