import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Typography, TextField, Button, Alert } from '@mui/material';
import { isNil, head } from 'ramda';

import { ReactComponent as DeleteIcon } from 'src/assets/images/deleteIcon.svg';
import { ModalLayout, ModalType } from 'src/enums/ModalType';
import useModals from 'src/hooks/useModals';
import useRouter from 'src/hooks/useRouter';
import useSnackbar from 'src/hooks/useSnackbar';
import useGroups from 'src/hooks/useGroups';
import useSubgroups from 'src/hooks/useSubgroups';
import appRoutes from 'src/routes/appRoutes';
import { Subgroup } from 'src/types/resources/Subgroup';
import { attributesToSubmit, initialValues, CreateSubgroupFormData, validationSchema } from 'src/forms/createSubgroup';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import Box from 'src/components/Box';

import styles from './styles';

type CreateSubgroupFormProps = {
  groupId: number;
  subgroup?: Subgroup;
};

const CreateSubgroupForm: React.FC<CreateSubgroupFormProps> = props => {
  const { groupId, subgroup } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { push } = useRouter();
  const { loadGroups } = useGroups();
  const { createSubgroup, updateSubgroup, deleteSubgroup, isSubgroupDeletePending, loadSubgroups, showSubgroup } =
    useSubgroups();
  const { showModal, hideModal } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();

  const isAddForm = isNil(subgroup);
  const formTitle = isAddForm ? 'Create Subgroup' : 'Edit Subgroup';
  const submitButtonText = isAddForm ? 'Create' : 'Save';

  const handleSubmit = async (
    formData: CreateSubgroupFormData,
    { setErrors }: FormikHelpers<CreateSubgroupFormData>,
  ) => {
    const params = attributesToSubmit(formData, groupId);
    setFormError(null);
    try {
      if (isAddForm) {
        await createSubgroup(params).unwrap();
        enqueueSuccessSnackbar('Subgroup was added');
        loadGroups().unwrap();
      } else {
        await updateSubgroup({ id: subgroup.id, params }).unwrap();
        enqueueSuccessSnackbar('Subgroup was edited');
        showSubgroup({ id: subgroup.id });
      }
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(error.response.data.errors.nonFieldErrors);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const handleSubgroupDelete = (subgroupId: number) => async () => {
    try {
      await deleteSubgroup({ id: subgroupId });
      loadSubgroups();
      hideModal();
      push(appRoutes.organizationAdmin.groupsPath());
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        setFormError(head(error.response.data.errors.nonFieldErrors));
      }
    }
  };

  const handleDeleteSubgroupModalShow = () => {
    showModal({
      type: ModalType.ConfirmationDialog,
      modalLayout: ModalLayout.talentModals,
      props: {
        description: 'You are about to delete a subgroup.',
        confirmButtonText: 'Delete',
        onConfirm: handleSubgroupDelete(subgroup.id),
      },
    });
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit: handleFormikSubmit,
  } = useFormik<CreateSubgroupFormData>({
    initialValues: initialValues(subgroup),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.wrapper}>
          <Typography variant="h3" sx={styles.title}>
            {formTitle}
          </Typography>

          <Box sx={styles.row}>
            <TextField
              id="name"
              label="Subgroup Name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </Box>

          <Box sx={styles.footer}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
              {submitButtonText}
            </Button>
            <Button variant="outlined" onClick={hideModal} sx={styles.button}>
              Cancel
            </Button>
            {!isAddForm && (
              <Button
                variant="text"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteSubgroupModalShow}
                sx={styles.buttonDelete}
                disabled={isSubgroupDeletePending}
              >
                Delete Subgroup
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </>
  );
};

export default CreateSubgroupForm;
