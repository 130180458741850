import { ManagerRequiredDocument } from 'src/types/resources/RequiredDocument';
import { ListResponse, SingleResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  organizationId?: number;
  page?: number;
  ordering?: string;
};

export type ShowParams = {
  id: number;
};

export default {
  index(params: IndexParams): Promise<ListResponse<ManagerRequiredDocument>> {
    const url = apiRoutes.superAdmin.requiredDocumentsPath();
    return Fetcher.get(url, params);
  },
  show({ id }: ShowParams): Promise<SingleResponse<ManagerRequiredDocument>> {
    const url = apiRoutes.superAdmin.requiredDocumentPath(id);
    return Fetcher.get(url);
  },
};
