import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    padding: { tablet: '24px 40px 0' },
    boxSizing: 'border-box',
    width: { tablet: '520px' },
    minHeight: '300px',
  },
  row: {
    marginTop: '23px',
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
  },
  cell: {
    marginRight: '14px',
    '&:last-of-type': {
      marginRight: '0',
    },
  },
  datePicker: {
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'row',
    flexWrap: 'nowrap',
  },
  buttons: {
    margin: '40px 0',
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 25%',
    marginTop: '35px',
    marginLeft: '14px',
  },
  datepickerWrapper: {
    maxWidth: '75%',
    flex: '1 0 75%',
  },
  datepickerField: {
    input: {
      cursor: 'pointer',
    },
  },
  disabledDatepickerField: {
    pointerEvents: 'none',
    cursor: 'default',
  },
};

export default styles;
