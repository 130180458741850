import { TimeTracking } from 'src/types/resources/TimeTracking';
import { ListResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  page?: number;
  ordering?: string;
  periodAfter?: string;
  periodBefore?: string;
};

export default {
  index(params: IndexParams = { page: 1, ordering: '' }): Promise<ListResponse<TimeTracking>> {
    const url = apiRoutes.superAdmin.timeTrackingPath();
    return Fetcher.get(url, params);
  },
};
