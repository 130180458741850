import {
  SpecializationsSliceStateType,
  SpecializationsSliceActionsType,
  loadSpecializations,
} from 'src/store/specializationsSlice';
import { IndexParams } from 'src/repositories/SpecializationsRepository';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';

type UseSpecializationsType = Pick<SpecializationsSliceStateType, 'specializations'> &
  SpecializationsSliceActionsType & {
    isSpecializationsLoadFinished: boolean;
  };

const useWorkExperiences = (): UseSpecializationsType => {
  const dispatch = useAppDispatch();
  const {
    specializations,
    index: { fetchStatus: loadFetchStatus },
  } = useAppSelector(state => state.specializations);

  const isSpecializationsLoadFinished = getFetchStatus(loadFetchStatus).isFinished;

  return {
    specializations,
    isSpecializationsLoadFinished,
    loadSpecializations: (params?: IndexParams) => dispatch(loadSpecializations(params)),
  };
};

export default useWorkExperiences;
