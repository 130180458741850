import { TextField, TextFieldProps, Typography, TypographyProps as MuiTypographyProps } from '@mui/material';
import { PatternFormat } from 'react-number-format';

import { getEnvironment } from 'src/utils/environment';
import { formatPhoneNumberForForm } from 'src/utils/phoneNumber';
import Box from 'src/components/Box';

import PhoneNumberCaption from './components/PhoneNumberCaption';
import styles from './styles';

type PhoneNumberFieldProps = Omit<TextFieldProps, 'ref' | 'type'> & {
  value: string;
  defaultValue?: string;
  displayType?: 'input' | 'text';
  isCaptionVisible?: boolean;
  TypographyProps?: MuiTypographyProps;
  CaptionTypographyProps?: MuiTypographyProps;
};

const PRODUCTION_FORMAT = '(###) ###-####';
const QA_FORMAT = '+# (###) ###-####';

const PRODUCTION_PLACEHOLDER = '(___) ___-____';
const QA_PLACEHOLDER = '+_ (___) ___-____';

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = props => {
  const {
    value,
    displayType = 'input',
    isCaptionVisible = true,
    TypographyProps = {},
    CaptionTypographyProps = {},
    ...restProps
  } = props;

  const isQaEnvironment = getEnvironment(process.env.REACT_APP_ENV).isQa;
  const isDisplayTypeInput = displayType === 'input';

  const renderText = (formattedValue: string) => {
    if (isDisplayTypeInput) {
      return null;
    }

    return (
      <Typography variant="body1" component="span" {...TypographyProps}>
        {formattedValue}
      </Typography>
    );
  };

  return (
    <Box>
      <PatternFormat
        {...restProps}
        type="tel"
        customInput={TextField}
        mask="_"
        value={isDisplayTypeInput ? value : formatPhoneNumberForForm(value)}
        format={isQaEnvironment ? QA_FORMAT : PRODUCTION_FORMAT}
        placeholder={isQaEnvironment ? QA_PLACEHOLDER : PRODUCTION_PLACEHOLDER}
        displayType={displayType}
        renderText={renderText}
        sx={styles.fieldInput}
      />
      {isDisplayTypeInput && isCaptionVisible && (
        <Box sx={styles.captionWrapper}>
          <PhoneNumberCaption {...CaptionTypographyProps} />
        </Box>
      )}
    </Box>
  );
};

export default PhoneNumberField;
