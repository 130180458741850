import { useState } from 'react';
import { Typography, Alert } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';

import { ShareState } from 'src/enums/ShareState';
import OrganizationUserPresenter from 'src/presenters/OrganizationUserPresenter';
import { attributesToSubmit, initialValues, ShareGroupFormData, validationSchema } from 'src/forms/shareGroup';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useModals from 'src/hooks/useModals';
import useSnackbar from 'src/hooks/useSnackbar';
import Box from 'src/components/Box';
import ShareGroupRepository from 'src/repositories/manager/ShareGroupRepository';

import ManagerSelect from './components/ManagerSelect';
import SubgroupSelect from './components/SubgroupSelect';
import styles from './styles';

type ShareGroupFormProps = {
  groupId: number;
};

const ShareGroupForm: React.FC<ShareGroupFormProps> = props => {
  const { groupId } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const [shareState, setShareState] = useState<ShareState>(ShareState.selectSubgroup);
  const { enqueueSuccessSnackbar } = useSnackbar();
  const { hideModal } = useModals();
  const isSelectSubgroupState = shareState === ShareState.selectSubgroup;
  const isSelectManagerState = shareState === ShareState.selectManager;

  const handleSubmit = async (values: ShareGroupFormData, { setErrors }: FormikHelpers<ShareGroupFormData>) => {
    setFormError(null);
    const params = attributesToSubmit(values);
    const managerNames = values.managers.map(manager => OrganizationUserPresenter.fullName(manager)).join(', ');
    try {
      await ShareGroupRepository.create(params);
      hideModal();
      enqueueSuccessSnackbar(`You shared the group with ${managerNames}`);
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(error.response.data.errors.nonFieldErrors);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const { isSubmitting, setFieldValue, submitForm } = useFormik<ShareGroupFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Box sx={styles.wrapper}>
      {formError && <Alert severity="error">{formError}</Alert>}
      <Box sx={styles.header}>
        <Typography variant="h3" sx={styles.title}>
          Share Group
        </Typography>
      </Box>
      {isSelectSubgroupState && (
        <SubgroupSelect
          groupId={groupId}
          setFieldValue={setFieldValue}
          onSubmit={() => {
            setShareState(ShareState.selectManager);
          }}
        />
      )}
      {isSelectManagerState && (
        <ManagerSelect isSubmitting={isSubmitting} setFieldValue={setFieldValue} onSubmit={() => submitForm()} />
      )}
    </Box>
  );
};

export default ShareGroupForm;
