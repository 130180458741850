import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiDialogType = {
  defaultProps: ComponentsProps['MuiDialog'];
  styleOverrides: ComponentsOverrides['MuiDialog'];
};

const MuiDialog: MuiDialogType = {
  styleOverrides: {
    container: {
      paddingTop: { tablet: '10px' },
      paddingBottom: { tablet: '10px' },
    },
    paper: {
      overflow: 'visible',
      margin: 0,
      borderRadius: '8px',
    },
    paperFullScreen: {
      transform: 'none',
      borderRadius: 0,
    },
  },
  defaultProps: {},
};

export default MuiDialog;
