import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    width: '520px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    marginTop: '24px',
  },
  rateWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  rateField: {
    width: '53px',
    marginLeft: '8px',
  },
  rateInput: {
    padding: '10px',
  },
  rateInputAdornment: {
    marginLeft: 0,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '40px',
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
};

export default styles;
