/* eslint-disable react/jsx-no-duplicate-props */
import { useState } from 'react';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { getPasswordRequirementMessage } from 'src/utils/password';

import styles from './styles';

type PasswordFieldProps = TextFieldProps & {
  isRequirementsVisible?: boolean;
  isTouched?: boolean;
  height?: string;
};

const PasswordField: React.FC<PasswordFieldProps> = props => {
  const { isRequirementsVisible, isTouched, helperText, onBlur, height, ...restProps } = props;
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isMessageVisible, setIsMessageVisible] = useState<boolean>(false);

  const fieldType = isPasswordVisible ? 'text' : 'password';
  const Icon = isPasswordVisible ? VisibilityIcon : VisibilityOffIcon;

  const currentHelperText =
    isRequirementsVisible && isMessageVisible && !isTouched ? getPasswordRequirementMessage() : helperText;

  const handlePasswordVisibleToggle = () => {
    setIsPasswordVisible(prevState => !prevState);
  };

  const handleFocus = () => {
    setIsMessageVisible(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    setIsMessageVisible(false);
    onBlur(event);
  };

  if (height) {
    // @ts-ignore
    styles.input.height = height;
  }

  return (
    <TextField
      type={fieldType}
      InputProps={{
        sx: styles.input,
        endAdornment: (
          <IconButton sx={styles.visibilityButton} onClick={handlePasswordVisibleToggle}>
            <Icon />
          </IconButton>
        ),
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      helperText={currentHelperText}
      {...restProps}
    />
  );
};

export default PasswordField;
