import { createSlice } from '@reduxjs/toolkit';
import { isNil } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { Organization, OrganizationEngagementsCount, OrganizationUserCount } from 'src/types/resources/Organization';
import OrganizationRepository, {
  LoadUsersCountParams,
  ShowParams,
  UpdateParams,
} from 'src/repositories/organizationAdmin/OrganizationRepository';

export type OrganizationSliceStateType = {
  meta: Meta;
  organization: Organization;
  organizationUsersCount: OrganizationUserCount;
  organizationEngagementsCount: OrganizationEngagementsCount;
  departmentUsersCount: Record<number, OrganizationUserCount>;
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  loadUsersCount: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  loadEngagementsCount: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type OrganizationSliceActionsType = {
  showOrganization: (params: ShowParams) => { unwrap: () => void };
  updateOrganization: (params: UpdateParams) => { unwrap: () => void };
  loadOrganizationUsersCount: (params: LoadUsersCountParams) => {
    unwrap: () => Promise<SingleResponse<OrganizationUserCount>>;
  };
  loadOrganizationEngagementsCount: (id: ID) => {
    unwrap: () => Promise<SingleResponse<OrganizationEngagementsCount>>;
  };
};

const initialState: OrganizationSliceStateType = {
  meta: {} as Meta,
  organization: null,
  organizationUsersCount: {} as OrganizationUserCount,
  organizationEngagementsCount: {} as OrganizationEngagementsCount,
  departmentUsersCount: {},
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  loadUsersCount: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  loadEngagementsCount: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const showOrganization = createAsyncAction('organizationAdmin/organization/show', OrganizationRepository.show);
export const updateOrganization = createAsyncAction(
  'organizationAdmin/organization/update',
  OrganizationRepository.update,
);
export const loadOrganizationUsersCount = createAsyncAction(
  'organizationAdmin/organization/loadUsersCount',
  OrganizationRepository.loadUsersCount,
);
export const loadOrganizationEngagementsCount = createAsyncAction(
  'organizationAdmin/organization/loadEngagementsCount',
  OrganizationRepository.loadEngagementsCount,
);

const organizationSlice = createSlice({
  name: 'organizationAdmin/organization',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(showOrganization.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showOrganization.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.organization = payload.data;
    });
    builder.addCase(showOrganization.rejected, state => {
      state.show.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(updateOrganization.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateOrganization.fulfilled, state => {
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateOrganization.rejected, state => {
      state.update.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadOrganizationUsersCount.pending, state => {
      state.loadUsersCount.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadOrganizationUsersCount.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const departmentId = meta.arg?.params?.departmentId;
      const isDepartmentsUsersCount = !isNil(departmentId);

      state.loadUsersCount.fetchStatus = FetchStatus.fulfilled;
      if (isDepartmentsUsersCount) {
        state.departmentUsersCount[departmentId] = payload.data;
      } else {
        state.organizationUsersCount = payload.data;
      }
    });
    builder.addCase(loadOrganizationUsersCount.rejected, state => {
      state.loadUsersCount.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(loadOrganizationEngagementsCount.pending, state => {
      state.loadEngagementsCount.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadOrganizationEngagementsCount.fulfilled, (state, action) => {
      state.loadEngagementsCount.fetchStatus = FetchStatus.fulfilled;
      state.organizationEngagementsCount = action.payload.data;
    });
    builder.addCase(loadOrganizationEngagementsCount.rejected, state => {
      state.loadEngagementsCount.fetchStatus = FetchStatus.failed;
    });
  },
});

export default organizationSlice.reducer;
