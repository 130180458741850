import * as yup from 'yup';

import { Department } from 'src/types/resources/Department';
import TalentSpecialization from 'src/types/resources/TalentSpecialization';
import { ONE_OF_CHECKBOXES_TRUE } from 'src/forms/customRules';

type FormSpecialization = {
  name: string;
  value: boolean;
};

export type ManageSpecializationsFormData = {
  specializations: Record<number, FormSpecialization>;
};

export type ManageSpecializationsFormDataToSubmit = Partial<
  Omit<Department, 'specializations'> & {
    specializations: number[];
  }
>;

export const validationFields = {
  specializations: yup
    .object()
    .transform(currentValue => {
      return Object.values<FormSpecialization>(currentValue).reduce(
        (acc, specialization) => ({
          ...acc,
          [specialization.name]: specialization.value,
        }),
        {} as FormSpecialization,
      );
    })
    .test(ONE_OF_CHECKBOXES_TRUE),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (
  department: Department,
  specializations?: TalentSpecialization[],
): ManageSpecializationsFormData => {
  const selectedSpecializations =
    department.specializations?.reduce((acc, specialization) => {
      return { ...acc, [specialization.id]: true };
    }, {}) || {};

  return {
    specializations: specializations.reduce((acc, specialization) => {
      return {
        ...acc,
        [specialization.id]: { name: specialization.name, value: Boolean(selectedSpecializations[specialization.id]) },
      };
    }, {}),
  };
};

export const attributesToSubmit = (values: ManageSpecializationsFormData): ManageSpecializationsFormDataToSubmit => {
  return {
    specializations: Object.entries(values.specializations)
      .filter(([, specialization]) => Boolean(specialization.value))
      .map(([specializationId]) => Number(specializationId)),
  };
};
