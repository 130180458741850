import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { OrganizationUser } from 'src/types/resources/Organization';
import OrganizationsUsersRepository, { IndexParams } from 'src/repositories/manager/OrganizationUsersRepository';

export type OrganizationUsersSliceStateType = {
  meta: Meta;
  organizationUsers: OrganizationUser[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type OrganizationsSliceActionsType = {
  loadOrganizationUsers: (params?: IndexParams) => Promise<unknown>;
};

const initialState: OrganizationUsersSliceStateType = {
  meta: {} as Meta,
  organizationUsers: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadOrganizationUsers = createAsyncAction('organizations/users/index', OrganizationsUsersRepository.index);

const organizationsSlice = createSlice({
  name: 'organizations/users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadOrganizationUsers.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadOrganizationUsers.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.organizationUsers = payload.results;
    });
    builder.addCase(loadOrganizationUsers.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
  },
});

export default organizationsSlice.reducer;
