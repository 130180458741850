import { CreateDepartmentFormDataToSubmit } from 'src/forms/superAdmin/createDepartment';
import { Department } from 'src/types/resources/Department';
import { SingleResponse, ListResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  page?: number;
  ordering?: string;
  organizationId?: number;
};

export type CreateParams = CreateDepartmentFormDataToSubmit;

export type UpdateParams = {
  id: number;
  params: Partial<
    Omit<Department, 'specializations'> & {
      specializations: number[];
    }
  >;
};

export default {
  index(params: IndexParams = { page: 1, ordering: '' }): Promise<ListResponse<Department>> {
    const url = apiRoutes.superAdmin.departmentsPath();
    return Fetcher.get(url, params);
  },
  create(params: CreateParams): Promise<SingleResponse<Department>> {
    const url = apiRoutes.superAdmin.departmentsPath();
    return Fetcher.post(url, params);
  },
  update({ id, params }: UpdateParams): Promise<SingleResponse<Department>> {
    const url = apiRoutes.superAdmin.departmentPath(id);
    return Fetcher.patch(url, params);
  },
};
