import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiToggleButtonGroupType = {
  defaultProps: ComponentsProps['MuiToggleButtonGroup'];
  styleOverrides: ComponentsOverrides['MuiToggleButtonGroup'];
};

const MuiToggleButtonGroup: MuiToggleButtonGroupType = {
  styleOverrides: {
    root: {
      flexWrap: 'wrap',
      '& .MuiToggleButtonGroup-grouped': {
        marginRight: '8px',
        marginBottom: '8px',
        borderColor: 'transparent',
        '&:hover, &:focus': {
          borderColor: COLORS.orange9,
        },
        '&:not(:first-of-type)': {
          marginLeft: 0,
          borderRadius: 'inherit',
        },
        '&:not(:last-of-type)': {
          marginLeft: 0,
          borderRadius: 'inherit',
        },
      },
    },
  },
  defaultProps: {},
};

export default MuiToggleButtonGroup;
