import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiFormControlType = {
  defaultProps: ComponentsProps['MuiFormControl'];
  styleOverrides: ComponentsOverrides['MuiFormControl'];
};

const MuiFormControl: MuiFormControlType = {
  styleOverrides: {
    root: {
      width: '100%',
      marginRight: '16px',
      '&&:last-child': {
        marginRight: 0,
      },
      '& .MuiInputLabel-root + .MuiInput-root .MuiInput-input::placeholder': {
        opacity: '0.6 !important',
      },
    },
  },
  defaultProps: {},
};

export default MuiFormControl;
