import { alpha } from '@mui/system';

import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  listTitle: {
    color: COLORS.black100,
    fontSize: '1em',
    fontWeight: 600,
    marginBottom: '5px',
    padding: '0 24px',
  },
  list: {
    overflow: 'hidden auto',
  },
  listItem: {
    position: 'relative',
    paddingLeft: '24px',
    paddingRight: '24px',
    '&.Mui-selected': {
      backgroundColor: COLORS.orange11,
    },
    '&.Mui-selected::after': {
      display: 'block',
    },
    '& .MuiListItemSecondaryAction-root': {
      right: '24px',
    },
    '&:hover': {
      backgroundColor: alpha(COLORS.orange11, 0.5),
    },
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  noResults: {
    padding: '0 24px',
    color: COLORS.black40,
  },
  selectedListItem: {
    backgroundColor: COLORS.orange11,
    '&:hover': {
      backgroundColor: COLORS.orange11,
    },
  },
};

export default styles;
