import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import AvailabilitiesRepository, {
  ShowParams,
  CreateParams,
  DeleteParams,
  UpdateParams,
} from 'src/repositories/talents/AvailabilitiesRepository';
import { Meta } from 'src/types/meta';
import Availability from 'src/types/resources/Availability';
import { createAsyncAction } from 'src/utils/createAsyncAction';

export type AvailabilitiesSliceStateType = {
  availabilities: Availability[];
  availability: Availability;
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type AvailabilitiesSliceActionsType = {
  loadAvailabilities: () => { unwrap: () => ReturnType<typeof AvailabilitiesRepository.index> };
  showAvailability: (params: ShowParams) => { unwrap: () => ReturnType<typeof AvailabilitiesRepository.show> };
  createAvailability: (params: CreateParams) => { unwrap: () => ReturnType<typeof AvailabilitiesRepository.create> };
  updateAvailability: (params: UpdateParams) => { unwrap: () => ReturnType<typeof AvailabilitiesRepository.update> };
  deleteAvailability: (params: DeleteParams) => { unwrap: () => ReturnType<typeof AvailabilitiesRepository.delete> };
};

const initialState: AvailabilitiesSliceStateType = {
  availabilities: [],
  availability: {} as Availability,
  meta: {} as Meta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadAvailabilities = createAsyncAction('talents/availabilities/index', AvailabilitiesRepository.index);
export const showAvailability = createAsyncAction('talents/availabilities/show', AvailabilitiesRepository.show);
export const createAvailability = createAsyncAction('talents/availabilities/create', AvailabilitiesRepository.create);
export const updateAvailability = createAsyncAction('talents/availabilities/update', AvailabilitiesRepository.update);
export const deleteAvailability = createAsyncAction('talents/availabilities/delete', AvailabilitiesRepository.delete);

const availabilitiesSlice = createSlice({
  name: 'talents/availabilities',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadAvailabilities.pending, state => {
      state.index.error = null;
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadAvailabilities.fulfilled, (state, { payload }) => {
      const { count, next, previous, results } = payload;
      state.availabilities = results;
      state.meta = { count, next, previous };
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadAvailabilities.rejected, (state, { error }) => {
      state.index.error = error;
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(showAvailability.pending, state => {
      state.show.error = null;
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showAvailability.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.availability = payload.data;
    });
    builder.addCase(showAvailability.rejected, (state, { error }) => {
      state.show.error = error;
      state.show.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(createAvailability.pending, state => {
      state.create.error = null;
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createAvailability.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createAvailability.rejected, (state, { error }) => {
      state.create.error = error;
      state.create.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(updateAvailability.pending, state => {
      state.update.error = null;
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateAvailability.fulfilled, state => {
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateAvailability.rejected, (state, { error }) => {
      state.update.error = error;
      state.update.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(deleteAvailability.pending, state => {
      state.delete.error = null;
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteAvailability.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteAvailability.rejected, (state, { error }) => {
      state.delete.error = error;
      state.delete.fetchStatus = FetchStatus.failed;
    });
  },
});

export default availabilitiesSlice.reducer;
