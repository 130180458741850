import { CreateSubgroupFormData } from 'src/forms/createSubgroup';
import { GroupFormData } from 'src/forms/organizationGroup';
import { Subgroup } from 'src/types/resources/Subgroup';
import apiRoutes from 'src/routes/apiRoutes';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  groupId: number;
  page?: number;
  name?: string;
};

export type ShowParams = {
  id: number;
};

export type UpdateParams = {
  id: number;
  params: GroupFormData;
};

export type DeleteParams = {
  id: number;
};

export default {
  index(params: IndexParams): Promise<ListResponse<Subgroup>> {
    const url = apiRoutes.manager.subgroupsPath();
    return Fetcher.get(url, {
      page: 1,
      ...params,
    });
  },
  show({ id }: ShowParams): Promise<SingleResponse<Subgroup>> {
    const url = apiRoutes.manager.subgroupPath(id);
    return Fetcher.get(url);
  },
  create(params: CreateSubgroupFormData): Promise<SingleResponse<Subgroup>> {
    const url = apiRoutes.manager.subgroupsPath();
    return Fetcher.post(url, params);
  },
  update({ id, params }: UpdateParams): Promise<SingleResponse<Subgroup>> {
    const url = apiRoutes.manager.subgroupPath(id);
    return Fetcher.patch(url, params);
  },
  delete({ id }: DeleteParams): Promise<void> {
    const url = apiRoutes.manager.subgroupPath(id);
    return Fetcher.delete(url);
  },
};
