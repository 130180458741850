import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Alert, Button, Typography } from '@mui/material';
import { head, isNil } from 'ramda';

import { attributesToSubmit, initialValues, SpecializationFormData } from 'src/forms/talents/specialization';
import { isAxiosError, isUnprocessedEntityError } from 'src/utils/responseErrors';
import { sortByIds } from 'src/utils/keysConverter';
import { ExpertiseType } from 'src/enums/Specialization';
import CommonSpecialization from 'src/types/resources/Specialization';
import TalentSpecialization from 'src/types/resources/TalentSpecialization';
import SpecializationPresenter from 'src/presenters/SpecializationPresenter';
import useTalentSpecializations from 'src/hooks/talents/useSpecializations';
import useModals from 'src/hooks/useModals';
import ToggleTagGroup from 'src/components/Talents/Specialization/components/ToggleTagGroup';
import Loader from 'src/components/Loader';
import Box from 'src/components/Box';

import styles from './styles';

type FormProps = {
  commonSpecialization?: CommonSpecialization;
  talentSpecialization?: TalentSpecialization;
  expertiseLevel: ExpertiseType;
};

const FinalForm: React.FC<FormProps> = props => {
  const { commonSpecialization, talentSpecialization, expertiseLevel } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const [currentSpecialization, setCurrentSpecialization] = useState<CommonSpecialization | null>(null);
  const { hideModal } = useModals();
  const { createSpecialization, updateSpecialization } = useTalentSpecializations();

  const isAddForm = isNil(talentSpecialization);
  const submitButtonText = isAddForm ? 'Add' : 'Save';
  const currentSpecializationName = commonSpecialization?.name || '';

  const handleSubmit = async (formData: SpecializationFormData) => {
    const params = attributesToSubmit(formData, commonSpecialization.id);
    setFormError(null);
    try {
      if (isAddForm) {
        await createSpecialization(params).unwrap();
      } else {
        await updateSpecialization({ id: talentSpecialization.id, params }).unwrap();
      }
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        setFormError(head(error.response.data.errors.nonFieldErrors));
      }
    }
  };

  const {
    values,
    isSubmitting,
    handleSubmit: handleFormikSubmit,
    setFieldValue,
  } = useFormik<SpecializationFormData>({
    initialValues: initialValues(commonSpecialization, talentSpecialization, expertiseLevel),
    onSubmit: handleSubmit,
  });

  const handleChangeToggle = (fieldName: string) => {
    return async <T,>(event: React.MouseEvent<HTMLElement>, newValue: T) => {
      await setFieldValue(fieldName, newValue);
    };
  };

  useEffect(() => {
    setCurrentSpecialization(() => {
      return {
        ...commonSpecialization,
        roles: sortByIds(commonSpecialization.roles, values.roles),
        skills: sortByIds(commonSpecialization.skills, values.skills),
      };
    });
  }, []);

  if (isNil(currentSpecialization)) {
    return <Loader />;
  }

  const isRolesPresent = SpecializationPresenter.isRolesPresent(currentSpecialization);
  const isSkillsPresent = SpecializationPresenter.isSkillsPresent(currentSpecialization);

  return (
    <>
      {formError && (
        <Alert sx={styles.error} severity="error">
          {formError}
        </Alert>
      )}
      <form onSubmit={handleFormikSubmit}>
        <Box sx={styles.form}>
          <Typography sx={styles.title} variant="h3">
            {`Choose ${currentSpecializationName} Skills`}
          </Typography>

          {isRolesPresent && (
            <Box sx={styles.row}>
              <Typography sx={styles.label} variant="caption" component="p">
                {`Roles within ${currentSpecializationName}`}
              </Typography>
              <ToggleTagGroup
                tags={currentSpecialization?.roles}
                value={values.roles}
                onChange={handleChangeToggle('roles')}
              />
            </Box>
          )}

          {isSkillsPresent && (
            <Box sx={styles.row}>
              <Typography sx={styles.label} variant="caption" component="p">
                Module skills
              </Typography>
              <ToggleTagGroup
                tags={currentSpecialization?.skills}
                value={values.skills}
                onChange={handleChangeToggle('skills')}
              />
            </Box>
          )}

          <Box sx={styles.row}>
            <Box sx={styles.buttons}>
              <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
                {submitButtonText}
              </Button>
              <Button variant="outlined" onClick={hideModal} sx={styles.button}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default FinalForm;
