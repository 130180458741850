import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiDialogTitleType = {
  defaultProps: ComponentsProps['MuiDialogTitle'];
  styleOverrides: ComponentsOverrides['MuiDialogTitle'];
};

const MuiDialogTitle: MuiDialogTitleType = {
  styleOverrides: {
    root: {
      // the same as styles for h3
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '32px',
      letterSpacing: '-0.022em',
      textTransform: 'initial',
    },
  },
  defaultProps: {},
};

export default MuiDialogTitle;
