import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { SingleResponse } from 'src/types/utils';
import { EngagementPOCreatedOrSaved, EngagementPO } from 'src/types/resources/EngagementPO';
import EngagementPORepository, {
  CreateParams,
  PartialUpdateParams,
} from 'src/repositories/manager/EngagementPORepository';
import { createAsyncAction } from 'src/utils/createAsyncAction';

export type EngagementPOsSliceStateType = {
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  partialUpdate: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  currentEngagementPOList: EngagementPOCreatedOrSaved[];
};

export type EngagementPOsSliceActionsType = {
  createEngagementPO: (params: CreateParams) => { unwrap: () => Promise<SingleResponse<EngagementPO>> };
  partialUpdateEngagementPO: (params: PartialUpdateParams) => { unwrap: () => Promise<SingleResponse<EngagementPO>> };
  setCurrentEngagementPOList: (params: EngagementPOCreatedOrSaved[]) => void;
  clearCurrentEngagementPOList: () => void;
};

const initialState: EngagementPOsSliceStateType = {
  meta: {} as Meta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  partialUpdate: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  currentEngagementPOList: [],
};

export const createEngagementPO = createAsyncAction('manager/engagementPOs/create', EngagementPORepository.create);
export const partialUpdateEngagementPO = createAsyncAction(
  'manager/engagementPOs/partialUpdate',
  EngagementPORepository.partialUpdate,
);

const EngagementPOsSlice = createSlice({
  name: 'manager/engagementPOs',
  initialState,
  reducers: {
    setCurrentEngagementPOList(state, action: PayloadAction<EngagementPOCreatedOrSaved[]>) {
      state.currentEngagementPOList = action.payload;
    },
    clearCurrentEngagementPOList(state) {
      state.currentEngagementPOList = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(createEngagementPO.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createEngagementPO.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createEngagementPO.rejected, state => {
      state.create.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(partialUpdateEngagementPO.pending, state => {
      state.partialUpdate.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(partialUpdateEngagementPO.fulfilled, state => {
      state.partialUpdate.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(partialUpdateEngagementPO.rejected, state => {
      state.partialUpdate.fetchStatus = FetchStatus.failed;
    });
  },
});

export const { actions: engagementPOsActions } = EngagementPOsSlice;
export const { reducer: engagementPOsReducer } = EngagementPOsSlice;
