import {
  PurchaseOrdersSliceActionsType,
  loadPurchaseOrders,
  createPurchaseOrders,
  updatePurchaseOrders,
  partialUpdatePurchaseOrders,
  PurchaseOrdersSliceStateType,
} from 'src/store/manager/purchaseOrdersSlice';
import {
  POListRequestParams,
  CreateParams,
  UpdateParams,
  PartialUpdateParams,
} from 'src/repositories/organizationAdmin/PurchaseOrderRepository';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';

type UsePurchaseOrderType = Pick<PurchaseOrdersSliceStateType, 'purchaseOrders' | 'meta'> &
  PurchaseOrdersSliceActionsType & {
    isLoadPurchaseOrdersFinished: boolean;
    isUpdatePurchaseOrderPending: boolean;
    isPartialUpdatePurchaseOrderPending: boolean;
  };

const usePurchaseOrders = (): UsePurchaseOrderType => {
  const dispatch = useAppDispatch();

  const {
    meta,
    purchaseOrders,
    index: { fetchStatus: PurchaseOrdersIndexFetchStatus },
    update: { fetchStatus: PurchaseOrderUpdateFetchStatus },
    partialUpdate: { fetchStatus: PurchaseOrderPartialUpdateFetchStatus },
  } = useAppSelector(state => state.managerPurchaseOrders);

  const isLoadPurchaseOrdersFinished = getFetchStatus(PurchaseOrdersIndexFetchStatus).isFinished;
  const isUpdatePurchaseOrderPending = getFetchStatus(PurchaseOrderUpdateFetchStatus).isPending;
  const isPartialUpdatePurchaseOrderPending = getFetchStatus(PurchaseOrderPartialUpdateFetchStatus).isPending;

  return {
    meta,
    purchaseOrders,
    isLoadPurchaseOrdersFinished,
    isUpdatePurchaseOrderPending,
    isPartialUpdatePurchaseOrderPending,
    loadPurchaseOrders: (params?: POListRequestParams) => dispatch(loadPurchaseOrders(params)),
    createPurchaseOrders: (params: CreateParams) => dispatch(createPurchaseOrders(params)),
    updatePurchaseOrders: (params: UpdateParams) => dispatch(updatePurchaseOrders(params)),
    partialUpdatePurchaseOrders: (params: PartialUpdateParams) => dispatch(partialUpdatePurchaseOrders(params)),
  };
};

export default usePurchaseOrders;
