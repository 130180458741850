import { ModalLayout } from 'src/enums/ModalType';
import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const styles: SxStyles = {
  [ModalLayout.default]: {
    position: 'absolute',
    right: '-58px',
    top: '-12px',
    zIndex: 3,
    border: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& span': {
      display: 'none',
    },
    '& svg': {
      width: '1.2em',
      height: '1.2em',
      fill: COLORS.white,
    },
  },
  [ModalLayout.talentModals]: {
    position: 'absolute',
    right: '-3px',
    top: '-38px',
    zIndex: 3,
    border: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& span': {
      color: COLORS.white,
      marginRight: '8px',
    },
    '& svg': {
      fontSize: '1.1rem',
      fill: COLORS.white,
    },
  },
};

export default styles;
