import apiRoutes from 'src/routes/apiRoutes';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';
import { GroupMember } from 'src/types/resources/GroupMember';

export type IndexParams = {
  name?: string;
  subgroupId?: number;
  availableFromDate?: string;
  availableToDate?: string;
  page?: number;
  ordering?: string;
  isUnavailableResourcesHidden?: boolean;
};

export type ShowParams = {
  id: number;
};

export type CreateParams = {
  talentId: number;
  subgroupId: number;
};

export type DeleteParams = {
  id: number;
};

const defaultIndexParams = {
  name: '',
  subgroupId: null,
  availableFromDate: '',
  availableToDate: '',
  page: 1,
  ordering: '',
  isUnavailableResourcesHidden: false,
} as IndexParams;

export default {
  index(params: IndexParams = defaultIndexParams): Promise<ListResponse<GroupMember>> {
    const url = apiRoutes.manager.groupMembersPath();
    return Fetcher.get(url, params);
  },
  show({ id }: ShowParams): Promise<SingleResponse<GroupMember>> {
    const url = apiRoutes.manager.groupMemberPath(id);
    return Fetcher.get(url);
  },
  create(params: CreateParams): Promise<SingleResponse<GroupMember>> {
    const url = apiRoutes.manager.groupMembersPath();
    return Fetcher.post(url, params);
  },
  delete({ id }: DeleteParams): Promise<void> {
    const url = apiRoutes.manager.groupMemberPath(id);
    return Fetcher.delete(url);
  },
};
