import { SuperAdminTalent } from 'src/types/resources/SuperAdminTalent';
import { AccountType } from 'src/enums/AccountType';
import TalentProfile from 'src/types/resources/TalentProfile';
import { ListResponse, SingleResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = Partial<{
  search: string;
  page: number;
  ordering: string;
  isDeactivated: boolean;
  isActive: boolean;
  isInternal: boolean;
  accountType: AccountType;
}>;

export type PartialUpdateParams = {
  id: number;
  params: {
    isDeactivated?: boolean;
    isTalentApprovedByRevuud?: boolean;
  };
};

export default {
  index(params: IndexParams): Promise<ListResponse<SuperAdminTalent>> {
    const url = apiRoutes.superAdmin.talentsPath();
    return Fetcher.get(url, params);
  },
  show(id: number): Promise<SingleResponse<TalentProfile>> {
    const url = apiRoutes.superAdmin.talentPath(id);
    return Fetcher.get(url);
  },
  partialUpdate({ id, params }: PartialUpdateParams): Promise<SingleResponse<TalentProfile>> {
    const url = apiRoutes.superAdmin.talentPath(id);
    return Fetcher.patch(url, params);
  },
};
