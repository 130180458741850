import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiSwitchType = {
  defaultProps: ComponentsProps['MuiSwitch'];
  styleOverrides: ComponentsOverrides['MuiSwitch'];
};

const MuiSwitch: MuiSwitchType = {
  styleOverrides: {
    root: {
      width: '40px',
      height: '24px',
      padding: 0,
    },
    track: {
      borderRadius: '15px',
      backgroundColor: COLORS.black20,
    },
    switchBase: {
      padding: 0,
      top: '4px',
      left: '4px',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: COLORS.white,
        '& + .MuiSwitch-track': {
          backgroundColor: COLORS.orange3,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: '16px',
      height: '16px',
    },
  },
  defaultProps: {},
};

export default MuiSwitch;
