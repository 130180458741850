import { Typography } from '@mui/material';
import { useEffect } from 'react';

import { ReactComponent as IconErrorConfirm } from 'src/assets/images/iconErrorConfirm.svg';
import useUsers from 'src/hooks/useUsers';
import Loader from 'src/components/Loader';
import ConfirmPassword from 'src/components/ConfirmationByEmail/components/ConfirmPassword';
import Box from 'src/components/Box';

import styles from './styles';

type NewPasswordProps = {
  uuid: string;
  token: string;
};

const NewPassword: React.FC<NewPasswordProps> = props => {
  const { uuid, token } = props;
  const { validatePassword, isValidatePasswordFinished, isValidatePasswordFailed } = useUsers();

  useEffect(() => {
    validatePassword({ uuid, token });
  }, []);

  if (!isValidatePasswordFinished) {
    return (
      <Box sx={styles.box}>
        <Loader />
      </Box>
    );
  }

  if (isValidatePasswordFailed) {
    return (
      <Box sx={styles.box}>
        <Box sx={styles.iconBox}>
          <IconErrorConfirm className="iconError" />
        </Box>
        <Box sx={styles.contentBox}>
          <Typography variant="h3" component="p" mb={3}>
            Reset password token is invalid.
          </Typography>
        </Box>
      </Box>
    );
  }

  return <ConfirmPassword token={token} uuid={uuid} />;
};

export default NewPassword;
