import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { ModalLayout } from 'src/enums/ModalType';

import styles from './styles';

type CloseButtonProps = {
  modalLayout: ModalLayout;
  hideModal: () => void;
};

const CloseButton: React.FC<CloseButtonProps> = props => {
  const { modalLayout, hideModal } = props;
  const currentCloseLayout = styles[modalLayout];

  return (
    <IconButton sx={currentCloseLayout} onClick={hideModal}>
      <Typography variant="button" component="span">
        Close
      </Typography>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
