import { COLORS } from 'src/theme/palette';
import { makeStyles } from 'src/utils/makeStyles';

const styles = makeStyles({
  modalWrapper: {
    padding: '25px',
    width: '512px',
    boxSizing: 'border-box',
  },
  row: {
    margin: '16px 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  zipCode: {
    width: '96px',
    flex: '0 0 auto',
  },
  buttons: {
    marginTop: '40px',
  },
  button: {
    padding: '8px 48px',
    marginRight: '10px',
  },
  dateRow: {
    position: 'relative',
    maxWidth: '360px',
    '&:before': {
      content: '"—"',
      position: 'absolute',
      top: '20px',
      left: '50%',
      lineHeight: 0,
      transform: 'translateX(-50%)',
      color: COLORS.black40,
    },
  },
  dateField: {
    width: '160px',
    marginRight: '40px',
  },
});

export default styles;
