import { Stack, TextField, TextFieldProps, Typography } from '@mui/material';

import clsx from 'src/utils/clsx';

import MinutesField from './components/MinutesField';
import styles from './styles';

type TimeFieldsProps = {
  HoursFieldProps: TextFieldProps;
  MinutesFieldProps: TextFieldProps;
  hoursInputRef?: React.MutableRefObject<HTMLInputElement>;
  focusedTimeField?: 'hours' | 'minutes';
  isReadOnly?: boolean;
};

const TimeFields: React.FC<TimeFieldsProps> = props => {
  const { HoursFieldProps, MinutesFieldProps, hoursInputRef = null, focusedTimeField = '', isReadOnly = false } = props;

  return (
    <Stack direction="row">
      <TextField
        name="hours"
        sx={styles.timeTextField}
        InputProps={{
          sx: clsx(styles.timeTextFieldInput, [
            [styles.focused, focusedTimeField === 'hours'],
            [styles.readOnly, isReadOnly],
          ]),
          placeholder: 'hh',
          readOnly: isReadOnly,
          inputRef: hoursInputRef,
        }}
        {...HoursFieldProps}
      />
      <Typography sx={styles.timeDivider} variant="body1">
        :
      </Typography>
      <MinutesField
        name="minutes"
        sx={styles.timeTextField}
        SelectProps={{
          sx: clsx(styles.timeTextFieldInput, [
            [styles.focused, focusedTimeField === 'minutes'],
            [styles.readOnly, isReadOnly],
          ]),
          inputProps: { sx: styles.selectInput, tabIndex: -1 },
          MenuProps: { sx: styles.selectMenu },
          readOnly: isReadOnly,
        }}
        {...MinutesFieldProps}
      />
    </Stack>
  );
};

export default TimeFields;
