import {
  TypedUseSelectorHook,
  useDispatch as reactReduxUseDispatch,
  useSelector as reactReduxUseSelector,
} from 'react-redux';

import type { RootState, AppDispatch } from 'src/store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => reactReduxUseDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = reactReduxUseSelector;
export const useSelector: TypedUseSelectorHook<RootState> = reactReduxUseSelector;
export const useDispatch = () => reactReduxUseDispatch<AppDispatch>();
