import apiRoutes from 'src/routes/apiRoutes';
import { TotalEngagementTimeSheet } from 'src/types/resources/EngagementTimeSheet';
import { OrgAdminEngagementTimeSheetDates } from 'src/types/resources/OrgAdminEngagementTimeSheetDates';
import { NestedTimeSheet } from 'src/types/resources/TimeSheet';
import { TimeTrackingFilters } from 'src/types/resources/TimeTracking';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  ordering?: string;
  page?: number;
} & TimeTrackingFilters;

export type PartialUpdateParams = {
  id: number;
  params: Partial<Pick<NestedTimeSheet, 'state' | 'clientComment'>>;
};

type MultipleUpdateParamsBase =
  | {
      approvalType: 'entire_day';
      engagementId: number;
      timekeepingDate: string;
    }
  | {
      approvalType: 'entire_engagement';
      engagementId: number;
      payPeriodBefore: string;
      payPeriodAfter: string;
    }
  | {
      approvalType: 'all_engagements';
      payPeriodBefore: string;
      payPeriodAfter: string;
    };

export type MultipleUpdateParams = MultipleUpdateParamsBase & Omit<IndexParams, 'payPeriodBefore' | 'payPeriodAfter'>;

export default {
  index(params: IndexParams): Promise<ListResponse<OrgAdminEngagementTimeSheetDates>> {
    const url = apiRoutes.organizationAdmin.timeSheetsPath();
    return Fetcher.get(url, params);
  },
  total(params: IndexParams): Promise<SingleResponse<TotalEngagementTimeSheet>> {
    const url = apiRoutes.organizationAdmin.timeSheetsTotalPath();
    return Fetcher.get(url, params);
  },
  adjustmentsTotal(params: Partial<IndexParams>): Promise<SingleResponse<TotalEngagementTimeSheet>> {
    const url = apiRoutes.organizationAdmin.timeSheetsAdjustmentsTotalPath();
    return Fetcher.get(url, params);
  },
  partialUpdate({ id, params }: PartialUpdateParams): Promise<SingleResponse<OrgAdminEngagementTimeSheetDates>> {
    const url = apiRoutes.organizationAdmin.timeSheetPath(id);
    return Fetcher.patch(url, params);
  },
  multipleUpdate(params: MultipleUpdateParams): Promise<ListResponse<void>> {
    const url = apiRoutes.organizationAdmin.timeSheetsPath();
    return Fetcher.patch(url, params);
  },
  checkPendingTimeSheets(params: IndexParams): Promise<SingleResponse<{ exists: boolean }>> {
    const url = apiRoutes.organizationAdmin.checkPendingTimeSheets();
    return Fetcher.get(url, params);
  },
};
