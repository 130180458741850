import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import { User } from 'src/types/resources/User';
import { SingleResponse } from 'src/types/utils';
import { SignUpFormDataToSubmit } from 'src/forms/signUpForm';
import { ConfirmPasswordFormDataToSubmit } from 'src/forms/confirmPassword';
import { ResetPasswordFormData } from 'src/forms/resetPassword';

export type ConfirmParams = {
  uuid: string;
  token: string;
};

export type PartialUpdateParams = Partial<Omit<User, 'avatarPicture'>> & {
  avatarPicture?: File | string | null;
};

const UsersRepository = {
  create(params: SignUpFormDataToSubmit): Promise<SingleResponse<User>> {
    const url = apiRoutes.signUpPath();
    return Fetcher.post(url, params);
  },
  show(): Promise<SingleResponse<User>> {
    const url = apiRoutes.currentUserPath();
    return Fetcher.get(url);
  },
  partialUpdate(params: PartialUpdateParams): Promise<SingleResponse<User>> {
    const url = apiRoutes.currentUserPath();
    return Fetcher.partialUpdateFormData(url, params);
  },
  confirm(params: ConfirmParams): Promise<SingleResponse<User>> {
    const url = apiRoutes.confirmEmailPath();
    return Fetcher.post(url, params);
  },
  confirmPassword(params: ConfirmPasswordFormDataToSubmit): Promise<SingleResponse<void>> {
    const url = apiRoutes.confirmPasswordPath();
    return Fetcher.post(url, params);
  },
  resetPassword(params: ResetPasswordFormData): Promise<SingleResponse<void>> {
    const url = apiRoutes.resetPasswordPath();
    return Fetcher.post(url, params);
  },
  validatePassword(params: ConfirmParams): Promise<SingleResponse<void>> {
    const url = apiRoutes.validatePasswordPath();
    return Fetcher.post(url, params);
  },
  resendVerificationEmail(params: { email: string }): Promise<void> {
    const url = apiRoutes.resendVerificationEmail();
    return Fetcher.post(url, params);
  },
};

export default UsersRepository;
