import { Button, FormHelperText, TextField, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { isEmpty, isNil } from 'ramda';

import {
  attributesToSubmit,
  initialValues,
  TimeSheetDeclineFormData,
  validationSchema,
} from 'src/forms/timeSheetDeclineForm';
import clsx from 'src/utils/clsx';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useModals from 'src/hooks/useModals';
import Box from 'src/components/Box';

import styles from './styles';

type DeclineTimeSheetFormProps = {
  TimeSheetDayDescription: React.ReactNode;
  talentComment: string | null;
  onSubmit: (params: TimeSheetDeclineFormData) => Promise<void>;
};

const DeclineTimeSheetForm: React.FC<DeclineTimeSheetFormProps> = props => {
  const { TimeSheetDayDescription, talentComment, onSubmit } = props;

  const { hideModal } = useModals();

  const isTalentCommentExist = !isNil(talentComment) && !isEmpty(talentComment);

  const handleSubmit = async (
    formData: TimeSheetDeclineFormData,
    { setErrors }: FormikHelpers<TimeSheetDeclineFormData>,
  ) => {
    const params = attributesToSubmit(formData);

    try {
      await onSubmit(params);
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit: handleFormikSubmit,
  } = useFormik<TimeSheetDeclineFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return (
    <Box sx={styles.wrapper}>
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.row}>
          <Typography variant="h3">Decline Entry</Typography>
        </Box>
        <Box sx={styles.row}>
          {TimeSheetDayDescription}
          {isTalentCommentExist && (
            <Box sx={styles.talentComment}>
              <Typography sx={styles.talentCommentLabel} variant="button" component="p">
                Description:
              </Typography>
              <Typography variant="body1">{talentComment}</Typography>
            </Box>
          )}
        </Box>

        <Box sx={styles.row}>
          <TextField
            id="clientComment"
            name="clientComment"
            label="Decline reason:"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.clientComment}
            error={touched.clientComment && Boolean(errors.clientComment)}
            placeholder="Type here a reason of decline"
            sx={styles.textField}
            multiline
            rows={3}
          />
          {touched.clientComment && errors.clientComment && (
            <FormHelperText error>{touched.clientComment && errors.clientComment}</FormHelperText>
          )}
        </Box>

        <Box sx={styles.row}>
          <Box sx={styles.buttons}>
            <Button
              type="submit"
              color="error"
              variant="contained"
              sx={clsx(styles.button, [[styles.declineButton, true]])}
              disabled={isSubmitting}
            >
              Decline
            </Button>
            <Button variant="outlined" sx={styles.button} onClick={() => hideModal()}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default DeclineTimeSheetForm;
