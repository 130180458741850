import { Task } from 'src/types/resources/Task';
import { AxiosHeaders, SingleResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export default {
  show(id: string): Promise<{ headers: AxiosHeaders; data: SingleResponse<Task> }> {
    const url = apiRoutes.tasksPath(id);
    return Fetcher.getWithHeader(url);
  },
};
