import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { KnowledgeCenterVideos } from 'src/types/resources/KnowledgeCenter';
import { ListResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import TalentsKnowledgeCenterVideosRepository, {
  IndexParams as TalentsIndexParams,
} from 'src/repositories/talents/KnowledgeCenterVideosRepository';
import ManagerKnowledgeCenterVideosRepository, {
  IndexParams as ManagerIndexParams,
} from 'src/repositories/manager/KnowledgeCenterVideosRepository';

export type KnowledgeCenterVideosSliceStateType = {
  meta: Meta;
  videos: KnowledgeCenterVideos[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type KnowledgeCenterVideosSliceActionsType = {
  loadTalentsKnowledgeCenterVideos: (params?: TalentsIndexParams) => {
    unwrap: () => Promise<ListResponse<KnowledgeCenterVideos>>;
  };
  loadManagerKnowledgeCenterVideos: (params?: ManagerIndexParams) => {
    unwrap: () => Promise<ListResponse<KnowledgeCenterVideos>>;
  };
  resetKnowledgeCenterVideos: () => void;
};

const initialState: KnowledgeCenterVideosSliceStateType = {
  meta: {} as Meta,
  videos: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadTalentsKnowledgeCenterVideos = createAsyncAction(
  'knowledgeCenterVideos/talents/index',
  TalentsKnowledgeCenterVideosRepository.index,
);
export const loadManagerKnowledgeCenterVideos = createAsyncAction(
  'knowledgeCenterVideos/manager/index',
  ManagerKnowledgeCenterVideosRepository.index,
);

const KnowledgeCenterFAQSlice = createSlice({
  name: 'knowledgeCenterVideos',
  initialState,
  reducers: {
    resetKnowledgeCenterVideos(state) {
      state.index.fetchStatus = FetchStatus.idle;
      state.videos = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTalentsKnowledgeCenterVideos.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTalentsKnowledgeCenterVideos.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.videos = payload.results;
    });
    builder.addCase(loadTalentsKnowledgeCenterVideos.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadManagerKnowledgeCenterVideos.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadManagerKnowledgeCenterVideos.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.videos = payload.results;
    });
    builder.addCase(loadManagerKnowledgeCenterVideos.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
  },
});

const {
  actions: { resetKnowledgeCenterVideos },
} = KnowledgeCenterFAQSlice;

export { resetKnowledgeCenterVideos };

export default KnowledgeCenterFAQSlice.reducer;
