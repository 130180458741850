import { useState } from 'react';

import { FetchStatus } from 'src/enums/FetchStatus';
import { FetchStatus as FetchStatusType } from 'src/types/utils';
import getFetchStatus from 'src/utils/fetchStatus';

const inferredCall = <ArgumentsType extends unknown[], ReturnValue>(
  functionToCall: (...args: ArgumentsType) => Promise<ReturnValue>,
  ...args: ArgumentsType
): Promise<ReturnValue> => {
  return functionToCall(...args);
};

type UseLoading = <ArgumentsType extends unknown[], ReturnValue>(
  func: (...args: ArgumentsType) => Promise<ReturnValue>,
) => {
  funcWithLoading: (...args: ArgumentsType) => Promise<ReturnValue>;
} & FetchStatusType;

const useLoading: UseLoading = func => {
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.idle);

  const funcWithLoading = async (...args: Parameters<typeof func>) => {
    setStatus(FetchStatus.pending);

    try {
      const result = await inferredCall(func, ...args);
      setStatus(FetchStatus.fulfilled);
      return result;
    } catch (error: unknown) {
      setStatus(FetchStatus.failed);
      throw error;
    }
  };

  const complexFetchStatus: FetchStatusType = getFetchStatus(status);

  return {
    funcWithLoading,
    ...complexFetchStatus,
  };
};

export default useLoading;
