import { Address } from 'src/types/address';

export const formatAddress = (address: Address, entriesText = ''): string => {
  const entriesDescription = entriesText ? `(${address.entries} ${entriesText}) ` : `(${address.entries}) `;

  const streetLine = `${address.streetLine} `;
  const secondary = `${address.secondary} `;
  const entries = address.entries > 1 ? entriesDescription : '';
  const city = `${address.city}, `;
  const state = `${address.state} `;
  const zip = `${address.zipcode}`;

  return streetLine + secondary + entries + city + state + zip;
};
