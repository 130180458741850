import { isNil } from 'ramda';
import * as yup from 'yup';

import { VALID_FILE_SIZE, getValidFileTypeTest } from 'src/forms/customRules';
import { DocumentFile } from 'src/forms/talents/documents';
import { ManagerRequiredDocument } from 'src/types/resources/RequiredDocument';
import { DOCUMENT_LIBRARY_VALID_FILE_TYPES } from 'src/utils/constants';
import { CreateParams } from 'src/repositories/talents/FilledDocumentsRepository';
import { getFilePayload } from 'src/utils/filePayload';

export type EngagementDocumentFileUploadData = {
  isChecked?: boolean;
  file?: DocumentFile;
};

export const validationFields = {
  confirm: yup.boolean().default(false),
  file: yup
    .object()
    .nullable()
    .shape({
      url: yup.string().nullable().default(null),
      source: yup
        .mixed()
        .nullable()
        .test(getValidFileTypeTest(DOCUMENT_LIBRARY_VALID_FILE_TYPES))
        .test(VALID_FILE_SIZE)
        .default(null),
    }),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (document?: ManagerRequiredDocument): EngagementDocumentFileUploadData => {
  if (isNil(document)) {
    return {
      ...validationSchema.getDefault(),
      file: {
        source: null,
        url: null,
      },
    };
  }

  return {
    isChecked: Boolean(document.filled?.isChecked),
    file: {
      source: null,
      url: document.filled?.file,
      name: document.filled?.originalFileName,
    },
  };
};

export const attributesToSubmit = (
  values: EngagementDocumentFileUploadData,
  engagementId: number,
  typeId: number,
): CreateParams => ({
  engagementId,
  typeId,
  isChecked: values.isChecked,
  ...getFilePayload(values.file, 'file'),
});
