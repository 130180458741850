import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Task } from 'src/types/resources/Task';
import { AxiosHeaders, SingleResponse } from 'src/types/utils';
import TasksRepository from 'src/repositories/superAdmin/TasksRepository';
import { createAsyncAction } from 'src/utils/createAsyncAction';

export type TaskStateType = {
  task: Task;
  location: string;
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type TaskActionsType = {
  loadTask: (params: string) => { unwrap: () => Promise<{ headers: AxiosHeaders; data: SingleResponse<Task> }> };
};

const initialState: TaskStateType = {
  task: {} as Task,
  location: null,
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadTask = createAsyncAction('superAdmin/tasks/show', TasksRepository.show);

const tasksSlice = createSlice({
  name: 'superAdmin/tasks',
  initialState,
  reducers: {
    resetTaskState: state => {
      state.show.fetchStatus = FetchStatus.idle;
      state.task = {} as Task;
      state.location = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTask.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
      state.location = undefined;
    });
    builder.addCase(loadTask.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.task = payload.data?.data;
      state.location = payload.headers?.location;
    });
    builder.addCase(loadTask.rejected, state => {
      state.show.fetchStatus = FetchStatus.failed;
      state.location = undefined;
    });
  },
});

const {
  actions: { resetTaskState },
} = tasksSlice;

export { resetTaskState };

export default tasksSlice.reducer;
