import { Button, ButtonProps as MuiButtonProps } from '@mui/material';

import { ReactComponent as EditIcon } from 'src/assets/images/editIcon.svg';

import styles from './styles';

type ButtonProps = MuiButtonProps & {
  isEditState?: boolean;
};

const EditButton: React.FC<ButtonProps> = props => {
  const { isEditState = false, ...restProps } = props;

  const buttonText = isEditState ? 'Cancel' : 'Edit';

  return (
    <Button sx={styles.root} variant="text" startIcon={isEditState ? null : <EditIcon />} size="small" {...restProps}>
      {buttonText}
    </Button>
  );
};

export default EditButton;
