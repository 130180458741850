import { UserRole } from 'src/enums/UserRole';
import OrganizationUserPresenter from 'src/presenters/OrganizationUserPresenter';
import UserPresenter from 'src/presenters/UsersPresenter';
import UserRolePresenter from 'src/presenters/UserRolePresenter';
import appRoutes from 'src/routes/appRoutes';
import { OrganizationUser } from 'src/types/resources/Organization';
import { User } from 'src/types/resources/User';

type NavigationItem = {
  label: string;
  hide?: boolean;
  path: string;
};

export type RadioItem = {
  value: string | number;
  label: string;
};

export const createUsersForRadioGroup = (users: OrganizationUser[]): RadioItem[] => {
  return users.map(organizationUser => {
    return {
      value: organizationUser.id,
      label: OrganizationUserPresenter.fullName(organizationUser),
    };
  });
};

type GetNavigationMenuItemsConfig = { modifyAppForDemoEnv: boolean };

export const getNavigationMenuItems = (
  user: User,
  { modifyAppForDemoEnv }: GetNavigationMenuItemsConfig,
): NavigationItem[] => {
  if (UserRolePresenter.isContactRole(user.role)) {
    return [];
  }

  const hasPermissionSendEngagement = UserPresenter.hasPermissionSendEngagement(user);

  const menuItemsMapByUserRole: Record<Exclude<UserRole, UserRole.Contact>, NavigationItem[]> = {
    [UserRole.Talent]: [
      {
        label: 'dashboard',
        path: appRoutes.talent.dashboardPath(),
      },
      {
        label: 'profile',
        path: appRoutes.talent.talentProfilePath(),
      },
      {
        label: 'opportunities',
        path: appRoutes.talent.opportunities(),
      },
      {
        label: 'engagements',
        path: appRoutes.engagements(),
      },
      {
        label: 'time tracking',
        path: appRoutes.talent.timeTrackingPath(),
      },
    ],
    [UserRole.Manager]: [
      {
        label: 'dashboard',
        path: appRoutes.rootPath(),
      },
      {
        label: 'opportunities',
        path: appRoutes.opportunities(),
      },
      {
        label: 'groups',
        path: appRoutes.manager.groupsPath(),
      },
      {
        label: 'search',
        path: appRoutes.manager.searchPath(),
      },
      {
        label: 'engagements',
        hide: !hasPermissionSendEngagement,
        path: appRoutes.manager.engagementsPath(),
      },
      {
        label: 'time tracking',
        hide: !hasPermissionSendEngagement,
        path: appRoutes.manager.timeTrackingPath(),
      },
    ],
    [UserRole.OrganizationAdmin]: [
      {
        label: 'dashboard',
        path: appRoutes.rootPath(),
      },
      {
        label: 'opportunities',
        path: appRoutes.opportunities(),
      },
      {
        label: 'groups',
        path: appRoutes.organizationAdmin.groupsPath(),
      },
      {
        label: 'search',
        path: appRoutes.organizationAdmin.searchPath(),
      },
      {
        label: 'engagements',
        path: appRoutes.organizationAdmin.engagementsPath(),
      },
      {
        label: 'time tracking',
        path: appRoutes.organizationAdmin.timeTrackingPath(),
      },
      {
        label: 'administration',
        path: appRoutes.organizationAdmin.organizationPath(),
      },
    ],
    [UserRole.SuperAdmin]: [
      {
        label: 'dashboard',
        path: appRoutes.superAdmin.dashboardPath(),
      },
      {
        label: 'talents',
        hide: modifyAppForDemoEnv,
        path: appRoutes.superAdmin.talentsPath(),
      },
      {
        label: 'organizations',
        path: appRoutes.superAdmin.organizationsPath(),
      },
      {
        label: 'accounting',
        path: appRoutes.superAdmin.accountingPath(),
      },
    ],
  };

  const menuItems = menuItemsMapByUserRole[user.role].filter(menuItem => !menuItem.hide);

  return menuItems;
};
