import useOrganizationsUsers from 'src/hooks/organizationAdmin/useOrganizationsUsers';
import { OrganizationUser } from 'src/types/resources/Organization';

import Form from '../Form';

type OrganizationAdminFormProps = {
  user: OrganizationUser;
};

const OrganizationAdminForm: React.FC<OrganizationAdminFormProps> = props => {
  const { user } = props;

  const { loadOrganizationUsers, partialUpdateOrganizationUser } = useOrganizationsUsers();

  return <Form user={user} onLoadUsers={loadOrganizationUsers} onUpdateUser={partialUpdateOrganizationUser} />;
};

export default OrganizationAdminForm;
