import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';

const styles: SxStyles = {
  box: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: {
      mobile: '100%',
      tablet: '800px',
    },
    minHeight: {
      mobile: 'auto',
      tablet: '640px',
    },
    padding: {
      mobile: '0 0 20px',
      tablet: '24px 40px 40px',
    },
  },
  heading: {
    marginBottom: '12px',
  },
  header: {
    marginBottom: '44px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginBottom: {
      mobile: 0,
      tablet: '48px',
    },
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  availabilityList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: {
      mobile: '24px',
      tablet: '16px',
    },
  },
  addButton: {
    padding: {
      mobile: 0,
      tablet: '18px',
    },
    marginRight: {
      mobile: 'auto',
      tablet: '48px',
    },
    border: {
      mobile: 0,
      tablet: `2px dashed ${COLORS.black10}`,
    },
    borderRadius: '8px',
    color: COLORS.black,
    '&:hover': {
      backgroundColor: {
        mobile: 'transparent',
        tablet: COLORS.orange10,
      },
    },
    '& .plusIcon': {
      fill: COLORS.orange5,
    },
  },
  footer: {
    marginTop: 2,
  },
};

export default styles;
