import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  box: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: { tablet: '760px' },
    minHeight: { tablet: '640px' },
    padding: { tablet: '24px 40px 40px' },
  },
  heading: {
    marginBottom: '12px',
  },
  header: {
    marginBottom: '40px',
  },
  body: {
    flexGrow: 1,
    marginBottom: '48px',
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  certificateList: {
    marginBottom: 2,
  },
  footer: {
    marginTop: 'auto',
  },
};

export default styles;
