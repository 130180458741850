import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  box: {
    maxWidth: '512px',
    width: {
      mobile: '100%',
      tablet: '512px',
    },
    padding: '24px',
  },
  row: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  title: {
    textAlign: {
      mobile: 'center',
      tablet: 'left',
    },
  },
  description: {
    fontWeight: {
      mobile: 400,
      tablet: 600,
    },
    textAlign: {
      mobile: 'center',
      tablet: 'left',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
    marginTop: '40px',
  },
  button: {
    width: {
      mobile: '100%',
      tablet: 'auto',
    },
    padding: '8px 48px',
  },
};

export default styles;
