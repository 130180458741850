import { IconButton, MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';
import { isEmpty, isNil } from 'ramda';

import { ReactComponent as SelectArrow } from 'src/assets/icons/selectArrow.svg';
import { ReactComponent as CrossIcon } from 'src/assets/images/crossIcon.svg';
import Box from 'src/components/Box';
import TypographyNoWrap from 'src/components/TypographyNoWrap';

import styles from './styles';

export type SelectItem = {
  value: string | number;
  label: string;
};

export type SelectProps = TextFieldProps & {
  values?: SelectItem[];
  onDelete?: (id: number) => void;
};

const Select: React.FC<SelectProps> = props => {
  const { values = [], onChange, value, placeholder, onDelete = null, SelectProps, ...restProps } = props;

  const isEmptyValue = isNil(value) || isEmpty(value) || value === 0;
  const isPlaceholderVisible = Boolean(placeholder) && isEmptyValue;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(event);
  };

  const handleMenuItemDelete = (id: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onDelete(id);
  };

  const renderPlaceholder = () => {
    return (
      <Typography component="span" sx={styles.placeholder}>
        {placeholder}
      </Typography>
    );
  };

  const renderValue = (fieldValue: unknown) => {
    const currentLabel = values.find(currentValue => currentValue.value === fieldValue);

    if (isNil(currentLabel)) {
      return null;
    }

    return (
      <TypographyNoWrap component="p" sx={styles.renderValue} tooltipVariant="light">
        {currentLabel.label}
      </TypographyNoWrap>
    );
  };

  return (
    <TextField
      {...restProps}
      select
      SelectProps={{
        ...SelectProps,
        IconComponent: SelectArrow,
        displayEmpty: true,
        renderValue: isPlaceholderVisible ? renderPlaceholder : renderValue,
      }}
      value={value}
      onChange={handleChange}
    >
      {values.map((selectItem, index) => (
        <MenuItem value={selectItem.value} key={selectItem.value || index}>
          <Box sx={styles.selectItem}>
            <Typography>{selectItem.label}</Typography>
            {!isNil(onDelete) && (
              <IconButton sx={styles.deleteButton} onClick={handleMenuItemDelete(Number(selectItem.value))}>
                <CrossIcon />
              </IconButton>
            )}
          </Box>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;
