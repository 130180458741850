import {
  SnackbarProvider as NotistackSnackbarProvider,
  SnackbarProviderProps as NotistackSnackbarProviderProps,
} from 'notistack';

import Snackbar from 'src/components/Snackbar';

const SnackbarProvider: React.FC<NotistackSnackbarProviderProps> = props => {
  const { children } = props;

  return (
    <NotistackSnackbarProvider
      autoHideDuration={3000}
      // @ts-ignore
      // (Pass all props to a custom snackbar) https://github.com/iamhosseindhv/notistack/issues/242
      content={(key, message) => <Snackbar id={key} options={message} />}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;
