import React from 'react';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { isNil } from 'ramda';

import { ReactComponent as CrossIcon } from 'src/assets/images/crossIcon.svg';

import styles from './styles';

type AssignedDepartmentFieldProps = TextFieldProps & {
  onDelete: () => void;
  children: React.ReactElement<typeof TextField>;
};

const DeletableTextField: React.FC<AssignedDepartmentFieldProps> = props => {
  const { onDelete = null, children, ...restProps } = props;

  return (
    <>
      {React.cloneElement(children, {
        InputProps: {
          endAdornment: !isNil(onDelete) && (
            <IconButton sx={styles.textFieldRemoveButton} onClick={onDelete}>
              <CrossIcon />
            </IconButton>
          ),
        },
        ...restProps,
      })}
    </>
  );
};

export default DeletableTextField;
