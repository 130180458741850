import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { ManagerRequiredDocument } from 'src/types/resources/RequiredDocument';
import { ListResponse, SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import RequiredDocumentsRepository, {
  IndexParams,
  ShowParams,
} from 'src/repositories/superAdmin/RequiredDocumentsRepository';

export type RequiredDocumentsSliceStateType = {
  meta: Meta;
  requiredDocument: ManagerRequiredDocument;
  requiredDocuments: ManagerRequiredDocument[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type RequiredDocumentsSliceActionsType = {
  loadRequiredDocuments: (params?: IndexParams) => { unwrap: () => Promise<ListResponse<ManagerRequiredDocument>> };
  showRequiredDocument: (params: ShowParams) => { unwrap: () => Promise<SingleResponse<ManagerRequiredDocument>> };
};

const initialState: RequiredDocumentsSliceStateType = {
  meta: {} as Meta,
  requiredDocument: {} as ManagerRequiredDocument,
  requiredDocuments: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadRequiredDocuments = createAsyncAction(
  'superAdmin/requiredDocuments/index',
  RequiredDocumentsRepository.index,
);
export const showRequiredDocument = createAsyncAction(
  'superAdmin/requiredDocuments/show',
  RequiredDocumentsRepository.show,
);

const RequiredDocumentsSlice = createSlice({
  name: 'superAdmin/requiredDocuments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadRequiredDocuments.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadRequiredDocuments.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.requiredDocuments = payload.results;
    });
    builder.addCase(loadRequiredDocuments.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(showRequiredDocument.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showRequiredDocument.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.requiredDocument = payload.data;
    });
    builder.addCase(showRequiredDocument.rejected, state => {
      state.show.fetchStatus = FetchStatus.failed;
    });
  },
});

export default RequiredDocumentsSlice.reducer;
