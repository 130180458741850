import { ChangeEvent, useEffect, useState } from 'react';
import { Theme, Button, IconButton, FormHelperText, InputLabel, TextFieldProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { isNil } from 'ramda';

import { ReactComponent as DocumentIcon } from 'src/assets/images/documentIcon.svg';
import { ReactComponent as ClipIcon } from 'src/assets/images/clipIcon.svg';
import { ReactComponent as CrossIcon } from 'src/assets/images/crossIcon.svg';
import { CERTIFICATE_VALID_FILE_TYPES } from 'src/utils/constants';
import clsx from 'src/utils/clsx';
import { isImage } from 'src/utils/string';
import useObjectURL from 'src/hooks/useObjectUrl';
import Box from 'src/components/Box';

import styles from './styles';

type UploadFileProps = Omit<TextFieldProps, 'onChange'> & {
  fileUrl: string | null;
  sx?: SxProps<Theme>;
  onChange?: (file: File | null) => void;
};

const UploadFile: React.FC<UploadFileProps> = props => {
  const { label: labelText, fileUrl, error, helperText, disabled, sx = {}, onChange } = props;
  const [isImagePreview, setIsImagePreview] = useState<boolean>(true);
  const { objectURL, setObject, setObjectURL } = useObjectURL(fileUrl, null);

  const handleDeletePreview = () => {
    onChange(null);
    setObject(null);
    setObjectURL(null);
  };

  const handleUploadPreview = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    setIsImagePreview(isImage(selectedFile.name));
    setObject(selectedFile);
    onChange(selectedFile);
  };

  const wrapperStyles = clsx(styles.uploadBox, [[sx, true]]);

  useEffect(() => {
    if (!isNil(fileUrl)) {
      setIsImagePreview(isImage(fileUrl));
    }
  }, []);

  return (
    <Box sx={wrapperStyles}>
      <InputLabel>{labelText}</InputLabel>
      {(!objectURL || error) && (
        <label htmlFor="upload-certificate-file">
          <input
            accept={CERTIFICATE_VALID_FILE_TYPES.join(',')}
            id="upload-certificate-file"
            type="file"
            name="file"
            onChange={handleUploadPreview}
            disabled={disabled}
          />
          <Button
            sx={styles.uploadButton}
            variant="outlined"
            startIcon={<ClipIcon />}
            component="span"
            disabled={disabled}
          >
            Upload {labelText}
          </Button>
        </label>
      )}
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
      {objectURL && !error && (
        <Box sx={styles.uploadPreviewBox}>
          {isImagePreview ? (
            <img src={objectURL} alt="file preview" width="200" height="136" />
          ) : (
            <Box sx={styles.documentIcon}>
              <DocumentIcon />
            </Box>
          )}
          <IconButton aria-label="delete" onClick={handleDeletePreview}>
            <CrossIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default UploadFile;
