import * as yup from 'yup';

import { ValidationError } from 'src/enums/Validation';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';

export type TalentNotFoundFeedbackFormData = {
  message: string;
  isIncludeLastSearch: boolean;
};
export type TalentNotFoundFeedbackFormDataToSubmit = {
  message: string;
  lastSearch: string;
};

export const validationFields = {
  message: yup.string().max(1000, getMaxFieldLengthErrorMessage(1000)).required(ValidationError.default).default(''),
  isIncludeLastSearch: yup.boolean().default(true),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (): TalentNotFoundFeedbackFormData => {
  return validationSchema.getDefault();
};

export const attributesToSubmit = (
  values: TalentNotFoundFeedbackFormData,
  lastSearch: string,
): TalentNotFoundFeedbackFormDataToSubmit => {
  return {
    message: values.message,
    lastSearch: values.isIncludeLastSearch ? lastSearch : '',
  };
};
