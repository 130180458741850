import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import TalentsRepository from 'src/repositories/manager/TalentsRepository';
import { SearchResourcesFormDataToSubmit } from 'src/forms/SearchResources';
import { TalentSearch } from 'src/types/resources/TalentSearch';

export type TalentSliceStateType = {
  meta: Meta;
  talents: TalentSearch[];
  matchedTalentsCount: number;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  loadMatchedTalentsCount: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type TalentsSliceActionsType = {
  loadTalents: (params: Partial<SearchResourcesFormDataToSubmit>) => { unwrap: () => void };
  loadMatchedTalentsCount: (params?: Partial<SearchResourcesFormDataToSubmit>) => { unwrap: () => void };
  resetTalents: () => void;
};

const initialState: TalentSliceStateType = {
  meta: {} as Meta,
  talents: [],
  matchedTalentsCount: 0,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  loadMatchedTalentsCount: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadTalents = createAsyncAction('resources/talents/index', TalentsRepository.index);
export const loadMatchedTalentsCount = createAsyncAction(
  'resources/talents/loadMatchedTalentsCount',
  TalentsRepository.loadMatchedCount,
);

const talentsSlice = createSlice({
  name: 'resources/talents',
  initialState,
  reducers: {
    resetTalents: state => {
      state.index.fetchStatus = FetchStatus.idle;
      state.talents = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTalents.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTalents.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.talents = payload.results;
    });
    builder.addCase(loadTalents.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(loadMatchedTalentsCount.pending, state => {
      state.loadMatchedTalentsCount.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadMatchedTalentsCount.fulfilled, (state, { payload }) => {
      state.loadMatchedTalentsCount.fetchStatus = FetchStatus.fulfilled;
      state.matchedTalentsCount = payload.data.count;
    });
    builder.addCase(loadMatchedTalentsCount.rejected, state => {
      state.loadMatchedTalentsCount.fetchStatus = FetchStatus.failed;
    });
  },
});

const {
  actions: { resetTalents },
} = talentsSlice;

export { resetTalents };

export default talentsSlice.reducer;
