import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Alert, Button, Checkbox, TextField, Typography } from '@mui/material';
import { head, isNil } from 'ramda';
import { EditorState } from 'draft-js';

import useWorkExperiences from 'src/hooks/talents/useWorkExperiences';
import useModals from 'src/hooks/useModals';
import {
  attributesToSubmit,
  initialValues,
  validationSchema,
  WorkExperienceFormData,
} from 'src/forms/talents/workExperience';
import WorkExperience from 'src/types/resources/WorkExperience';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import { TODAY, DATEPICKER_MIN_DATE, getNextYear } from 'src/utils/date';
import clsx from 'src/utils/clsx';
import TextEditor from 'src/components/TextEditor';
import DatePicker from 'src/components/DatePicker';
import Box from 'src/components/Box';

import styles from './styles';

type FormProps = {
  workExperience?: WorkExperience;
};

const Form: React.FC<FormProps> = props => {
  const { workExperience } = props;
  const isAddForm = isNil(workExperience);
  const formTitle = isAddForm ? 'Add Work Experience' : 'Edit Work Experience';

  const [formError, setFormError] = useState<string>(null);
  const [isStartDatepickerOpened, setIsStartDatepickerOpened] = useState<boolean>(false);
  const [isEndDatepickerOpened, setIsEndDatepickerOpened] = useState<boolean>(false);
  const { hideModal } = useModals();
  const { createWorkExperience, updateWorkExperience, loadWorkExperiences } = useWorkExperiences();

  const handleSubmit = async (
    formData: WorkExperienceFormData,
    { setSubmitting, setErrors }: FormikHelpers<WorkExperienceFormData>,
  ) => {
    const params = attributesToSubmit(formData);
    setFormError(null);
    try {
      if (isAddForm) {
        await createWorkExperience(params).unwrap();
      } else {
        await updateWorkExperience({ id: workExperience.id, params }).unwrap();
      }
      await loadWorkExperiences();
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(head(error.response.data.errors.nonFieldErrors));
        setErrors(parseToFormikErrors(errors));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const { values, errors, submitForm, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } =
    useFormik<WorkExperienceFormData>({
      initialValues: initialValues(workExperience),
      validationSchema,
      onSubmit: handleSubmit,
    });

  const handleWisywigChange = (editorState: EditorState) => {
    setFieldValue('responsibilities', editorState);
  };

  const handleStartDatepickerOpen = () => setIsStartDatepickerOpened(true);
  const handleStartDatepickerClose = () => {
    setFieldTouched('startPeriodDate', true, true);
    setIsStartDatepickerOpened(false);
  };
  const handleStartDatepickerChange = (date: unknown) => setFieldValue('startPeriodDate', date);

  const handleEndDatepickerOpen = () => setIsEndDatepickerOpened(true);
  const handleEndDatepickerClose = () => {
    setFieldTouched('endPeriodDate', true, true);
    setIsEndDatepickerOpened(false);
  };
  const handleEndDatepickerChange = (date: unknown) => setFieldValue('endPeriodDate', date);

  const nextYear = getNextYear();
  const maxStartPeriodDate = TODAY;
  const minEndPeriodDate = new Date(values.startPeriodDate);
  const disabledEndPeriodDate = values.isPresent || !values.startPeriodDate;

  const endDatepickerFieldStyle = clsx(styles.datepickerField, [
    [styles.disabledDatepickerField, disabledEndPeriodDate],
  ]);

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <Box sx={styles.wrapper}>
        <Typography variant="h3">{formTitle}</Typography>
        <Box sx={styles.row}>
          <TextField
            id="position"
            label="Job title"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.position}
            error={touched.position && Boolean(errors.position)}
            helperText={touched.position && errors.position}
          />
        </Box>
        <Box sx={styles.row}>
          <TextField
            id="organizationName"
            label="Organization"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.organizationName}
            error={touched.organizationName && Boolean(errors.organizationName)}
            helperText={touched.organizationName && errors.organizationName}
          />
        </Box>
        <Box sx={styles.row}>
          <Box sx={styles.datepickerWrapper}>
            <DatePicker
              open={isStartDatepickerOpened}
              onClose={handleStartDatepickerClose}
              views={['year', 'month']}
              label="From"
              OpenPickerButtonProps={{ disabled: true }}
              value={values.startPeriodDate}
              onChange={handleStartDatepickerChange}
              minDate={DATEPICKER_MIN_DATE}
              maxDate={maxStartPeriodDate}
              PaperProps={{ className: 'datepickerWithHiddenHeader' }}
              renderInput={params => (
                <TextField
                  {...params}
                  name="startPeriodDate"
                  sx={styles.datepickerField}
                  inputProps={{ ...params.inputProps, placeholder: 'From', readOnly: true }}
                  error={touched.startPeriodDate && Boolean(errors.startPeriodDate)}
                  helperText={touched.startPeriodDate && errors.startPeriodDate}
                  onClick={handleStartDatepickerOpen}
                />
              )}
            />
          </Box>
        </Box>
        <Box sx={styles.row}>
          <Box sx={styles.datepickerWrapper}>
            <DatePicker
              open={isEndDatepickerOpened}
              onClose={handleEndDatepickerClose}
              views={['year', 'month']}
              label="To"
              OpenPickerButtonProps={{ disabled: true }}
              value={values.endPeriodDate}
              onChange={handleEndDatepickerChange}
              minDate={minEndPeriodDate}
              maxDate={nextYear}
              PaperProps={{ className: 'datepickerWithHiddenHeader' }}
              disabled={disabledEndPeriodDate}
              renderInput={params => (
                <TextField
                  {...params}
                  name="endPeriodDate"
                  sx={endDatepickerFieldStyle}
                  inputProps={{ ...params.inputProps, placeholder: 'To', readOnly: true }}
                  error={touched.endPeriodDate && Boolean(errors.endPeriodDate)}
                  helperText={touched.endPeriodDate && errors.endPeriodDate}
                  onClick={handleEndDatepickerOpen}
                />
              )}
            />
          </Box>
          <Box sx={styles.checkboxWrapper}>
            <Checkbox id="isPresent" onChange={handleChange} checked={Boolean(values.isPresent)} />
            <Typography variant="body1">Present</Typography>
          </Box>
        </Box>
        <Box sx={styles.row}>
          <TextEditor
            label="Description of Responsibilities"
            value={values.responsibilities}
            onChange={handleWisywigChange}
            error={touched.responsibilities && errors.responsibilities}
          />
        </Box>
        <Box sx={styles.buttons}>
          <Button
            variant="contained"
            onClick={submitForm}
            sx={styles.button}
            data-testid="talent-work-experience-form-save-btn"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={hideModal}
            sx={styles.button}
            data-testid="talent-work-experience-form-cancel-btn"
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Form;
