import {
  TalentsSliceActionsType,
  TalentsSliceStateType,
  loadTalents,
  showTalent,
  partialUpdateTalent,
  createComment,
  partialUpdateComment,
} from 'src/store/superAdmin/talentsSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { IndexParams, PartialUpdateParams } from 'src/repositories/superAdmin/TalentsRepository';
import { CreateParams, UpdateParams } from 'src/repositories/CommentsRepository';

type UseTalentsType = Pick<TalentsSliceStateType, 'talent' | 'talents' | 'meta'> &
  TalentsSliceActionsType & {
    isTalentLoadFinished: boolean;
    isTalentsLoadFinished: boolean;
    isTalentLoadFailed: boolean;
  };

const useTalents = (): UseTalentsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    talent,
    talents,
    index: { fetchStatus: talentsIndexFetchStatus },
    show: { fetchStatus: talentsShowFetchStatus },
  } = useAppSelector(state => state.superAdminTalents);

  const isTalentLoadFinished = getFetchStatus(talentsShowFetchStatus).isFinished;
  const isTalentsLoadFinished = getFetchStatus(talentsIndexFetchStatus).isFinished;
  const isTalentLoadFailed = getFetchStatus(talentsShowFetchStatus).isFailed;

  return {
    meta,
    talent,
    talents,
    isTalentLoadFailed,
    isTalentLoadFinished,
    isTalentsLoadFinished,
    loadTalents: (params?: IndexParams) => dispatch(loadTalents(params)),
    showTalent: (id: number) => dispatch(showTalent(id)),
    partialUpdateTalent: (params: PartialUpdateParams) => dispatch(partialUpdateTalent(params)),
    createComment: (params: CreateParams) => dispatch(createComment(params)),
    partialUpdateComment: (params: UpdateParams) => dispatch(partialUpdateComment(params)),
  };
};

export default useTalents;
