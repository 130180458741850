import {
  IndexParams,
  LoadUsersCountParams,
  ShowParams,
  UpdateParams,
} from 'src/repositories/superAdmin/OrganizationsRepository';
import {
  OrganizationsSliceStateType,
  OrganizationsSliceActionsType,
  showOrganization,
  loadOrganizations,
  loadOrganizationsCounts,
  loadOrganizationUsersCount,
  createOrganization,
  updateOrganization,
} from 'src/store/superAdmin/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { CreateOrganizationFormDataToSubmit } from 'src/forms/createOrganization';

type UseOrganizationsType = Pick<
  OrganizationsSliceStateType,
  'meta' | 'organization' | 'organizations' | 'organizationUsersCount' | 'departmentUsersCount' | 'organizationsCount'
> &
  OrganizationsSliceActionsType & {
    isOrganizationLoadFinished: boolean;
    isOrganizationsLoadFinished: boolean;
    isOrganizationUsersCountLoadFinished: boolean;
  };

const useOrganizations = (): UseOrganizationsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    organization,
    organizations,
    organizationUsersCount,
    departmentUsersCount,
    organizationsCount,
    index: { fetchStatus: organizationsIndexFetchStatus },
    show: { fetchStatus: organizationsShowFetchStatus },
    loadUsersCount: { fetchStatus: organizationsLoadUsersCountFetchStatus },
  } = useAppSelector(state => state.superAdminOrganizations);

  const isOrganizationLoadFinished = getFetchStatus(organizationsShowFetchStatus).isFinished;
  const isOrganizationsLoadFinished = getFetchStatus(organizationsIndexFetchStatus).isFinished;
  const isOrganizationUsersCountLoadFinished = getFetchStatus(organizationsLoadUsersCountFetchStatus).isFinished;

  return {
    meta,
    organization,
    organizations,
    organizationUsersCount,
    organizationsCount,
    departmentUsersCount,
    isOrganizationLoadFinished,
    isOrganizationsLoadFinished,
    isOrganizationUsersCountLoadFinished,
    loadOrganizations: (params?: IndexParams) => dispatch(loadOrganizations(params)),
    loadOrganizationsCounts: (params: Pick<IndexParams, 'accountType' | 'isActive'>) =>
      dispatch(loadOrganizationsCounts(params)),
    loadOrganizationUsersCount: (params: LoadUsersCountParams) => dispatch(loadOrganizationUsersCount(params)),
    showOrganization: (params: ShowParams) => dispatch(showOrganization(params)),
    createOrganization: (params: CreateOrganizationFormDataToSubmit) => dispatch(createOrganization(params)),
    updateOrganization: (params: UpdateParams) => dispatch(updateOrganization(params)),
  };
};

export default useOrganizations;
