import { DiversityType, Result, defaultOptions, passwordStrength } from 'check-password-strength';
import { last } from 'ramda';

import { PasswordRequirement } from 'src/enums/Password';
import { MIN_PASSWORD_LENGTH } from 'src/utils/constants';

type Requirement = {
  name: DiversityType;
  message: string;
};

export const PASSWORD_REQUIREMENTS = [
  {
    name: PasswordRequirement.length,
    message: `min ${MIN_PASSWORD_LENGTH} characters`,
  },
  {
    name: PasswordRequirement.uppercase,
    message: 'capital letter',
  },
  {
    name: PasswordRequirement.number,
    message: 'number',
  },
  {
    name: PasswordRequirement.symbol,
    message: 'special symbol: !"#$%&\'()*+,-./:;<=>?@[]^_`{|}~',
  },
] as Requirement[];

export const getPasswordRequirementMessage = (
  requirements?: DiversityType[],
  isPasswordLengthValid?: boolean,
): string => {
  const errorPasswordRequirements = [] as string[];
  const [passwordLengthRequirement, ...restPasswordRequirements] = PASSWORD_REQUIREMENTS;

  if (!isPasswordLengthValid) {
    errorPasswordRequirements.push(passwordLengthRequirement.message);
  }

  restPasswordRequirements.forEach(passwordRequirement => {
    const isRequirementValid = requirements?.includes(passwordRequirement.name);
    if (!isRequirementValid) {
      errorPasswordRequirements.push(passwordRequirement.message);
    }
  });

  return `Must include: ${errorPasswordRequirements.join(', ')}`;
};

export const getPasswordStrength = (value: string): Result<string> => {
  const lastOption = last(defaultOptions);

  return passwordStrength(value, [
    ...defaultOptions,
    {
      ...lastOption,
      minLength: MIN_PASSWORD_LENGTH,
    },
  ]);
};
