import { DocumentFile } from 'src/forms/talents/documents';

export const getFilePayload = (file: DocumentFile, fileFieldName: string) => {
  const isEmpty = !file.source && !file.url;
  return {
    ...(file.source && {
      [fileFieldName]: file.source,
      originalFileName: file.source.name,
    }),
    ...(isEmpty && {
      [fileFieldName]: null,
      originalFileName: null,
    }),
  };
};
