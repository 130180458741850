import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import DocumentsRepository, { UpdateParams } from 'src/repositories/talents/DocumentsRepository';
import Documents from 'src/types/resources/Documents';
import { createAsyncAction } from 'src/utils/createAsyncAction';

export type DocumentsSliceStateType = {
  documents: Documents;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type DocumentsSliceActionsType = {
  loadDocuments: () => { unwrap: () => void };
  updateDocuments: (params: UpdateParams) => { unwrap: () => void };
};

const initialState: DocumentsSliceStateType = {
  documents: {} as Documents,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadDocuments = createAsyncAction('talents/documents/load', DocumentsRepository.index);
export const updateDocuments = createAsyncAction('talents/documents/update', DocumentsRepository.partialUpdate);

const DocumentsSlice = createSlice({
  name: 'talents/documents',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDocuments.pending, state => {
      state.index.error = null;
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload.data;
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadDocuments.rejected, (state, { error }) => {
      state.index.error = error;
      state.index.fetchStatus = FetchStatus.failed;
    });
  },
});

export default DocumentsSlice.reducer;
