import { isEmpty, isNil } from 'ramda';

import Specialization, { ShortSpecialization } from 'src/types/resources/Specialization';
import TalentSpecialization from 'src/types/resources/TalentSpecialization';
import { capitalize } from 'src/utils/string';

const SpecializationPresenter = {
  ancestors: (specialization: TalentSpecialization): string => {
    const { specialization: currentSpecialization, ancestors = [] } = specialization;
    const ancestorNames = ancestors.map(ancestor => ancestor.name);
    return [...ancestorNames, currentSpecialization.name].join(' › ');
  },
  ancestorsForSpecializion: (specialization: Specialization): string => {
    const { name, ancestors = [] } = specialization;
    const ancestorNames = ancestors.map(ancestor => ancestor.name);
    return [...ancestorNames, name].join(' › ');
  },
  fullAncestorElements: (specialization: Specialization): ShortSpecialization[] => {
    const { name: specializationName, ancestors = [] } = specialization;
    return [{ id: null, name: 'Specialization' }, ...ancestors, { id: null, name: specializationName }];
  },
  expertise: (specialization: TalentSpecialization): string => {
    return !isNil(specialization.expertise) ? capitalize(specialization.expertise) : '';
  },
  roleNames: (specialization: TalentSpecialization): string => {
    return specialization.roles.map(role => role.name).join(', ');
  },
  skillNames: (specialization: TalentSpecialization): string[] => {
    return specialization.skills.map(skill => skill.name);
  },
  isSkillsPresent: (specialization: Specialization): boolean => {
    const isSkillsExist = !isNil(specialization?.skills) && !isEmpty(specialization.skills);

    return isSkillsExist;
  },
  isRolesPresent: (specialization: Specialization): boolean => {
    const isRolesExist = !isNil(specialization?.roles) && !isEmpty(specialization.roles);

    return isRolesExist;
  },
};

export default SpecializationPresenter;
