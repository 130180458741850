import { isNil } from 'ramda';

import CertificateType from 'src/types/resources/CertificateType';
import { getFilename } from 'src/utils/string';

const CertificatePresenter = {
  filename: (certificate: CertificateType): string => {
    const { file } = certificate;

    if (isNil(file)) {
      return '';
    }

    return certificate.originalFileName || getFilename(file);
  },
};

export default CertificatePresenter;
