import * as yup from 'yup';
import { EditorState } from 'draft-js';

import { MAX_APPLY_MESSAGE_TEXTAREA_LENGTH, ValidationError } from 'src/enums/Validation';
import { convertToHtmlFormat, getRawTextEditorValue, isEmptyTextEditor } from 'src/utils/textEditor';
import { TalentOpportunityApplyInfo } from 'src/features/opportunities/types/talentOpportunityApply';

export type OpportunityApplyFormData = {
  skillsAndQualifications: EditorState;
  experience: EditorState;
  applyMessage: EditorState;
};

export type OpportunityApplyFormDataToSubmit = TalentOpportunityApplyInfo;

const fieledValidation = yup
  .mixed()
  .test({
    test: (state: EditorState) => !isEmptyTextEditor(state),
    message: ValidationError.default,
  })
  .test({
    test: (state: EditorState) => getRawTextEditorValue(state).length <= MAX_APPLY_MESSAGE_TEXTAREA_LENGTH,
    message: 'You cannot use more then 2,500 characters',
  })
  .default(EditorState.createEmpty());

export const validationFields = {
  skillsAndQualifications: fieledValidation,
  experience: fieledValidation,
  applyMessage: fieledValidation,
};

export const validationSchema = yup.object(validationFields);

export const initialValues: OpportunityApplyFormData = validationSchema.getDefault();

export const attributesToSubmit = (formData: OpportunityApplyFormData): OpportunityApplyFormDataToSubmit => {
  return {
    skillsAndQualifications: convertToHtmlFormat(formData.skillsAndQualifications),
    experience: convertToHtmlFormat(formData.experience),
    applyMessage: convertToHtmlFormat(formData.applyMessage),
  };
};
