import { COLORS } from 'src/theme/palette';
import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  root: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '4px 4px 4px 8px',
    borderRadius: '4px',
    border: '1px solid transparent',
    color: COLORS.black80,
  },
  default: {
    padding: 0,
    border: 0,
    borderRadius: 0,
    background: 'transparent',
  },
  error: {
    background: COLORS.orange11,
    borderColor: COLORS.red100,
  },
  disabled: {
    background: COLORS.black02,
    borderColor: COLORS.black20,
  },
  icon: {
    flexShrink: 0,
    marginTop: '4px',
    lineHeight: 0,
  },
  icon_default: {
    color: 'currentColor',
  },
  icon_error: {
    color: COLORS.red100,
  },
  icon_disabled: {
    color: COLORS.black40,
  },
  content: {
    whiteSpace: 'pre-wrap',
  },
};

export default styles;
