import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import SavedSearch from 'src/types/resources/SavedSearch';
import { SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import SavedSearchRepository, { CreateParams, DeleteParams } from 'src/repositories/manager/SavedSearchRepository';

export type SavedSearchesSliceStateType = {
  savedSearches: SavedSearch[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type SavedSearchesSliceActionsType = {
  loadSavedSearches: () => { unwrap: () => void };
  createSavedSearch: (params: CreateParams) => { unwrap: () => Promise<SingleResponse<SavedSearch>> };
  deleteSavedSearch: (params: DeleteParams) => { unwrap: () => void };
};

const initialState: SavedSearchesSliceStateType = {
  savedSearches: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadSavedSearches = createAsyncAction('manager/savedSearches/index', SavedSearchRepository.index);
export const createSavedSearch = createAsyncAction('manager/savedSearches/create', SavedSearchRepository.create);
export const deleteSavedSearch = createAsyncAction('manager/savedSearches/delete', SavedSearchRepository.delete);

const SavedSearchesSlice = createSlice({
  name: 'manager/savedSearches',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSavedSearches.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSavedSearches.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.savedSearches = payload.results;
    });
    builder.addCase(loadSavedSearches.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(createSavedSearch.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createSavedSearch.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createSavedSearch.rejected, state => {
      state.create.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(deleteSavedSearch.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteSavedSearch.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteSavedSearch.rejected, state => {
      state.delete.fetchStatus = FetchStatus.failed;
    });
  },
});

export default SavedSearchesSlice.reducer;
