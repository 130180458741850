import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { FetchStatus } from 'src/enums/FetchStatus';
import { Meta } from 'src/types/meta';
import { ListResponse, SingleResponse } from 'src/types/utils';
import { PurchaseOrder } from 'src/types/resources/PurchaseOrders';
import PurchaseOrderRepository, {
  POListRequestParams,
  CreateParams,
  UpdateParams,
  PartialUpdateParams,
} from 'src/repositories/organizationAdmin/PurchaseOrderRepository';
import { createAsyncAction } from 'src/utils/createAsyncAction';

export type PurchaseOrdersSliceStateType = {
  meta: Meta;
  purchaseOrders: PurchaseOrder[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  partialUpdate: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type PurchaseOrdersSliceActionsType = {
  loadPurchaseOrders: (params?: POListRequestParams) => { unwrap: () => Promise<ListResponse<PurchaseOrder>> };
  createPurchaseOrders: (params: CreateParams) => { unwrap: () => Promise<SingleResponse<PurchaseOrder>> };
  updatePurchaseOrders: (params: UpdateParams) => { unwrap: () => Promise<SingleResponse<PurchaseOrder>> };
  partialUpdatePurchaseOrders: (params: PartialUpdateParams) => {
    unwrap: () => Promise<SingleResponse<PurchaseOrder>>;
  };
};

const initialState: PurchaseOrdersSliceStateType = {
  meta: {} as Meta,
  purchaseOrders: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  partialUpdate: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadPurchaseOrders = createAsyncAction('manager/purchaseOrders/index', PurchaseOrderRepository.index);
export const createPurchaseOrders = createAsyncAction('manager/purchaseOrders/create', PurchaseOrderRepository.create);
export const updatePurchaseOrders = createAsyncAction('manager/purchaseOrders/update', PurchaseOrderRepository.update);
export const partialUpdatePurchaseOrders = createAsyncAction(
  'manager/purchaseOrders/partialUpdate',
  PurchaseOrderRepository.partialUpdate,
);

const PurchaseOrdersSlice = createSlice({
  name: 'manager/purchaseOrders',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadPurchaseOrders.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadPurchaseOrders.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.meta = omit(['results'], payload);
      state.purchaseOrders = payload.results;
    });
    builder.addCase(loadPurchaseOrders.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(createPurchaseOrders.fulfilled, (state, { payload }) => {
      state.purchaseOrders = state.purchaseOrders.concat([payload.data]);
      state.update.fetchStatus = FetchStatus.fulfilled;
    });

    builder.addCase(updatePurchaseOrders.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updatePurchaseOrders.fulfilled, (state, { payload }) => {
      state.purchaseOrders = state.purchaseOrders.map(po => (po.id === payload.data.id ? payload.data : po));
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updatePurchaseOrders.rejected, state => {
      state.update.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(partialUpdatePurchaseOrders.pending, state => {
      state.partialUpdate.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(partialUpdatePurchaseOrders.fulfilled, (state, { payload }) => {
      state.purchaseOrders = state.purchaseOrders.map(po => (po.id === payload.data.id ? payload.data : po));
      state.partialUpdate.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(partialUpdatePurchaseOrders.rejected, state => {
      state.partialUpdate.fetchStatus = FetchStatus.failed;
    });
  },
});

export default PurchaseOrdersSlice.reducer;
