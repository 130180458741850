import { TypographyVariantsOptions } from '@mui/material';

import { COLORS } from 'src/theme/palette';

const typography: TypographyVariantsOptions = {
  fontFamily: 'Roboto, Arial',
  h1: {
    color: COLORS.black100,
    fontWeight: 700,
    fontSize: 40,
    lineHeight: '48px',
    letterSpacing: '-0.024em',
  },
  h2: {
    fontWeight: 700,
    fontSize: 28,
    lineHeight: '36px',
    letterSpacing: '-0.016em',
  },
  h3: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: '-0.022em',
  },
  h4: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '28px',
    letterSpacing: '-0.014em',
  },
  h5: {
    color: COLORS.black100,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.01em',
  },
  h6: {
    fontWeight: 600,
    fontSize: 11,
    lineHeight: '16px',
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '24px',
    textTransform: 'none',
  },
  body1: {
    color: COLORS.black100,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.015em',
  },
  body2: {
    color: COLORS.black60,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.015em',
  },
  caption: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.01em',
  },
};

export default typography;
