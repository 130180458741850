import { pathOr } from 'ramda';

import { AccountType } from 'src/enums/AccountType';
import { Organization, ShortOrganizationWithRate } from 'src/types/resources/Organization';
import { joinTruthy } from 'src/utils/string';

const OrganizationPresenter = {
  rate: (organization: Organization): string => {
    return `${organization.rate}%`;
  },
  address: (organization: Organization): string => {
    const { address1, address2, city, state, zipCode } = organization;
    const stateAndZipCode = `${state} ${zipCode}`;

    return joinTruthy([address1, address2, city, stateAndZipCode]);
  },
  valueByField: (organization: Organization, field: keyof Organization): string => {
    return pathOr('N/A', [field], organization);
  },
  isTrackedViaPendo: (organization: Organization | ShortOrganizationWithRate | null): boolean => {
    return organization && organization.accountType !== AccountType.test;
  },
  isTestOrganization: (accountType: Organization['accountType']) => accountType === AccountType.test,
};

export default OrganizationPresenter;
