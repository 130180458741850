import { GroupFormData } from 'src/forms/organizationGroup';
import { Group } from 'src/types/resources/Group';
import apiRoutes from 'src/routes/apiRoutes';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  page?: number;
  ordering?: string;
};

export type UpdateParams = {
  id: number;
  params: GroupFormData;
};

export type DeleteParams = {
  id: number;
};

export default {
  index(params: IndexParams = { page: 1, ordering: '' }): Promise<ListResponse<Group>> {
    const url = apiRoutes.manager.groupsPath();
    return Fetcher.get(url, params);
  },
  create(params: GroupFormData): Promise<SingleResponse<Group>> {
    const url = apiRoutes.manager.groupsPath();
    return Fetcher.post(url, params);
  },
  update({ id, params }: UpdateParams): Promise<SingleResponse<Group>> {
    const url = apiRoutes.manager.groupPath(id);
    return Fetcher.patch(url, params);
  },
  delete({ id }: DeleteParams): Promise<void> {
    const url = apiRoutes.manager.groupPath(id);
    return Fetcher.delete(url);
  },
};
