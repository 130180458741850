/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice, createAction } from '@reduxjs/toolkit';

import { createAsyncAction } from 'src/utils/createAsyncAction';
import type { RootState } from 'src/store';
import { DataTableRequestParams } from 'src/types/dataTable';

import { opportunityApi } from './api/opportunityApi';
import { OpportunityOrgSideDetails } from './types';

type SliceState = { opportunityOrgSideDetails: OpportunitiesOrgSideDetailsStore };
type OpportunitiesOrgSideDetailsStore =
  | { state: 'loading'; talentsState: 'loading' }
  | { state: 'finished'; data: OpportunityOrgSideDetails; talentsState: 'finished' };
export type FetchDetailsParams = {
  id: number;
  params: DataTableRequestParams;
};

const initialState: SliceState = { opportunityOrgSideDetails: { state: 'loading', talentsState: 'loading' } };

export const fetchOpportunityDetails = createAsyncAction('opportunities/fetchOpportunity', async (id: number) => {
  const result = await opportunityApi.fetchOpportunityDetailsWithApplicants(id);
  return result.data;
});

export const fetchOpportunityDetailsWithTalentsSorting = createAsyncAction(
  'opportunities/fetchOpportunityWithTalentsSorting',
  async (data: FetchDetailsParams) => {
    const { id, params } = data;
    const result = await opportunityApi.fetchOpportunityDetailsWithApplicants(id, params);
    return result.data;
  },
);

export const removeApplyFromOpportunity = createAction<ID>('opportunities/removeApplyFromOpportunity');

export const setApplicantsLoadingFlag = createAction<{
  loadingState: OpportunitiesOrgSideDetailsStore['talentsState'];
}>('opportunityDetails/setApplicantsLoadingFlag');

const opportunitiesSlice = createSlice({
  name: 'opportunities',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchOpportunityDetails.pending, state => {
        state.opportunityOrgSideDetails.state = 'loading';
      })
      .addCase(fetchOpportunityDetails.fulfilled, (state, action) => {
        state.opportunityOrgSideDetails = {
          state: 'finished',
          talentsState: 'finished',
          data: action.payload,
        };
      })
      .addCase(fetchOpportunityDetailsWithTalentsSorting.pending, state => {
        state.opportunityOrgSideDetails.talentsState = 'loading';
      })
      .addCase(fetchOpportunityDetailsWithTalentsSorting.fulfilled, (state, action) => {
        state.opportunityOrgSideDetails = {
          state: 'finished',
          talentsState: 'finished',
          data: action.payload,
        };
      })
      .addCase(removeApplyFromOpportunity, (state, action) => {
        if (state.opportunityOrgSideDetails.state === 'finished') {
          state.opportunityOrgSideDetails.data.talents = state.opportunityOrgSideDetails.data.talents.filter(
            talent => talent.id !== action.payload,
          );
        }
      })
      .addCase(setApplicantsLoadingFlag, (state, action) => {
        state.opportunityOrgSideDetails.talentsState = action.payload.loadingState;
      });
  },
});

export default opportunitiesSlice.reducer;

export const selectOpportunityDetails = (state: RootState): OpportunityOrgSideDetails | null => {
  if (state.opportunities.opportunityOrgSideDetails.state === 'finished') {
    return state.opportunities.opportunityOrgSideDetails.data;
  }
  return null;
};

export const selectOpportunityDetailsState = (state: RootState) => state.opportunities.opportunityOrgSideDetails.state;
export const selectOpportunityDetailsTalentsIsLoading = (state: RootState) =>
  state.opportunities.opportunityOrgSideDetails.talentsState === 'loading';
