import { makeStyles } from 'src/utils/makeStyles';

const styles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '512px',
    minHeight: '200px',
    padding: 3,
  },
  title: {
    marginBottom: 3,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    marginBottom: 4,
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    marginTop: 'auto',
  },
  button: {
    padding: '7px 48px',
  },
});

export default styles;
