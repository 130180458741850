import { FetchStatus as FetchStatusType } from 'src/types/utils';
import { FetchStatus } from 'src/enums/FetchStatus';

const getFetchStatus = (status: FetchStatus): FetchStatusType => {
  const isIdle = status === FetchStatus.idle;
  const isPending = status === FetchStatus.pending;
  const isFulfilled = status === FetchStatus.fulfilled;
  const isFailed = status === FetchStatus.failed;

  return {
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
    isFinished: isFulfilled || isFailed,
  };
};

export default getFetchStatus;
