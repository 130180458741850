/* eslint-disable @typescript-eslint/no-explicit-any */
type UseHighOrderComponentType = {
  Component: React.FC<any>;
  props?: Record<string, unknown>;
};

const useHighOrderComponent = ({ Component, props }: UseHighOrderComponentType): React.ReactElement => (
  <Component {...props} />
);

export default useHighOrderComponent;
