import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';

type MuiTextFieldType = {
  defaultProps: ComponentsProps['MuiTextField'];
  styleOverrides: ComponentsOverrides['MuiTextField'];
};

const MuiTextField: MuiTextFieldType = {
  styleOverrides: {
    root: {
      '& .MuiInput-input': {
        // prevents the whole page from being scaled when editing form fields for Safari Mobile
        // https://stackoverflow.com/questions/30102792/css-media-query-to-target-only-ios-devices
        '@supports (-webkit-touch-callout: none)': {
          fontSize: '16px',
          transform: 'scale(0.875)',
          transformOrigin: 'left center',
        },
      },
      // hides browsers autofill effects for inputs
      '& .MuiInput-input:-webkit-autofill': {
        boxShadow: '0 0 0 100px #fff inset',
      },
      '& .Mui-error': {
        '& .MuiInput-input::placeholder': {
          color: COLORS.red100,
          opacity: 1,
        },
        '& .MuiInputAdornment-root svg': {
          fill: COLORS.red100,
        },
      },
      '& .MuiInputAdornment-root svg': {
        fill: COLORS.black40,
      },
    },
  },
  defaultProps: {
    variant: 'standard',
    autoComplete: 'off',
  },
};

export default MuiTextField;
