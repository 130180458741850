import { Organization } from './Organization';

enum CommentPermissionTitle {
  orgUserPublic = 'org_user_public',
  superAdminPublic = 'super_admin_public',
  superAdminPrivate = 'super_admin_private',
}

export enum CommentPermissionId {
  orgUserPublic = 1,
  superAdminPublic = 3,
  superAdminPrivate = 4,
}

export type Comment = {
  commentText: string;
  id: ID;
  createdById: ID;
  createdBy: string;
  lastUpdatedBy: string;
  createdAt: string;
  deletedAt: string | null;
  lastUpdatedAt: string | null;
  impersonatedBy: { fullName: string; id: ID };
  isActive: boolean;
  isUpdated: boolean;
  commentPermission: {
    id: CommentPermissionId;
    title: CommentPermissionTitle;
  };
  organization: Pick<Organization, 'id' | 'logo' | 'name'>;
};

export type ProfileComment = {
  comment: Comment;
  id: ID;
  profile: { id: ID; fullName: string };
};
