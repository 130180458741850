import { ComponentsOverrides, ComponentsProps, tableCellClasses } from '@mui/material';

import typography from 'src/theme/typography';
import { COLORS } from 'src/theme/palette';

type MuiTableCellType = {
  defaultProps: ComponentsProps['MuiTableCell'];
  styleOverrides: ComponentsOverrides['MuiTableCell'];
};

const MuiTableCell: MuiTableCellType = {
  styleOverrides: {
    root: {
      padding: '17px 17px 17px 0',
      [`&.${tableCellClasses.head}`]: {
        fontWeight: typography.h6.fontWeight,
        fontSize: typography.h6.fontSize,
        lineHeight: typography.h6.lineHeight,
        letterSpacing: typography.h6.letterSpacing,
        textTransform: typography.h6.textTransform,
        paddingTop: '10px',
        paddingBottom: '10px',
        border: 'none',
        color: COLORS.black40,
      },
      [`& .${tableCellClasses.body}`]: {
        paddingTop: '19px',
        paddingBottom: '19px',
      },
      '&:last-of-type': {
        paddingRight: 0,
      },
      '&.MuiTableCell-sizeSmall': {
        padding: '4px 16px 4px 0',
      },
    },
  },
  defaultProps: {},
};

export default MuiTableCell;
