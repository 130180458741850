import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    padding: { tablet: '24px 40px 0' },
    boxSizing: 'border-box',
    width: { tablet: '520px' },
    minHeight: '300px',
  },
  row: {
    marginTop: '23px',
  },
  datePicker: {
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'row',
    flexWrap: 'nowrap',
  },
  buttons: {
    margin: '40px 0',
  },
  button: {
    marginRight: '10px',
    paddingLeft: '48px',
    paddingRight: '48px',
  },
};

export default styles;
