import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiFormControlLabelType = {
  defaultProps: ComponentsProps['MuiFormControlLabel'];
  styleOverrides: ComponentsOverrides['MuiFormControlLabel'];
};

const MuiFormControlLabel: MuiFormControlLabelType = {
  styleOverrides: {
    root: {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  defaultProps: {},
};

export default MuiFormControlLabel;
