import { useEffect, useState } from 'react';
import * as SmartySDK from 'smartystreets-javascript-sdk';

type UseAddressAutocomplete = {
  queryAutocompleteForAddresses: (
    query: string,
    hasSecondaries?: boolean,
  ) => Promise<SmartySDK.usAutocompletePro.Lookup>;
};

const useAddressAutocomplete = (): UseAddressAutocomplete => {
  const [autoCompleteClient, setAutoCompleteClient] =
    useState<SmartySDK.core.Client<SmartySDK.usAutocompletePro.Lookup, SmartySDK.usAutocompletePro.Lookup>>(null);

  const queryAutocompleteForAddresses = (query: string, hasSecondaries = false) => {
    const lookup = new SmartySDK.usAutocompletePro.Lookup(query);

    if (hasSecondaries) {
      lookup.selected = query;
    }

    return autoCompleteClient.send(lookup);
  };

  useEffect(() => {
    const SmartyCore = SmartySDK.core;
    const websiteKey = '117571923534738710';
    const smartySharedCredentials = new SmartyCore.SharedCredentials(websiteKey);
    const autoCompleteClientBuilder = new SmartyCore.ClientBuilder(smartySharedCredentials).withLicenses([
      'us-autocomplete-pro-cloud',
    ]);
    const newAutoCompleteClient = autoCompleteClientBuilder.buildUsAutocompleteProClient();

    setAutoCompleteClient(newAutoCompleteClient);
  }, []);

  return {
    queryAutocompleteForAddresses,
  };
};

export default useAddressAutocomplete;
