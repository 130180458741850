import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '512px',
    height: '600px',
    overflow: 'hidden',
  },
  header: {
    padding: '24px',
  },
  title: {
    paddingBottom: '12px',
  },
};

export default styles;
