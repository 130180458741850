import { useContext } from 'react';

import { FeatureFlagsContext } from 'src/contexts/FeatureFlagsContext';

export const useFeatureFlags = () => {
  const features = useContext(FeatureFlagsContext);

  if (features === null) {
    throw new Error('Feature flags object is equal to null.');
  }

  return features;
};
