import { FilterLabelsType } from 'src/pages/ManagerTimetracking/types';

import { TimeTrackingApproveModalType } from '../constants/TimeTrackingModalConstatants';

export const getFilterListMapForTimeTrackingModal = (
  filterLabels: FilterLabelsType,
  modalType: TimeTrackingApproveModalType,
) => {
  return {
    'Purchase Orders': filterLabels.poIds || [],
    ...(modalType === TimeTrackingApproveModalType.ALL_ENGAGEMENTS && {
      'Engagement Owners': filterLabels.engagementOwnerIds || [],
      Talents: filterLabels.talentIds || [],
      Departments: filterLabels.departmentIds || [],
    }),
  };
};
