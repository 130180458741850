import { SxStyles } from 'src/types/theme';

const styles: SxStyles = {
  entry: {
    position: 'relative',
  },
  date: {
    marginBottom: '4px',
    position: {
      mobile: 'absolute',
      tablet: 'static',
    },
    top: {
      mobile: '-48px',
      tablet: 0,
    },
    left: {
      mobile: '-24px',
      tablet: 0,
    },
  },
  textEditorWrapper: {
    margin: '14px 0',
  },
  descriptionList: {
    padding: 0,
    paddingLeft: '8px',
    listStyleType: 'disc',
  },
  descriptionListItem: {
    display: 'list-item',
    padding: '2px 0',
  },
};

export default styles;
