import {
  ExternalLinksSliceActionsType,
  ExternalLinksSliceStateType,
  loadExternalLinks,
  createExternalLink,
  updateExternalLink,
  deleteExternalLink,
} from 'src/store/talents/externalLinksSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { CreateParams, DeleteParams, UpdateParams } from 'src/repositories/talents/ExternalLinksRepository';

type UseExternalLinksType = Pick<ExternalLinksSliceStateType, 'externalLinks'> &
  ExternalLinksSliceActionsType & {
    isExternalLinksLoadFinished: boolean;
  };

const useExternalLinks = (): UseExternalLinksType => {
  const dispatch = useAppDispatch();
  const {
    externalLinks,
    index: { fetchStatus: externalLinksIndexFetchStatus },
  } = useAppSelector(state => state.talentsExternalLinks);

  const isExternalLinksLoadFinished = getFetchStatus(externalLinksIndexFetchStatus).isFinished;

  return {
    externalLinks,
    isExternalLinksLoadFinished,
    loadExternalLinks: () => dispatch(loadExternalLinks()),
    createExternalLink: (args: CreateParams) => dispatch(createExternalLink(args)),
    updateExternalLink: (args: UpdateParams) => dispatch(updateExternalLink(args)),
    deleteExternalLink: (args: DeleteParams) => dispatch(deleteExternalLink(args)),
  };
};

export default useExternalLinks;
