import { Box, Typography, Checkbox, FormControlLabel, Button } from '@mui/material';
import { useFormik, FormikConfig } from 'formik';

import TextFieldWithCounter from 'src/components/TextFieldWithCounter';
import { MAX_DEFAULT_TEXTAREA_LENGTH } from 'src/enums/Validation';
import dictionary from 'src/dictionary/Engagement/Terms';
import { useModals, useUsers, useSnackbar } from 'src/hooks';
import { EngagementTerm } from 'src/types/resources/EngagementTerms';
import { useCreateEngagementTermMutation, useEditEngagementTermMutation } from 'src/services/EngagementTerms';

import formSchema, { getFormDefaultValues } from './formSchema';
import styles from './styles';

export type EngagementTermsFormModalProps = {
  mode: 'create' | 'edit';
  term?: EngagementTerm;
};
type EngagementTermsFormValues = Pick<EngagementTerm, 'isAcknowledgementStatementAccepted' | 'text'>;

const EngagementTermsFormModal: React.FunctionComponent<EngagementTermsFormModalProps> = props => {
  const { mode, term: termFromProps } = props;
  const { hideModal } = useModals();
  const { currentUser } = useUsers();
  const [createEngagementTerm, { isLoading: isCreateLoading }] = useCreateEngagementTermMutation();
  const [editEngagementTerm, { isLoading: isPatchLoading }] = useEditEngagementTermMutation();
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const isLoading = isCreateLoading || isPatchLoading;
  const isCreateMode = mode === 'create';

  const handleSubmit: FormikConfig<EngagementTermsFormValues>['onSubmit'] = async formValues => {
    try {
      if (isCreateMode) {
        await createEngagementTerm({
          ...formValues,
          organizationId: currentUser.organization.id,
        }).unwrap();
      } else {
        await editEngagementTerm({
          termId: termFromProps.id,
          data: { ...formValues, organizationId: termFromProps.organizationId },
        }).unwrap();
      }

      enqueueSuccessSnackbar();
      hideModal();
    } catch (error) {
      enqueueErrorSnackbar();
    }
  };

  const { values, errors, submitForm, touched, handleBlur, handleChange } = useFormik<EngagementTermsFormValues>({
    validationSchema: formSchema,
    initialValues: getFormDefaultValues(mode, termFromProps),
    onSubmit: handleSubmit,
  });

  return (
    <Box sx={styles.modalWrapper}>
      <Typography variant="h3" mb={2}>
        {isCreateMode ? dictionary.formAddTerms : dictionary.formEditTerms}
      </Typography>
      <Typography variant="body1" mb={1}>
        {dictionary.termsFormModalDescription}
      </Typography>
      <TextFieldWithCounter
        label={dictionary.title}
        maxValue={MAX_DEFAULT_TEXTAREA_LENGTH}
        rootSx={{ mb: 1 }}
        name="text"
        type="textarea"
        multiline
        rows={6}
        variant="standard"
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={dictionary.termsTextFieldPlaceholder}
        value={values.text}
        error={Boolean(errors.text) && touched.text}
        helperText={(touched.text && errors.text) || ' '}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="isAcknowledgementStatementAccepted"
            checked={values.isAcknowledgementStatementAccepted}
            onChange={handleChange}
          />
        }
        label={dictionary.formAcknowledgement}
        sx={{ mb: 2 }}
      />
      <Box sx={styles.buttons}>
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => {
            submitForm();
          }}
          disabled={!values.isAcknowledgementStatementAccepted || isLoading}
        >
          {isCreateMode ? 'Add' : 'Update'}
        </Button>
        <Button variant="outlined" sx={styles.button} onClick={hideModal}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default EngagementTermsFormModal;
