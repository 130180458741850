import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { isEmpty } from 'ramda';

import useModals from 'src/hooks/useModals';
import useSubgroups from 'src/hooks/useSubgroups';
import Box from 'src/components/Box';
import AvatarInfo from 'src/components/AvatarInfo';
import SearchForm from 'src/components/SearchForm';
import SelectableList from 'src/components/SelectableList';

import styles from './styles';

type SubgroupSelectProps = {
  groupId: number;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => Promise<unknown>;
  onSubmit: () => void;
};

const SubgroupSelect: React.FC<SubgroupSelectProps> = props => {
  const { groupId, setFieldValue, onSubmit } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(true);
  const { hideModal } = useModals();
  const { subgroups, isSubgroupsLoadFinished, loadSubgroups } = useSubgroups();

  const currentSubgroups = subgroups[groupId]?.subgroups;

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleSelectSubgroups = () => {
    onSubmit();
  };

  useEffect(() => {
    loadSubgroups({ groupId, name: searchValue });
  }, [searchValue]);

  useEffect(() => {
    if (isSubgroupsLoadFinished) {
      setFieldValue('groupId', groupId);
      setFieldValue('subgroups', currentSubgroups);
    }
  }, [isSubgroupsLoadFinished]);

  return (
    <>
      <Box sx={styles.body}>
        <SearchForm
          sx={styles.search}
          placeholder="Search by subgroup name"
          onSearch={handleSearch}
          searchEntityType="subgroup"
        />
        <SelectableList
          title="Select Subgroup"
          isLoading={!isSubgroupsLoadFinished}
          data={currentSubgroups}
          onItemSelect={selectedSubgroups => {
            setFieldValue('subgroups', selectedSubgroups);
            setIsSelectDisabled(isEmpty(selectedSubgroups));
          }}
          renderListItem={listItemProps => <AvatarInfo notBold hideAvatar primaryText={listItemProps.name} />}
        />
      </Box>
      <Box sx={styles.footer}>
        <Button variant="contained" sx={styles.button} onClick={handleSelectSubgroups} disabled={isSelectDisabled}>
          Select Manager
        </Button>
        <Button variant="outlined" onClick={hideModal} sx={styles.button}>
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default SubgroupSelect;
