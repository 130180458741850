import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Typography, TextField, Button, Alert, InputAdornment, FormHelperText } from '@mui/material';

import useOrganizations from 'src/hooks/superAdmin/useOrganizations';
import useModals from 'src/hooks/useModals';
import useSnackbar from 'src/hooks/useSnackbar';
import {
  attributesToSubmit,
  initialValues,
  ChangeRateFormData,
  buildValidationSchema,
  DEFAULT_TIER_ONE_RATE_VALUE,
} from 'src/forms/changeRate';
import { Organization } from 'src/types/resources/Organization';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import Box from 'src/components/Box';
import { useFeatureFlags } from 'src/hooks';

import styles from './styles';

type ChangeRateFormProps = {
  organization: Organization;
};

const ChangeRateForm: React.FC<ChangeRateFormProps> = props => {
  const { organization } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { showOrganization, updateOrganization } = useOrganizations();
  const { hideModal } = useModals();
  const { featureShowOrgStartRate } = useFeatureFlags();
  const { enqueueSuccessSnackbar } = useSnackbar();

  const handleSubmit = async (formData: ChangeRateFormData, { setErrors }: FormikHelpers<ChangeRateFormData>) => {
    const params = attributesToSubmit(formData, organization.name);
    setFormError(null);
    try {
      await updateOrganization({
        id: organization.id,
        params: { ...params, accountType: organization.accountType },
      }).unwrap();
      enqueueSuccessSnackbar('Rate was changed');
      showOrganization({ id: organization.id });
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(error.response.data.errors.nonFieldErrors);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit: handleFormikSubmit,
  } = useFormik<ChangeRateFormData>({
    initialValues: initialValues(organization, featureShowOrgStartRate),
    validationSchema: buildValidationSchema(featureShowOrgStartRate),
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const tierRateInputShown = featureShowOrgStartRate && (!values.rate || values.rate === 0);

  const onRateChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    const floatRate = value === '' ? undefined : parseFloat(value);
    let valueToSet = 0;

    if (floatRate === 0) {
      valueToSet = 0;
    } else {
      valueToSet = floatRate;
    }

    setFieldValue('rate', valueToSet);

    if (!valueToSet && !tierRateInputShown) {
      setFieldValue('tierRate', DEFAULT_TIER_ONE_RATE_VALUE);
    }
  };

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.wrapper}>
          <Typography variant="h3" sx={styles.title}>
            Change Rate
          </Typography>

          <Box sx={styles.row}>
            <Typography variant="button">
              Rate appears in the Talent Engagements and Organization Timekeeping.
            </Typography>
            <Typography variant="body1">
              It defines the Per Hour Rate the Organization will be paying for the Talent. 20% is default. You can
              change the percentage below. For Tier Pricing Clients enter a 0%. The Tier 1 rate will be defaulted to $26
              but can be overwritten.
            </Typography>
          </Box>

          <Box sx={styles.row}>
            <Box sx={styles.rateWrapper}>
              <Typography variant="body1">Talent Hourly Rate +</Typography>
              <TextField
                id="rate"
                type="number"
                onBlur={handleBlur}
                onChange={onRateChange}
                value={values.rate}
                error={touched.rate && Boolean(errors.rate)}
                InputProps={{
                  sx: styles.rateInput,
                  value: values.rate,
                  endAdornment: (
                    <InputAdornment sx={styles.rateInputAdornmentEnd} position="end">
                      %
                    </InputAdornment>
                  ),
                }}
                sx={styles.rateField}
              />
              {tierRateInputShown && (
                <>
                  <Typography variant="body1">Tier 1 Rate</Typography>
                  <TextField
                    id="tierRate"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.tierRate}
                    error={touched.tierRate && Boolean(errors.tierRate)}
                    InputProps={{
                      sx: styles.rateInput,
                      value: values.tierRate,
                      startAdornment: (
                        <InputAdornment sx={styles.rateInputAdornmentStart} position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                    sx={styles.rateField}
                  />
                </>
              )}
            </Box>
            {touched.rate && errors.rate && <FormHelperText error>{errors.rate}</FormHelperText>}
          </Box>

          <Box sx={styles.footer}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
              Change
            </Button>
            <Button variant="outlined" onClick={hideModal} sx={styles.button}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ChangeRateForm;
