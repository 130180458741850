import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { CreateSubgroupFormDataToSubmit } from 'src/forms/createSubgroup';
import { FetchStatus } from 'src/enums/FetchStatus';
import SubgroupsRepository, {
  IndexParams,
  ShowParams,
  UpdateParams,
  DeleteParams,
} from 'src/repositories/organizationAdmin/SubgroupsRepository';
import { SingleResponse } from 'src/types/utils';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import { Subgroup } from 'src/types/resources/Subgroup';
import { Meta } from 'src/types/meta';

export type SubgroupSlice = { meta: Meta; subgroups: Subgroup[] };

export type SubgroupsSliceStateType = {
  subgroups: Record<number, SubgroupSlice>;
  subgroup: Subgroup;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type SubgroupsSliceActionsType = {
  loadSubgroups: (params?: IndexParams) => Promise<unknown>;
  showSubgroup: (params: ShowParams) => { unwrap: () => Promise<SingleResponse<Subgroup>> };
  createSubgroup: (params: CreateSubgroupFormDataToSubmit) => { unwrap: () => Promise<SingleResponse<Subgroup>> };
  updateSubgroup: (params: UpdateParams) => { unwrap: () => Promise<SingleResponse<Subgroup>> };
  deleteSubgroup: (params: DeleteParams) => { unwrap: () => Promise<void> };
  resetSubgroups: () => void;
};

const initialState: SubgroupsSliceStateType = {
  subgroups: {},
  subgroup: {} as Subgroup,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadSubgroups = createAsyncAction('subgroups/index', SubgroupsRepository.index);
export const showSubgroup = createAsyncAction('subgroups/show', SubgroupsRepository.show);
export const createSubgroup = createAsyncAction('subgroups/create', SubgroupsRepository.create);
export const updateSubgroup = createAsyncAction('subgroups/update', SubgroupsRepository.update);
export const deleteSubgroup = createAsyncAction('subgroups/delete', SubgroupsRepository.delete);

const subgroupsSlice = createSlice({
  name: 'subgroups',
  initialState,
  reducers: {
    resetSubgroups(state) {
      state.subgroups = [];
    },
    resetSubgroup(state) {
      state.subgroup = initialState.subgroup;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadSubgroups.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSubgroups.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const groupId = meta.arg?.groupId;

      state.subgroups[groupId] = {
        meta: omit(['results'], payload),
        subgroups: payload.results,
      };
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadSubgroups.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(showSubgroup.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showSubgroup.fulfilled, (state, { payload }) => {
      state.subgroup = payload.data;
      state.show.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(showSubgroup.rejected, state => {
      state.show.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(deleteSubgroup.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteSubgroup.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteSubgroup.rejected, state => {
      state.delete.fetchStatus = FetchStatus.failed;
    });
  },
});

const {
  actions: { resetSubgroups, resetSubgroup },
} = subgroupsSlice;

export { resetSubgroups, resetSubgroup };

export default subgroupsSlice.reducer;
