import { CertificateFormData } from 'src/forms/talents/certificate';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';
import CertificateType, { CertificateResources } from 'src/types/resources/CertificateType';
import { SingleResponse } from 'src/types/utils';

export type CreateParams = CertificateFormData;
export type UpdateParams = {
  id: number;
  params: CertificateFormData;
};
export type DeleteParams = {
  id: number;
};

export default {
  index(): Promise<CertificateResources> {
    const url = apiRoutes.talent.certificatesPath();
    return Fetcher.get(url);
  },
  create(params: CreateParams): Promise<SingleResponse<CertificateType>> {
    const url = apiRoutes.talent.certificatesPath();
    return Fetcher.postFormData(url, params);
  },
  update({ id, params }: UpdateParams): Promise<SingleResponse<CertificateType>> {
    const url = apiRoutes.talent.certificatePath(id);
    return Fetcher.putFormData(url, params);
  },
  delete({ id }: DeleteParams): Promise<void> {
    const url = apiRoutes.talent.certificatePath(id);
    return Fetcher.delete(url);
  },
};
