import { lazy, FC, LazyExoticComponent } from 'react';

const lazyWithRetry = (componentImport: () => Promise<{ default: FC<unknown> }>): LazyExoticComponent<FC<unknown>> =>
  lazy(async () => {
    const isPageAlreadyForceRefreshed = JSON.parse(
      window.localStorage.getItem('isPageAlreadyForceRefreshed') || 'false',
    );

    try {
      const component = await componentImport();
      window.localStorage.setItem('isPageAlreadyForceRefreshed', 'false');
      return component;
    } catch (error) {
      if (!isPageAlreadyForceRefreshed) {
        window.localStorage.setItem('isPageAlreadyForceRefreshed', 'true');
        window.location.reload();
        return { default: {} as FC<unknown> };
      }
      throw error;
    }
  });

export default lazyWithRetry;
