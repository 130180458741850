import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ExternalLinksRepository, {
  CreateParams,
  DeleteParams,
  UpdateParams,
} from 'src/repositories/talents/ExternalLinksRepository';
import { FetchStatus } from 'src/enums/FetchStatus';
import { ExternalLink } from 'src/types/resources/ExternalLink';

export type ExternalLinksSliceStateType = {
  externalLinks: ExternalLink[];
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type ExternalLinksSliceActionsType = {
  loadExternalLinks: () => { unwrap: () => void };
  createExternalLink: (params: CreateParams) => { unwrap: () => void };
  updateExternalLink: (params: UpdateParams) => { unwrap: () => void };
  deleteExternalLink: (params: DeleteParams) => { unwrap: () => void };
};

const initialState: ExternalLinksSliceStateType = {
  externalLinks: [],
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadExternalLinks = createAsyncThunk('talents/externalLinks/index', ExternalLinksRepository.index);
export const createExternalLink = createAsyncThunk(
  'talents/externalLinks/create',
  async (args: CreateParams, { rejectWithValue }) => {
    try {
      return await ExternalLinksRepository.create(args);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const updateExternalLink = createAsyncThunk(
  'talents/externalLinks/update',
  async (args: UpdateParams, { rejectWithValue }) => {
    try {
      return await ExternalLinksRepository.update(args);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const deleteExternalLink = createAsyncThunk(
  'talents/externalLinks/delete',
  async (args: DeleteParams, { rejectWithValue }) => {
    try {
      return await ExternalLinksRepository.delete(args);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const overviewSlice = createSlice({
  name: 'talents/externalLinks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadExternalLinks.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadExternalLinks.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.externalLinks = payload.results;
    });
    builder.addCase(loadExternalLinks.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(updateExternalLink.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateExternalLink.fulfilled, state => {
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateExternalLink.rejected, (state, { error }) => {
      state.update.fetchStatus = FetchStatus.failed;
      state.update.error = error;
    });
  },
});

export default overviewSlice.reducer;
