import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Typography, TextField, Button, Alert, Checkbox, FormControlLabel } from '@mui/material';

import {
  attributesToSubmit,
  initialValues,
  TalentNotFoundFeedbackFormData,
  validationSchema,
} from 'src/forms/talentNotFoundFeedback';
import { SearchResourcesFormData } from 'src/forms/SearchResources';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import useSnackbar from 'src/hooks/useSnackbar';
import useModals from 'src/hooks/useModals';
import Box from 'src/components/Box';
import { CreateParams } from 'src/repositories/manager/TalentNotFoundFeedbackRepository';
import SpecializationPresenter from 'src/presenters/SpecializationPresenter';

import styles from './styles';

type TalentNotFoundFeedbackFormProps = {
  params: SearchResourcesFormData;
  onCreateTalentNotFoundFeedback: (params: CreateParams) => void;
};

const TalentNotFoundFeedbackForm: React.FC<TalentNotFoundFeedbackFormProps> = props => {
  const { params, onCreateTalentNotFoundFeedback } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { hideModal } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();

  const { specialization, parentSpecialization } = params.specializations;
  const specializations = SpecializationPresenter.ancestorsForSpecializion(parentSpecialization || specialization);

  const handleSubmit = async (
    formData: TalentNotFoundFeedbackFormData,
    { setErrors }: FormikHelpers<TalentNotFoundFeedbackFormData>,
  ) => {
    const paramsToSubmit = attributesToSubmit(formData, specializations);
    setFormError(null);
    try {
      await onCreateTalentNotFoundFeedback(paramsToSubmit);
      enqueueSuccessSnackbar('Feedback has been sent');
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(error.response.data.errors.nonFieldErrors);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit: handleFormikSubmit,
  } = useFormik<TalentNotFoundFeedbackFormData>({
    initialValues: initialValues(),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.wrapper}>
          <Typography variant="h3" sx={styles.title}>
            I still haven’t found what I’m looking for
          </Typography>

          <Box sx={styles.row}>
            <Typography variant="h5" sx={styles.title}>
              We can help! Let us know the skill sets you are looking for in a Talent and we will help you.
            </Typography>
          </Box>

          <Box sx={styles.row}>
            <TextField
              id="message"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              error={touched.message && Boolean(errors.message)}
              helperText={touched.message && errors.message}
              placeholder="Tell us what you are looking for. Include specific skill sets, roles, certifications
               and anything else that would be helpful in finding the perfect fit for your company."
              multiline
              rows={6}
            />
          </Box>
          {specializations && (
            <FormControlLabel
              control={
                <Checkbox name="isIncludeLastSearch" onChange={handleChange} checked={values.isIncludeLastSearch} />
              }
              label="Include last Search:"
            />
          )}
          <Box sx={styles.row}>
            <Typography sx={styles.title}>{values.isIncludeLastSearch && specializations}</Typography>
          </Box>

          <Box sx={styles.footer}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
              Send
            </Button>
            <Button variant="outlined" onClick={hideModal} sx={styles.button}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default TalentNotFoundFeedbackForm;
