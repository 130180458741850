/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import { isEmpty, isNil } from 'ramda';
import { parseISO } from 'date-fns';

import Documents from 'src/types/resources/Documents';
import { ValidationError } from 'src/enums/Validation';
import { VALID_FILE_SIZE, getValidFileTypeTest } from 'src/forms/customRules';
import { getDateByYear } from 'src/utils/date';
import { getFilename } from 'src/utils/string';
import { REQUIRED_DOCUMENT_VALID_FILE_TYPES } from 'src/utils/constants';
import { RequiredDocument } from 'src/types/resources/RequiredDocument';

export type DocumentFile = {
  source: File | null;
  name?: string | null;
  url: string | null;
};

type DocumentFormData = {
  beginDate: Date | null;
  expirationDate: Date | null;
  file: DocumentFile | null;
};

type DocumentFormDataToSubmit = {
  beginDate?: string;
  expirationDate?: string;
  file?: DocumentFile;
  originalFileName: RequiredDocument['originalFileName'];
};

export type DocumentsFormData = {
  multipanelDrugScreen: DocumentFormData;
  backgroundCheck: DocumentFormData;
  fluShot: DocumentFormData;
  mmrVax: DocumentFormData;
  tuberculosisVax: DocumentFormData;
  covid19Vax: DocumentFormData;
};

export type DocumentsFormDataToSubmit = {
  multipanelDrugScreen?: DocumentFormDataToSubmit;
  backgroundCheck?: DocumentFormDataToSubmit;
  fluShot?: DocumentFormDataToSubmit;
  mmrVax?: DocumentFormDataToSubmit;
  tuberculosisVax?: DocumentFormDataToSubmit;
  covid19Vax?: DocumentFormDataToSubmit;
};

const createFieldSchema = () => {
  return yup.object({
    file: yup
      .object()
      .nullable()
      .shape({
        source: yup
          .mixed()
          .nullable()
          .test(getValidFileTypeTest(REQUIRED_DOCUMENT_VALID_FILE_TYPES))
          .test(VALID_FILE_SIZE)
          .default(null),
      }),
    beginDate: yup.date().nullable().typeError(ValidationError.date).default(null),
    expirationDate: yup
      .date()
      .notRequired()
      .nullable()
      .typeError(ValidationError.date)
      .min(yup.ref('beginDate'), ValidationError.dateEndBeforeDateStart)
      .default(null),
  });
};

const createFieldSchemaWithRequiredDates = () => {
  return yup.object({
    file: yup
      .object()
      .nullable()
      .shape({
        source: yup
          .mixed()
          .nullable()
          .test(getValidFileTypeTest(REQUIRED_DOCUMENT_VALID_FILE_TYPES))
          .test(VALID_FILE_SIZE)
          .default(null),
      }),
    beginDate: yup
      .date()
      .nullable()
      .default(null)
      .when('file', {
        is: (file: File | null) => Boolean(file),
        then: yup.date().nullable().typeError(ValidationError.date).required(ValidationError.default),
      }),
    expirationDate: yup
      .date()
      .nullable()
      .default(null)
      .min(yup.ref('beginDate'), ValidationError.dateEndBeforeDateStart)
      .when('file', {
        is: (file: File | null) => Boolean(file),
        then: yup.date().nullable().typeError(ValidationError.date).required(ValidationError.default),
      }),
  });
};

const createDocumentFile = (fileURL: string, originalFileName: string): DocumentFile => ({
  source: null,
  name: originalFileName || getFilename(fileURL),
  url: fileURL,
});

export const validationFields = {
  backgroundCheck: createFieldSchemaWithRequiredDates(),
  multipanelDrugScreen: createFieldSchemaWithRequiredDates(),
  fluShot: createFieldSchema(),
  mmrVax: createFieldSchema(),
  tuberculosisVax: createFieldSchema(),
  covid19Vax: createFieldSchema(),
};

export const validationSchema = yup.object(validationFields);

export const initialTouchedValues = {
  multipanelDrugScreen: { beginDate: true, expirationDate: true },
  backgroundCheck: { beginDate: true, expirationDate: true },
};

export const initialValues = (documents?: Documents): DocumentsFormData => {
  return Object.entries(documents).reduce((acc, [documentName, document]) => {
    acc[documentName] = {
      beginDate: document.beginDate && parseISO(document.beginDate),
      expirationDate: document.expirationDate && parseISO(document.expirationDate),
      file: document.file && createDocumentFile(document.file, document.originalFileName),
    };
    return acc;
  }, {} as DocumentsFormData);
};

const convertValuesToSubmit = (values: DocumentsFormData): DocumentsFormDataToSubmit => {
  return Object.entries(values).reduce((acc, [key, document]) => {
    acc[key] = {
      beginDate: document.beginDate && getDateByYear(document.beginDate.toISOString()),
      expirationDate: document.expirationDate && getDateByYear(document.expirationDate.toISOString()),
      file: document.file,
    };
    return acc;
  }, {} as DocumentsFormDataToSubmit);
};

const getValuesDiff = (
  values: DocumentsFormDataToSubmit,
  prevValues: DocumentsFormDataToSubmit,
): DocumentsFormDataToSubmit => {
  return Object.entries(values).reduce((acc, [documentName, document]) => {
    const innerDiffValues = Object.entries(document).reduce((innerAcc, [fieldName, fieldValue]) => {
      const isDocumentFile = typeof fieldValue !== 'string' && !isNil(fieldValue);

      const hasChanges = isDocumentFile
        ? !isNil(fieldValue) && fieldValue?.url !== prevValues[documentName][fieldName]?.url
        : fieldValue !== prevValues[documentName][fieldName];

      if (hasChanges) {
        innerAcc[fieldName] = isDocumentFile ? fieldValue?.source || 'null' : fieldValue || 'null';
      }

      return innerAcc;
    }, {});

    if (!isEmpty(innerDiffValues)) {
      acc[documentName] = innerDiffValues;
    }
    return acc;
  }, {});
};

export const attributesToSubmit = (
  values: DocumentsFormData,
  prevValues: DocumentsFormData,
): DocumentsFormDataToSubmit => {
  const convertedValues = convertValuesToSubmit(values);
  const convertedPrevValues = convertValuesToSubmit(prevValues);

  return getValuesDiff(convertedValues, convertedPrevValues);
};
