import { Typography, Theme, List, ListItem } from '@mui/material';
import { SxProps } from '@mui/system';

import AvailabilityPresenter from 'src/presenters/Availability';
import Availability from 'src/types/resources/Availability';
import clsx from 'src/utils/clsx';
import { convertFromHtmlFormat } from 'src/utils/textEditor';
import useScreen from 'src/hooks/useScreen';
import Box from 'src/components/Box';
import TextEditor from 'src/components/TextEditor';
import { useFeatureFlags } from 'src/hooks';

import styles from './styles';

type EntryProps = {
  availability: Availability;
  sx?: SxProps<Theme>;
};

const Entry: React.FC<EntryProps> = props => {
  const { availability, sx = {} } = props;
  const { lessThanTablet } = useScreen();
  const { featureUseNewTalentProfileRate } = useFeatureFlags();

  const availableDate = AvailabilityPresenter.availableDate(availability);
  const description = AvailabilityPresenter.description(availability, !featureUseNewTalentProfileRate);
  const { note } = availability;
  const convertedNote = availability.note && convertFromHtmlFormat(note);
  const boxStyles = clsx(styles.entry, [[sx, true]]);

  const renderDescription = () => {
    if (!lessThanTablet)
      return (
        <Typography variant="body1" sx={styles.description}>
          {description}
        </Typography>
      );
    return (
      <List sx={styles.descriptionList}>
        <ListItem sx={styles.descriptionListItem}>{AvailabilityPresenter.employmentTypes(availability)}</ListItem>
        <ListItem sx={styles.descriptionListItem}>{AvailabilityPresenter.hoursPerWeek(availability)}</ListItem>
        {!featureUseNewTalentProfileRate && (
          <ListItem sx={styles.descriptionListItem}>{AvailabilityPresenter.hourlyWage(availability)}</ListItem>
        )}
      </List>
    );
  };

  return (
    <Box sx={boxStyles}>
      <Typography variant="button" component="p" sx={styles.date}>
        {availableDate}
      </Typography>
      {renderDescription()}
      {convertedNote && (
        <Box sx={styles.textEditorWrapper}>
          <TextEditor value={convertedNote} readOnly />
        </Box>
      )}
    </Box>
  );
};

export default Entry;
