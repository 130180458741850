import * as yup from 'yup';

import { ValidationError } from 'src/enums/Validation';
import { Subgroup } from 'src/types/resources/Subgroup';
import { OrganizationUser } from 'src/types/resources/Organization';

export type ShareGroupFormData = {
  groupId: number;
  subgroups: Subgroup[];
  managers: OrganizationUser[];
};

export type ShareGroupFormDataToSubmit = {
  groupId: number;
  subgroupIds: number[];
  managerIds: number[];
};

export const validationFields = {
  groupId: yup.number().nullable().required(ValidationError.default).default(null),
  subgroups: yup.array().default([]),
  managers: yup.array().default([]),
};

export const validationSchema = yup.object(validationFields);

export const initialValues: ShareGroupFormData = validationSchema.getDefault();

export const attributesToSubmit = (values: ShareGroupFormData): ShareGroupFormDataToSubmit => {
  const { groupId, subgroups, managers } = values;
  const subgroupIds = subgroups.map(subgroup => subgroup.id);
  const managerIds = managers.map(manager => manager.id);

  return {
    groupId,
    subgroupIds,
    managerIds,
  };
};
