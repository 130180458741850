import { ReactNode, useEffect, useState } from 'react';
import { Checkbox, List, ListItem, ListItemButton, Typography } from '@mui/material';
import { isEmpty, isNil } from 'ramda';

import Loader from 'src/components/Loader';
import clsx from 'src/utils/clsx';

import styles from './styles';

type SelectableListProps<T> = {
  title?: string;
  isLoading?: boolean;
  data: T[];
  isAllSelected?: boolean;
  onItemSelect: (resources: T[]) => void;
  renderListItem: (resource: T) => ReactNode | null;
};

const SelectableList = <T extends { avatarPicture?: string; id: number }>(
  props: SelectableListProps<T>,
): JSX.Element => {
  const { title = '', data, isAllSelected = false, isLoading, onItemSelect, renderListItem } = props;
  const [checkedListItems, setCheckedListItems] = useState<T[]>([]);

  const handleToggleListItem = (resource: T) => () => {
    const currentIndex = checkedListItems.findIndex(listItem => listItem.id === resource.id);
    const newCheckedListItems = [...checkedListItems];

    if (currentIndex === -1) {
      newCheckedListItems.push(resource);
    } else {
      newCheckedListItems.splice(currentIndex, 1);
    }

    setCheckedListItems(newCheckedListItems);
    onItemSelect(newCheckedListItems);
  };

  useEffect(() => {
    if (!isNil(data) && isAllSelected) {
      setCheckedListItems(data);
    }
  }, [data]);

  if (isLoading || isNil(data)) {
    return <Loader />;
  }

  if (isEmpty(data)) {
    return (
      <Typography sx={styles.noResults} variant="caption">
        No results found...
      </Typography>
    );
  }

  return (
    <>
      {title && <Typography sx={styles.listTitle}>{title}</Typography>}
      <List sx={styles.list} component="nav">
        {data.map(resource => {
          const selectedItem = !!checkedListItems.find(item => item.id === resource.id);

          return (
            <ListItem
              sx={clsx(styles.listItem, [[styles.selectedListItem, selectedItem]])}
              key={resource.id}
              onClick={handleToggleListItem(resource)}
              secondaryAction={<Checkbox edge="start" checked={selectedItem} tabIndex={-1} disableRipple />}
            >
              <ListItemButton sx={styles.button}>{renderListItem(resource)}</ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default SelectableList;
