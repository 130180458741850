export enum PasswordStrength {
  tooWeak = 'Too weak',
  weak = 'Weak',
  medium = 'Medium',
  strong = 'Strong',
}

export enum PasswordRequirement {
  length = 'length',
  lowercase = 'lowercase',
  uppercase = 'uppercase',
  symbol = 'symbol',
  number = 'number',
}
