import { Typography } from '@mui/material';

import clsx from 'src/utils/clsx';
import { formatCurrency } from 'src/utils/formatCurrency';
import { formatTime } from 'src/utils/date';
import Alert from 'src/components/TimeTracking/components/Alert';

import styles from './styles';

type AlertPayPeriodAmountChangedProps = {
  originalHours: number;
  newHours: number;
  hourlyFee: string;
};

const AlertPayPeriodAmountChanged: React.FC<AlertPayPeriodAmountChangedProps> = props => {
  const { originalHours, newHours, hourlyFee } = props;

  const timeDiff = newHours - originalHours;
  const isOriginalEqualNewHours = timeDiff === 0;

  const hours = Math.abs(timeDiff);
  const amount = hours * parseFloat(hourlyFee);

  const alertVariant = timeDiff >= 0 ? 'disabled' : 'error';
  const isErrorVariant = alertVariant === 'error';
  const isDisabledVariant = alertVariant === 'disabled';

  const formattedHours = formatTime(hours.toString(), { padStartHours: 1 });
  const formattedAmount = formatCurrency(amount);

  let AlertContent = null;

  if (isErrorVariant) {
    AlertContent = (
      <Typography>
        You have reduced the number of hours:{' '}
        <Typography sx={styles.boldText} component="span">
          &minus;{formattedHours}
        </Typography>{' '}
        hours.{`\n`}
        <Typography sx={clsx(styles.boldText, [[styles.errorText, true]])} component="span">
          {formattedAmount}
        </Typography>{' '}
        will be deducted from your next Pay Period amount.
      </Typography>
    );
  }

  if (isDisabledVariant) {
    AlertContent = (
      <Typography>
        You have increased the number of hours:{' '}
        <Typography sx={styles.boldText} component="span">
          {formattedHours}
        </Typography>{' '}
        hours.{`\n`}
        <Typography sx={styles.boldText} component="span">
          {formattedAmount}
        </Typography>{' '}
        will be added to your next Pay Period amount.
      </Typography>
    );
  }

  return AlertContent && !isOriginalEqualNewHours && <Alert variant={alertVariant}>{AlertContent}</Alert>;
};

export default AlertPayPeriodAmountChanged;
