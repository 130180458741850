import { ChangeEvent } from 'react';
import { Stack, Typography } from '@mui/material';

import { DOCUMENT_LIBRARY_VALID_FILE_TYPES } from 'src/utils/constants';
import FileField from 'src/components/FileField';
import Box from 'src/components/Box';

import styles from './styles';

type SelectFileProps = {
  id: string;
  description: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  disabled?: boolean;
};

const SelectFile: React.FC<SelectFileProps> = props => {
  const { id, description = '', onChange, disabled = false } = props;

  return (
    <Stack direction="row" alignItems="center">
      <Box sx={styles.fileFieldWrapper}>
        <FileField
          id={id}
          accept={DOCUMENT_LIBRARY_VALID_FILE_TYPES.join(',')}
          onChange={onChange}
          disabled={disabled}
        />
      </Box>
      {description && (
        <Typography variant="caption" sx={styles.uploadFileCaption}>
          {description}
        </Typography>
      )}
    </Stack>
  );
};

export default SelectFile;
