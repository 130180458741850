import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';

export const createAsyncAction = <Returned, ThunkArg = Record<string, unknown> | void>(
  type: string,
  thunk: AsyncThunkPayloadCreator<Returned, ThunkArg>,
): AsyncThunk<Returned, ThunkArg, Record<string, unknown>> => {
  return createAsyncThunk<Returned, ThunkArg, Record<string, unknown>>(type, async (arg, thunkAPI) => {
    try {
      return await thunk(arg, thunkAPI);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};
