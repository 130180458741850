import * as yup from 'yup';
import { isNil } from 'ramda';

import { ValidationError } from 'src/enums/Validation';
import { Subgroup } from 'src/types/resources/Subgroup';

export type CreateSubgroupFormData = {
  name: string;
};

export type CreateSubgroupFormDataToSubmit = {
  name: string;
  groupId: number;
};

export const validationFields = {
  name: yup.string().required(ValidationError.default).default(''),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (subgroup: Subgroup): CreateSubgroupFormData => {
  if (isNil(subgroup)) {
    return validationSchema.getDefault();
  }

  return {
    name: subgroup.name,
  };
};
export const attributesToSubmit = (values: CreateSubgroupFormData, groupId: number): CreateSubgroupFormDataToSubmit => {
  return { ...values, groupId };
};
