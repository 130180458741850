/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const apiRoutes = {
  signUpPath: (): string => '/api/v1/public/talents/',
  confirmEmailPath: (): string => '/api/v1/public/talent/activations/',
  citiesList: () => '/api/v1/public/cities/',
  statesList: () => '/api/v1/public/states/',
  signInPath: (): string => '/api/v1/sessions/',
  currentUserPath: (): string => '/api/v1/user/',
  resetPasswordPath: (): string => '/api/v1/password/reset/',
  validatePasswordPath: (): string => '/api/v1/password/reset/validate/',
  confirmPasswordPath: (): string => '/api/v1/password/reset/confirm/',
  inviteTalentPath: (): string => '/api/v1/talent_invites/',
  inviteUserPath: (): string => '/api/v1/invites/',
  specializationPath: (id: number): string => `/api/v1/specializations/${id}/`,
  specializationsPath: (): string => '/api/v1/specializations/',
  impersonatePath: (): string => `/api/v1/user/impersonate/`,
  tasksPath: (id: string): string => `/api/v1/tasks/${id}/`,
  faqFeedbackPath: (): string => `/api/v1/faq-feedback/`,
  payDate: () => '/api/v1/pay-date/',
  featureFlags: () => '/api/v1/environment-variables/',
  resendVerificationEmail: () => '/api/v1/public/talents/resend_registration/',
  settings: () => '/api/v1/settings/',
  organization: {
    opportunity: (opportunityId?: ID) =>
      opportunityId === undefined ? '/api/v1/org-side/opportunity/' : `/api/v1/org-side/opportunity/${opportunityId}/`,
    opportunities: () => '/api/v1/org-side/opportunities/',
    opportunitySpecialization: (opportunitySpecializationId?: ID): string =>
      opportunitySpecializationId === undefined
        ? '/api/v1/org-side/opportunity-specialization/'
        : `/api/v1/org-side/opportunity-specialization/${opportunitySpecializationId}/`,
    opportunityApply: (applyId?: ID) =>
      applyId === undefined
        ? `/api/v1/org-side/opportunity-talent/`
        : `/api/v1/org-side/opportunity-talent/${applyId}/`,
    orgComment: (commentId?: ID) =>
      commentId === undefined ? `/api/v1/org-side/comment/` : `/api/v1/org-side/comment/${commentId}/`,
    linkedToProfileComment: () => `/api/v1/org-side/profile_comment/`,
    engagementTerms: (termId?: ID) =>
      termId === undefined ? `/api/v1/org-side/engagement-terms/` : `/api/v1/org-side/engagement-terms/${termId}/`,
  },
  talent: {
    agreement: () => '/api/v1/talent/agreement/',
    engagementAgreement: (engagementAgreementId?: ID) =>
      engagementAgreementId === undefined
        ? '/api/v1/talent/engagement-agreement/'
        : `/api/v1/talent/engagement-agreement/${engagementAgreementId}/`,
    workExperiencesPath: (): string => '/api/v1/talent/work-experiences/',
    workExperiencePath: (id: number): string => `/api/v1/talent/work-experiences/${id}/`,
    externalLinkPath: (id: number): string => `/api/v1/talent/external-links/${id}/`,
    externalLinksPath: (): string => `/api/v1/talent/external-links/`,
    availabilitiesPath: (): string => '/api/v1/talent/availabilities/',
    availabilityPath: (id: number): string => `/api/v1/talent/availabilities/${id}/`,
    certificatePath: (id: number): string => `/api/v1/talent/certificates/${id}/`,
    certificatesPath: (): string => '/api/v1/talent/certificates/',
    specializationPath: (id: number): string => `/api/v1/talent/specializations/${id}/`,
    specializationsPath: (): string => '/api/v1/talent/specializations/',
    documentsPath: (): string => `/api/v1/talent/document_set/`,
    engagementsPath: (): string => `/api/v1/talent/engagements/`,
    engagementsShortPath: (): string => `/api/v1/talent/engagements-dashboard/`,
    engagementPath: (id: number): string => `/api/v1/talent/engagements/${id}/`,
    acceptEngagementPath: (id: number): string => `/api/v1/talent/engagements/${id}/accept/`,
    declineEngagementPath: (id: number): string => `/api/v1/talent/engagements/${id}/decline/`,
    timeSheetPath: (id: number): string => `/api/v1/talent/time-sheets/${id}/`,
    timeSheetsPath: (): string => `/api/v1/talent/time-sheets/`,
    timeSheetsTotalPath: (): string => `/api/v1/talent/time-sheets/total/`,
    timeSheetsAdjustmentsPath: (): string => `/api/v1/talent/time-sheets/adjustments/`,
    chatsPath: (): string => `/api/v1/talent/chats/`,
    filledDocumentsPath: (): string => `/api/v1/talent/filled-documents/`,
    filledDocumentPath: (id: number): string => `/api/v1/talent/filled-documents/${id}/`,
    organizationsPath: (): string => '/api/v1/talent/organizations/',
    organizationNames: (): string => '/api/v1/talent/organization_names/',
    knowledgeCenterFAQPath: (): string => '/api/v1/talent/knowledge-center-faq/',
    knowledgeCenterVideosPath: (): string => '/api/v1/talent/knowledge-center-videos/',
    showScreen: (userId: ID) => `/api/v1/talent/show-screen/${userId}/`,
    payPeriodsTotals: () => '/api/v1/talent/pay-periods/total/',
    payPeriodsExtendedTotals: () => '/api/v1/talent/pay-periods/extended-total/',
    opportunities: (opportunityId?: ID) =>
      opportunityId === undefined ? '/api/v1/talent/opportunities/' : `/api/v1/talent/opportunities/${opportunityId}/`,
    opportunityApply: (opportunityApplyId?: ID): string =>
      opportunityApplyId === undefined
        ? '/api/v1/talent/opportunity-apply/'
        : `/api/v1/talent/opportunity-apply/${opportunityApplyId}/`,
    profileVideo: (videoId?: ID) =>
      videoId === undefined ? '/api/v1/talent/profile-video/' : `/api/v1/talent/profile-video/${videoId}/`,
    dashboardActionItems: () => '/api/v1/talent/action-items/',
    dashboard: () => '/api/v1/talent/dashboard/',
  },
  manager: {
    shareGroupPath: (): string => '/api/v1/manager/share-groups/',
    groupsPath: (): string => '/api/v1/manager/groups/',
    groupPath: (id: number): string => `/api/v1/manager/groups/${id}/`,
    subgroupsPath: (): string => `/api/v1/manager/subgroups/`,
    subgroupPath: (id: number): string => `/api/v1/manager/subgroups/${id}/`,
    talentsPath: (): string => `/api/v1/manager/talents/`,
    talentPath: (id: number): string => `/api/v1/manager/talents/${id}/`,
    talentDocumentSetPath: (id: number): string => `/api/v1/manager/talents/${id}/document_set/`,
    talentMatchedCountPath: (): string => `/api/v1/manager/talents/matched_count/`,
    chatsPath: (): string => `/api/v1/manager/chats/`,
    groupMembersPath: (): string => `/api/v1/manager/group-members/`,
    groupMemberPath: (id: number): string => `/api/v1/manager/group-members/${id}/`,
    timeSheetsPath: (): string => `/api/v1/manager/time-sheets/`,
    checkPendingTimeSheets: (): string => `/api/v1/manager/time-sheets/exists/`,
    timeSheetPath: (id: number): string => `/api/v1/manager/time-sheets/${id}/`,
    timeSheetsAdjustmentsPath: (): string => `/api/v1/manager/time-sheets/adjustments/`,
    timeSheetsAdjustmentPath: (id: number): string => `/api/v1/manager/time-sheets/adjustments/${id}/`,
    timeSheetsTotalPath: (): string => '/api/v1/manager/time-sheets/total/',
    timeSheetsAdjustmentsTotalPath: (): string => '/api/v1/manager/time-sheets/adjustments/total/',
    requiredDocumentsPath: (): string => `/api/v1/manager/required-documents/`,
    requiredDocumentPath: (id: ID): string => `/api/v1/manager/required-documents/${id}/`,
    requiredDocumentDeactivatePath: (id: ID): string => `/api/v1/manager/required-documents/${id}/deactivate/`,
    organizationUsersPath: (): string => '/api/v1/manager/users/',
    savedSearchesPath: (): string => '/api/v1/manager/saved-searches/',
    savedSearchPath: (id: number): string => `/api/v1/manager/saved-searches/${id}/`,
    dashboardPath: (): string => '/api/v1/manager/dashboard/',
    talentNotFoundFeedbackPath: (): string => '/api/v1/manager/feedbacks/talent-not-found/',
    engagementsPath: (): string => `/api/v1/manager/engagements/`,
    engagementPath: (id: ID): string => `/api/v1/manager/engagements/${id}/`,
    engagementTalentsPath: (): string => '/api/v1/manager/engagements/talents/',
    engagementOwnersPath: (): string => '/api/v1/manager/engagements/owners/',
    engagementDepartmentsPath: (): string => '/api/v1/manager/engagements/departments/',
    engagementNameValidation: (): string => '/api/v1/manager/engagements/name-validation/',
    engagementAllPOsPath: (): string => '/api/v1/manager/engagements/purchase-order-numbers/',
    engagementPOPath: (parentLookupEngagementId: ID, id: ID): string =>
      `/api/v1/manager/engagements/${parentLookupEngagementId}/purchase-order-numbers/${id}/`,
    deleteEngagementAgreements: (engagementId: ID): string =>
      `/api/v1/manager/engagements/${engagementId}/delete_agreements/`,
    poNumbersPath: (): string => '/api/v1/manager/po-numbers/',
    poNumberPath: (id: number): string => `/api/v1/manager/po-numbers/${id}/`,
    poDescriptionsPath: (): string => '/api/v1/manager/po-descriptions/',
    poDescriptionPath: (id: number): string => `/api/v1/manager/po-descriptions/${id}/`,
    knowledgeCenterFAQPath: (): string => '/api/v1/manager/knowledge-center-faq/',
    knowledgeCenterVideosPath: (): string => '/api/v1/manager/knowledge-center-videos/',
  },
  organizationAdmin: {
    organizationsPath: (): string => '/api/v1/org-admin/organizations/',
    organizationPath: (id: number): string => `/api/v1/org-admin/organizations/${id}/`,
    organizationUsersPath: (): string => '/api/v1/org-admin/users/',
    organizationUserPath: (id: number): string => `/api/v1/org-admin/users/${id}/`,
    organizationUserReassignPath: (id: number): string => `/api/v1/org-admin/users/${id}/reassign/`,
    organizationUsersCountPath: (id: number): string => `/api/v1/org-admin/organizations/${id}/users_count/`,
    timeSheetPath: (id: number): string => `/api/v1/org-admin/time-sheets/${id}/`,
    timeSheetsPath: (): string => `/api/v1/org-admin/time-sheets/`,
    checkPendingTimeSheets: (): string => `/api/v1/org-admin/time-sheets/exists/`,
    timeSheetsAdjustmentsPath: (): string => `/api/v1/org-admin/time-sheets/adjustments/`,
    timeSheetsAdjustmentPath: (id: number): string => `/api/v1/org-admin/time-sheets/adjustments/${id}/`,
    timeSheetsTotalPath: (): string => '/api/v1/org-admin/time-sheets/total/',
    timeSheetsAdjustmentsTotalPath: (): string => '/api/v1/org-admin/time-sheets/adjustments/total/',
    departmentsPath: (): string => '/api/v1/org-admin/departments/',
    departmentPath: (id: number): string => `/api/v1/org-admin/departments/${id}/`,
    savedSearchesPath: (): string => '/api/v1/org-admin/saved-searches/',
    savedSearchPath: (id: number): string => `/api/v1/org-admin/saved-searches/${id}/`,
    dashboardPath: (): string => '/api/v1/org-admin/dashboard/',
    engagementPOsPath: (engagementPOId?: ID) =>
      engagementPOId === undefined
        ? '/api/v1/org-admin/engagement_purchase_order/'
        : `/api/v1/org-admin/engagement_purchase_order/${engagementPOId}/`,
    organizationEngagementsCountPath: (id: ID): string => `/api/v1/org-admin/organizations/${id}/engagements_count/`,
    purchaseOrderPath: (poId?: ID): string =>
      poId === undefined ? '/api/v1/org-admin/purchase_order/' : `/api/v1/org-admin/purchase_order/${poId}/`,
  },
  superAdmin: {
    organizationsPath: (): string => '/api/v1/superadmin/organizations/',
    organizationsCounts: (): string => '/api/v1/superadmin/organizations/count/',
    organizationPath: (id: number): string => `/api/v1/superadmin/organizations/${id}/`,
    organizationUsersCountPath: (id: number): string => `/api/v1/superadmin/organizations/${id}/users_count/`,
    organizationUsersPath: (): string => `/api/v1/superadmin/users/`,
    timeTrackingPath: (): string => `api/v1/superadmin/time-tracking/`,
    organizationUserPath: (id: number): string => `/api/v1/superadmin/users/${id}/`,
    organizationUserReassignPath: (id: number): string => `/api/v1/superadmin/users/${id}/reassign/`,
    talentsPath: (): string => `/api/v1/superadmin/talents/`,
    talentPath: (id: number): string => `/api/v1/superadmin/talents/${id}/`,
    chatsPath: (): string => `/api/v1/superadmin/chats/`,
    departmentsPath: (): string => '/api/v1/superadmin/departments/',
    departmentPath: (id: number): string => `/api/v1/superadmin/departments/${id}/`,
    requiredDocumentsPath: (): string => `/api/v1/superadmin/required-documents/`,
    requiredDocumentPath: (id: number): string => `/api/v1/superadmin/required-documents/${id}/`,
    accountingExportPath: (): string => `/api/v1/superadmin/qb_export/`,
    runTask: (): string => `/api/v1/task-apply/`,
  },
  orgSide: {
    actionItems: () => '/api/v1/org-side/action-items/',
    opportunityAiMatching: () => '/api/v1/org-side/opportunity-ai/',
    opportunityAiMatchRating: (matchId?: ID) =>
      matchId === undefined
        ? '/api/v1/org-side/opportunity-ai-talent-rating/'
        : `/api/v1/org-side/opportunity-ai-talent-rating/${matchId}/`,
    opportunityTalent: (applyId?: ID) =>
      applyId === undefined
        ? '/api/v1/org-side/opportunity-talent/'
        : `/api/v1/org-side/opportunity-talent/${applyId}/`,
    dashboard: () => '/api/v1/org-side/dashboard/',
    calcEngagementSavings: () => '/api/v1/org-side/engagements/savings/',
  },
};

export default apiRoutes;
