import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { COLORS } from 'src/theme/palette';
import typography from 'src/theme/typography';

type MuiFormLabelType = {
  defaultProps: ComponentsProps['MuiFormLabel'];
  styleOverrides: ComponentsOverrides['MuiFormLabel'];
};

const MuiFormLabel: MuiFormLabelType = {
  styleOverrides: {
    root: {
      color: COLORS.black100,
      fontWeight: typography.button.fontWeight,
      fontSize: typography.button.fontSize,
      lineHeight: typography.button.lineHeight,
      textTransform: typography.button.textTransform,
      display: 'block',
      padding: '0',
      marginBottom: '4px',
      transform: 'none',
    },
    asterisk: {
      color: COLORS.red100,
    },
  },
  defaultProps: {},
};

export default MuiFormLabel;
