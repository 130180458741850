import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchStatus } from 'src/enums/FetchStatus';
import { createAsyncAction } from 'src/utils/createAsyncAction';
import TalentsRepository, { IndexParams, PatchParams } from 'src/repositories/manager/TalentsRepository';
import CommentsRepository, { CreateParams, UpdateParams } from 'src/repositories/CommentsRepository';
import TalentProfile from 'src/types/resources/TalentProfile';
import { TalentSearch } from 'src/types/resources/TalentSearch';
import { ProfileComment } from 'src/types/resources/Comment';

import { createCommentThunkAction, updateCommentReducer } from './common';

import type { RootState } from '.';

export type TalentsSliceStateType = {
  talents: TalentSearch[];
  talent: TalentProfile;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  showTalentWelcomeScreen: boolean;
};

export type TalentsSliceActionsType = {
  loadTalents: (params?: IndexParams) => { unwrap: () => void };
  showTalent: (id: number) => { unwrap: () => void };
  partialUpdateTalent: (params: PatchParams) => { unwrap: () => void };
  resetTalent: () => void;
  createComment: (params: CreateParams) => { unwrap: () => void };
  partialUpdateComment: (params: UpdateParams) => { unwrap: () => void };
};

const initialState: TalentsSliceStateType = {
  talents: [],
  talent: {} as TalentProfile,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  showTalentWelcomeScreen: false,
};

export const loadTalents = createAsyncAction('manager/talents/index', TalentsRepository.index);
export const showTalent = createAsyncAction('manager/talents/show', TalentsRepository.show);
export const partialUpdateTalent = createAsyncAction('manager/talents/partialUpdate', TalentsRepository.partialUpdate);
export const addComment = createAction<ProfileComment>('manager/talents/comments/add');
export const createComment = createCommentThunkAction('manager/talents/comments/create', addComment);
export const partialUpdateComment = createAsyncAction(
  'manager/talents/comments/edit',
  CommentsRepository.partialUpdate,
);

const talentsSlice = createSlice({
  name: 'manager/talents',
  initialState,
  reducers: {
    resetTalent(state) {
      state.show.fetchStatus = FetchStatus.idle;
      state.talent = initialState.talent;
    },
    setShowTalentWelcomeScreen(state, action: PayloadAction<{ showTalentWelcomeScreen: boolean }>) {
      state.showTalentWelcomeScreen = action.payload.showTalentWelcomeScreen;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTalents.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTalents.fulfilled, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.fulfilled;
      state.talents = payload.results;
    });
    builder.addCase(loadTalents.rejected, state => {
      state.index.fetchStatus = FetchStatus.failed;
    });

    builder.addCase(showTalent.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(showTalent.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.talent = payload.data;
    });
    builder.addCase(showTalent.rejected, state => {
      state.show.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(addComment, (state, { payload }) => {
      state.talent.comments = [payload, ...state.talent.comments];
    });
    builder.addCase(partialUpdateComment.fulfilled, (state, { payload }) => {
      state.talent.comments = updateCommentReducer(state.talent.comments, payload);
    });
  },
});

export const selectShowTalentWelcomeScreenStatus = (
  state: RootState,
): TalentsSliceStateType['showTalentWelcomeScreen'] => state.talents.showTalentWelcomeScreen;

const {
  actions: { resetTalent, setShowTalentWelcomeScreen },
} = talentsSlice;

export { resetTalent, setShowTalentWelcomeScreen };

export default talentsSlice.reducer;
