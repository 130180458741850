import apiRoutes from 'src/routes/apiRoutes';
import { AdjustedTimeSheet, NestedTimeSheet } from 'src/types/resources/TimeSheet';
import { ListResponse, SingleResponse } from 'src/types/utils';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = {
  payPeriodAfter?: string;
  payPeriodBefore?: string;
  state?: string;
  talentIds?: string;
  ordering?: string;
  page?: number;
};

export type PartialUpdateParams = {
  id: number;
  params: Partial<Pick<NestedTimeSheet, 'state' | 'clientComment'>>;
};

export default {
  index(params: IndexParams): Promise<ListResponse<AdjustedTimeSheet>> {
    const url = apiRoutes.manager.timeSheetsAdjustmentsPath();
    return Fetcher.get(url, params);
  },
  partialUpdate({ id, params }: PartialUpdateParams): Promise<SingleResponse<AdjustedTimeSheet>> {
    const url = apiRoutes.manager.timeSheetsAdjustmentPath(id);
    return Fetcher.patch(url, params);
  },
};
