export function initializePendoInstance(options: pendo.InitOptions) {
  window.pendo.initialize(options);
  // eslint-disable-next-line no-console
  console.info('Pendo Agent initialized. Events will be written.');
}

export function updatePendoInstance(options: pendo.InitOptions) {
  const pendoMessage = window.pendo.startSendingEvents();
  window.pendo.updateOptions(options);
  // eslint-disable-next-line no-console
  console.info(`${pendoMessage.slice(0, 20)} and updated with a new user${pendoMessage.slice(20)}`);
}

export function clearPendoInstance() {
  const pendoMessage = window.pendo.stopSendingEvents();
  window.pendo.clearSession();
  // eslint-disable-next-line no-console
  console.info(pendoMessage);
}
