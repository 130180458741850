import { EditorState } from 'draft-js';
import { isNil } from 'ramda';
import * as yup from 'yup';

import { GetMaxLengthTest, WYSIWYG_NOT_EMPTY } from 'src/forms/customRules';
import { ValidationError } from 'src/enums/Validation';
import { convertFromHtmlFormat, convertToHtmlFormat } from 'src/utils/textEditor';

export type OverviewFormData = {
  summary: EditorState;
};

export type OverviewFormDataToSubmit = Omit<OverviewFormData, 'summary'> & {
  summary: string;
};

const emptyEditorState = EditorState.createEmpty();

export const validationFields = {
  summary: yup
    .mixed()
    .test(WYSIWYG_NOT_EMPTY)
    .test(GetMaxLengthTest(2500))
    .default(emptyEditorState)
    .required(ValidationError.default),
};

export const validationSchema = yup.object(validationFields);

export const initialValues = (summary?: string): OverviewFormData => {
  if (isNil(summary)) {
    return validationSchema.getDefault();
  }

  const convertedSummary = convertFromHtmlFormat(summary);

  return {
    summary: convertedSummary,
  };
};

export const attributesToSubmit = (values: OverviewFormData): OverviewFormDataToSubmit => {
  const { summary } = values;

  const convertedSummary = convertToHtmlFormat(summary);

  return {
    summary: convertedSummary,
  };
};
