import { ComponentsOverrides, ComponentsProps } from '@mui/material';

type MuiTableType = {
  defaultProps: ComponentsProps['MuiTable'];
  styleOverrides: ComponentsOverrides['MuiTable'];
};

const MuiTable: MuiTableType = {
  styleOverrides: {
    root: {},
  },
  defaultProps: {},
};

export default MuiTable;
