import { isNil } from 'ramda';

import { ExpertiseType } from 'src/enums/Specialization';
import Specialization from 'src/types/resources/Specialization';
import TalentSpecialization from 'src/types/resources/TalentSpecialization';

export type SpecializationFormData = Pick<TalentSpecialization, 'expertise'> & {
  roles: number[];
  skills: number[];
};

export type SpecializationFormDataToSubmit = {
  expertise: ExpertiseType;
  roles: number[];
  skills: number[];
  specializationId: number;
};

export const createRootSpecialization = (children: Specialization[]): Specialization => {
  const shortChildren = children.map(({ id, name }) => ({ id, name }));

  return {
    id: null,
    name: '',
    numchild: children.length,
    roles: [],
    skills: [],
    ancestors: [],
    children: shortChildren,
  };
};

export const initialValues = (
  commonSpecialization?: Specialization,
  talentSpecialization?: TalentSpecialization,
  expertiseLevel?: ExpertiseType,
): SpecializationFormData => {
  if (isNil(talentSpecialization) || commonSpecialization?.id !== talentSpecialization?.specialization?.id) {
    return {
      expertise: expertiseLevel,
      roles: [],
      skills: [],
    };
  }
  return {
    expertise: expertiseLevel,
    roles: talentSpecialization.roles.map(role => role.id),
    skills: talentSpecialization.skills.map(skill => skill.id),
  };
};

export const attributesToSubmit = (
  values: SpecializationFormData,
  specializationId: number,
): SpecializationFormDataToSubmit => {
  const { expertise, ...restValues } = values;
  return {
    ...restValues,
    expertise: isNil(expertise) ? null : expertise,
    specializationId,
  };
};
